import React, {Component} from 'react';
import AsyncSelect from 'react-select/async';
import stockService from '../../services/StockService';
import cloneDeep from 'lodash/cloneDeep';
import {STOCK_GROUP_HOSPITAL_RANGE_CODES} from "../../store/AppConstants";

const discontinuedProdCode = ['10378', '10379'];

export default class SearchStock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultProdCode: props.defaultProdCode,
            prodCode: props.selected,
            stocks: [],
            selected: props.selected ? {label: props.selected, value: props.selected} : null,
            isLoadingOptions: false,
            filter: {
                bmFlag: this.props.filter.bmFlag,
                discontinued: this.props.filter.discontinued,
                soldOut: this.props.filter.soldOut,
                stockGroups: this.props.filter.stockGroups
            }
        }
        ;

        this.loadOptions = this.loadOptions.bind(this);
        this.fetchOptions = this.fetchOptions.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        //defaultOptions on load
        this.fetchOptions(this.state.defaultProdCode || this.state.prodCode);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.defaultProdCode !== nextProps.defaultProdCode) {
            if (nextProps.defaultProdCode) {
                this.setState({defaultProdCode: nextProps.defaultProdCode}, () => {
                    this.fetchOptions(nextProps.defaultProdCode);
                })
            }
        } else {
            if (this.state.prodCode !== nextProps.selected) {
                this.setState({
                    prodCode: nextProps.prodCode,
                    selected: nextProps.selected
                        ? {label: nextProps.selected, value: nextProps.selected}
                        : null
                }, () => {
                    //load externally selectedItem
                    this.fetchOptions(nextProps.prodCode);
                })
            } else {
                let {filter} = this.state;

                if (filter.bmFlag !== nextProps.filter.bmFlag
                    || filter.discontinued !== nextProps.filter.discontinued
                    || filter.soldOut !== nextProps.filter.soldOut
                    || JSON.stringify(filter.stockGroups) !== JSON.stringify(nextProps.filter.stockGroups)
                ) {
                    filter = {
                        bmFlag: nextProps.filter.bmFlag,
                        discontinued: nextProps.filter.discontinued,
                        soldOut: nextProps.filter.soldOut,
                        stockGroups: nextProps.filter.stockGroups
                    };
                    this.setState({filter})
                }
            }
        }
    }


    handleSelect(selectedStock, event) {
        let selected = selectedStock;
        let prodCode = selectedStock ? selectedStock.prodCode : "";

        switch (event) {
            case "selected":
                this.setState({selected, prodCode});
                break;
            case "onChange":
            case "default":
            default:
                this.setState({selected, prodCode});
                if (prodCode) {
                    stockService.getStock(prodCode).then(response => {
                        let selected = response.data;
                        selected.value = selected.prodCode;
                        selected.label = selected.prodCode + (selected.description ? " | " + selected.description : "");
                        this.setState({selected: selected});
                        this.props.handleSelection(response.data);
                    }).catch(error => {
                        console.error(error)
                    })
                } else {
                    this.props.handleSelection(null);
                }
        }

        if (!prodCode) {
            this.fetchOptions("");
        }

    };


    fetchOptions(inputValue, callback) {
        let stocks = [];
        let request = {
            soldOut: this.state.filter.soldOut,
            discontinued: this.state.filter.discontinued,
            description: inputValue,
            bm_Flag: this.state.filter.bmFlag,
            stockGroups: this.props.filter.stockGroups ? this.props.filter.stockGroups : []
        };
        stockService.getStocks(request).then(response => {
            let data = response.data;
            let {filter} = this.state;
            if (filter.stockGroups === STOCK_GROUP_HOSPITAL_RANGE_CODES) {
                //remove all prodcode that soldout for newOrder
                data = data.filter(x => !x.soldOut && !discontinuedProdCode.includes(x.prodCode));
                //contain prodcode for saved orders
                if (!this.props.newItem && this.state.prodCode) {
                    let selectedProdcode = (response.data || []).find(x => x.prodCode === this.state.prodCode);
                    if (selectedProdcode) {
                        data.push(selectedProdcode);
                    }
                }
            }
            for (let i in data) {
                let temp = {
                    label: data[i].prodCode + (data[i].description ? " | " + data[i].description : ""),
                    value: data[i].prodCode,
                    id: data[i].prodCode,
                    prodCode: data[i].prodCode,
                    description: data[i].description
                };
                stocks.push(cloneDeep(temp));
                if (this.props.defaultProdCode && ( (!this.state.selected) || (this.props.defaultProdCode !== this.state.selected.value))) {
                    if (data[i].prodCode === this.props.defaultProdCode) {
                        this.handleSelect(temp, "default");
                    }
                }
                if (this.state.prodCode) {
                    if (data[i].prodCode === this.state.prodCode) {
                        this.handleSelect(temp, "selected");
                    }
                }
            }
            this.setState({stocks});
            if (callback) {
                return callback(stocks)
            } else {
                return stocks;
            }
        }).catch(error => {
            console.error(error)
        });
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.stocks);
            }
        }
    };


    render() {
        let {selected, isLoadingOptions, stocks} = this.state;
        let {disabled} = this.props;
        return (
            <AsyncSelect
                isDisabled={disabled}
                cacheOptions
                isLoading={isLoadingOptions}
                isClearable={true}
                defaultOptions={stocks}
                loadOptions={this.loadOptions}
                onChange={(selection) => this.handleSelect(selection, "onChange")}
                value={selected}
                placeholder={"Search..."}
            />
        )
    }
}