import React, { Component } from 'react';
import UserService from "../../services/UserService";
import { Link, Redirect } from "react-router-dom";
import { Col, FormText, Row, Card, CardHeader, CardBody, Container, Alert, UncontrolledAlert } from "reactstrap";


export default class ForgotPasswordPage extends Component {
    constructor(props) {
        super(props);

        const params = new URLSearchParams(this.props.location.search);
        const token = params.get('token');

        this.state = {
            token: token,
            newPassword: "",
            rePassword: "",
            submitted: "",
            loading: "",
            error: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.userService = new UserService();


    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { newPassword, rePassword, token } = this.state;

        if (!(newPassword && rePassword && token)) {
            return
        }
        if (newPassword !== rePassword) {
            alert("New Password and Re-Password Doesn't Match");
            return
        }

        this.setState({ loading: true });
        this.userService.updatePasswordByReset(newPassword, rePassword, token).then(
            user => {
                const { from } = this.props.location.state || { from: { pathname: "/" } };
                this.props.history.push(from);
                window.location.reload();
            },
            error => {
                this.setState({ "error": error.Message, loading: false });
            }
        )
    }

    render() {
        if (UserService.isLoggedIn())
            return <Redirect to={{ pathname: '/' }} />;
        const { newPassword, rePassword, submitted, loading, error, token } = this.state;
        return (
            <div style={{ marginTop: 20 }}>
                <Row>
                    <Col xl={4} lg={4} md={3} sm={0} xs={0} />
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className="text-center o-visible">
                            <img
                                style={{ maxWidth: 200 }}
                                alt=""
                                src="https://winonline.blob.core.windows.net/winonline/company_logo/SMARTHP.png" />
                        </div>
                        <hr />
                        <Card style={{ borderRadius: ".50rem" }} className={"ds-9"}>
                            <CardBody>
                                <Container>

                                    <h5>Reset Password</h5>
                                    <form name="form" className="theme-form m-t-15" onSubmit={this.handleSubmit}
                                        autoComplete="off">
                                        <div
                                            className={'form-group' + (submitted && !newPassword && !rePassword ? ' has-error' : '')}>
                                            <label className="pt-0" htmlFor="token">Security Code</label>
                                            <input type="text" className="form-control" name="token" value={token}
                                                onChange={this.handleChange}
                                                placeholder={"Enter received security code"}
                                                autoComplete="off" />
                                            {submitted && !token &&
                                                <FormText className="help-block">Enter Security Code that you have received
                                                    in email</FormText>
                                            }
                                        </div>

                                        <div
                                            className={'form-group' + (submitted && !newPassword && !rePassword ? ' has-error' : '')}>
                                            <label className="pt-0" htmlFor="newPassword">New Password</label>
                                            <input type="password"
                                                name="newPassword"
                                                className="form-control"
                                                value={newPassword}
                                                onChange={this.handleChange}
                                                placeholder={"Enter your new password"}
                                                autoComplete="off" />
                                        </div>
                                        <div
                                            className={'form-group' + (submitted && !newPassword && !rePassword ? ' has-error' : '')}>

                                            <label className="pt-0" htmlFor="rePassword">Confirm Password</label>
                                            <input type="password"
                                                name="rePassword"
                                                className="form-control"
                                                value={rePassword}
                                                onChange={this.handleChange}
                                                placeholder={"Confirm your new password"}
                                                autoComplete="off" />
                                            {submitted && !newPassword && !rePassword &&
                                                <FormText className="help-block">Enter your password twice as we can verify
                                                    you typed it in correctly </FormText>
                                            }
                                        </div>

                                        <div className="form-group">
                                            <Row>
                                                <Col xl={5} lg={5} md={7} sm={12} xs={12} className="mt-1 text-left">
                                                    <Link to="/"
                                                        onClick={() => this.setState({ stage: 'LOGIN' })}>Back to
                                                        Login?</Link>
                                                </Col>
                                                <Col xl={7} lg={7} md={7} sm={12} xs={12} className="text-right">
                                                    <button className="btn btn-primary btn-block" disabled={loading}>
                                                        Update Password
                                                        {loading &&
                                                            <img
                                                                alt="loading"
                                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                        }
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </form>
                                </Container>
                            </CardBody>
                        </Card>
                        {
                            error &&
                            <UncontrolledAlert color={"danger"}>{error}</UncontrolledAlert>
                        }
                        <Alert className={"mt-3"}>
                            <p>Enter your new account password.</p>
                            <hr />
                            <p>Once confirmed, you'll be logged into your account and your new password
                                will be active.</p>
                        </Alert>
                    </Col>
                </Row>
            </div>
        )
    }
}