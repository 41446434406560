export const MOBILE_SCREEN_WIDTH_IN_PIXEL = 800;
export const EXTERNAL_USER_ROLES = ["ExternalAdmin", "ExternalUser", "ExternalSalesRep"];
export const BUNNINGS_STORE_ACCOUNT_IDs = ["01000", "01005", "00010", "09009", "09111", "01800"];

export const SALES_ORDER_CREATED_FROM_KEYWAY_PRODUCT_BUILDER_V1 = "KEYWAY_PRODUCT_BUILDER_V1";
export const PRODUCT_BUILDER_HOSPITAL_RANGE_CUSTOM = "Custom";
export const PRODUCT_BUILDER_HOSPITAL_RANGE_FIITING = "Fitting";
export const PRODUCT_BUILDER_HOSPITAL_RANGE_PART = "Part";
export const STOCK_GROUP_HOSPITAL_RANGE_CODES = ["B-HOS", "C-HOS", "HOS"];
export const PRODUCT_BUILDER_CURTAIN_TRACK_PART = "Part";
export const PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM = "Custom";
export const PRODUCT_BUILDER_ROLLER_BLIND_SINGLE_PRODCODE = "ROLSINGLE";
export const PRODUCT_BUILDER_PANEL_TRACK_PRODCODE = "KW-PANELTR";
export const PRODUCT_BUILDER_ROMAN_BLIND_PRODCODE = "KW-ROMAN";
export const PRODUCT_BUILDER_PROJECTION_AWNING_PRODCODE = "KW-PROJAWN";
export const PRODUCT_BUILDER_QUIKLOK_PRODCODE = "KW-QUIKLOK";
export const PRODUCT_BUILDER_CAFE_CRANK_PRODCODE = "KW-CAFECRANK";
export const PRODUCT_BUILDER_CUSTOM_PART = "Part";
export const PRODUCT_BUILDER_CUSTOM_BUILD = "Build";
export const PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_PROD_INTERNAL_LIVE = true;
export const PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_PROD_EXTERNAL_LIVE = true;
export const PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_TEST_INTERNAL_LIVE = true;
export const PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_TEST_EXTERNAL_LIVE = true;
export const PRODUCT_BUILDER_ORDER_GUIDELINE_DOCUMENT_PUBLIC_URL = "https://winonline.blob.core.windows.net/winonline/static/Windoware-Product-Order-Guidelines.pdf";
export const PRODUCT_BUILDER_CURTAIN_TRACK_CUSTOM_trackOnlyNotCompatibleComponents = [
    "runner",
    "runnerColour",
    "control",
    "cordType",
    "cordSizeType",
    "cordCustomEnteredLength",
    "stacking",
    "overlapArms",
    "underlapArms",
    "operation"
];
export const PRODUCT_BUILDER_FOLDING_ARM_AWNING_MODEL_COOLUM_SEMI_CASSETTE = "Coolum - Semi Cassette";
export const WINDOWARE_DEFAULT_CONTACTNUMBER = "61 7 3299 3788";
export const WINDOWARE_DEFAULT_CONTACTEMAIL = "sales@windoware.com.au";
export const WINDOWARE_DEFAULT_NAME = "Windoware Team";
export const taxRate = 0.10;
//statuses includes Pending, Awaiting Furth Info, Awaiting production
//export const ORDER_GATEWAY_PENDING_TAB_STATUS = [30, 40];
//statuses includes Waiting on Stock, Awaiting Further Info (remove Scheduled by Oli)
export const PRODUCTION_SCHEDULER_ORDER_STATUS = [40,50];
//statuses includes PC Awaiting Picking, PC Awaiting Drop Off, PC Awaiting Pickup, PC Complete
//export const PRODUCTION_SCHEDULER_POWDERCOATING_ORDER_STATUS = [57, 58, 59, 60];
export const requestMethodOptions = ["GET", "POST", "PUT", "DELETE"];
export const ADDRESS_VALIDATION_MAX_LENGTH_CONTACT_NAME = 20;
export const ADDRESS_VALIDATION_MAX_LENGTH_COMPANY = 50;
export const ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE1 = 50;
export const ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE2 = 50;
export const ADDRESS_VALIDATION_MAX_LENGTH_CITY = 25;
export const ADDRESS_VALIDATION_MAX_LENGTH_STATE = 3;
export const ADDRESS_VALIDATION_MAX_LENGTH_POSTCODE = 6;
export const ADDRESS_VALIDATION_MAX_LENGTH_FAX = 20;
export const ADDRESS_VALIDATION_MAX_LENGTH_COUNTRY = 20;
export const ADDRESS_VALIDATION_MAX_LENGTH_COUNTRY_CODE = 2;
export const ADDRESS_VALIDATION_MAX_LENGTH_PHONE = 20;
export const ADDRESS_VALIDATION_MAX_LENGTH_EMAIL = 50;

export const PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_COURIER_INSTRUCTIONS = 25;
export const PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_NOTES = 500;
export const PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_RETAIL_NAME = 50;
export const PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_RETAIL_EMAIL = 50;
export const PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_CUST_ORD_NUM = 16;
export const PRODUCT_BUILDER_VALIDATION_MIN_LENGTH_CUST_ORD_NUM = 8;
export const DISABLED_SALES_ORDER_ENQUIRY_OPTIONS = [ "Packing", "Invoiced", "Shipped", "Paid" ];
export const WAREHOUSE_LOCATION_TYPES = [
    { locationTypeID: 1, name: "Pick" },
    { locationTypeID: 2, name: "Bulk" }
];

export const widthExtenders = [
    {label: "50mm", key: "widthExtender50mm"},
    {label: "100mm", key: "widthExtender100mm"},
    {label: "150mm", key: "widthExtender150mm"},
];
export const zipJoiners = [
    {label: "100mm", key: "zipJoiner100mm"},
    {label: "200mm", key: "zipJoiner200mm"},
];
export const REWORK_ORDER_ACCOUNT_IDs = "01005";
export const PRODUCT_BUILDER_SEARCH_DEBTOR = ["01000", "01005", "00010", "09009", "09111"];