import React, {Component} from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Row,
    Spinner
} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import queryString from 'query-string';
import CustomerService from '../../services/CustomerService';
import SearchCity from '../../components/search/SearchCity';
import SearchState from '../../components/search/SearchState';
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';
import SearchPostcode from '../../components/search/SearchPostcode';
import { handleErrorMessage, validateEmail} from "../../services/CommonService";
/*import DebtorPreviewEditModal from '../../components/debtor/DebtorPreviewEditModal';*/
/*import CRMService from "../../services/crm/CRMService";*/
import UserService from "../../services/UserService";
import classnames from 'classnames';
import {
    ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE1,
    ADDRESS_VALIDATION_MAX_LENGTH_COMPANY, ADDRESS_VALIDATION_MAX_LENGTH_EMAIL, ADDRESS_VALIDATION_MAX_LENGTH_PHONE,
    BUNNINGS_STORE_ACCOUNT_IDs
} from "../../store/AppConstants";

export default class ManageCustomerPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            debtor: {},
            debtorPaymentTerms: [],
            debtorAccountStatuses: [],
            debtorCategories: [],
            debtorClassifications: [],
            reps: [],
            formErrors: {},
            isLoadingSave: false,
            isLoadingDebtor: false,
            isOpenDebtorPreviewModal: false,
            isEditMode: false,
            hasSubmitTried: false
        };
        this.userService = new UserService();
        this.customerService = new CustomerService();
        this.handleCancel = this.handleCancel.bind(this);
        this.getDebtor = this.getDebtor.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        //this.getDebtorAccountStatus = this.getDebtorAccountStatus.bind(this);
        //this.getDebtorCategory = this.getDebtorCategory.bind(this);
        //this.getDebtorPaymentTerms = this.getDebtorPaymentTerms.bind(this);
        //this.getReps = this.getReps.bind(this);
        this.openDebtorPreviewModal = this.openDebtorPreviewModal.bind(this);
        this.toggleDebtorPreviewModal = this.toggleDebtorPreviewModal.bind(this);
        this.refreshAgainPreviewModal = this.refreshAgainPreviewModal.bind(this);
        //this.generateNewAccountID = this.generateNewAccountID.bind(this);
        //this.initDebtorObj = this.initDebtorObj.bind(this);
    }

    componentDidMount() {
        let accountID = "";
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            accountID = searchParams.accountID;
            this.setState({ accountID });
        }
        if (accountID) {
            this.getDebtor(accountID)
        } else {
            let debtor = this.initDebtorObj(accountID);
            this.setState({debtor, isEditMode: false}, () => {
                this.handleChange(true, "isParent");
            });
        }

        //this.getDebtorAccountStatus();
        //this.getDebtorCategory();
        //this.getDebtorPaymentTerms();
        //this.getDebtorClassifications();
        //this.getReps();
    }

    /*initDebtorObj(accountID) {

        let currentUser = this.userService.getLoggedInUser();
        return {
            accountID: accountID,
            parent: '', //[Computed)]
            child: '', //[Computed)]
            company: '',
            date: null,
            industrial: false,
            fo: true,
            bo: true,
            standInfo: null,
            address1: "",
            address2: "",
            address3: "",
            address4: "",
            city: '',
            state: '',
            postCode: '',
            phone: '',
            PhoneAfterHrs: null,
            fax: null,
            webSite: null,
            email: '',
            courier: 0,
            repArea1: 0,
            repArea2: 0,
            bankAcctName: "",
            bankName: null,
            bankSuburb: null,
            bankBSB: null,
            bankAcct: null,
            discount1: 0,
            discount2: 0,
            rebate: 0,
            openBal: 0,
            current: 0,
            thirtyDays: 0,
            sixtyDays: 0,
            ninetyDays: 0,
            //[Computed)] balance: 0,
            fwdDateAmount: 0,
            terms: null,
            numDaysCredit: null,
            creditLimit: 0,
            lastInvNum: null,
            lastInvDate: null,
            lastInvAmt: null,
            lastPayDate: null,
            lastPayAmt: null,
            lastPick: null,
            staxNum: null,
            webLogin: null,
            webPassword: null,
            mtd: 0,
            ytd: 0,
            ly: 0,
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0,
            printInvoice: true,
            invoiceQty: 1,
            printPackSlip: false,
            packSlipQty: 0,
            rrpType: 0,
            rrpQty: 0,
            rrpMarkup1: 0,
            rrpMarkup2: 0,
            international: false,
            country: "AUSTRALIA",
            currencyType: "AUD",
            companyNum: 1,
            specialReq: null,
            invMsg: null,
            salesNotes: null,
            remarks: null,
            fwdVal: null,
            sdi: 0,
            flagMsg: "",
            dateAdded: getDateString(new Date(), "DD/MM/YYYY HH:mm:ss"),
            creditHold: false,
            abn: "",
            selector: null,
            tradingTerms: null,
            ly3: 0,
            gSuffix: 0,
            edi: 0,
            xRefRequired: false,
            storeNum: "",
            consignmentAccount: false,
            paymentTermsID: 1,
            preferredCourierID: 0,
            preferredCourierAccount: null,
            addedBy: currentUser.username,
            allowNoFreight: false,
            typeID: 0,
            cashSaleAccount: false,
            statementPrint: true,
            statementEmail: false,
            accountStatusID: 0,
            emailOnDispatch: false,
            excludeFuelSurcharge: true,
            shippingRouteID: 1,
            requiresASN: false,
            payBeforeDiscount: 0,
            payBeforeDay: 0,
            orderConfirmation: false,
            bmPriceLevel: 0,
            creditTermsID: 0,
            gcRecord: null,
            optimisticLockField: null,
            priceLevelID: 1,
            incoTermID: 0,
            roundDecimalPlaces: 2,
            orderLimit: 0,
            orderLimitFormulaID: 0,
            emailInvoice: false,
            debtorCategoryID: 3,
            doNotUse: false,
            classificationID: 1,
            allowDiscountOnSpecialPrices: 0,
            id: null,
            createdBy: currentUser.username,
            ammendedBy: currentUser.username,
            showCodeOnInvoice: 0,
            courierInstructions: null,
            invoiceFormat: 1,
            statementFormat: 1,
            isParent: "",// [Computed)]
            isChild: "",// [Computed)]
            searchKey: "",  // [Computed)]
            isChargeOnlyAccount: false,
            isInternal: false,
            isCreditApproved: null,
        }
    }

    getReps() {
        RepService.fetchReps().then(res => {
            this.setState({reps: res.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorAccountStatus() {
        this.customerService.getDebtorAccountStatus().then(response => {
            this.setState({debtorAccountStatuses: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorClassifications() {
        this.crmService.getDebtorClassification().then(response => {
            this.setState({debtorClassifications: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorCategory() {
        this.customerService.getDebtorCategory().then(response => {
            this.setState({debtorCategories: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorPaymentTerms() {
        this.customerService.getDebtorPaymentTerms().then(response => {
            this.setState({debtorPaymentTerms: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    generateNewAccountID() {
        let {debtor} = this.state;
        this.customerService.generateNewAccountID(debtor.isParent ? "parent" : "child", debtor.parent).then(response => {
            debtor.accountID = response.data.accountID;
            debtor.parent = response.data.parent;
            debtor.child = response.data.child;
            debtor.isParent = response.data.isParent;
            debtor.isChild = response.data.isChild;
            this.setState({debtor});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }*/

    getDebtor(accountID) {
        this.setState({isLoadingDebtor: true});

        this.customerService.searchCustomer(accountID).then(response => {
            this.setState({debtor: response.data, isLoadingDebtor: false, isEditMode: true});
        }).catch(error => {
            this.setState({isLoadingDebtor: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleChange(change, key) {
        let {debtor, hasSubmitTried} = this.state;

        switch (key) {
            case "isParent":
                debtor[key] = change;
                debtor.isChild = !debtor.isParent;
                debtor.accountID = "";
                debtor.parent = "";
                debtor.child = "";

                this.setState({debtor}, () => {
                    this.generateNewAccountID();
                });
                break;
            case "isChild":
                debtor[key] = change;
                debtor.isParent = !debtor.isChild;
                debtor.accountID = "";
                debtor.parent = "";
                debtor.child = "";
                this.setState({debtor});
                break;
            case "parent":
                debtor[key] = change;
                this.setState({debtor}, () => {
                    this.generateNewAccountID();
                });
                break;
            default:
                debtor[key] = change;
                this.setState({debtor});
                break;
        }

        if (hasSubmitTried) {
            let result = this.validateForm(debtor);
            this.setState({formErrors: result.formErrors});
        }
    }

    handleCancel() {
        this.props.history.push('/customer/account/detail?accountID=' + this.state.accountID);
    }

    openDebtorPreviewModal() {
        this.setState({isOpenDebtorPreviewModal: true});
    }

    toggleDebtorPreviewModal(data) {
        this.setState({isOpenDebtorPreviewModal: data});
    }

    refreshAgainPreviewModal() {
        this.getDebtor(this.state.debtor.accountID);
        this.setState({isOpenDebtorPreviewModal: false});
    }

    validateForm(debtor) {
        let formErrors = {};
        let valid = true;
        if (!debtor.accountID) {
            formErrors.accountID = "AccountID should not be empty!";
            valid = false;
        }
        if (!debtor.company) {
            formErrors.company = "Please enter company name!";
            valid = false;
        }
        if (debtor.company && debtor.company.length > ADDRESS_VALIDATION_MAX_LENGTH_COMPANY) {
            formErrors.company = "Max " + ADDRESS_VALIDATION_MAX_LENGTH_COMPANY + " characters allowed!";
            valid = false;
        }
        if (!debtor.address1) {
            formErrors.address1 = "Please enter address!";
            valid = false;
        }
        if (debtor.address1 && debtor.address1.length > ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE1) {
            formErrors.address1 = "Max " + ADDRESS_VALIDATION_MAX_LENGTH_ADDRESS_LINE1 + " characters allowed!";
            valid = false;
        }
        if (!debtor.state) {
            formErrors.state = "Please select state!";
            valid = false;
        }
        if (!debtor.city) {
            formErrors.city = "Please select city!";
            valid = false;
        }
        if (!debtor.postCode) {
            formErrors.postCode = "Please select postCode!";
            valid = false;
        }
        if (!debtor.email) {
            formErrors.email = "Please enter email!";
            valid = false;
        }
        if (debtor.email && !validateEmail(debtor.email)) {
            formErrors.email = "Please enter valid email!";
            valid = false;
        }
        if (debtor.email && debtor.email.length > ADDRESS_VALIDATION_MAX_LENGTH_EMAIL) {
            formErrors.email = "Max " + ADDRESS_VALIDATION_MAX_LENGTH_EMAIL + " characters allowed!";
            valid = false;
        }
        if (!debtor.phone) {
            formErrors.phone = "Please enter phone!";
            valid = false;
        }
        if (debtor.phone && debtor.phone.length > ADDRESS_VALIDATION_MAX_LENGTH_PHONE) {
            formErrors.phone = "Max " + ADDRESS_VALIDATION_MAX_LENGTH_PHONE + " characters allowed!";
            valid = false;
        }
        return {valid: valid, formErrors: formErrors};
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = {...this.state.debtor};
        let result = this.validateForm(req);
        this.setState({formErrors: result.formErrors, hasSubmitTried: true});
        if (result.valid) {
            this.setState({isLoadingSave: true, formErrors: {}});
            this.customerService.updateDebtor(req).then(response => {
                this.setState({isLoadingSave: false});
                this.getDebtor(this.state.debtor.accountID);
                toast.success("Updated!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.props.history.push('/customer/account/detail?accountID=' + this.state.debtor.accountID);
            }).catch(error => {
                this.setState({isLoadingSave: false});
                console.log(error.data);
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    handleAccountChange(selectedAccount) {
        if (selectedAccount) {
            this.handleChange(selectedAccount.accountID, 'parent');
        }
    }

    render() {
        let {
            debtor, formErrors, isLoadingDebtor, debtorClassifications, debtorCategories, debtorAccountStatuses, reps,
            debtorPaymentTerms, isOpenDebtorPreviewModal, isEditMode, isLoadingSave
        } = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/customer/list')}>Customers List</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{isEditMode ? 'Edit' : 'New'}</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader>
                        <h6>{isEditMode ? 'Edit' : 'New'}</h6>
                    </CardHeader>
                    <CardBody>
                        {isLoadingDebtor
                            ? <Spinner color="primary"/>
                            : <Form>
                                <FormGroup>
                                    <Row>
                                        <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                                            <Label>Account Type</Label>
                                            <Row>
                                                <Col xl={6} lg={6} md={6} sm={8} xs={12}>
                                                    <Button color={"link"}
                                                            size={"sm"}
                                                            disabled={isEditMode}
                                                            onClick={() => this.handleChange(!debtor.isParent, "isParent")}>
                                                        <i className={classnames("mr-2", "fa", "fa-lg", {
                                                            "fa-check-square-o": debtor.isParent,
                                                            "fa-square-o": !debtor.isParent
                                                        })} aria-hidden="true"/>
                                                    </Button>
                                                    Parent
                                                </Col>
                                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                    <Button color={"link"}
                                                            size={"sm"}
                                                            disabled={isEditMode}
                                                            onClick={() => this.handleChange(!debtor.isChild, "isChild")}>
                                                        <i className={classnames("mr-2", "fa", "fa-lg", {
                                                            "fa-check-square-o": debtor.isChild,
                                                            "fa-square-o": !debtor.isChild
                                                        })} aria-hidden="true"/>
                                                    </Button>
                                                    Child
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col xl={9} lg={8} md={8} sm={12} xs={12} className={"border-left"}>
                                            {
                                                debtor.isChild
                                                    ? <div className={"mb-2"}>
                                                        Select the parent customer to whom under you want to create a child
                                                        account
                                                        <SearchDebtorAcccount
                                                            handleAccountChange={(selectedAccount) => this.handleAccountChange(selectedAccount)}
                                                            value={debtor.parent}
                                                            selectedAccountID={debtor.parent}
                                                            includeChildren={false}
                                                            excludeClosedandInactive={true}
                                                            parentAccountIDs={BUNNINGS_STORE_ACCOUNT_IDs}
                                                        />
                                                    </div>
                                                    : null
                                            }
                                            <Row>
                                                {
                                                    debtor.accountID
                                                        ? <Col lg={4} md={4} sm={8} xs={12}>
                                                            <FormGroup>
                                                                <Label>AccountID</Label>
                                                                <Input
                                                                    disabled={true}
                                                                    name="accountID"
                                                                    type="text"
                                                                    value={debtor.accountID}
                                                                    onChange={(e) => this.handleChange(e.target.value, 'accountID')}
                                                                    placeholder="AccountID"/>
                                                            </FormGroup>
                                                        </Col>
                                                        : null
                                                }

                                                {
                                                    (debtor.isChild && debtor.parent)
                                                        ? <Col lg={4} md={4} sm={8} xs={12}>
                                                            <FormGroup>
                                                                <Label>Parent</Label>
                                                                <Input
                                                                    disabled={true}
                                                                    name="parent"
                                                                    type="text"
                                                                    value={debtor.parent}
                                                                    onChange={(e) => this.handleChange(e.target.value, 'parent')}
                                                                    placeholder="Parent part of the AccountID"/>
                                                            </FormGroup>
                                                        </Col>
                                                        : null
                                                }

                                                {
                                                    ( debtor.isChild && debtor.child)
                                                        ? <Col lg={4} md={4} sm={8} xs={12}>
                                                            <FormGroup>
                                                                <Label>Child</Label>
                                                                <Input
                                                                    disabled={true}
                                                                    name="child"
                                                                    type="text"
                                                                    value={debtor.child}
                                                                    onChange={(e) => this.handleChange(e.target.value, 'child')}
                                                                    placeholder="Child part of the AccountID"/>
                                                            </FormGroup>
                                                        </Col>
                                                        : null
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <hr/>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Company</Label>
                                            <Input
                                                name="company"
                                                type="text"
                                                value={debtor.company || ''}
                                                onChange={(e) => this.handleChange(e.target.value, 'company')}
                                                placeholder="write company here..."/>
                                            <FormText color="danger">{formErrors.company}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Address</Label>
                                            <Input
                                                name="address1"
                                                type="text"
                                                value={debtor.address1 || ''}
                                                placeholder="write address here..."
                                                onChange={(e) => this.handleChange(e.target.value, 'address1')}/>
                                            <FormText color="danger">{formErrors.address1}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>State</Label>
                                            <SearchState
                                                handleSelection={(selectedState) => this.handleChange(selectedState, "state")}
                                                selected={debtor.state || ''}
                                                defaultSelected={''}
                                                filters={{city: debtor.city || '', postcode: debtor.postCode || ''}}
                                            />
                                            <FormText color="danger">{formErrors.state}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>City</Label>
                                            <SearchCity
                                                handleSelection={(selectedCity) => this.handleChange(selectedCity, "city")}
                                                selected={debtor.city || ''}
                                                defaultSelected={''}
                                                filters={{state: debtor.state || '', postcode: debtor.postCode || ''}}
                                            />
                                            <FormText color="danger">{formErrors.city}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Post Code</Label>
                                            <SearchPostcode
                                                handleSelection={(selectedPostcode) => this.handleChange(selectedPostcode, "postCode")}
                                                selected={debtor.postCode || ''}
                                                defaultSelected={''}
                                                filters={{city: debtor.city || '', state: debtor.state || ''}}
                                            />
                                            <FormText color="danger">{formErrors.postCode}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Email</Label>
                                            <Input
                                                type="text"
                                                name="email"
                                                value={debtor.email || ''}
                                                placeholder="write email here "
                                                onChange={(e) => this.handleChange(e.target.value, 'email')}>
                                            </Input>
                                            <FormText color="danger">{formErrors.email}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>Phone</Label>
                                            <Input
                                                name="phone"
                                                type="text"
                                                value={debtor.phone || ''}
                                                onChange={(e) => this.handleChange(e.target.value, 'phone')}
                                                placeholder="write phone no here..."/>
                                            <FormText color="danger">{formErrors.phone}</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label>ABN</Label>
                                            <Input
                                                type="text"
                                                name="abn"
                                                value={debtor.abn || ''}
                                                placeholder="write ABN here "
                                                onChange={(e) => this.handleChange(e.target.value, 'abn')}>
                                            </Input>
                                            <FormText color="danger">{formErrors.abn}</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>}
                    </CardBody>
                    <CardFooter>
                        <div className={"text-right"}>
                            {/*{*/}
                            {/*    isEditMode*/}
                            {/*        ? <Button color='primary'*/}
                            {/*                  size={"sm"}*/}
                            {/*                  onClick={this.openDebtorPreviewModal}*/}
                            {/*                  className={"mr-2"}>*/}
                            {/*            <i className="fa fa-pencil mr-2" aria-hidden="true"/>*/}
                            {/*            Edit Preview*/}
                            {/*        </Button>*/}
                            {/*        : null*/}
                            {/*}*/}
                            <Button color='primary'
                                    size={"sm"}
                                    onClick={this.handleSubmit}
                                    disabled={isLoadingSave}
                                    className={"mr-2"}>
                                {
                                    isLoadingSave
                                        ? <Spinner size="sm" className="mr-2"
                                                   color={"light"}/>
                                        : <i className="fa fa-floppy-o mr-2"
                                             aria-hidden="true"/>
                                }
                                {
                                    isLoadingSave
                                        ? "Saving"
                                        : "Save"
                                }
                            </Button>
                            <Button color='secondary'
                                    size={"sm"}
                                    onClick={this.handleCancel}>
                                <i className="fa fa-times mr-2" aria-hidden="true"/>
                                Cancel
                            </Button>
                        </div>
                    </CardFooter>
                </Card>

                {/*{*/}
                {/*    isOpenDebtorPreviewModal*/}
                {/*        ? <DebtorPreviewEditModal*/}
                {/*            accountID={debtor.accountID}*/}
                {/*            reps={reps}*/}
                {/*            debtorAccountStatuses={debtorAccountStatuses}*/}
                {/*            debtorCategories={debtorCategories}*/}
                {/*            debtorPaymentTerms={debtorPaymentTerms}*/}
                {/*            debtorClassifications={debtorClassifications}*/}
                {/*            isOpen={isOpenDebtorPreviewModal}*/}
                {/*            toggleModal={this.toggleDebtorPreviewModal}*/}
                {/*            refresh={this.refreshAgainPreviewModal}*/}
                {/*        />*/}
                {/*        : null*/}
                {/*}*/}

                <ToastContainer/>
            </div>
        );
    }
}
