import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    ListGroup, ListGroupItem, Badge, Button, Spinner
} from 'reactstrap';
import { getLocalDateStringFromUtcDateString } from '../../services/CommonService';
import queryString from 'query-string';

export default class DebtorIntroduction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            debtor: props.debtor
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.debtor !== nextProps.debtor) {
            this.setState({ debtor: nextProps.debtor });
        }
    }

    getAccountStatusDescription(accountStatusDescription) {
        let color = '';
        switch (accountStatusDescription) {
            case 'Active':
                color = 'green';
                break;

            case 'Inactive':
            case 'COD':
                color = 'orange';
                break;

            case 'Legal Action':
            case 'Closed':
            case 'Bad Debt':
            case 'Rep Not To Call':
                color = 'red';
                break;

            default:
                color = 'green';
                break;
        }

        return color;
    }

    render() {
        const { hasDebtorWritePrivilege, alreadyInvited, usersByEmail, alreadyRegisteredAccountID } = this.props;
        const { debtor } = this.state;
        return (
            <div>
                <ListGroup>
                    <ListGroupItem>
                        <strong>
                            {debtor.company}&nbsp;
                            {hasDebtorWritePrivilege && debtor ?
                                <Link className="btn btn-sm btn-primary"
                                    to={"/customers/manage/account/one?" + queryString.stringify({ accountID: debtor.accountID })}>
                                    <i className="fa fa-pencil mr-2"
                                        aria-hidden="true" />
                        Edit</Link>
                                : null}
                        </strong>
                    </ListGroupItem>



                    {(() => {
                        if (debtor.accountStatusDescription) {
                            return (
                                <ListGroupItem>
                                    <strong>Status: </strong>
                                    <span style={{
                                        color: this.getAccountStatusDescription(debtor.accountStatusDescription),
                                        fontWeight: 'bold'
                                    }}>{debtor.accountStatusDescription}
                                        &nbsp;&nbsp;&nbsp;
                                        {debtor.creditHold ? <Badge color="danger">ON CREDIT HOLD</Badge> : null}
                                    </span>&nbsp;&nbsp;
                                    {debtor.aBN ? <span> <strong>ABN: </strong>{debtor.aBN}&nbsp;&nbsp;</span> : null}
                                    &nbsp;&nbsp;
                                    {/*{(!debtor.email || (debtor.email && !usersByEmail[debtor.email])) ?*/}
                                    {/*    (debtor.email && alreadyRegisteredAccountID.includes(debtor.accountID)) ?*/}
                                    {/*        <span className="text-center">*/}
                                    {/*            <Button size={"sm"} color="warning" disabled={true} title={"already registered email"}>*/}
                                    {/*                <i className="fa fa-user-circle-o" aria-hidden="true" />&nbsp;*/}
                                    {/*            Registered*/}
                                    {/*        </Button>*/}
                                    {/*        </span>*/}
                                    {/*        :*/}
                                    {/*    //(debtor.email && alreadyInvited.includes(debtor.email)) ?*/}
                                    {/*    //        <span>*/}
                                    {/*    //            <Button size={"sm"} color="success" title={"Click to re-invite"} disabled={hasDebtorWritePrivilege ? false : true}*/}
                                    {/*    //            onClick={() => this.props.handleChange(debtor, "customer-signup-invite-workingCustomer")}>*/}
                                    {/*    //            <i className="fa fa-check" aria-hidden="true" />&nbsp;*/}
                                    {/*    //            Invited*/}
                                    {/*    //        </Button>*/}
                                    {/*    //    </span>*/}
                                    {/*    //    : <span>*/}
                                    {/*    //        <Button size={"sm"} color="info" title={"Click to invite"}*/}
                                    {/*    //                disabled={hasDebtorWritePrivilege ? debtor.loadingInvite : true}*/}
                                    {/*    //            onClick={() => this.props.handleChange(debtor, "customer-signup-invite-workingCustomer")}>*/}
                                    {/*    //            {debtor.loadingInvite ? <Spinner className={"mr-2"} color={"light"} size="sm" />*/}
                                    {/*    //                : <i className="fa fa-user-plus mr-2" aria-hidden="true" />}*/}
                                    {/*    //            {debtor.loadingInvite ? "Inviting" : "Invite"}*/}
                                    {/*    //        </Button>*/}
                                    {/*    //    </span> */}
                                    {/*        : null}*/}


                                    {(debtor.email && usersByEmail[debtor.email]) ?
                                        <span className="text-center">
                                            <Button size={"sm"} color="warning" disabled={true} title={"already registered email"}>
                                                <i className="fa fa-user-circle-o" aria-hidden="true" />&nbsp;
                                                Registered
                                            </Button>
                                        </span> : null}
                                </ListGroupItem>
                            )
                        }
                    })()}

                    {(() => {
                        if (debtor.phone || debtor.email) {
                            return (
                                <ListGroupItem>
                                    <strong>General Contact: </strong>
                                    {(() => {
                                        if (debtor.phone || debtor.email) {
                                            return (
                                                <span>
                                                    <a
                                                        href={"tel:" + debtor.phone}>
                                                        <i className="text-muted  fa fa-phone"
                                                            aria-hidden="true" />
                                                                    &nbsp;{debtor.phone}
                                                    </a>&nbsp;&nbsp; &nbsp;&nbsp;</span>)
                                        }
                                    })()}
                                    {(() => {
                                        if (debtor.email) {
                                            return (
                                                <span>
                                                    <a href={"mailto:" + debtor.email}>
                                                        <i className="text-muted fa fa-envelope-o"
                                                            aria-hidden="true" />
                                                                        &nbsp;{debtor.email}</a>&nbsp;
                                                    &nbsp;&nbsp; &nbsp;&nbsp;
                                                </span>
                                            )
                                        }
                                    })()}
                                </ListGroupItem>)
                        }
                    })()}

                    {(() => {
                        if (debtor && debtor.debtorAddress) {
                            return <ListGroupItem>
                                <strong>Billing Address: </strong>
                                <a
                                    href={"http://maps.google.com/?q=" + debtor.debtorAddress}
                                    target="_blank">
                                    <i className="text-muted fa fa-map-marker"
                                        aria-hidden="true" />
                                &nbsp;{debtor.debtorAddress}
                                </a>
                            </ListGroupItem>
                        }
                    })()}


                </ListGroup>
            </div>
        )
    };
}
