import React, { Component } from 'react';
import {
    Modal, ModalBody, ModalHeader, ModalFooter,
    Button,
    Label, Input, Spinner, FormGroup, FormText
} from 'reactstrap';
import ProductionScheduleService from '../../services/production/ProductionScheduleService';
import { toast, ToastContainer } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import { handleErrorMessage } from '../../services/CommonService';
import classnames from 'classnames';

export default class ProductionScheduleOrderProdStatusChangeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            salesOrder: {},
            formErrors: {}
        };
        this.checkOrderIsSpecialOrder = this.checkOrderIsSpecialOrder.bind(this);
    }

    componentDidMount() {
        this.setState({ salesOrder: cloneDeep(this.props.salesOrder) }, () => { this.checkOrderIsSpecialOrder() });
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.salesOrder !== nextProps.salesOrder) {
            this.setState({ salesOrder: cloneDeep(nextProps.salesOrder) }, () => { this.checkOrderIsSpecialOrder() });
        }
    }

    checkOrderIsSpecialOrder() {
        let { salesOrder } = this.state;
        if (salesOrder.whStatus.includes("SPECIAL")) {
            salesOrder.isSpecialOrder = true;
            this.setState({ salesOrder });
        }
    }

    handleChange(key, value) {
        let { salesOrder } = this.state;
        switch (key) {
            case "isSpecialOrder":
                salesOrder.isSpecialOrder = !value;
                if (salesOrder.isSpecialOrder) {
                    salesOrder.whStatus = "SPECIAL " + salesOrder.whStatus;
                }
                else {
                    salesOrder.whStatus = salesOrder.whStatus.replace("SPECIAL ","");
                }
                break;
            default:
                salesOrder[key] = value;
        }
        this.setState({ salesOrder });
    }

    validate(req) {
        let formErrors = { notes: '', isValid: true };
        if (req.notes.length > 50) {
            formErrors.notes = "Not more than 50 characters.";
            formErrors.isValid = false;
        }
        return formErrors;
    }

    updateProductScheduleProdNotes(salesOrder) {
        let req = {
            ordNum: salesOrder.ordNum,
            notes: salesOrder.whStatus,
        };

        let formErrors = this.validate(req);
        if (!formErrors.isValid) {
            this.setState({ formErrors });
            return;
        }

        salesOrder.isUpdatingProductScheduleProdNotes = true;
        this.setState({ salesOrder });

        ProductionScheduleService.updateProductScheduleProdNotes(req).then(response => {
            salesOrder.whStatus = response.data;
            salesOrder.isUpdatingProductScheduleProdNotes = false;
            this.setState({ salesOrder });
            this.props.handleProductScheduleProdNotes(salesOrder);
            toast.success("Saved");
        }).catch(error => {
            salesOrder.isUpdatingProductScheduleProdNotes = false;
            this.setState({ salesOrder });
            toast.error(handleErrorMessage(error));
        });
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { salesOrder, formErrors } = this.state;

        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Order No. {salesOrder.ordNum}
                </ModalHeader>
                <ModalBody>
                    <div>
                        <FormGroup>
                        <Label>Notes</Label>
                        <Input
                            type={"textarea"}
                                name={"whStatus"}
                                value={salesOrder.whStatus}
                            onChange={(e) => this.handleChange("whStatus", e.target.value)}
                        />
                            <FormText color="danger">{formErrors.notes}</FormText>
                            </FormGroup>
                        <br/>
                        <a href={"javascript:void(0)"} onClick={() => this.handleChange("isSpecialOrder", salesOrder.isSpecialOrder)}>
                            <i className={classnames("mr-2", "fa", "fa-lg", {
                                "fa-check-square-o": salesOrder.isSpecialOrder ,
                                "fa-square-o": !salesOrder.isSpecialOrder
                            })} /></a>
                            Custom Order
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={'primary'}
                            size='sm'
                            className={"mr-2"}
                            disabled={salesOrder.isUpdatingProductScheduleProdNotes}
                            onClick={() => this.updateProductScheduleProdNotes(salesOrder)}>
                            {salesOrder.isUpdatingProductScheduleProdNotes
                                ? <Spinner className={"mr-2"} color={"light"} size={"sm"} />
                                : <i className='fa fa-floppy-o mr-2' />
                            }
                            {salesOrder.isUpdatingProductScheduleProdNotes
                                ? "Updating"
                                : "Update"
                            }
                        </Button>
                        <Button color={"secondary"} size="sm" onClick={() => toggle(!isOpen)}>
                            <i className='fa fa-times mr-2' />Close</Button>
                    </div>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }
}