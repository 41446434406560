import React, { Component } from 'react';
import { Table, Label, Button, Card, CardHeader, Row, Col, Collapse, Badge } from "reactstrap";
import NumberFormat from "react-number-format";
import { ToastContainer, toast } from 'react-toastify';
import { handleErrorMessage } from '../../services/CommonService';
import stockService from "../../services/StockService";
export default class OrderEnquiryProductionModeStockGroupList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: true,
            stockGroupName: 'Pickface',
            stockDetals: {},
            stockGroup : [{ stock_Group_Name: "Pickface", isOpen: true },
            { stock_Group_Name: "Bulk", isOpen: false },
            { stock_Group_Name: "Fabric", isOpen: false }]
        };

        this.toggleStockGroupCollapse = this.toggleStockGroupCollapse.bind(this);
    }

    componentDidMount() {
        this.stockDetails(this.state.stockGroupName);
    }

    toggleStockGroupCollapse(key, change) {
        let {stockGroupName, stockGroup } = this.state;
        for (let item in stockGroup) { 
            if (stockGroup[item].stock_Group_Name === key) {
                stockGroup[item].isOpen = change;
            }
        }
        if (change) {
            stockGroupName = key;
            this.stockDetails(stockGroupName);
        } else {
            stockGroupName = null;
        }
        this.setState({ stockGroupName, stockGroup });
    }

    stockDetails(stockGroupName) {
        let { stockDetails, stockGroup } = this.state;
        let { ordNum } = this.props;
        stockService.getStockDetails(ordNum, stockGroupName).then(response => {
            for (let key in stockGroup) {
                if (stockGroupName === stockGroup[key].stock_Group_Name) { 
                    stockGroup[key] = {
                        data: response.data,
                        stock_Group_Name: stockGroupName,
                        isOpen: true
                    }    
                }
            }
            this.setState({ stockGroup });
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    render() {
        let { isOpen, stockGroupName, stockDetails, stockGroup } = this.state;
        return (
            <div>
                {(stockGroup || []).map((item, itemIndex) => {
                    return <Card className={"mt-1 mb-2"}>
                        <CardHeader>
                                <Row>
                                    <Col>
                                        <Button
                                            size={"sm"}
                                            color={"link"}
                                            onClick={() => this.toggleStockGroupCollapse(item.stock_Group_Name, !item.isOpen)}
                                        >{item.stock_Group_Name}
                                        </Button>
                                    </Col>
                            </Row>
                        </CardHeader> 
                        {
                            item.stock_Group_Name && item.isOpen
                                ?
                                <Collapse isOpen={isOpen}>
                                    <div>
                                        <Table bordered responsive size={"sm"} striped={true} className={"mb-0"}>
                                            <thead>
                                                <tr>
                                                    <th style={{ backgroundColor: "#fff" }}>S.no</th>
                                                    <th>Item Code</th>
                                                    <th>Stock Group Name</th>
                                                    <th className="text-center">Status</th>
                                                    <th>Stock Master Group Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(item.data || []).map((items, indexs) =>
                                                    <tr key={indexs}>
                                                        <td>{indexs + 1}</td>
                                                        <td>{items.prodCode}</td>
                                                        <td>{items.stockGroupName}</td>
                                                        <td className="text-center">{items.soldOut ? <Badge className="mr-2" color={"warning"}>SoldOut</Badge> : null}
                                                            {items.available ? <Badge className="mr-2" color={"success"}>Available</Badge> : null}
                                                            {items.discontinued ? <Badge color={"danger"}>Discontinued</Badge> : null}
                                                        </td>
                                                        <td>{items.stockMasterGroupName}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Collapse>
                                : null
                        }
                    </Card>
                })}
            </div>
        );
    }
};