import React, {Component} from 'react';
import {Badge, Button, Card, Col, ListGroup, ListGroupItem, Row} from 'reactstrap';
import {Redirect} from "react-router-dom";
import OrderService from "../../services/OrderService";
import UserService from "../../services/UserService";
import {changeFormatOfDateString} from '../../services/CommonService';
import 'react-toastify/dist/ReactToastify.css';
import {isEmpty} from 'lodash';
import SalesOrderNotes from "../../components/sales/SalesOrderNotes";
import OrderEnquiryOrderItem from "./OrderEnquiryOrderItem";
import OrderEnquiryCustomerDetail from "./OrderEnquiryCustomerDetail";
import OrderEnquiryDocumentDownload from "./OrderEnquiryDocumentDownload";
import OrderEnquiryPrintMode from "./OrderEnquiryPrintMode";
import UploadFiles from "../../components/attachment/UploadFiles";
import ReactToPrint from "react-to-print";

let visibleColumnsInOrderItems = ["expandIcon", "prodCode", "description", "productName", "location", "fabric", "width", "drop", "qty", "price", "qtyprice", "discVal", "orderedExtendedEx"];
const documentTypes = ["Production Label"];

export default class OrderEnquiryEditMode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOM: {
                order: {},
            },
            isOpenBOMModal: false,
        };

        this.orderService = new OrderService();
        this.userService = new UserService();
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
    }

    toggleBOMModal(change, context) {
        let {isOpenBOMModal, workingBOM} = this.state;
        if (change) {
            isOpenBOMModal = true;
            workingBOM.order = context;
        } else {
            isOpenBOMModal = false;
            workingBOM.order = {};
        }
        this.setState({isOpenBOMModal, workingBOM});
    }

    render() {
        let {
            order,
            toggleExpandRow, toggleAllRowExpanded,
            salesOrder,
            currentUser,
            viewMode
        } = this.props;

        if (order.salesOrderJobStatusId >= 95)  //  if order is invoiced then we can show the qty supplied column..
        {
            let visibleColumns = visibleColumnsInOrderItems;
            let index = visibleColumns.indexOf("qty");
            visibleColumns.splice(index + 1, 0, "qtySupplied");
            visibleColumnsInOrderItems = visibleColumns;
        }
        return (
            <div>
                <div>
                    <div>
                        <Row>
                            <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                <h4>#{order.ordNum}
                                    <small>
                                        <Badge color="warning"
                                               href="javascript:void(0)"
                                               className={"ml-2"}>
                                            {order.salesOrderJobStatusDescription}
                                        </Badge>
                                    </small>
                                </h4>
                                <div>
                                    <span className="text-muted">Entered by- </span>
                                    {order.enteredByUser ? order.enteredByUser.firstName : order.enteredBy}
                                    <span className="text-muted">{' | '}</span>
                                    {changeFormatOfDateString(order.ordDate, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}
                                    {(order.highPriority) ?
                                        <Badge color="danger" className={"m-1 p-1"} pill>MUSTGO</Badge>
                                        : null
                                    }
                                    {(order.excludeFuelSurcharge) ?
                                        <Badge color="info" className={"m-1 p-1"} pill>No Fuel
                                            Surcharge</Badge>
                                        : null
                                    }
                                    {(order.isInstallation) ?
                                        <Badge color="info" className={"m-1 p-1"} pill>>Installation</Badge>
                                        : null
                                    }
                                    {(order.isRemake) ?
                                        <Badge color="info" className={"m-1 p-1"}>Remake</Badge> : null
                                    }

                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12} className={"text-right"}>
                                {
                                    !currentUser.isExternalUser
                                        ? <OrderEnquiryDocumentDownload currentUser={currentUser}
                                            orderCreatedFrom={order.createdFrom}
                                            documentTypes={documentTypes}
                                            ordNum={order.ordNum}/>
                                        : null
                                }
                                {((!currentUser.isExternalUser)) ? <div className="mt-2">
                                    <ReactToPrint
                                        trigger={() => {
                                            return <Button color={"primary"} size={"sm"}>
                                                <i className="fa fa-print mr-2"
                                                   aria-hidden="true"/>Print
                                            </Button>;
                                        }}
                                        content={() => this.componentRef}
                                    />

                                </div> : null}
                            </Col>
                     </Row>
                    </div>
                    <hr/>
                    <div>
                        <div>
                            <OrderEnquiryCustomerDetail order={order} salesOrder={salesOrder}
                                viewMode={viewMode}          currentUser={currentUser}/>
                        </div>
                        <hr/>
                        <div>
                            <ListGroup className={"list-group-horizontal-sm"}>
                                {
                                    (order.custOrdNum || order.custOrdDate)
                                        ? <ListGroupItem>
                                            <div style={{minWidth: 150, maxWidth: 250}}>
                                                {
                                                    order.custOrdNum ?
                                                        <div>
                                                            <p className={"mb-1"}>
                                                                <strong>Customer Order No.</strong>
                                                            </p>
                                                            <div>{order.custOrdNum}</div>
                                                        </div> : null
                                                }
                                                {
                                                    order.custOrdDate ?
                                                        <div className="mt-2">
                                                            <p className={"mb-1"}>
                                                                <strong>Customer Order Date</strong>
                                                            </p>
                                                            <div>
                                                                {changeFormatOfDateString(order.custOrdDate, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}
                                                            </div>
                                                        </div> : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                <ListGroupItem>
                                    <div style={{minWidth: 150, maxWidth: 250}}>
                                        <div>
                                            <p className={"mb-1"}><strong>Required on</strong>
                                                <Button
                                                    className={"pl-0 float-right"}
                                                    color={"link"}
                                                    size={"sm"}
                                                    title={"click here to edit"}
                                                   >
                                                </Button></p>
                                            <div>
                                                {changeFormatOfDateString(order.required, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}

                                            </div>
                                        </div>
                                        {
                                            order.jobRef ?
                                                <div className="mt-2">
                                                    <p className={"mb-1"}>
                                                        <strong>Job Reference</strong>
                                                    </p>
                                                    <div>{order.jobRef}</div>
                                                </div> : null
                                        }
                                        {order.repArea ?
                                            <div className="mt-2">
                                                <p className={"mb-1"}><strong>Sales
                                                    Rep</strong>
                                                </p>
                                                <div>{order.repArea.name} ({order.repArea.code})</div>
                                            </div> : null
                                        }
                                    </div>
                                </ListGroupItem>
                                {
                                    (order.couriers || order.consignNum || order.courierInstructions)
                                        ? <ListGroupItem>
                                            <div style={{minWidth: 150, maxWidth: 250}}>
                                                {order.couriers ?
                                                    <div>
                                                        <p className={"mb-1"}><strong>Shipment
                                                            by</strong></p>
                                                        <div>{order.couriers}</div>
                                                    </div> : null
                                                }
                                                {order.consignNum ?
                                                    <div className="mt-2">
                                                        <p className={"mb-1"}><strong>Consignment
                                                            no</strong>
                                                        </p>
                                                        <div>{order.consignNum}</div>
                                                    </div> : null
                                                }
                                                {order.courierInstructions ?
                                                    <div className="mt-2">
                                                        <p className={"mb-1"}><strong>Courier Instructions</strong></p>
                                                        <div>{order.courierInstructions}</div>
                                                    </div> : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                {
                                    (order.invPrinted || order.shipDate)
                                        ? <ListGroupItem>
                                            <div style={{minWidth: 150, maxWidth: 250}}>
                                                {
                                                    order.invPrinted
                                                        ? <div className="mt-2">
                                                            <p className={"mb-1"}><strong>Invoiced date</strong></p>
                                                            <div>{changeFormatOfDateString(order.invPrinted, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}</div>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    order.shipDate
                                                        ? <div className="mt-2">
                                                            <p className={"mb-1"}><strong>Shipped date</strong></p>
                                                            <div>{changeFormatOfDateString(order.shipDate, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}</div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                <ListGroupItem>
                                    <div style={{minWidth: 150, maxWidth: 250, overflowY: 'auto'}}>
                                        <p className={"mb-1"}><strong>Notes</strong><Button
                                            color="link"
                                            className={"pl-0 float-right"}
                                            size={"sm"}
                                            title={"click here to edit"}
                                            >
                                        </Button></p>
                                        {order.notes}
                                    </div>
                                </ListGroupItem>
                                {
                                    (salesOrder && !isEmpty(salesOrder.attachmentIDs))
                                        ? <ListGroupItem>
                                            <div style={{minWidth: 150, maxWidth: 250}}>
                                                <UploadFiles
                                                    isReadOnly={true}
                                                    attachmentIDs={salesOrder ? salesOrder.attachmentIDs : ""}/>
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                <ListGroupItem>
                                    <div style={{minWidth: 200, maxWidth: 250}}>
                                        <SalesOrderNotes ordNum={order.ordNum}
                                                         isReadOnly={false}/>
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </div>
                        <hr/>
                        <div>
                            <Card>
                                <OrderEnquiryOrderItem
                                    order={order}
                                    currentUser={currentUser}
                                    visibleColumnsInOrderItems={visibleColumnsInOrderItems}
                                    toggleExpandRow={toggleExpandRow}
                                    toggleAllRowExpanded={toggleAllRowExpanded}

                                />
                            </Card>
                        </div>
                    </div>
                </div>

                <div style={{display: "none"}}>
                    <OrderEnquiryPrintMode order={order}
                                           currentUser={currentUser}
                                           visibleColumnsInOrderItems={visibleColumnsInOrderItems}
                                           toggleExpandRow={toggleExpandRow}
                                           toggleAllRowExpanded={toggleAllRowExpanded}
                                           ref={el => (this.componentRef = el)}
                    />
                </div>
            </div>
        );

    }
}
