import React, {Component} from 'react';
import {Button, Nav, NavItem, NavLink, Spinner, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import OrderEnquiryProductionModeOrderItemsProduct from "./OrderEnquiryProductionModeOrderItemsProduct";
import OrderEnquiryProductionModeOrderItemsProductPartList from "./OrderEnquiryProductionModeOrderItemsProductPartList";
import OrderEnquiryProductionModeStockGroupList from "./OrderEnquiryProductionModeStockGroupList";

let tabs = [
    {
        key: "partsByProductItem",
        label: "Parts per product item"
    },
    {
        key: "allParts",
        label: "Parts for all Build "
    },
    {
        key: "stock_group",
        label: "Stock Group"
    },
];

export default class OrderEnquiryProductionModeOrderItems extends Component {

    render() {
        let {
            order, activeTab, toggleTab, isPrinting, toggleProductCollapse,
            toggleProductItemCollapse, bomModal
        } = this.props;

        let activeTabObj = tabs.find(x => x.key === activeTab);
        return (
            <div>
                <div className={classnames({"card": !isPrinting})}>
                    <div className={classnames({"card-header": !isPrinting})}>
                        {
                            isPrinting
                                ? <h5>{activeTabObj ? activeTabObj.label : ""}</h5>
                                : <Nav tabs card>
                                    {tabs.map((tab, index) => {
                                            return <NavItem className={"hoverableItem"} key={index}>
                                                <NavLink
                                                    className={classnames({active: activeTab === tab.key})}
                                                    onClick={() => {
                                                        toggleTab(tab.key);
                                                    }}>
                                                    <Button size={"sm"}
                                                            color={"link"}>{tab.label}
                                                    </Button>
                                                </NavLink>
                                            </NavItem>
                                        }
                                    )}
                                </Nav>
                        }
                    </div>
                    <div className={classnames({"card-body": !isPrinting})}>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId='partsByProductItem'>
                                <OrderEnquiryProductionModeOrderItemsProduct
                                    toggleProductCollapse={toggleProductCollapse}
                                    toggleProductItemCollapse={toggleProductItemCollapse}
                                    isPrinting={isPrinting}
                                    order={order}
                                    bom={bomModal}
                                />
                            </TabPane>
                            <TabPane tabId='allParts'>
                                <OrderEnquiryProductionModeOrderItemsProductPartList
                                    order={order}
                                    rows={order.partsConsolidated}
                                    activeParts={"allParts"}
                                    bom={bomModal}
                                />
                            </TabPane>
                            <TabPane tabId='stock_group'>
                                <OrderEnquiryProductionModeStockGroupList ordNum={order.ordNum} />
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>
        )
    }
}