import { authHeader } from '../../helpers/auth-header';
import { config } from '../../helpers/config'
import axios from '../axios';
import axiosFileDownloader from "../axiosFileDownloader";

export default class SalesOrderService {
   
    getOutstandingOrderSummary() {
        return axios.get('api/dashboard/outstanding/order/summary');
    }


  /*  getSalesOrderKeywayByDebtorInvoiceOrderNum(ordNum) {
        return axios.get('api/keyway/sales-order/one/by/debtor-invoice-order-number?ordNum=' + ordNum)
    }

    getSalesOrderAttachmentID(ordNum) {
        return axios.get('api/keyway/sales-order/order/attachmentID?OrdNum=' + ordNum);
    }
*/
}