import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    Col,
    Row,
    Table,
    Card,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    Badge, Spinner, Breadcrumb,
    BreadcrumbItem
} from "reactstrap";
import {ToastContainer, toast} from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import emailLogService from '../../services/EmailLogService';
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';
import {handleErrorMessage} from '../../services/CommonService';
import EmailLogModal from '../../components/modal/EmailLogModal';
//import StatementScheduledDateUpdateModal from '../../components/modal/StatementScheduledDateUpdateModal';
import quartzService from '../../services/QuartzService';
import cloneDeep from 'lodash/cloneDeep';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import UploadFiles from '../../components/attachment/UploadFiles';
import * as FileSaver from 'file-saver';
import queryString from 'query-string';
import {BUNNINGS_STORE_ACCOUNT_IDs} from "../../store/AppConstants";

const date = new Date();
export default class EmailLogsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emailLogData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "createdDate",
                        direction: false
                    },
                    filterRequest: {
                        accountID: '',
                        emailJobType: 'All',
                        startDate: moment(date).subtract(30, 'day').toDate(),
                        endDate: date,
                        fullName: '',
                        emailJobDescription: '',
                        emailJobTypeFilter: '',
                        emailJobName: ''
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            pageLabel: this.props.pageLabel,
            loading: true,
            isOpenModal: false,
            downloading: false,
            selectedEmailLog: {},
            nextScheduledDate: ''
        };

        this.toggleEmailLogModal = this.toggleEmailLogModal.bind(this);
        this.toggleTriggerModal = this.toggleTriggerModal.bind(this);
        this.refreshAgain = this.refreshAgain.bind(this);
        this.openEmailLogModal = this.openEmailLogModal.bind(this);
    }

    componentDidMount() {
        let {emailLogData} = this.state;
        this.getEmailLogs(emailLogData);
        //switch (emailLogData.request.filterRequest.emailJobType) {
        //    case "StatementEmail":
        //        this.getNextScheduledDateOfStatement();
        //        break;
        //    case "FollowUp":
        //        this.getNextQuoteReminderEmailScheduledDate();
        //        break;
        //}
    }

    getNextScheduledDateOfStatement() {
        quartzService.getNextScheduledDate().then(response => {
            this.setState({nextScheduledDate: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getEmailLogs(emailLogData) {
        this.setState({loading: true});

        let request = cloneDeep(emailLogData.request);
        if (request.filterRequest.startDate != null) {
            request.filterRequest.startDate = moment(request.filterRequest.startDate).format('DD/MM/YYYY');
        }

        if (request.filterRequest.endDate != null) {
            request.filterRequest.endDate = moment(request.filterRequest.endDate).format('DD/MM/YYYY');
        }

        emailLogService.getEmailLogs(request).then(response => {
            emailLogData.response = response.data;
            this.setState({emailLogData, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleChange(change, key) {
        let {emailLogData} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (emailLogData.request.sortRequest.key === change) {
                    emailLogData.request.sortRequest.direction = !emailLogData.request.sortRequest.direction;
                } else {
                    emailLogData.request.sortRequest.key = change;
                    emailLogData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getEmailLogs(emailLogData);
                }
                break;
            case "pageSize":
                emailLogData.request.pageRequest[key] = parseInt(change);
                emailLogData.request.pageRequest.currentPage = 1;
                this.getEmailLogs(emailLogData);
                break;
            case "currentPage":
                emailLogData.request.pageRequest[key] = change;
                this.getEmailLogs(emailLogData);
                break;
            case "emailJobName":
                if (change === "All") {
                    emailLogData.request.filterRequest.emailJobName = '';
                    this.setState({ emailLogData }, () => {
                       this.getEmailLogs(emailLogData);
                    });
                } else {
                    emailLogData.request.filterRequest[key] = change;
                    emailLogData.request.pageRequest.currentPage = 1;
                    this.setState({ emailLogData }, () => {
                       this.getEmailLogs(emailLogData);
                    });
                }
                break;
            case "emailJobTypeFilter":
                if (change === "All") {
                    emailLogData.request.filterRequest.emailJobTypeFilter = '';
                    this.setState({ emailLogData }, () => {
                        this.getEmailLogs(emailLogData);
                    });
                } else {
                    emailLogData.request.filterRequest[key] = change;
                    emailLogData.request.pageRequest.currentPage = 1;
                    this.setState({ emailLogData }, () => {
                        this.getEmailLogs(emailLogData);
                    });
                }
                break;
            default:
                emailLogData.request.filterRequest[key] = change;
                emailLogData.request.pageRequest.currentPage = 1;
                this.getEmailLogs(emailLogData);
                this.setState({emailLogData});
        }

    }

    getStore() {
        let { emailLogData } = this.state;
        return [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                isVisible: true,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{recordIndex + 1}</span>;
                }

            },
            {
                key: "accountID",
                label: "Account",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNodeColSpan: 2,
                searchNode: <div>
                    <SearchDebtorAcccount
                        handleAccountChange={(accountID) => this.handleChange(accountID, 'accountID')}
                        selectedAccountID={emailLogData.request.filterRequest.accountID}
                        defaultAccountID={emailLogData.request.filterRequest.accountID}
                        includeChildren={true}
                        excludeClosedandInactive={false}
                        parentAccountIDs={BUNNINGS_STORE_ACCOUNT_IDs}
                    />
                </div>,
                isVisible: true,
                render: function (value, record, recordIndex, data, ref) {
                    return <Link
                        className={'btn btn-primary btn-sm'}
                        style={{color: "white"}}
                        title={"Click here to see account details"}
                        to={"/customer/account/detail?" + queryString.stringify({ accountID: value })}>{value}</Link>;
                }

            },
            {
                key: "company",
                label: "Company",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle text-left",
                searchNode: null,
                searchNodeColSpan: 0,
                isVisible: true,
                render: function (value, record, recordIndex, data, ref) {
                    return <span className="sm">{value}</span>;
                }
            },
            {
                key: "tos",
                label: "To",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle text-left",
                searchNodeColSpan: 1,
                searchNode: <Input type='text' placeholder='Search here...'
                                   name='to' value={emailLogData.request.filterRequest.to || ''}
                    onChange={(e) => this.handleChange(e.target.value, e.target.name)} />,
                isVisible: true,
                render: function (value, record, recordIndex, data, ref) {
                    return <span className="sm">{value}</span>;
                }
            },
            {
                key: "fullName",
                label: "Sent By",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: false,
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle text-left",
                searchNodeColSpan: 1,
                searchNode: <Input type='text' placeholder='Search here...'
                    name='fullName' value={emailLogData.request.filterRequest.fullName || ''}
                    onChange={(e) => this.handleChange(e.target.value, e.target.name)} />,
                isVisible: emailLogData.request.filterRequest.emailJobType === "All" ? true : false,
                render: function (value, record, recordIndex, data, ref) {
                    if (record.sentByUserId === null && record.triggeredBy === "AUTO_GENERATED") {
                        return <span className="sm">Keyway</span>;
                    }
                    else {
                        return <span className="sm">{value}</span>;
                    }
                }
            },
            {
                key: "createdDate",
                label: "Sent On",
                type: "text",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: true,
                valueClassName: "align-middle text-right",
                labelClassName: "hoverableItem align-middle text-left",
                searchNodeColSpan: 1,
                searchNode: <div>
                    <div className={"text-right mb-1"}>
                        <DatePicker
                            className="form-control form-control-sm"
                            selected={emailLogData.request.filterRequest.startDate}
                            onChange={date => this.handleChange(date, "startDate")}
                            selectsStart
                            startDate={emailLogData.request.filterRequest.startDate}
                            endDate={emailLogData.request.filterRequest.endDate}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            placeholderText="Start date"
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            dropdownMode="select"
                            withPortal
                        />
                    </div>
                    <div className={"text-right"}>
                        <DatePicker
                            className="form-control form-control-sm"
                            selected={emailLogData.request.filterRequest.endDate}
                            onChange={date => this.handleChange(date, "endDate")}
                            selectsEnd
                            startDate={emailLogData.request.filterRequest.startDate}
                            endDate={emailLogData.request.filterRequest.endDate}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            placeholderText="End date"
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            dropdownMode="select"
                            withPortal
                        />
                    </div>
                </div>,
                isVisible: true,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>;
                }
            },
            {
                key: "monthString",
                label: "Month",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: false,
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle text-left",
                searchNode: null,
                searchNodeColSpan: 1,
                isVisible: emailLogData.request.filterRequest.emailJobType === "StatementEmail" ? true : false,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>;
                }
            },
            {
                key: "year",
                label: "Year",
                type: "text",
                colSpan: 1,
                minWidth: 70,
                sorterApplicable: false,
                valueClassName: "text-right align-middle",
                labelClassName: "align-middle text-left",
                searchNode: null,
                searchNodeColSpan: 1,
                isVisible: emailLogData.request.filterRequest.emailJobType === "StatementEmail" ? true : false,
                render: function (value, record, recordIndex, data, ref) {
                    return <span className="text-right">{value}</span>;
                }
            },
            {
                key: "emailJobName",
                label: "Email Job Name",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "text-left align-middle",
                labelClassName: "hoverableItem text-left align-middle",
                searchNode: <Input type="select" name="emailJobName" value={emailLogData.request.filterRequest.emailJobName}
                    onChange={(e) => this.handleChange(e.target.value, e.target.name)}>
                    <option select value="" disabled>Select Job Name</option>
                    <option value="All">All</option>
                    <option value="Statement">Statement</option>
                    <option value="Dispatch">Dispatch</option>
                    <option value="User">User</option>
                    <option value="Lead">Lead</option>
                    <option value="FollowUp">Follow Up</option>
                    <option value="PowderCoat">PowderCoat</option>
                    <option value="DebtorInvoice">Debtor Invoice</option>
                    <option value="Share">Share</option>
                </Input>,
                searchNodeColSpan: 1,
                isVisible: emailLogData.request.filterRequest.emailJobType === "All" ? true : false,
                render: function (value, record, recordIndex, data, ref) {
                    return <span className="text-right">{value}</span>;
                }
            },
            {
                key: "emailJobType",
                label: "Email Job Type",
                type: "text",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: true,
                valueClassName: "text-left align-middle",
                labelClassName: "hoverableItem text-left align-middle",
                searchNode: <Input type="select" name="emailJobTypeFilter" value={emailLogData.request.filterRequest.emailJobTypeFilter}
                    onChange={(e) => this.handleChange(e.target.value, e.target.name)}>
                    <option select value="" disabled>Select Job Type</option>
                    <option value="All">All</option>
                    <option value="StatementEmail">Statement Email</option>
                    <option value="InvoiceEmail">Invoice Email</option>
                    <option value="DispatchMessage">Dispatch Message</option>
                    <option value="Registration">Registration</option>
                    <option value="Signup">Sign Up</option>
                    <option value="ForgotPassword">Forgot Password</option>
                    <option value="ChangePassword">Change Password</option>
                    <option value="SHPDocument">SHP Document</option>
                    <option value="Lead">Lead</option>
                    <option value="FollowUp">Follow Up</option>
                    <option value="PowderCoat">PowderCoat</option>
                    <option value="OrderSubmitted">Order Submitted</option>
                    <option value="Share">Share</option>
                </Input>,
                searchNodeColSpan: 1,
                isVisible: emailLogData.request.filterRequest.emailJobType === "All" ? true : false,
                render: function (value, record, recordIndex, data, ref) {
                    return <span className="text-right">{value}</span>;
                }
            },

            {
                key: "emailJobDescription",
                label: "Email Job Description",
                type: "text",
                colSpan: 1,
                minWidth: 230,
                sorterApplicable: true,
                valueClassName: "text-left align-middle",
                labelClassName: "hoverableItem text-left align-middle",
                searchNode: <Input type='text' placeholder='Search here...'
                    name='emailJobDescription' value={emailLogData.request.filterRequest.emailJobDescription || ''}
                    onChange={(e) => this.handleChange(e.target.value, e.target.name)} />,
                searchNodeColSpan: 1,
                isVisible: emailLogData.request.filterRequest.emailJobType === "All" ? true : false,
                render: function (value, record, recordIndex, data, ref) {
                    return <span className="text-right">{value}</span>;
                }
            },
            {
                key: "attachmentIDs",
                label: "Attachments",
                type: "text",
                colSpan: 1,
                minWidth: 200,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "text-center align-middle text-left",
                searchNode: null,
                searchNodeColSpan: 1,
                isVisible: true,
                render: function (value, record, recordIndex, data, ref) {
                    return <UploadFiles
                        isReadOnly={true}
                        attachmentIDs={value}/>;
                }
            },
            {
                key: "isError",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "align-middle text-left",
                searchNodeColSpan: 1,
                searchNode: <Input type='select' name='isError' value={emailLogData.request.filterRequest.isError}
                                   onChange={(e) => this.handleChange(e.target.value, e.target.name)}>
                    <option value="">All</option>
                    <option value="False">Success</option>
                    <option value="True">Error</option>
                </Input>,
                isVisible: true,
                render: function (value, record, recordIndex, data, ref) {
                    return <Badge color={value ? 'danger' : 'success'}>
                        {value ? 'Error' : 'Success'}
                    </Badge>;
                }
            },
            {
                key: "action",
                label: "View Email",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "text-center align-middle text-left",
                searchNode: null,
                searchNodeColSpan: 1,
                isVisible: true,
                render: function (value, record, recordIndex, data, ref) {
                    return <div className={"text-center"}>
                        <Button color={"primary"}
                                outline={true} size={"sm"}
                                onClick={() => ref.openEmailLogModal(record)}>
                            Open
                        </Button>
                    </div>;
                }
            }
        ];
    }

    openEmailLogModal(selectedEmailLog) {
        this.setState({selectedEmailLog, isOpenModal: true});
    }

    toggleEmailLogModal(data) {
        this.setState({isOpenModal: data});
    }

    openTriggerModal() {
        this.setState({isOpenTriggerModal: true});
    }

    toggleTriggerModal(data) {
        this.setState({isOpenTriggerModal: data});
    }

    refreshAgain() {
        let { emailLogData } = this.state;
        this.setState({isOpenTriggerModal: false});
        switch (emailLogData.request.filterRequest.emailJobType) {
            case "StatementEmail":
                this.getNextScheduledDateOfStatement();
                break;
            case "FollowUp":
                this.getNextQuoteReminderEmailScheduledDate();
                break;
        }
    }

    printStatementLogsInExcel = () => {
        let {emailLogData} = this.state;
        let emailJobType = emailLogData.request.filterRequest.emailJobType;
        let request = cloneDeep(emailLogData.request);

        if (request.filterRequest.startDate != null) {
            request.filterRequest.startDate = moment(request.filterRequest.startDate).format('DD/MM/YYYY');
        }

        if (request.filterRequest.endDate != null) {
            request.filterRequest.endDate = moment(request.filterRequest.endDate).format('DD/MM/YYYY');
        }

        this.setState({downloading: true});
        emailLogService.generateLogsInExcelReport(request).then(response => {
            this.setState({downloading: false});
            switch (emailJobType) {
                case "All":
                    FileSaver.saveAs(response.data, "AllEmailsSentThisMonth" + ".xlsx");
                    break;
                case "StatementEmail":
                    FileSaver.saveAs(response.data, "MonthlyStatementEmails" + ".xlsx");
                    break;
                case "FollowUp":
                    FileSaver.saveAs(response.data, "Quotes Reminder Sent Emails" + ".xlsx");
                    break;
            }
        }).catch(error => {
            this.setState({downloading: false});
            toast.error(handleErrorMessage(error));
        });
    };

    render() {

        let { emailLogData, loading, nextScheduledDate, downloading, pageLabel } = this.state;
        let { request, response } = emailLogData;
        let store = this.getStore();
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Email</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardBody>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <h5>{pageLabel}</h5>
                    </Col>
                    {/*{nextScheduledDate || request.filterRequest.emailJobType !== "All" ?*/}
                    {/*    <Col xl={6} lg={6} md={6} sm={12} xs={12}>*/}
                    {/*        <div className='text-right'>*/}
                    {/*            <Badge color='info' className='hand-cursor'*/}
                    {/*                   title='Update scheduled date'*/}
                    {/*                   onClick={() => this.openTriggerModal()}>*/}
                    {/*                <i className='fa fa-pencil mr-2'/>*/}
                    {/*                <strong>Next scheduled date : </strong>*/}
                    {/*                {nextScheduledDate} </Badge>*/}
                    {/*        </div>*/}
                    {/*    </Col> : null}*/}
                </Row>
                    <Row>
                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                            <div className={"text-left"}>
                                {loading ? <p>Loading...</p> :
                                    <p>Showing
                                        {' '}{((request.pageRequest.currentPage - 1) * request.pageRequest.pageSize) + 1}
                                        {' '}to {((request.pageRequest.currentPage) * request.pageRequest.pageSize)}
                                        {' '}of {response.totalRecords}
                                        {' '}entries</p>
                                }
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <div className='text-right'>
                                <Button color='success'
                                        outline={true} size='sm'
                                        title={'Click here to export data'}
                                        onClick={this.printStatementLogsInExcel} disabled={downloading}>
                                    {downloading
                                        ? <Spinner size="sm"
                                                   className={"mr-2"}
                                                   style={{color: "green"}}/>
                                        : <i className="fa fa-file-excel-o mr-2" aria-hidden="true"/>}Export
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div>
                        <Table striped bordered responsive hover size='sm'>
                            <thead>
                            <tr>
                                {(store || []).map((item, index) => {
                                    return (
                                        item.isVisible && <th key={index}
                                            onClick={() => this.handleChange(item.key, "sortKey")}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{minWidth: item.minWidth}}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                            "fa-sort": (request.sortRequest.key !== item.key),
                                                            "fa-sort-amount-asc": (request.sortRequest.key === item.key && request.sortRequest.direction),
                                                            "fa-sort-amount-desc": (request.sortRequest.key === item.key && !request.sortRequest.direction),
                                                        }
                                                    )} aria-hidden="true"/> : null
                                            }

                                        </th>
                                    );
                                })}
                            </tr>
                            <tr>
                                    {(store || []).map((item, index) => {
                                        if (item.isVisible && item.searchNodeColSpan > 0) {
                                        return (
                                            <td key={index} colSpan={item.searchNodeColSpan}
                                                className={"align-middle"}>
                                                {item.searchNode}
                                            </td>
                                        );
                                    }
                                    return null;
                                })}
                            </tr>

                            </thead>

                            <tbody>
                            {
                                loading
                                    ? <tr>
                                        <td colSpan={(store || []).length}><Spinner color={"primary"}/></td>
                                    </tr>
                                    : (response.records || []).map((row, rowIndex) =>
                                        <tr key={rowIndex} className={"align-middle"}>
                                            {(store || []).map((column, columnIndex) => {
                                                return (
                                                    column.isVisible && <td key={columnIndex} className={column.valueClassName}>
                                                        {column.render(row[column.key], row, rowIndex, emailLogData, this)}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    )}
                            </tbody>
                        </Table>
                            </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={request.pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={request.pageRequest.currentPage}
                                        itemsCountPerPage={request.pageRequest.pageSize}
                                        totalItemsCount={response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                    </Card>
                {this.state.isOpenModal ? <EmailLogModal
                    isOpen={this.state.isOpenModal}
                    toggle={this.toggleEmailLogModal}
                    emailLog={this.state.selectedEmailLog}
                /> : null}

                {this.state.isOpenTriggerModal ? <StatementScheduledDateUpdateModal
                    isOpen={this.state.isOpenTriggerModal}
                    toggle={this.toggleTriggerModal}
                    refreshAgain={this.refreshAgain}
                /> : null}
                <ToastContainer/>
            </div>
        );
    }
}
