import { isEmpty, cloneDeep } from 'lodash';
import { findIndex, getSorted, groupBy } from "../services/CommonService";

class OrderEnquiryUtil {

    static Instance() {
        return new OrderEnquiryUtil()
    }

    initOrderProducts(order) {
        let products = order.products;
        (products || []).forEach(product => {
            product.isOpen = false;
            //product.items = order.items.filter(x => !isEmpty(x.product) && !isEmpty(x.productLabel) && x.product.code === product.code);
            product.items = order.items.filter(x => !isEmpty(x.product) && x.product.code === product.code);
            product.items.forEach(x => {
                x.isOpen = false;
            });
        });
        return products;
    }

    initOrderItemProductParts(order) {
        let products = order.products;
        (products || []).forEach(product => {
            product.items.forEach(item => {
                item.isOpen = false;

                //preparing parts
                item.parts = order.parts.filter(x => x.debtorInvoiceParentItemNum === item.parentItemNum);

                //calculating attributeRowSpan
                item.parts = getSorted(item.parts, 'attribute', true);
                let tempPartsByAttribute = groupBy(item.parts, 'attribute');
                (item.parts || []).map(part => {
                    part.attributeRowSpan = tempPartsByAttribute[part.attribute].length;
                    tempPartsByAttribute[part.attribute] = [];
                });
            })
        });
        return products;
    }

    initOrderItemConsolidatedParts(order) {
        let partsConsolidated = [], partsConsolidatedIndex = -1;
        let temp = {};
        let partsByProdCode = groupBy(order.parts, 'prodCode');
        if (!isEmpty(partsByProdCode)) {
            for (let prodCode in partsByProdCode) {
                partsByProdCode[prodCode].forEach(part => {
                    partsConsolidatedIndex = findIndex(partsConsolidated, "prodCode", part.prodCode);
                    if (partsConsolidatedIndex === -1) {
                        temp = cloneDeep(part);
                        temp.attribute = temp.attribute ? temp.attribute : "";
                        temp.attributeRowSpan = temp.attributeRowSpan ? temp.attributeRowSpan : 1;
                        partsConsolidated.push(temp);
                    } else {
                        partsConsolidated[partsConsolidatedIndex].attribute = temp.attribute ? temp.attribute : "";
                        partsConsolidated[partsConsolidatedIndex].attributeRowSpan = 1;
                        partsConsolidated[partsConsolidatedIndex].label = temp.label ? temp.label : "";
                        partsConsolidated[partsConsolidatedIndex].swishQtyWithWastage += part.swishQtyWithWastage;
                        partsConsolidated[partsConsolidatedIndex].keywayQtyWithDeduction += part.keywayQtyWithDeduction;
                        partsConsolidated[partsConsolidatedIndex].swishQty += part.swishQty;
                    }

                });
            }
        }
        return partsConsolidated;
    }
}

export default OrderEnquiryUtil.Instance();