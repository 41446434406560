import React from 'react';

import {cloneDeep, isEmpty} from 'lodash';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';

class VenetianUtil {

    static Instance() {
        return new VenetianUtil();
    }


    updateFormError(key, product, itemIndex, order) {
        let width, drop, condition1 = "", condition2 = "", condition3 = "", currDiff, prevDiff, setIndex, temp;
        width = product.items[itemIndex].configuration.width.selected.value;
        drop = product.items[itemIndex].configuration.drop.selected.value;


        if (product.items.length > 0 && product.items[itemIndex]) {
            let formError = {
                isValid: true,
                message: ""
            };
            switch (key) {
                case "quantity":
                    if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < product.items[itemIndex].configuration.quantity.min) || (product.items[itemIndex].configuration.quantity.selected.value > product.items[itemIndex].configuration.quantity.max)) {
                        formError = {
                            isValid: false,
                            message: "Expected quantity between " + product.items[itemIndex].configuration.quantity.min + " and " + product.items[itemIndex].configuration.quantity.max,
                        };
                    }
                    product.items[itemIndex].configuration.quantity.formError = formError;
                    break;
                case "width":
                    if ((!product.items[itemIndex].configuration.width.selected.value) || (product.items[itemIndex].configuration.width.selected.value < product.items[itemIndex].configuration.width.min) || (product.items[itemIndex].configuration.width.selected.value > product.items[itemIndex].configuration.width.max)) {
                        formError = {
                            isValid: false,
                            message: "Expected width between " + product.items[itemIndex].configuration.width.min + " and " + product.items[itemIndex].configuration.width.max,
                        };
                    }
                    if (formError.isValid) {
                        if (!(product.items[itemIndex].configuration.fineline.selected.value && product.items[itemIndex].configuration.fineline.selected.value.makeBuild && product.items[itemIndex].configuration.fineline.selected.value.makeBuild.prodCode)) {
                            temp = "The closest size available is x99 or x15.";
                            prevDiff = 1000000;
                            condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                            condition2 = product.items[itemIndex].configuration.colour.selected.value.optionKey;
                            condition3 = product.items[itemIndex].configuration.mount.selected.value.optionKey;

                            (product.items[itemIndex].configuration.bom.options || []).forEach(o => {
                                switch (o.optionKey) {
                                    case "MakeBuild":
                                        o.sets.forEach((s, sindex) => {
                                            if (
                                                (
                                                    (s.condition1 ? s.condition1 === (condition1) : true)
                                                    && (s.condition2 ? s.condition2 === (condition2) : true)
                                                    && (s.condition3 ? s.condition3 === (condition3) : true)
                                                )
                                            ) {
                                                currDiff = Math.abs(width - s.min) > Math.abs(width - s.max) ? Math.abs(width - s.max) : Math.abs(width - s.min);
                                                if (currDiff < prevDiff) {
                                                    prevDiff = currDiff;
                                                    setIndex = sindex;
                                                }
                                            }
                                        });
                                        if (setIndex > -1) {
                                            temp = "The closest size available is between " + o.sets[setIndex].min + "mm and " + o.sets[setIndex].max + "mm.";
                                        }
                                        break;
                                }
                            });

                            formError = {
                                isValid: false,
                                message: temp
                            };
                        }
                    }
                    product.items[itemIndex].configuration.width.formError = formError;
                    break;
                case "drop":
                    if ((!product.items[itemIndex].configuration.drop.selected.value) || (product.items[itemIndex].configuration.drop.selected.value < product.items[itemIndex].configuration.drop.min) || (product.items[itemIndex].configuration.drop.selected.value > product.items[itemIndex].configuration.drop.max)) {
                        formError = {
                            isValid: false,
                            message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                        };
                    }
                    product.items[itemIndex].configuration.drop.formError = formError;
                    break;
                case "parentStockItem":
                    if (!(product.items[itemIndex].stocks.some(s => s.isParentItem))) {
                        formError = {
                            isValid: false,
                            message: "Fineline item missing",
                        };
                    }
                    /*if(formError.isValid){
                        if (product.items[itemIndex].configuration.fineline.selected.value.product && product.items[itemIndex].configuration.fineline.selected.value.product.soldOut) {
                            formError = {
                                isValid: false,
                                message: "Sold out!",
                            };
                        }
                    }*/
                    product.items[itemIndex].configuration.fineline.formError = formError;
                    break;
                default:
                    break;
            }

            product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);
        }

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return product.items[itemIndex].configuration.width.formError.isValid
            && product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
            && product.items[itemIndex].configuration.fineline.formError.isValid
    }

    updateRules(key, product, itemIndex, order) {
        switch (key) {
            case "quantity":
                product.items[itemIndex].configuration.quantity.min = 1;
                product.items[itemIndex].configuration.quantity.max = 10000;
                break;
            case "width":
                product.items[itemIndex].configuration.width.min = 350;
                product.items[itemIndex].configuration.width.max = 2100;
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.min = 300;
                product.items[itemIndex].configuration.drop.max = 2100;
                break;
        }
        return product;
    }

    updateStocks(key, product, itemIndex, order) {
        let comment, stocks, stockIndex, label, attribute, width, drop, condition1 = "", condition2 = "",
            condition3 = "", setIndex,
            isStandardSize;
        width = product.items[itemIndex].configuration.width.selected.value;
        drop = product.items[itemIndex].configuration.drop.selected.value;

        switch (key) {
            case "model":
                attribute = "model";
                label = "";
                comment = "";
                stocks = [];
                if (!isEmpty(product.items[itemIndex].configuration.model.selected.value)) {
                    product.items[itemIndex].configuration.model.selected.value.sets.forEach(set => {
                        stocks = [...stocks, ...set.stocks]
                    });
                }

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.model.selected.stocks = stocks;
                break;
            case "bom":
                attribute = "Bom";
                label = "";
                comment = "";
                stocks = [];

                product.items[itemIndex].configuration.fineline.selected.value = {
                    product: {},
                    cutFee: {},
                    makeBuild: {}
                };

                condition1 = product.items[itemIndex].configuration.model.selected.value.optionKey;
                condition2 = product.items[itemIndex].configuration.colour.selected.value.optionKey;
                condition3 = product.items[itemIndex].configuration.mount.selected.value.optionKey;

                (product.items[itemIndex].configuration.bom.options || []).forEach(o => {
                    switch (o.optionKey) {
                        case "MakeBuild":
                            o.sets.forEach(s => {
                                if ((s.condition1 ? s.condition1 === (condition1) : true)) {
                                    if ((s.condition2 ? s.condition2 === (condition2) : true)) {
                                        if ((s.condition3 ? s.condition3 === (condition3) : true)) {
                                            if ((width >= s.min && width <= s.max)) {
                                                if ((drop >= s.secondaryMin && drop <= s.secondaryMax)) {
                                                    (s.stocks || []).forEach(stockItem => {
                                                        stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                                        stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                                        product.items[itemIndex].configuration.fineline.selected.value.makeBuild = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem, "makeBuild");
                                                        product.items[itemIndex].configuration.fineline.selected.value.makeBuild.type = s.description;
                                                    });
                                                }
                                            }
                                        }
                                    }
                                }
                            });
                            break;
                        case "BunningsBuild":
                            if (product.items[itemIndex].configuration.fineline.selected.value.makeBuild && product.items[itemIndex].configuration.fineline.selected.value.makeBuild.prodCode) {
                                setIndex = o.sets.findIndex(s => {
                                    if ((s.condition1 ? s.condition1 === (condition1) : true)) {
                                        if ((s.condition2 ? s.condition2 === (condition2) : true)) {
                                            if ((s.condition3 ? s.condition3 === (condition3) : true)) {
                                                switch (product.items[itemIndex].configuration.fineline.selected.value.makeBuild.type) {
                                                    case "Standard":
                                                        if ((width >= s.min) && (width <= s.max)) {
                                                            if ((drop >= s.secondaryMin) && (drop <= s.secondaryMax)) {
                                                                return true;
                                                            }
                                                        }
                                                        break;
                                                    case "OffRange":
                                                        if (width <= parseInt(s.setKey)) {
                                                            if (drop <= parseInt(s.setLabel)) {
                                                                return true;
                                                            }
                                                        }
                                                        break;
                                                }
                                            }
                                        }
                                    }
                                });
                                if (setIndex > -1) {
                                    isStandardSize = ((width === parseInt(o.sets[setIndex].setKey)) && (drop === parseInt(o.sets[setIndex].setLabel)));
                                    (o.sets[setIndex].stocks || []).forEach(stockItem => {
                                        stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                        stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                        product.items[itemIndex].configuration.fineline.selected.value.product = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem, "product");
                                        product.items[itemIndex].configuration.offRange.selected.value = salesOrderProductBuilderV1Service.createOffRangeItemInstance(stockItem);
                                    });
                                    stocks = [...stocks, ...o.sets[setIndex].stocks];
                                }
                            }
                            break;
                        case "Cut Fee":
                            if (!isStandardSize) {
                                o.sets.forEach(s => {
                                    (s.stocks || []).forEach(stockItem => {
                                        stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                        stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                        product.items[itemIndex].configuration.fineline.selected.value.cutFee = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem, "cutFee");
                                    });
                                    stocks = [...stocks, ...s.stocks];
                                });
                            }
                            break;
                    }
                });

                stocks.forEach((stockItem, stockIndex) => {
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.bom.selected.stocks = stocks;
                break;
        }
        return product;
    }

    doCalculation(key, product, itemIndex) {
        let width = product.items[itemIndex].configuration.width.selected.value;
        let drop = product.items[itemIndex].configuration.drop.selected.value;
        let mount = product.items[itemIndex].configuration.mount.selected.value.optionKey;

        let deduction = salesOrderProductBuilderV1Service.initDeduction();
        switch (key) {
            case "keyway":
                deduction.deductionWidth = -65;
                break;
            case "hooding":
                deduction.deductionWidth = -35;
                break;
            case "hoodRailHole":
                deduction.deductionWidth = -30;
                break;
            case "bottomRail":
                deduction.deductionWidth = -45;
                break;
            case "sideChannel":
                switch (mount) {
                    case "Top Mount":
                        deduction.deductionDrop = -110;
                        break;
                    case "Face Mount":
                        deduction.deductionDrop = -105;
                        break;
                }
                break;
            case "fabric":
                deduction.deductionWidth = -65;
                break;
        }
        deduction.cutWidth = width + (deduction.deductionWidth);
        deduction.cutDrop = drop + (deduction.deductionDrop);
        return deduction;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            if (product.maxWidth < product.items[itemIndex].configuration.width.selected.value) {
                product.maxWidth = product.items[itemIndex].configuration.width.selected.value;
            }
        });
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            product = this.updateStocks("model", product, itemIndex, order);
            product = this.updateStocks("bom", product, itemIndex, order);
        }
        return product;
    }

    getItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            let stocks = [];
            stocks = [...stocks, ...product.items[itemIndex].configuration.model.selected.stocks];
            stocks = [...stocks, ...product.items[itemIndex].configuration.bom.selected.stocks];
            product.items[itemIndex].stocks = stocks;
        }
        return product;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode, showError) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex);
        if(showError){
            product = this.updateFormError("quantity", product, itemIndex, order);
            product = this.updateFormError("width", product, itemIndex, order);
            product = this.updateFormError("drop", product, itemIndex, order);
            product = this.updateFormError("parentStockItem", product, itemIndex, order);
        }
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling, discountByProductCode);
        return product;
    }

    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutWidth", params.cutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutDrop", params.cutDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLinearWidth", params.stockLinearWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression);
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, stockByProdCode, comment) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.drop.selected.value : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        let deduction = {};
        stockItem.width = 0;
        stockItem.drop = 0;
        stockItem.length = (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1;
        stockItem.stockLinearWidth = (stockInventoryItem && stockInventoryItem.linearWidth > 0) ? stockInventoryItem.linearWidth * 1.0 : 1;

        let params = {
            width: width,
            drop: drop,
            stockLength: stockItem.length,
            stockLinearWidth: stockItem.stockLinearWidth,
            swishConversionFactor: stockItem.swishConversionFactor,
        };

        switch (attribute) {
            default:
                stockItem.width = stockItem.isParentItem ? width : 0;
                stockItem.drop = stockItem.isParentItem ? drop : 0;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
        }
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | Row-" + (itemIndex + 1) + " | ";

        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }
        if (!pg.items[itemIndex].configuration.width.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.width.formError.message);
        }
        if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }
        if (!pg.items[itemIndex].configuration.fineline.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.fineline.formError.message);
        }


        return errors;
    }


    toSalesOrderItemVenetianRequest(product, itemIndex, order) {


        let salesOrderItemVenetian = {
            ID: product.items[itemIndex].configuration.ID ? product.items[itemIndex].ID : null,
            salesOrderItemID: product.items[itemIndex].configuration.salesOrderItemID ? product.items[itemIndex].salesOrderItemID : null,
            salesOrderID: product.items[itemIndex].configuration.salesOrderID ? product.items[itemIndex].salesOrderID : null,
            quantity: product.items[itemIndex].configuration.quantity.selected.value,
            width: product.items[itemIndex].configuration.width.selected.value,
            drop: product.items[itemIndex].configuration.drop.selected.value,
            model: product.items[itemIndex].configuration.model.selected.value.optionKey,
            mount: product.items[itemIndex].configuration.mount.selected.value.optionKey,
            colour: product.items[itemIndex].configuration.colour.selected.value.optionKey,
            standardProdCode: product.items[itemIndex].configuration.fineline.selected.value.product.prodCode,
            makeProdCode: product.items[itemIndex].configuration.fineline.selected.value.makeBuild.prodCode,
            type: product.items[itemIndex].configuration.fineline.selected.value.makeBuild.type,
            stocks: product.items[itemIndex].stocks,
        };
        return salesOrderItemVenetian;
    }

}

export default VenetianUtil.Instance();