import React, {Component} from "react";
import {
    Button,
    Col,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from 'reactstrap';
import Select from 'react-select';
import {toast} from "react-toastify";
import RoleService from '../../services/RoleService';
import {groupBy, handleErrorMessage} from '../../services/CommonService';
import {dashboardCards} from '../../store/DashboardCardConstants';
import PrivilegeService from "../../services/PrivilegeService";
import {cloneDeep} from 'lodash';

const roleTypeOptions = [
    {value: false, label: 'Internal'},
    {value: true, label: 'External'}
];

export default class ManageUserRoleModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: this.props.selectedItem,
            saving: false,
            dashboardOptions: [],
            selectedDashboardOptions: [],
            role: {},
            privilegeOptions: [],
            privilegeGroupNames: [],
            selectedPrivilegeOptions: [],
            formErrors: {},
            selectedPrivilegeGroups: [],
            selectedRoleType: [],
            selectedRole: ""
        };

        this.roleService = new RoleService();
        this.privilegeService = new PrivilegeService();
        this.handleChange = this.handleChange.bind(this);
        this.getPrivilegesList = this.getPrivilegesList.bind(this);
        this.saveUserRole = this.saveUserRole.bind(this);
        this.setPrivilegesAccordingToRole = this.setPrivilegesAccordingToRole.bind(this);
        this.getAllValues = this.getAllValues.bind(this);

        let roles = cloneDeep(this.props.roles);
        this.roleOptions = roles.map((item) => ({
            label: item.role.name,
            value: item.role.name,
            roleID: item.role.roleID
        }))
    }

    componentDidMount() {
        let {dashboardOptions} = this.state;
        let roles = cloneDeep(this.props.roles);
        dashboardCards.forEach(option => {
            dashboardOptions.push(option);
        });

        this.setState({dashboardOptions, roles}, () => {
            this.getAllValues();
        });
    }


    getAllValues() {
        let {selectedItem, role, dashboardOptions, selectedRole} = this.state;
        let selectedDashboardOptions = [], selectedRoleType = [];
        if (selectedItem.role) {
            role = selectedItem.role;
            selectedRoleType.push(roleTypeOptions.find(x => x.value === role.isExtUser));
        }
        if (selectedItem.dashboard) {
            selectedItem.dashboard.forEach(ele => {
                if (dashboardOptions.find(x => x.value === ele))
                    selectedDashboardOptions.push(dashboardOptions.find(x => x.value === ele));
            });
        }
        if (selectedRole) {
            if (selectedItem.role.name) {
                selectedItem.role.name = "";
            }
            if (selectedItem.role.description) {
                selectedItem.role.description = "";
            }
        }

        this.setState({selectedDashboardOptions, role, selectedRoleType, selectedItem}, () => {
            this.getPrivilegesList();
        });
    }

    handleChange(key, change) {
        let {role, privilegeOptions, privilegeGroupNames} = this.state;
        let privilege = [], dashboards = '', selectedPrivilegeOptions = [], selectedPrivilegeGroups = [];
        switch (key) {
            case "privilegeIds":
                if (change) {
                    for (let i = 0; i < change.length; i++) {
                        privilegeOptions.map(privilege => {
                            if (privilege.options.find(x => x.value === change[i].value) && !selectedPrivilegeGroups.some(x => x.label === privilege.label)) {
                                selectedPrivilegeGroups.push(privilegeGroupNames.find(x => x.label === privilege.label));
                            }
                        });
                        privilege.push(change[i].value);
                    }
                    role.privilegeIds = privilege;
                    this.setState({selectedPrivilegeGroups, selectedPrivilegeOptions: change, role});
                }
                else {
                    this.setState({selectedPrivilegeGroups, selectedPrivilegeOptions, role});
                }
                break;
            case "dashboards":
                if (change) {
                    for (let i = 0; i < change.length; i++) {
                        dashboards += change[i].value.toString() + ","
                    }
                    role.dashboards = dashboards.slice(0, -1);
                    this.setState({selectedDashboardOptions: change, role});
                }
                break;
            case "groups":
                if (change) {
                    for (let i = 0; i < change.length; i++) {
                        if (privilegeOptions.find(x => x.label === change[i].value)) {
                            privilegeOptions.find(x => x.label === change[i].value).options.map(option => {
                                privilege.push(option.value);
                                selectedPrivilegeOptions.push(option);
                            });
                        }
                    }
                    role.privilegeIds = privilege;
                    this.setState({selectedPrivilegeGroups: change, selectedPrivilegeOptions, role});
                }
                else {
                    this.setState({selectedPrivilegeGroups, selectedPrivilegeOptions, role});
                }
                break;
            case "roleType":
                if (change) {
                    role.isExtUser = change.value;
                    this.setState({role, selectedRoleType: change});
                }
                break;
            case "name":
            case "description":
                role[key] = change;
                this.setState({role});
                break;
        }


    }

    saveUserRole() {
        this.setState({saving: true});
        let result = this.validateForm(this.state.role);
        if (!result.valid) {
            this.setState({formErrors: result.formErrors, saving: false});
            return false;
        }
        else {
            this.roleService.saveRole(this.state.role).then(response => {
                this.setState({saving: false});
                toast.success("Saved!", {position: toast.POSITION.BOTTOM_CENTER});
                this.props.refreshAgain();
            }).catch(error => {
                this.setState({saving: false});
                toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
            })
        }
    }

    getPrivilegesList() {
        let privilegeOptions = [], groupNames = [], privilegeGroupNames = [], privilege = [];
        let {selectedItem, selectedPrivilegeOptions, selectedPrivilegeGroups, role} = this.state;

        this.privilegeService.getPrivilege().then(response => {
            let privileges = response.data;
            (privileges || []).forEach(privilege => {
                if (privilege.groupName === null) {
                    privilege.groupName = "Other";
                }
            });
            groupNames = groupBy((privileges || []), 'groupName');

            privileges.filter(privilege => {
                if (!privilegeGroupNames.some(x => x.value === privilege.groupName))
                    privilegeGroupNames.push({'value': privilege.groupName, 'label': privilege.groupName});
            });

            for (let key in groupNames) {
                let privilegeDropDownOptions = [];
                groupNames[key].map(privilege => {
                    privilegeDropDownOptions.push({'value': privilege.id, 'label': privilege.name});
                });
                privilegeOptions.push({'label': key, 'options': privilegeDropDownOptions});

            }
            if (selectedItem.role) {
                privilegeOptions.map(option => {
                    selectedItem.role.privileges.forEach(item => {
                        if (option.options.find(x => x.label === item)) {
                            selectedPrivilegeOptions.push(option.options.find(x => x.label === item));
                            privilege.push(option.options.find(x => x.label === item).value);
                            if (!selectedPrivilegeGroups.some(x => x.label === option.label))
                                selectedPrivilegeGroups.push(privilegeGroupNames.find(x => x.label === option.label));
                        }
                    });
                });
                role.privilegeIds = privilege;
            }
            this.setState({
                privilegeOptions,
                selectedPrivilegeOptions,
                privilegeGroupNames,
                selectedPrivilegeGroups,
                role
            });
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        })
    }

    validateForm(role) {
        let formErrors = {};
        let valid = true;
        if (!role.name) {
            formErrors.name = "Please Enter Name";
            valid = false;
        }
        if (!role.privilegeIds) {
            formErrors.selectedPrivilegeOptions = "Please Select atleast one Privilege";
            valid = false;
        }
        if (role.isExtUser === null || role.isExtUser === undefined) {
            formErrors.selectedRoleType = "Please Select atleast one User Type";
            valid = false;
        }
        return {valid: valid, formErrors: formErrors};
    }


    setPrivilegesAccordingToRole(selectedRole) {
        let filteredPrivilegeObject = this.state.roles.filter((item) => item.role.roleID === selectedRole.roleID)[0];
        this.setState({selectedItem: filteredPrivilegeObject, selectedRole: selectedRole}, () => this.getAllValues());
    }

    render() {
        let {isOpen, toggle, isEdit} = this.props;
        let {
            role, saving, dashboardOptions, selectedDashboardOptions, privilegeOptions, selectedPrivilegeOptions,
            formErrors, privilegeGroupNames, selectedPrivilegeGroups, selectedRoleType, selectedRole
        } = this.state;

        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    {isEdit ? "Edit" : "Add"} User Role
                </ModalHeader>
                <ModalBody>
                    {isEdit ? null : <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <FormGroup>
                                <Label>Select the Role you wish to get the Dashboard and Privileges from:</Label>
                                <Select
                                    placeholder="Select the Role"
                                    value={selectedRole}
                                    options={this.roleOptions}
                                    onChange={this.setPrivilegesAccordingToRole}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>}
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="name">Name*</Label>
                                <Input type="text" name="name"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={role.name}
                                       placeholder="Enter name here" disabled={isEdit}/>
                                <FormText color="danger">{formErrors.name}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="description">Description</Label>
                                <Input type="textarea" name="description"
                                       onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                       value={role.description}
                                       placeholder="Enter description here"/>
                            </FormGroup>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="roleType">Type*</Label>
                                <Select
                                    placeholder="Select role type"
                                    options={roleTypeOptions}
                                    value={selectedRoleType}
                                    onChange={(e) => this.handleChange("roleType", e)}
                                    isMulti={false}/>
                                <FormText color="danger">{formErrors.selectedRoleType}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="dashboard">Dashboard</Label>
                                <Select
                                    options={dashboardOptions}
                                    value={selectedDashboardOptions}
                                    placeholder={"Select dashboard card"}
                                    onChange={(e) => this.handleChange("dashboards", e)}
                                    closeMenuOnSelect={true}
                                    isSearchable={true}
                                    isMulti={true}
                                    defaultOptions
                                />
                            </FormGroup>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="group">Privilege Group</Label>
                                <Select
                                    options={privilegeGroupNames}
                                    value={selectedPrivilegeGroups}
                                    placeholder={"Select privilege group"}
                                    onChange={(e) => this.handleChange("groups", e)}
                                    closeMenuOnSelect={true}
                                    isSearchable={true}
                                    isMulti={true}
                                    defaultOptions
                                />
                            </FormGroup>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <FormGroup>
                                <Label for="privilege">Privilege*</Label>
                                <Select
                                    options={privilegeOptions}
                                    placeholder={"Select privilege"}
                                    value={selectedPrivilegeOptions}
                                    onChange={(e) => this.handleChange("privilegeIds", e)}
                                    isSearchable={true}
                                    isMulti={true}
                                    defaultOptions
                                />
                                <FormText color="danger">{formErrors.selectedPrivilegeOptions}</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size="sm" onClick={this.saveUserRole} disabled={saving}>
                            {saving
                                ? <Spinner size="sm"
                                           className={"mr-2"}
                                           style={{color: "white"}}/>
                                : <i className="fa fa-floppy-o mr-2"/>}
                            {isEdit ? "Update" : "Save"}</Button>
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times"/>&nbsp;
                            Cancel</Button>
                    </div>
                </ModalFooter>

            </Modal>
        );
    }
}