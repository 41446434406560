import axios from "./axios";

class QuartzService {

    static Instance() {
        return new QuartzService();
    }

    getNextScheduledDate() {
        return axios.get('api/Quartz/next/scheduled-date');
    }

    updateTrigger(data) {
        return axios.get('api/Quartz/UpdateTrigger?CronExpression=' + data);
    }

    getNextQuotesReminderEmailScheduledDate() {
        return axios.get('api/Quartz/quotes/reminder/email/next/scheduled-date');
    }
    updateTriggerQuotesReminderEmail(data) {
        return axios.get('api/Quartz/scheduled/date/quotes/reminder/email/UpdateTrigger?CronExpression=' + data);
    }
}

export default QuartzService.Instance();