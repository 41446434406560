import React, {Component} from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import {getProductionSchedulePowdercoatingOrderStatusColorName} from '../../services/CommonService'

export default class ProductionScheduleSameOrderItemsModal extends Component {

    getOrders(productionLinesSchedule, searchText) {
        let filteredOrders = [];
        productionLinesSchedule.forEach((pls, plsIndex) => {
            filteredOrders.push(...(pls.orders || []).filter(order => (order.ordNum + "").includes(searchText)));
        });
        return filteredOrders
    }

    render() {
        let {isOpen, toggle, handleChange, handleSubmit, searchText, productionLinesSchedule} = this.props;

        let filteredOrders = this.getOrders(productionLinesSchedule, searchText);

        return (
            <Modal isOpen={isOpen} size="md" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Search
                </ModalHeader>
                <ModalBody>
                    <div>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-search"
                                       aria-hidden="true"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                onChange={(e) => handleChange("sameOrderItemsModal_searchText", e.target.value.trim())}
                                type="search"
                                name="searchText"
                                value={searchText}
                                placeholder="Search by Order no."/>
                        </InputGroup>
                    </div>
                    <hr/>
                    <div>
                        {(filteredOrders || []).map((order, orderIndex) => {
                            order.salesOrderJobStatusColor = getProductionSchedulePowdercoatingOrderStatusColorName(order.salesOrderJobStatusID);
                            return <Card className={"m-2"} key={orderIndex}>
                                <CardBody>
                                    <Button color={"primary"}
                                            size={"sm"}
                                            onClick={() => handleSubmit(order)}
                                    >
                                        {order.ordNum}
                                    </Button>
                                    <hr/>
                                    <div>
                                        <strong className="mr-2">
                                            Production Line
                                        </strong>
                                        :&nbsp;{order.productionLineName}
                                    </div>
                                    <div>
                                        <strong className="mr-2">
                                            Status
                                        </strong>
                                        :&nbsp;
                                        <Badge
                                            color={order.salesOrderJobStatusColor}>
                                            {order.salesOrderJobStatusCode}
                                        </Badge>
                                        ({order.salesOrderJobStatusDescription})
                                    </div>
                                    {order.scheduleOn
                                        ? <div>
                                            <strong className="mr-2">
                                                Schedule On
                                            </strong>
                                            :&nbsp;{order.scheduleOnLabel}
                                        </div>
                                        : null
                                    }
                                    {order.followUpDate
                                        ? <div>
                                            <strong className="mr-2">
                                                Follow-up On
                                            </strong>
                                            :&nbsp;{order.followUpDateLabel}
                                        </div>
                                        : null
                                    }
                                    <div>
                                        <strong className="mr-2">
                                            Fabric
                                        </strong>
                                        :&nbsp;{order.fabric}</div>
                                    <div>
                                        <strong className="mr-2">
                                            Qty
                                        </strong>
                                        :&nbsp;{order.qty}</div>
                                </CardBody>
                            </Card>
                        })}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color={"secondary"}
                                onClick={() => toggle(!isOpen)}>
                            Close
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}