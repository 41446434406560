import React from 'react';
import {
    Card,
    CardBody, Button, Col, Row, Input, FormText
} from 'reactstrap';
import classnames from "classnames";
import SearchDebtorAcccount from '../../../search/SearchDebtorAcccount';
import { BUNNINGS_STORE_ACCOUNT_IDs } from "../../../../store/AppConstants";

function CustomerDetails(props) {

    let {defaultAccountID, order, handleOrderChange, orderSubmitTried, orderSummaryDownloadTried, formError, customer, isExternalUser} = props;
    //should not capture the customer email
    if (isExternalUser) {
        order.retailEmail = customer.email;
    }
    return (
        <div>
            <Card>
                <CardBody>
                    <div>
                        <p><strong>Store Details</strong></p>
                        <div className={"mb-1"}>Account</div>
                        <SearchDebtorAcccount
                            handleAccountChange={(selectedAccountID) => handleOrderChange("customer", selectedAccountID)}
                            selectedAccountID={order ? order.accountID : ""}
                            defaultAccountID={defaultAccountID}
                            includeChildren={true}
                            excludeClosedandInactive={true}
                            parentAccountIDs={BUNNINGS_STORE_ACCOUNT_IDs}
                        />
                    </div>

                    <Row className={"mt-2"}>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className={"mb-1"}>Customer Name*</div>
                            <Input type="text" name="retailFirstName"
                                   value={order.retailFirstName}
                                   onChange={(e) => handleOrderChange(e.target.name, e.target.value)}
                                   placeholder="customer name"
                                   invalid={((orderSubmitTried || orderSummaryDownloadTried) && formError.retailFirstName)}
                            />
                            {
                                ((orderSubmitTried || orderSummaryDownloadTried) && formError.retailFirstName)
                                    ? <FormText
                                        color="danger">
                                        <i className="fa fa-exclamation-triangle mr-2"
                                           aria-hidden="true"/>
                                        {formError.retailFirstName}
                                    </FormText>
                                    : null
                            }
                        </Col>
                        {!isExternalUser ?
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Row>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <div className={"mb-1"}>Customer Email*</div>
                                    </Col>
                                    {
                                        (customer && customer.email && (!isExternalUser))
                                            ? <Col xl={6} lg={6} md={6} sm={12} xs={12} className={"text-right"}>
                                                <Button color="link" size={"sm"} className={"pt-0 pb-0"}
                                                    onClick={() => handleOrderChange("retailEmail", customer.email !== order.retailEmail ? customer.email : "")}>
                                                    <i className={classnames("fa", "fa-lg", {
                                                        "fa-check-square-o": customer.email === order.retailEmail,
                                                        "fa-square-o": customer.email !== order.retailEmail,
                                                    }
                                                    )} />
                                                </Button>Use Store Email
                                            </Col>
                                            : null
                                    }
                                </Row>

                                <Input type="email" name="retailEmail"
                                    value={order.retailEmail}
                                    onChange={(e) => handleOrderChange(e.target.name, e.target.value)}
                                    placeholder="customer email"
                                    invalid={(orderSubmitTried && formError.retailEmail)}
                                />
                                {
                                    (orderSubmitTried && formError.retailEmail)
                                        ? <FormText
                                            color="danger">
                                            <i className="fa fa-exclamation-triangle mr-2"
                                                aria-hidden="true" />
                                            {formError.retailEmail}
                                        </FormText>
                                        : null
                                }
                            </Col>
                            : null}
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}

export default CustomerDetails;