import axios from './axios';
import axiosFileDownloader from "./axiosFileDownloader";

class DashboardService {

    static Instance() {
        return new DashboardService();
    }

    getPBOrderData() {

        return axios.get('api/dashboard/product-builder/order/summary/weekly/data');
    }

    getPBOrderCurrentWeekData(request) {
        return axios.post('api/dashboard/product-builder/order/summary/current/week/data', request, {
            timeout: 120 * 1000
        });
    }

    getWeeklyPerformanceUnitPriceData(request) {
        return axios.post('api/dashboard/order/summary/weekly-performance/data', request, {
            timeout: 120 * 1000
        });
    }

    getProductBuilderOrderEnteredBy() {
        return axios.get('api/dashboard/product-builder/order/enteredby');
    }

    generatePBOrderEnteredBy() {
        return axiosFileDownloader.get('api/dashboard/product/builder/order/entered/by/export');
    }
}

export default DashboardService.Instance();