import React, {Component} from 'react';
import {
    Button,
    Table,
    Spinner,
} from "reactstrap";

import ProductionCapacityService from '../../services/production/ProductionCapacityService';
import {
    getSorted,
    addMonths,
    getDates,
    getDateObj,
    getMomentObj,
    handleErrorMessage
} from '../../services/CommonService';
import ProductionCapacitySaveModal from '../../components/modal/ProductionCapacitySaveModal';
import {toast} from 'react-toastify';
import moment from 'moment';


export default class ProductionCapacityCalender extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productionLinesList: [],
            loading: false,
            capacityCalendar: [],
            defaultProductionCapacities: [],
            selectedProductionLine: '',
            selectedDate: null,
            selectedCapacity: '',
            selectedNote: '',
            isOpenModal: false,
            refreshAgain: false,
            selectedProductionLineIds: [],
            allSelected: false
        };
        this.productionCapacityService = new ProductionCapacityService();
        this.refresh = this.refresh.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);

    }

    componentDidMount() {
        this.productionCapacityService.getProductionLineList().then(response => {
            let data = response.data;
            data = getSorted(data, "shortName", true);
            this.setState({productionLinesList: data});
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
        this.refresh();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.refreshAgain !== nextProps.refreshAgain) {
            this.setState({refreshAgain: nextProps.refreshAgain});
            if (nextProps.refreshAgain) {
                this.setState({capacityCalender: []});
                this.refresh();
            }
        }
    }

    refresh() {
        this.setState({loading: true});
        this.productionCapacityService.getDefaultProductionCapacityForEachProductionLines().then(response => {
            this.setState({defaultProductionCapacities: response.data}, () => {
                this.getProductionCapacitiesCalender()
            });
        }).catch(error => {
            this.setState({loading: false});
            toast.error(handleErrorMessage(error));
        });
    }

    getProductionCapacitiesCalender() {
        let {defaultProductionCapacities} = this.state;
        this.productionCapacityService.getProductionCapacityWithDateAndProductionLineName().then(response => {
            let data = response.data;
            let startDate = moment();
            let endDate = addMonths(startDate, 3, "momentObj");

            let daysArr = getDates(startDate, endDate);

            daysArr.forEach(days => {
                let productionCapacity = data.filter(d => d.productionDate === days);

                // if capacity for the respective date not available, then add default one
                if (productionCapacity.length === 0) {
                    let pc = {
                        productionDate: days,
                        productionLines: []
                    };

                    pc.productionLines.push(...defaultProductionCapacities);
                    data.push(pc);
                }
            });

            // sort by dates
            let sortedData = data.sort(function (a, b) {
                let dateA = getMomentObj(a.productionDate, "ddd, DDMMMYY"),
                    dateB = getMomentObj(b.productionDate, "ddd, DDMMMYY");
                return dateA.diff(dateB);
            });

            // sort by production line name
            sortedData.forEach(d => {
                d.productionLines = getSorted(d.productionLines, "productionLineName", true);
            });

            this.setState({capacityCalendar: sortedData, loading: false, refreshAgain: false});

        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }

    triggerModal(productionCapacity, productionDate) {
        this.state.selectedProductionLineIds.push(productionCapacity.productionLineId);
        this.setState({
            selectedDate: getDateObj(productionDate, "ddd, DDMMMYY"),
            selectedProductionLine: parseInt(productionCapacity.productionLineId),
            selectedCapacity: productionCapacity.capacity,
            selectedNote: productionCapacity.notes,
            isOpenModal: true,
            selectedProductionLineIds: this.state.selectedProductionLineIds,
        });
    }

    toggleModal(change) {
        this.setState({isOpenModal: change, selectedProductionLineIds: [], allSelected: false});
    }

    handleCloseModal(data, isRefresh) {
        this.setState({isOpenModal: data, selectedProductionLineIds: [], allSelected: false});
        if (isRefresh) {
            this.setState({loading: true, capacityCalender: [], selectedProductionLineIds: [], allSelected: false});
            this.getProductionCapacitiesCalender();
        }
    }

    getDateColor(productionDate) {
        // check if day is weekend
        let date = getMomentObj(productionDate, "ddd, DDMMMYY");
        if (date.weekday() === 6 || date.weekday() === 0) {
            return "table-danger";
        }
        return "";
    }

    render() {
        let {capacityCalendar, loading, productionLinesList, selectedDate, selectedProductionLine, selectedCapacity, allSelected, isOpenModal, selectedProductionLineIds, selectedNote} = this.state;
        return (
            <div>
                {loading ? <Spinner color="primary" size="sm"/> :
                    <Table responsive striped bordered size="sm" hover>
                        <thead>
                        <th>Dates</th>
                        {(productionLinesList || []).map((pl, plIndex) => {
                                return (
                                    <th key={plIndex}>
                                        {pl.shortName === 'COMPONENTS' ? 'W/H PICKS' : pl.shortName}
                                    </th>
                                );
                            }
                        )}
                        </thead>

                        <tbody>
                        {(capacityCalendar || []).map((schedule, scheduleIndex) => {
                            return (
                                <tr key={scheduleIndex} className={this.getDateColor(schedule.productionDate)}>
                                    <th style={{minWidth: '150px'}}>
                                        {schedule.productionDate}
                                    </th>
                                    {(schedule.productionLines || []).map((pl, plIndex) => {
                                            return (
                                                <td key={plIndex} className="text-right">
                                                    <Button color={'link'} size="sm"

                                                            className={pl.default ? "" : "text-danger"}
                                                            onClick={this.triggerModal.bind(this, pl, schedule.productionDate)}
                                                    >{pl.capacity}</Button>
                                                    {pl.notes ? <div className="text-danger">
                                                        <small>{pl.notes}</small>
                                                    </div> : null}
                                                </td>
                                            );
                                    })}
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                }

                <ProductionCapacitySaveModal
                    productionLinesList={productionLinesList}
                    startDate={selectedDate}
                    selectedProductionLineIds={selectedProductionLineIds}
                    capacity={selectedCapacity}
                    notes={selectedNote}
                    isOpen={isOpenModal}
                    toggle={this.toggleModal}
                    handlerCloseModal={this.handleCloseModal}
                    allSelected={allSelected}
                />
            </div>
        );
    }
}

