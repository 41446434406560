import React, {Component} from "react";
import classnames from 'classnames';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Badge, UncontrolledTooltip} from "reactstrap";
import {newID} from '../../services/CommonService';

export default class Paragraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCopied: false
        };
        this.htmlID = newID();
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.getInnerHtml = this.getInnerHtml.bind(this);
        this.getOuterHtml = this.getOuterHtml.bind(this);
    }

    copyToClipboard(change) {
        this.setState({isCopied: change});
        setTimeout(() => {
            this.setState({isCopied: false});
        }, 5000);
    }

    renderContent() {
        let {isCopied} = this.state;
        let { text, isCopyable } = this.props;
        let innerHtml = this.getInnerHtml();
        if (isCopyable) {
            return (
                <CopyToClipboard text={text}
                                 onCopy={() => this.copyToClipboard(true)}>
                    <span>
                        {innerHtml}
                        <a href={"javascript:void(0)"}
                           id={this.htmlID}
                        >
                        <i className={classnames("ml-2", "fa", {
                                "fa-clipboard": !isCopied,
                                "fa-check": isCopied,
                            }
                        )} aria-hidden="true"/>
                        </a>
                        <UncontrolledTooltip placement="top" target={this.htmlID}>
                            {
                                isCopied
                                    ? "Copied!"
                                    : "Copy"
                            }
                        </UncontrolledTooltip>
                    </span>
                </CopyToClipboard>
            );
        }
        return innerHtml
    }

    getInnerHtml() {
        let {text, innerTag, innerTagColour} = this.props;
        let result = null;
        let customClassName = innerTagColour ? "text-" + innerTagColour : "";
        switch (innerTag) {
            case "strong":
                result = <strong clasName={customClassName}>{text}</strong>;
                break;
            case "span":
                result = <span clasName={customClassName}>{text}</span>;
                break;
            case "badge":
                result = <Badge color={innerTagColour}>{text}</Badge>;
                break;
            default:
                result = text;
        }
        return result;
    }

    getOuterHtml() {
        let {outerTag} = this.props;
        let result = null;
        let content = this.renderContent();
        switch (outerTag) {
            case "div":
                result = <div>{content}</div>;
                break;
            case "span":
                result = <span>{content}</span>;
                break;
            case "small":
                result = <small>{content}</small>;
                break;
            default:
                result = content;
                break;
        }
        return result;
    }

    render() {
        if (!this.props.text) {
            return null;
        }
        return this.getOuterHtml();
    }
}