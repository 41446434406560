import React, {Component} from 'react';
import NumberFormat from "react-number-format";
import classnames from 'classnames';
import {Button} from "reactstrap";
/*import salesOrderProductBuilderV1Service from '../../../../services/sales/SalesOrderProductBuilderV1Service'*/

export function isAllRowsExpanded(rows) {
    return rows.every(row => (!row.isExpandable) || (row.isExpandable && row.isExpanded))
}

export let salesOrderEnquiryPageOrderItemStore = [

    {
        key: "expandIcon",
        colSpan: 1,
        minWidth: 10,
        sorterApplicable: false,
        labelClassName: "text-center hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            if (rows[rowIndex].isExpandable) {
                return "text-center hoverableItem align-middle";
            }
            return "";
        },
        renderLabel: function (rows, componentRef) {
            let isExpandableExist = false;
            rows.forEach(row => {
                if (row.isExpandable) {
                    isExpandableExist = true;
                }
            });
            if (isExpandableExist) {
                let isExpandedAll = isAllRowsExpanded(rows);
                return <Button color={"primary"} size={"sm"} onClick={componentRef.toggleAllRowExpanded}>
                    <i className={classnames("fa", {
                            "fa-plus-circle": !isExpandedAll,
                            "fa-minus-circle": isExpandedAll,
                        }
                    )}/>
                </Button>
            }
            return null;
        },

        render: function (rowIndex, rows, componentRef) {
            if (rows[rowIndex].isExpandable) {
                return <Button color={"primary"} size={"sm"}
                               onClick={() => componentRef.toggleExpandRow(rowIndex, !rows[rowIndex].isExpanded)}>
                    <i className={classnames("fa", {
                            "fa-plus-circle": !rows[rowIndex].isExpanded,
                            "fa-minus-circle": rows[rowIndex].isExpanded,
                        }
                    )}/>
                </Button>
            }
            return null;
        }
    }, {
        key: "prodCode",
        colSpan: 1,
        minWidth: 105,
        sorterApplicable: true,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "";
        },
        renderLabel: function (rows, componentRef) {
            return "Item code";
        },
        render: function (rowIndex, rows, componentRef) {
            return rows[rowIndex][this.key];
        }
    },{
        key: "stockItemCd",
        colSpan: 1,
        minWidth: 105,
        sorterApplicable: true,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "";
        },
        renderLabel: function (rows, componentRef) {
            return "Item code";
        },
        render: function (rowIndex, rows, componentRef) {
            return rows[rowIndex].dimStockItem ? rows[rowIndex].dimStockItem[this.key] : rows[rowIndex].prodCode;
        }
    },
    {
        key: "description",
        colSpan: 1,
        minWidth: 75,
        sorterApplicable: true,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "";
        },
        renderLabel: function (rows, componentRef) {
            return "Description";
        },
        render: function (rowIndex, rows, componentRef) {
            return rows[rowIndex][this.key];
        }
    },
    {
        key: "stockItemDescription",
        colSpan: 1,
        minWidth: 75,
        sorterApplicable: true,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "";
        },
        renderLabel: function (rows, componentRef) {
            return "Description";
        },
        render: function (rowIndex, rows, componentRef) {
            return rows[rowIndex].dimStockItem ? rows[rowIndex].dimStockItem[this.key] : rows[rowIndex].description;
        }
    },
    {
        key: "width",
        colSpan: 1,
        minWidth: 75,
        sorterApplicable: true,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "text-right";
        },
        renderLabel: function (rows, componentRef) {
            return "Width";
        },
        render: function (rowIndex, rows, componentRef) {
            return <NumberFormat
                value={rows[rowIndex][this.key]}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator={false}
            />;
        }
    },
    {
        key: "drop",
        colSpan: 1,
        minWidth: 70,
        sorterApplicable: true,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "text-right";
        },
        renderLabel: function (rows, componentRef) {
            return "Drop";
        },
        render: function (rowIndex, rows, componentRef) {
            return <NumberFormat
                value={rows[rowIndex][this.key]}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator={false}
            />;
        }
    },
    {
        key: "qty",
        colSpan: 1,
        minWidth: 55,
        sorterApplicable: true,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "text-right";
        },
        renderLabel: function (rows, componentRef) {
            return "Qty";
        },
        render: function (rowIndex, rows, componentRef) {
            return <NumberFormat
                value={rows[rowIndex][this.key]}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator={true}
            />;
        }
    },
    {
        key: "qtySupplied",
        colSpan: 1,
        minWidth: 45,
        sorterApplicable: true,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "text-right";
        },
        renderLabel: function (rows, componentRef) {
            return "Supplied Qty";
        },
        render: function (rowIndex, rows, componentRef) {
            return <NumberFormat
                value={rows[rowIndex][this.key]}
                displayType={'text'}
                decimalScale={2}
                thousandSeparator={true}
            />;
        }
    },
    {
        key: "price",
        colSpan: 1,
        minWidth: 100,
        sorterApplicable: true,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "text-right";
        },
        renderLabel: function (rows, componentRef) {
            return "Unit Price";
        },
        render: function (rowIndex, rows, componentRef) {
            let result = rows[rowIndex].price;

            return <NumberFormat
                value={result}
                displayType={'text'} // below condition bcoz some HOS/CTR order have price 0.0042 so 4 decimal places needed.. else 2 places
                decimalScale={(rows[rowIndex].price > 0 && rows[rowIndex].price < 1) ? 4 : 2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'}
            />;
        }
    }, {
        key: "qtyprice",
        colSpan: 1,
        minWidth: 65,
        sorterApplicable: false,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "text-right";
        },
        renderLabel: function (rows, componentRef) {
            return "Price";
        },
        render: function (rowIndex, rows, componentRef) {
            let result;

            if (componentRef && componentRef.props && componentRef.props && componentRef.props.order && componentRef.props.order.salesOrderJobStatusId >= 95) {
                result = rows[rowIndex].qtySupplied * rows[rowIndex].price;
            } else {
                result = rows[rowIndex].qty * rows[rowIndex].price;
            }
            return <NumberFormat
                value={result}
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'}
            />;
        }
    },
    {
        key: "discVal",
        renderLabel: function (rows, componentRef) {
            return "Disc.";
        },
        colSpan: 1,
        minWidth: 65,
        sorterApplicable: true,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "text-right";
        },
        render: function (rowIndex, rows, componentRef) {
            let result;
            if (componentRef && componentRef.props && componentRef.props && componentRef.props.order && componentRef.props.order.salesOrderJobStatusId >= 95) {
                result = (rows[rowIndex].qtySupplied * rows[rowIndex].price) * (rows[rowIndex].discount / 100);
            } else {
                result = rows[rowIndex][this.key];
            }
            return <NumberFormat
                value={result}
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'}
            />;
        }
    },
    {
        key: "orderedExtendedEx",
        renderLabel: function (rows, componentRef) {
            return "Total";
        },
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        searchNode: null,
        labelClassName: "hoverableItem align-middle",
        getValueClassName: function (rowIndex, rows, componentRef) {
            return "text-right";
        },
        render: function (rowIndex, rows, componentRef) {
            let result;
            if (componentRef && componentRef.props && componentRef.props && componentRef.props.order && componentRef.props.order.salesOrderJobStatusId >= 95) {
                result = (rows[rowIndex].qtySupplied * rows[rowIndex].price) - ((rows[rowIndex].qtySupplied * rows[rowIndex].price) * (rows[rowIndex].discount / 100));
            } else {
                result = rows[rowIndex][this.key];
            }
            return <NumberFormat
                value={result}
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'}
            />;
        }
    }
];