import moment from 'moment';
import { FORBIDDEN, UNAUTHORIZED } from "../store/Auth";
import { cloneDeep } from 'lodash';
import { FINANCIAL_YEAR, MONTHS } from "../store/DateTime";
import { WINDOWARE_DEFAULT_CONTACTEMAIL, WINDOWARE_DEFAULT_CONTACTNUMBER, WINDOWARE_DEFAULT_NAME } from '../store/AppConstants';

const now = new Date();

let lastId = 0;

export function newID(prefix = 'id') {
    lastId++;
    return `${prefix}${lastId}`;
}

// Array Utility Methods

export function ifExist(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return true;
        }
    }
    return false;
}

// call this function, passing-in your date
export function dateToFromNowDaily(myDate) {
    if (myDate) {
        // get from-now for this date
        let fromNow = moment(myDate).fromNow();

        // ensure the date is displayed with today and yesterday
        return moment(myDate).calendar(null, {
            // when the date is closer, specify custom values
            lastWeek: '[Last] dddd',
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            // when the date is further away, use from-now functionality
            sameElse: function () {
                return "[" + fromNow + "]";
            }
        });
    }
    return "";
}

export function findIndex(array, attr, value) {
    if (!array) {
        return -1;
    }
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

export function findItems(array, attr, value) {
    let result = [];
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            result.push(array[i]);
        }
    }
    return result;
}

export function getDistinct(array, attr) {
    let unique = [];
    let distinct = [];
    for (let i = 0; i < array.length; i++) {
        if (!unique[array[i][attr]]) {
            distinct.push(array[i]);
            unique[array[i][attr]] = 1;
        }
    }
    return distinct;
}

export function getDistinctValues(array, attr) {
    let unique = [];
    let distinct = [];
    for (let i = 0; i < array.length; i++) {
        if (!unique[array[i][attr]]) {
            distinct.push(array[i][attr]);
            unique[array[i][attr]] = 1;
        }
    }
    return distinct;
}

export function removeDuplicates(array) {
    let unique = [];
    let distinct = [];
    for (let i = 0; i < array.length; i++) {
        if (!unique[array[i]]) {
            distinct.push(array[i]);
            unique[array[i]] = 1;
        }
    }
    return distinct;
}


// sorting
export function getSorted(array, attr1, isAttr1Ascending, attr2, isAttr2Ascending, attr3, isAttr3Ascending, attr4, isAttr4Ascending) {
    array.sort((a, b) => compare(a, b, attr1, isAttr1Ascending, attr2, isAttr2Ascending, attr3, isAttr3Ascending, attr4, isAttr4Ascending));
    return array;
}

export function compare(a, b, attr1, isAttr1Ascending, attr2, isAttr2Ascending, attr3, isAttr3Ascending, attr4, isAttr4Ascending) {
    let result = collate(a, b, attr1, isAttr1Ascending);
    if (result === 0 && attr2) {
        result = collate(a, b, attr2, isAttr2Ascending);
    }
    if (result === 0 && attr3) {
        result = collate(a, b, attr3, isAttr3Ascending);
    }
    if (result === 0 && attr4) {
        result = collate(a, b, attr4, isAttr4Ascending);
    }
    return result;
}

function collate(a, b, attr, ascending) {
    if (a[attr] > b[attr] || b[attr] === null) {
        if (ascending) {
            return 1;
        } else {
            return -1;
        }
    }
    if (b[attr] > a[attr] || a[attr] === null) {
        if (ascending) {
            return -1;
        } else {
            return 1;
        }
    }
    return 0;
}

export function isAllSelected(arr, param) {
    if (arr) {
        for (let i = 0; i < arr.length; i++) {
            if (!arr[i][param]) {
                return false;
            }
        }
        return true;
    }
    return false;
}

export function isAllRowsExpanded(rows) {
    return rows.every(row => (!row.isExpandable) || (row.isExpandable && row.isExpanded))
}

export function findItemHavingMinValue(arr, param) {
    if (!arr) {
        return -1;
    }
    return Math.min.apply(Math, arr.map(function (o) {
        return o[param];
    }));
}

export function findItemHavingMaxValue(arr, param) {
    if (!arr) {
        return -1;
    }
    if (isEmpty(arr)) {
        return 0;
    }
    return Math.max.apply(Math, arr.map(function (o) {
        return o[param];
    }));
}

// Date Utility Methods
export function isToday(dateString, pattern) {
    const todayDate = moment(new Date()).format(pattern);
    return dateString === todayDate;
}

function sortDates(a, b) {
    return a.getTime() - b.getTime();
}

export function getMinDate(array) {
    // type of array should be date only

    let sorted = array.sort(sortDates);
    return sorted[0];
}

export function getMaxDate(array) {
    // type of array should be date only

    let sorted = array.sort(sortDates);
    return sorted[sorted.length - 1];
}

export function getUtcDate(utcDateString) {
    if (utcDateString) {
        return moment.utc(utcDateString);
    }
}

export function convertUtcDateToLocalDate(utcDateString) {
    let localDate = "";
    if (utcDateString) {
        localDate = getUtcDate(utcDateString).local();
        return localDate;
    }
}

export function getLocalDateStringFromUtcDateString(utcDateString, localDateStringFormat) {
    if (utcDateString) {
        return convertUtcDateToLocalDate(utcDateString).format(localDateStringFormat);
    }
    return "";
}

export function changeFormatOfDateString(dateString, currentFormat, targetFormat) {
    if (dateString) {
        let momentDate = moment(dateString, currentFormat);
        return momentDate.format(targetFormat);
    }
    return "";
}

export function getDateObj(dateString, currentFormat) {
    if (dateString) {
        let momentDate = moment(dateString, currentFormat);
        return momentDate.toDate();
    }
    return null;
}

export function getMomentObj(dateString, currentFormat) {
    if (dateString) {
        return moment(dateString, currentFormat);
    }
    return null;
}

export function getDateString(dateObj, targetFormat) {
    if (dateObj) {
        return moment(dateObj).format(targetFormat);
    }
    return "";
}


export function getStartEndDateFromRangeText(dateRangeText) {

    let baseDate;
    let from_date;
    let to_date;
    switch (dateRangeText) {
        case "Today":
            from_date = new Date();
            to_date = from_date;
            break;
        case "Tomorrow":
            from_date = moment().add(1, 'days').toDate();
            to_date = from_date;
            break;
        case "This week":
            baseDate = moment();
            from_date = baseDate.startOf('week').toDate();
            to_date = baseDate.endOf('week').toDate();
            break;
        case "This month":
            baseDate = moment();
            from_date = baseDate.startOf('month').toDate();
            to_date = baseDate.endOf('month').toDate();
            break;
        case "This quarter":
            baseDate = moment();
            from_date = baseDate.startOf('quarter').toDate();
            to_date = baseDate.endOf('quarter').toDate();
            break;
        case "This year":
            baseDate = moment();
            from_date = baseDate.startOf('year').toDate();
            to_date = baseDate.endOf('year').toDate();
            break;
        case "Last week":
            baseDate = moment().subtract(1, 'weeks');
            from_date = baseDate.startOf('week').toDate();
            to_date = baseDate.endOf('week').toDate();
            break;
        case "Last month":
            baseDate = moment().subtract(1, 'months');
            from_date = baseDate.startOf('month').toDate();
            to_date = baseDate.endOf('month').toDate();
            break;
        case "Last quarter":
            baseDate = moment().subtract(1, 'quarters');
            from_date = baseDate.startOf('quarter').toDate();
            to_date = baseDate.endOf('quarter').toDate();
            break;
        case "Last year":
            baseDate = moment().subtract(1, 'years');
            from_date = baseDate.startOf('year').toDate();
            to_date = baseDate.endOf('year').toDate();
            break;
    }
    return {
        startDate: from_date,
        endDate: to_date
    }
}

export function addMonths(dateObj, months, returnObjType) {
    let momentObj = moment(dateObj).add(months, 'M');
    return returnDate(momentObj, "momentObj", returnObjType);
}

export function addDate(dateObj, amount, time, returnObjType) {
    let momentObj = moment(dateObj);

    let key = "";
    switch (time) {
        case "years":
            key = "y";
            break;
        case "quarters":
            key = "Q";
            break;
        case "months":
            key = "M";
            break;
        case "weeks":
            key = "w";
            break;
        case "days":
            key = "d";
            break;
        case "hours":
            key = "h";
            break;
        case "minutes":
            key = "m";
            break;
    }

    if (key) {
        momentObj.add(amount, key);
    }

    return returnDate(momentObj, "momentObj", returnObjType);
}

export function getStartOrEndOfDate(dateObj, time, operation, returnObjType) {
    let momentObj = moment(dateObj);
    let key = "";
    switch (time) {
        case 'year':// set to January 1st, 12:00 am this year
        case 'month': // set to the first of this month, 12:00 am
        case 'quarter': // set to the beginning of the current quarter, 1st day of months, 12:00 am
        case 'week':// set to the first day of this week, 12:00 am
        case 'day': // set to 12:00 am today
        case 'date': // set to 12:00 am today
        case 'hour': // set to now, but with 0 mins, 0 secs, and 0 ms
        case 'minute': // set to now, but with 0 seconds and 0 milliseconds
            key = time;
            break;
    }

    switch (operation) {
        case 'start':
            momentObj.startOf(key);
            break;
        case 'end':
            momentObj.endOf(key);
            break;
    }
    return returnDate(momentObj, "momentObj", returnObjType);
}

export function returnDate(obj, currentObjectType, targetObjectType) {
    switch (currentObjectType) {
        case "dateObj":
            switch (targetObjectType) {
                case "dateObj":
                    return obj;
                case "momentObj":
                    return moment(obj);
            }
            break;
        case "momentObj":
            switch (targetObjectType) {
                case "dateObj":
                    return obj.toDate();
                case "momentObj":
                    return obj;
            }
    }
    return obj;
}


// getting range between dates
export function getDates(startDate, endDate) {
    let dateArray = [];

    let currDate = moment(startDate).startOf('day');
    let lastDate = moment(endDate).startOf('day');

    while (currDate.diff(lastDate) < 0) {
        dateArray.push(moment(currDate).format('ddd, DDMMMYY'));
        currDate.add(1, 'days');
    }
    return dateArray;
}

export function getDatesWithFormat(startDate, endDate, format) {
    let dateArray = [];

    let currDate = moment(startDate).startOf('day');
    let lastDate = moment(endDate).startOf('day');

    while (currDate.diff(lastDate) <= 0) {
        dateArray.push(moment(currDate).format(format));
        currDate.add(1, 'days');
    }
    return dateArray;
}
//Other Utilities

export function getFullName(firstName, lastName) {
    let fullName = "";
    if (firstName) {
        fullName = fullName + firstName;
    }
    if (lastName) {
        fullName = fullName + " " + lastName;
    }
    return fullName;
}

export function isEmpty(param, type) {
    switch (type) {
        case "primitive":
            return !param;

        default:
            if (param) {
                for (let x in param) {
                    return false;
                }
            }
            return true;
    }
}

export function scrollTo(id, type) {
    if (id) {
        switch (id) {
            case "top":
                window.scrollTo(0, 0);
                break;
            default:
                let element = document.getElementById(id);
                if (element) {
                    switch (type) {
                        case "left":
                            element.scrollLeft = 0;
                            break;
                        case "right":
                            element.scrollRight = 0;
                            break;
                        default:
                            element.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                            });
                    }

                }
        }
    }
}


export function getDeliveryAddressString(item) {
    return (item.delAddr1 ? item.delAddr1 + ", " : "")
        + (item.delAddr2 ? item.delAddr2 + ", " : "")
        + (item.delAddr3 ? item.delAddr3 + ", " : "")
        + (item.delAddr4 ? item.delAddr4 + ", " : "")
        + (item.delCity ? item.delCity + ", " : "")
        + (item.delState ? item.delState + ", " : "")
        + (item.delPostCode ? item.delPostCode : "");
}

export function getParentCompanyAddress(item) {
    return (item.p_Addr1 ? item.p_Addr1 + ", " : "")
        + (item.p_Addr2 ? item.p_Addr2 + ", " : "")
        + (item.p_Addr3 ? item.p_Addr3 + ", " : "")
        + (item.p_Addr4 ? item.p_Addr4 + ", " : "")
        + (item.p_City ? item.p_City + ", " : "")
        + (item.p_State ? item.p_State + ", " : "")
        + (item.p_PostCode ? item.p_PostCode : "");
}

export function getCommonAddressString(item) {
    return (item.address1 ? item.address1 + ", " : "")
        + (item.address2 ? item.address2 + ", " : "")
        + (item.address3 ? item.address3 + ", " : "")
        + (item.address4 ? item.address4 + ", " : "")
        + (item.city ? item.city + ", " : "")
        + (item.state ? item.state + ", " : "")
        + (item.postCode ? item.postCode : "");
}

export function getRetailDeliveryAddressString(item) {
    return (item.retailAddressLine1 ? item.retailAddressLine1 + ", " : "")
        + (item.retailAddressLine2 ? item.retailAddressLine2 + ", " : "")
        + (item.retailAddressLine3 ? item.retailAddressLine3 + ", " : "")
        + (item.retailAddressLine4 ? item.retailAddressLine4 + ", " : "")
        + (item.retailCity ? item.retailCity + ", " : "")
        + (item.retailState ? item.retailState + ", " : "")
        + (item.retailPostCode ? item.retailPostCode : "");
}

export function getColors() {
    return [
        '#007bff',
        '#e83e8c',
        '#20c997',
        '#fd7e14',
        '#6610f2',
        '#dc3545',
        "#3f51b5",
        '#ffc107',
        '#28a745',

        '#17a2b8',
        '#6c757d',
        '#343a40',

        "#8bc34a",
        "#795548",
        //akshay added more colors
        '#ffcc99',
        '#8066cc',
        '#9191ff',
        '#b8e6d1',
        '#b85cff',
        '#c8c8af',
        '#33CCFF',
        '#FFFF00',
    ];
}

export function colorConvertHexToRgb(hex) {
    let rgbString = null;
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    let rgb = result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
    if (rgb) {
        rgbString = 'rgb(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ')';
    }
    return rgbString;

}

export function numberWithThousandSeparator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function groupBy(objectArray, property, optionalWrapperParam) {
    return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        // Add object to list for given key's value
        if (optionalWrapperParam) {
            if (!acc[key][optionalWrapperParam]) {
                acc[key][optionalWrapperParam] = [];
            }
            acc[key][optionalWrapperParam].push(obj);
        } else {
            acc[key].push(obj);
        }
        return acc;
    }, {});
}

export function arrayToDictionary(objectArray, property) {
    return objectArray.reduce((a, x) => ({ ...a, [x[property]]: x }), {})
}

export function getYears(year, back) {
    return Array.from({ length: back }, (v, i) => year - back + i + 1);
}

export function handleErrorMessage(error) {
    if (error.response) {
        if (error.response.data) {
            if (error.response.data.Message) {
                return error.response.data.Message;
            }
            else {
                if (error.response.status === UNAUTHORIZED) {
                    return "Your session has expired, redirecting to the login page";
                }
                if (error.response.status === FORBIDDEN) {
                    return "Access denied!";
                }

                return "Something went wrong!";
            }
        }
        else {
            if (error.response.status === UNAUTHORIZED) {
                return "Your session has expired, redirecting to the login page";
            }
            if (error.response.status === FORBIDDEN) {
                return "Access denied!";
            }

            return "Something went wrong!";
        }

    }
    else if (error.Message) {
        return error.Message;
    }
    else if (!error.message && !error.response) {
        return "Something went wrong!";
    }
}

export function displayECD(utcDateString, targetFormat) {
    if (utcDateString) {
        let dateObj = getMomentObj(utcDateString, "DD/MM/YYYY");
        if (dateObj) {
            if (!dateObj.isBefore(now, 'day')) {
                return dateObj.format(targetFormat);
            }
        }
    }
    return "";
}

export function validateEmail(mail) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail);

}

export function addBusinessDays(originalMomentDate, numDaysToAdd) {
    const Sunday = 0;
    const Saturday = 6;
    let daysRemaining = numDaysToAdd;

    const resultMomentDate = originalMomentDate.clone();

    while (daysRemaining > 0) {
        resultMomentDate.add(1, 'days');
        if (resultMomentDate.day() !== Sunday && resultMomentDate.day() !== Saturday) {
            daysRemaining--;
        }
    }

    return resultMomentDate;
}

export function getDebtorCategoryColor(category) {
    switch (category) {
        case "Gold":
            return "warning";
        case "Silver":
            return "secondary";
        case "Blue":
        default:
            return "primary";
    }
}


export function round(value, precision) {
    let multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export function roundUp(value, precision) {
    let multiplier = Math.pow(10, precision || 0);
    return Math.ceil(value * multiplier) / multiplier;
}

export function roundDown(value, precision) {
    let multiplier = Math.pow(10, precision || 0);
    return Math.floor(value * multiplier) / multiplier;
}

export function concatenateStrings(demiltter, ...chunks) {
    let result = "";
    (chunks || []).forEach((chunk, chunkIndex) => {
        if (chunk) {
            result = result + chunk;
        }
        if (chunks.length > chunkIndex + 1 && chunks[chunkIndex + 1]) {
            result = result + demiltter;
        }
    });
    if (result) {
        result = result.trim();
    }
    return result;
}

export function getStartEndDateByWeekNumber(weekNumber, year) {
    let result = "";
    let lastDate = new Date(year, 0, (1 + (weekNumber - 1) * 7));
    while (lastDate.getDay() !== 6) {
        lastDate.setDate(lastDate.getDate() + 1);
    }
    let firstDate = new Date(lastDate);
    firstDate.setDate(firstDate.getDate() - 6);

    if (firstDate.getDate() > lastDate.getDate())
        result = getDateString(firstDate, 'DD MMM') + "-" + getDateString(lastDate, 'DD MMM');
    else
        result = getDateString(firstDate, 'DD') + "-" + getDateString(lastDate, 'DD MMM');
    return result;
}

export function getDateFromWeek(weekNumber, year) {
    return moment().day("Sunday").year(year).week(weekNumber);
}

export function getWeekNumbersBetweenDates(startDate, endDate) {
    let startDateMoment = moment(startDate);
    let endDateMoment = moment(endDate);
    let weeks = [];
    let temp;
    let weekStartDateMoment, weekEndDateMoment;
    while (startDateMoment.diff(endDateMoment, 'days') <= 0) {
        temp = {
            weekNumber: startDateMoment.week(),
            year: startDateMoment.year(),
            month: startDateMoment.month(),
            dateString: startDateMoment.format("DD/MM/YYYY"),
        };
        weekStartDateMoment = getDateFromWeek(temp.weekNumber, (temp.weekNumber === 1 && temp.month === 11) ? temp.year + 1 : temp.year).startOf('week');
        weekEndDateMoment = getDateFromWeek(temp.weekNumber, (temp.weekNumber === 1 && temp.month === 11) ? temp.year + 1 : temp.year).endOf('week');
        temp.weekStartDateString = weekStartDateMoment.format("DD/MM/YYYY");
        temp.weekEndDateString = weekEndDateMoment.format("DD/MM/YYYY");
        temp.displayWeekRange = "";
        if (weekStartDateMoment.month() === weekEndDateMoment.month()) {
            temp.displayWeekRange = weekStartDateMoment.format("DD");
            temp.displayWeekRange = temp.displayWeekRange + "-" + weekEndDateMoment.format("DD");
            temp.displayWeekRange = temp.displayWeekRange + " " + weekEndDateMoment.format("MMM");
        } else {
            temp.displayWeekRange = weekStartDateMoment.format("DDMMM");
            temp.displayWeekRange = temp.displayWeekRange + "-" + weekEndDateMoment.format("DDMMM");
        }
        weeks.push(cloneDeep(temp));
        startDateMoment = startDateMoment.add(1, "days");
    }
    return getDistinct(weeks, 'weekNumber');
}


export function getFinancialMonthList(financialYear) {
    let months = cloneDeep(MONTHS);
    months = getSorted(months, 'financialYearMonthNumber', true);
    let financialYearParts = financialYear.split("-");
    months.forEach(m => {
        m.financialYear = financialYear;
        switch (m.financialYearPart) {
            case "A":
                m.year = financialYearParts[0];
                break;
            case "B":
                m.year = financialYearParts[1];
                break;
        }
    });
    return months;
}

export function getFinancialYear(momentDate) {
    return getFinancialYearByYearMonth(momentDate.year(), momentDate.month() + 1)
}

export function getFinancialYearByYearMonth(year, monthNumber) {
    return monthNumber < FINANCIAL_YEAR.startMonth.monthNumber ? concatenateStrings("-", (year - 1), year) : concatenateStrings("-", year, (year + 1));
}

export function getFinancialYearList(dateObj, numberOfYears) {
    let momentDate = moment(dateObj);
    let financialYears = [];
    financialYears.push(getFinancialYearByYearMonth(momentDate.year(), momentDate.month() + 1));
    for (let i = 1; i < numberOfYears; i++) {
        financialYears.push(getFinancialYear(momentDate.subtract(1, 'y')));
    }
    return financialYears;
}

export function roundToNearestEvenNumber(number) {
    return 2 * Math.round(number / 2);
}

export function roundUpToNearestEvenNumber(number) {
    return 2 * roundUp(number / 2, 0);
}

export function roundUpToNearestOddNumber(number) {
    return (2 * roundDown((number / 2), 0)) + 1;
}

export function DebtorEmailContent(documentType, order) {
    let emailMessage = { subject: "", body: "" };
    let emailbodyfooter = '\n\n' + "Have a great day!" + '\n\n'
        + "Kind Regards," + '\n'
        + WINDOWARE_DEFAULT_NAME + '\n'
        + "Phone: " + WINDOWARE_DEFAULT_CONTACTNUMBER + '\n'
        + "Email: " + WINDOWARE_DEFAULT_CONTACTEMAIL;
    switch (documentType) {
        case "Sales_Invoice":
            emailMessage.subject = "Windoware | Invoice- @OrdNum";
            emailMessage.body = "Dear @Company," + '\n\n' +
                "Your Invoice has been shared with you." + '\n\n'
                + "I have attached a copy for your reference.";
            break;
        case "Sales_Order":
            emailMessage.subject = "Windoware | Order- @OrdNum";
            emailMessage.body = "Dear @Company," + '\n\n' +
                "Please find the attached Order Summary for your reference.";
            break;
        case "Sales_Quote":
            emailMessage.subject = "Windoware | Quote- @OrdNum";
            emailMessage.body = "Dear @Company," + '\n\n' +
                "Thank you for giving us the opportunity to quote on your job." + '\n\n'
                + "I have attached a copy for your reference and will hold it in the system for 30 days" + '\n\n'
                + "I look forward to hearing from you soon.";
            break;
    }
    if (order.isRetail) {
        emailMessage.subject = emailMessage.subject.replace('Windoware', '@Company');
        emailMessage.body = emailMessage.body.replace("@Company", (order.retailFirstName + " " + order.retailLastName));
        emailbodyfooter = emailbodyfooter.replace(WINDOWARE_DEFAULT_CONTACTNUMBER, order.phone);
        emailbodyfooter = emailbodyfooter.replace(WINDOWARE_DEFAULT_CONTACTEMAIL, order.email);
    }
    emailMessage.subject = emailMessage.subject.replace('@Company', order.companyName);
    emailMessage.body = emailMessage.body.replace('@Company', order.companyName);
    emailMessage.body = (emailMessage.body + emailbodyfooter);
    return emailMessage;
}

export function getDatesBetweenDateRange(startDate, endDate) {
    let startDateMoment = getMomentObj(startDate, "DD/MM/YYYY");
    let endDateMoment = getMomentObj(endDate, "DD/MM/YYYY");
    let dates = [];
    let temp;
    while (startDateMoment.diff(endDateMoment, 'days') <= 0) {
        temp = {
            dateString: startDateMoment.format("DD/MM/YYYY"),
            dayName: startDateMoment.format("dddd"),
            displayDayName: startDateMoment.format("ddd")
        };
        dates.push(cloneDeep(temp));
        startDateMoment = startDateMoment.add(1, "days");
    }
    return getDistinct(dates, 'dateString');
}

export function getStartEndDateWithFormat(fromToDate) {
    let dateFormat;
    let startDateMoment = getMomentObj(fromToDate.startDate, "DD/MM/YYYY");
    let endDateMoment = getMomentObj(fromToDate.endDate, "DD/MM/YYYY");
    if (startDateMoment.month() === endDateMoment.month()) {
        dateFormat = startDateMoment.format("DD");
        dateFormat = dateFormat + "-" + endDateMoment.format("DD");
        dateFormat = dateFormat + " " + endDateMoment.format("MMM");
    } else {
        dateFormat = startDateMoment.format("DDMMM");
        dateFormat = dateFormat + "-" + endDateMoment.format("DDMMM");
    }
    return dateFormat;
}

export function getCurrentWeekDateFromMonToSun(currentDate) {
    let current = moment(currentDate);
    let date = {
        startDate: '',
        endDate: ''
    };
    //get day from sunday to saturday
    date.startDate = getStartOrEndOfDate(current, 'week', "start", "momentObj");
    date.endDate = getStartOrEndOfDate(current, 'week', "end", "momentObj");
    //start from monday to sunday
    date.startDate = addDate(date.startDate, 1, 'days', 'momentObj').format("DD/MM/YYYY");
    date.endDate = addDate(date.endDate, 1, 'days', 'momentObj').format("DD/MM/YYYY");
    return date;
}

export function getWeekDatesFromMonToSun(dateObj, lastWeekNumber) {
    let current = moment(dateObj);
    let startDate = getDateFromWeek((current.week() - lastWeekNumber), current.year());
    let endDate = getStartOrEndOfDate(startDate, 'week', "end", "momentObj");
    let date = {
        startDate: '',
        endDate: ''
    };
    //start from monday to sunday
    date.startDate = addDate(startDate, 1, 'days', 'momentObj').format("DD/MM/YYYY");
    date.endDate = addDate(endDate, 1, 'days', 'momentObj').format("DD/MM/YYYY");
    return date;
}

export function getWeeklyDateList(dateObj, numberOfWeeks) {
    let weeks = [];
    for (let i = 0; i < numberOfWeeks; i++) {
        let start_endDate = getWeekDatesFromMonToSun(dateObj, i);
        weeks.push(getStartEndDateWithFormat(start_endDate));
    }
    return weeks;
}
export function getDataWeekWiseBetweenDatesFromMonToSun(startDate, endDate) {
    let startDateMoment = moment(startDate);
    let endDateMoment = moment(endDate);
    let weeks = [];
    let temp;
    let weekStartDateMoment, weekEndDateMoment;
    while (startDateMoment.diff(endDateMoment, 'weeks') <= 0) {
        temp = {
            weekNumber: startDateMoment.week(),
            year: startDateMoment.year(),
            month: startDateMoment.month(),
        };
        weekStartDateMoment = getDateFromWeek(temp.weekNumber, (temp.weekNumber === 1 && temp.month === 11) ? temp.year + 1 : temp.year).startOf('week');
        weekEndDateMoment = getDateFromWeek(temp.weekNumber, (temp.weekNumber === 1 && temp.month === 11) ? temp.year + 1 : temp.year).endOf('week');
        //start from monday to sunday
        weekStartDateMoment = addDate(weekStartDateMoment, 1, 'days', 'momentObj');
        weekEndDateMoment = addDate(weekEndDateMoment, 1, 'days', 'momentObj');

        temp.weekStartDateString = weekStartDateMoment.format("DD/MM/YYYY");
        temp.weekEndDateString = weekEndDateMoment.format("DD/MM/YYYY");

        temp.weekDates = getDatesWithFormat(weekStartDateMoment, weekEndDateMoment, "DD/MM/YYYY");//get list of week dates

        temp.displayWeekRange = weekStartDateMoment.format("DD") + " - " + weekEndDateMoment.format("DD MMM");

        weeks.push(cloneDeep(temp));
        startDateMoment = startDateMoment.add(1, "weeks");
    }
    return (weeks);
}
/*

export function calculatePercentage(number, percent) {
    return (number * (percent / 100));
    //return ((number * markUp) / 100);
}
*/

export function getOrderColorName(order, selectedOrdNum) {
    let colorName = "primary";
    switch (true) {
        case selectedOrdNum === order.ordNum:
            colorName = "success";
            break;
        case order.exceedsCreditLimit === true:
            colorName = "danger";
            break;
        default:
            colorName = "primary";
            break;
    }
    return colorName;
}

export function getPowdercoatingPurchaseOrderStatusColorName(status) {
    //-1:cancelled, 0:In Process, 1: on hold, 2: Approved, 3: Sent, 4: completed
    let colorName = 'primary';
    switch (status) {
        case -1:
        case 1:
            colorName = "danger";
            break;
        case 4:
            colorName = "success";
            break;
        case 0:
            colorName = "warning";
            break;
        case 2:
        case 3:
            colorName = "info";
            break;
    }
    return colorName;
}

export function getProductionSchedulePowdercoatingOrderStatusColorName(salesOrderJobStatusID) {
    let color = "light";
    switch (salesOrderJobStatusID) {
        case 57:
            color = "primary";
            break;
        case 58:
            color = "warning";
            break;
        case 59:
            color = "danger";
            break;
        case 60:
            color = "success";
            break;
    }
    if (salesOrderJobStatusID >= 90) {
        color = "success";
    }
    return color;
}

export function getShipmentStatusColorName(statusID) {
    let colorName = 'primary';
    switch (statusID) {
        case 7:
            colorName = "success";
            break;
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
            colorName = "info";
            break;
    }
    return colorName;
}

export function getChildAccountID(accountID) {
    let childAccountID = "";
    if (accountID) {
        let arr = accountID.split("-");
        if (arr.length > 1) {
            childAccountID = arr[1];
        }
    }
    return childAccountID;
}


export function getProductGroupString(order) {
    let productGroupString = [];
    let productGroups = order.orderDetails;

    (productGroups || []).map((data) => {
        productGroupString.push(data.product + "(" + data.qty + ")");
    });

    return productGroupString.toString();
}


export function isDeepEqual(object1, object2) {
    const objKeys1 = Object.keys(object1);
    const objKeys2 = Object.keys(object2);

    if (objKeys1.length !== objKeys2.length) return false;

    for (let key of objKeys1) {
        const value1 = object1[key];
        const value2 = object2[key];

        const isObjects = isObject(value1) && isObject(value2);

        if ((isObjects && !isDeepEqual(value1, value2)) ||
            (!isObjects && value1 !== value2)
        ) {
            return false;
        }
    }
    return true;
}

const isObject = (object) => {
    return object != null && typeof object === "object";
};

