import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import {Redirect} from "react-router-dom";
import {changeFormatOfDateString} from '../../services/CommonService';
import {isEmpty} from 'lodash';
import OrderEnquiryProductionModeOrderItems from "./OrderEnquiryProductionModeOrderItems";

export default class OrderEnquiryProductionModePrint extends Component {

    render() {
        let {
            order,
            toggleTab,
            activeTab,
            toggleProductCollapse,
            toggleProductItemCollapse,
            prepareOrderModel,
        } = this.props;

        return (
            <div>
                <div>
                    <div>
                        <Row>
                            <Col>
                                <h5>Order #{order.ordNum}</h5>
                                <div className="mb-1">
                                    <strong>Customer Account ID</strong>:<span className="ml-2">{order.accountID}</span>
                                </div>
                                <div className="mb-1">
                                    <strong>{order.delCompany}</strong>
                                </div>
                            </Col>
                            <Col>
                                <div className="text-right mt-4">
                                    <div className="mb-1">
                                        <strong>Order
                                            Date</strong>:<span
                                        className="ml-2">{changeFormatOfDateString(order.ordDate, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}</span>
                                    </div>
                                    <div>
                                        <strong>Require
                                            Date</strong>:<span
                                        className="ml-2">{changeFormatOfDateString(order.required, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    <div>
                        <OrderEnquiryProductionModeOrderItems
                            toggleTab={toggleTab}
                            activeTab={activeTab}
                            isPrinting={true}
                            order={order}
                            toggleProductCollapse={toggleProductCollapse}
                            toggleProductItemCollapse={toggleProductItemCollapse}
                            prepareOrderModel={prepareOrderModel}
                        />
                    </div>
                </div>
            </div>
        );

    }
}
