import React, {Component} from 'react';
import {
    Table,
    Spinner
} from 'reactstrap';
import NumberFormat from "react-number-format";
import {isEmpty} from 'lodash';

export default class DebtorOutstandingBalance extends Component {

    render() {

        let {outstandingBalance, isLoading} = this.props;

        return (
            <div>
                {isLoading
                    ? <Spinner color={"primary"}/>
                    : <div>
                        {isEmpty(outstandingBalance)
                            ? null
                            : <Table size={"sm"} responsive>
                                <tbody>
                                <tr>
                                    <th>Current</th>
                                    <td>
                                        <NumberFormat value={outstandingBalance.current}
                                                      displayType={'text'}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      thousandSeparator={true}
                                                      prefix={'$'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>30 Days</th>
                                    <td>
                                        <NumberFormat value={outstandingBalance.thirtyDays}
                                                      displayType={'text'}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      thousandSeparator={true}
                                                      prefix={'$'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        60 Days
                                    </th>
                                    <td>
                                        <NumberFormat value={outstandingBalance.sixtyDays}
                                                      displayType={'text'}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      thousandSeparator={true}
                                                      prefix={'$'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        90 Days
                                    </th>
                                    <td>
                                        <NumberFormat value={outstandingBalance.ninetyDays}
                                                      displayType={'text'}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      thousandSeparator={true}
                                                      prefix={'$'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Balance
                                    </th>
                                    <td>
                                        <NumberFormat value={outstandingBalance.balance}
                                                      displayType={'text'}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      thousandSeparator={true}
                                                      prefix={'$'}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        }
                    </div>
                }
            </div>
        );

    }
}
