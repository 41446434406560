import axios from '../axios';
import {cloneDeep, isEmpty} from 'lodash';
import {
    calculatePercentage,
    findIndex,
    findItems,
    getDistinct,
    validateEmail,
    getChildAccountID
} from "../CommonService";
import {productConstant} from '../../store/ProductGroups';
import quiklokUtil from '../../components/sales/create/productBuilderKeywayVersion/products/quiklok/QuiklokUtil';
import verticalIndoorUtil from '../../components/sales/create/productBuilderKeywayVersion/products/verticalIndoor/VerticalIndoorUtil';
import rollerBlindUtil from '../../components/sales/create/productBuilderKeywayVersion/products/rollerBlind/RollerBlindSingleUtil';
import venetianUtil from '../../components/sales/create/productBuilderKeywayVersion/products/venetian/VenetianUtil';
import bistroUtil from '../../components/sales/create/productBuilderKeywayVersion/products/bistro/BistroUtil';
import {
    PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_COURIER_INSTRUCTIONS,
    PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_CUST_ORD_NUM,
    PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_NOTES, PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_RETAIL_EMAIL,
    PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_RETAIL_NAME,
    PRODUCT_BUILDER_VALIDATION_MIN_LENGTH_CUST_ORD_NUM,
    taxRate
} from "../../store/AppConstants";
import {v4 as uuidv4} from 'uuid';

class SalesOrderProductBuilderV1Service {

    static Instance() {
        return new SalesOrderProductBuilderV1Service();
    }

    getSalesOrder(ordNum) {
        return axios.get('api/sales/order/product-builder/v1/order/one?ordNum=' + ordNum);
    }

    getProductBuilder(productID, accountID) {
        return axios.get('api/sales/order/product-builder/v1/configuration/by-product?productID=' + productID + '&accountID=' + accountID, {
            timeout: 600000
        })
    }

    getProductGroups() {
        return axios.get('api/product/group/list');
    }

    initOrderSubmitRequest(order, user, freight) {
        let request = {
            id: order.id,
            accountID: order.accountID,
            debtorInvoiceOrdNum: order.debtorInvoiceOrdNum ? order.debtorInvoiceOrdNum : null,
            isQuote: false,
            contactName: order.contactName,
            fax: order.fax,
            phone: order.phone,
            email: order.email,
            delCompany: order.delCompany,
            delAddr1: order.delAddr1,
            delAddr2: order.delAddr2,
            delAddr3: order.delAddr3,
            delAddr4: order.delAddr4,
            delCity: order.delCity,
            delState: order.delState,
            delPostCode: order.delPostCode,
            requiredDateString: order.requiredDateString ? order.requiredDateString : "",
            notes: order.notes,
            custOrdNum: (user.isExternalUser ? (getChildAccountID(order.accountID) + order.custOrdNum) : order.custOrdNum).trim(),
            jobRef: order.jobRef ? order.jobRef : "",
            freightMethod: freight.selectedMethodOption,
            freightRuleID: order.pricing.freight.ruleId,
            salesOrderStatus: {ID: (order.salesOrderStatus && order.salesOrderStatus.ID) ? order.salesOrderStatus.ID : 0},
            salesOrderItems: [],
            attachmentIDs: order.attachmentIDs,
            enteredByUser: null,
            isRetail: order.isRetail,
            retailFirstName: order.retailFirstName,
            retailLastName: order.retailLastName,
            retailEmail: order.retailEmail,
            retailPhone: order.retailPhone,
            retailMarkupDiscount: order.retailMarkupDiscount,
            retailMarkupDiscountValue: order.pricing.retailMarkupValueDiscVal,
            retailAddressLine1: order.retailAddressLine1,
            retailAddressLine2: order.retailAddressLine2,
            retailAddressLine3: order.retailAddressLine3,
            retailAddressLine4: order.retailAddressLine4,
            retailCity: order.retailCity,
            retailState: order.retailState,
            retailPostCode: order.retailPostCode,
            courierInstructions: order.courierInstructions,
            isAuthorityToLeave: order.isAuthorityToLeave,
            isAgreementSigned: order.isAgreementSigned,
        };
        return request;
    }

    saveSalesOrderKeyway(order) {
        if (order.id) {
            return this.updateSalesOrderKeyway(order);
        } else {
            return this.createSalesOrderKeyway(order);
        }
    }

    createSalesOrderKeyway(order) {
        return axios.post('api/sales/order/product-builder/v1/order/create', order, {
            timeout: 600000
        });
    }

    updateSalesOrderKeyway(order) {
        return axios.put('api/sales/order/product-builder/v1/order/update', order, {
            timeout: 600000
        });
    }

    initFreightObj() {
        return {
            allRules: [],
            methodOptions: [],
            selectedMethodOption: "",
            isMetroPostcode: false,
            isLoadingAllRules: false,
            isLoadingMetroPostcodeCheck: false
        };
    }


    updateFreightMethodOptions(freight, postcode, isFreightResetRequired) {
        let freightApplicableRules, freightMethodIndex;
        if (isEmpty(freight.allRules)) {
            return freight;
        }
        // sort rules
        freight.allRules = freight.allRules.sort((a, b) => this.freightSortComparator(a, b));

        //find applicable rules
        freightApplicableRules = freight.allRules.filter(rule => (rule.postcode ? postcode === rule.postcode : true));

        //find applicable methods
        freight.methodOptions = getDistinct(freightApplicableRules, "methodKey");

        //select a already selected method or default first one
        freight.selectedMethodOption = isFreightResetRequired ? "" : freight.selectedMethodOption;
        freightMethodIndex = findIndex(freight.methodOptions, "methodKey", freight.selectedMethodOption);
        freightMethodIndex = freightMethodIndex > -1 ? freightMethodIndex : 0;
        freight.selectedMethodOption = freight.methodOptions[freightMethodIndex].methodKey;
        return freight;
    }

    getApplicableFreightRules(freightAllRules, freightSelectedMethodKey) {
        let rules = findItems(freightAllRules, 'methodKey', freightSelectedMethodKey);
        rules = rules.sort((a, b) => this.freightSortComparator(a, b));
        return rules;
    }

    freightSortComparator(x, y) {
        let result = (x.priority === y.priority) ? 0 : (x.priority > y.priority) ? -1 : 1;
        if (result === 0) {
            result = ('' + y.postcode).localeCompare(x.postcode);
        }
        return result;
    }

    calculateFreightCharges(order, freight) {
        let selectedFreight = {
            charge: 0,
            ruleId: 0
        };

        let applicableRules = this.getApplicableFreightRules(freight.allRules, freight.selectedMethodOption);

        let flag = false;
        let preGSTOrderTotal = order.pricing.price - order.pricing.discVal;
        for (let i in applicableRules) {
            if (applicableRules[i].postcode && applicableRules[i].postcode !== order.delPostCode) {
                continue;
            }
            switch (applicableRules[i].attributeKey) {
                case "orderTotal":
                    if (applicableRules[i].attributeValueMin <= preGSTOrderTotal && preGSTOrderTotal <= applicableRules[i].attributeValueMax) {
                        selectedFreight.ruleId = applicableRules[i].id;
                        if (freight.isMetroPostcode) {
                            selectedFreight.charge = applicableRules[i].metroCharge;
                        } else {
                            selectedFreight.charge = applicableRules[i].nonMetroCharge;
                        }
                        flag = true;
                    }
                    break;
                case "width":
                    if (applicableRules[i].attributeValueMin <= order.maxWidth && order.maxWidth <= applicableRules[i].attributeValueMax) {
                        selectedFreight.ruleId = applicableRules[i].id;
                        if (freight.isMetroPostcode) {
                            selectedFreight.charge = applicableRules[i].metroCharge;
                        } else {
                            selectedFreight.charge = applicableRules[i].nonMetroCharge;
                        }
                        flag = true;
                    }
                    break;
                case "n/a":
                    selectedFreight.ruleId = applicableRules[i].id;
                    if (freight.isMetroPostcode) {
                        selectedFreight.charge = applicableRules[i].metroCharge;
                    } else {
                        selectedFreight.charge = applicableRules[i].nonMetroCharge;
                    }
                    flag = true;
                    break;
                default:
                    break;
            }
            if (flag) {
                break;
            }
        }
        if (!(selectedFreight.charge && selectedFreight.charge > 0)) {
            selectedFreight.charge = 0;
        }
        return selectedFreight;
    }

    calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling) {
        // charges per quantity
        if (packagingAndHandling && packagingAndHandling.chargeByProductCode && packagingAndHandling.chargeByProductCode[product.code]) {
            return packagingAndHandling.chargeByProductCode[product.code] * quantity;
        }
        return 0;
    }

    calculateOrderTotal(order) {
        let subTotal = (order.pricing.price + order.pricing.freight.charge + order.pricing.packagingAndHandlingCharges) - order.pricing.discVal;
        order.pricing.tax = subTotal * taxRate;
        order.pricing.total = subTotal + order.pricing.tax;

        if (order.isRetail) {
            order.pricing.retailMarkupValueDiscVal = this.calculatePercentage(order.pricing.retailMarkupValue, order.retailMarkupDiscount);
        }
        return order.pricing;
    }

    getRetailMarkupByProduct(product, productMarkupByProductCode) {
        let result = 0;
        if (productMarkupByProductCode && productMarkupByProductCode[product.code]) {
            result = productMarkupByProductCode[product.code].markup;
        }
        return result;
    }

    isVisibleOrderSummary(order, products) {
        //return (order.pricing && order.pricing.price > 0) || order.attachmentIDs;
        return (products.some(p => (p.items).length > 0)) || order.attachmentIDs;
    }

    isVisibleOrderPricingTable(order) {
        return (order.pricing && order.pricing.price > 0);
    }

    getApplicableProducts(user, products, accountID, order) {
        let result = [], pIndex, flag, isExists;
        if (user.isTestEnvironment && user.isExternalUser) {
            products = products.filter(p => p.isVisibleOnProductBuilderToExternalCustomerOnTestEnvironment)
        }
        if (user.isTestEnvironment && !user.isExternalUser) {
            products = products.filter(p => p.isVisibleOnProductBuilderToInternalUserOnTestEnvironment)
        }
        if (!user.isTestEnvironment && user.isExternalUser) {
            products = products.filter(p => p.isVisibleOnProductBuilderToExternalCustomerOnProdEnvironment)
        }
        if (!user.isTestEnvironment && !user.isExternalUser) {
            products = products.filter(p => p.isVisibleOnProductBuilderToInternalUserOnProdEnvironment)
        }
        products.forEach(product => {
            flag = true;
            if (order && order.salesOrderItems) {
                isExists = (order.salesOrderItems || []).some((soi) => soi.productID === product.id);
            }
            if (!isExists) {
                if (!isEmpty(product.visibleForAccountIDs)) {
                    let visibleForAccountIDs = product.visibleForAccountIDs.split(/[\s,]+/);
                    isExists = visibleForAccountIDs.some(a => accountID.includes(a));
                    if (!isExists) {
                        flag = false;
                    }
                }
            }
            if (flag) {
                result.push(product);
            }
        });
        return result;
    }

    calculatePricing(product, productQuantity, stocks, pricing, discount) {
        pricing.unitPrice = 0;
        pricing.unitDiscVal = 0;
        pricing.price = 0;
        pricing.discVal = 0;
        pricing.discount = discount;
        let stockIndex = -1;
        stocks.forEach(stock => {
            stock.allowDiscount = true;
            stockIndex = product.builder.stocks.findIndex(s => s.prodCode === stock.prodCode);
            if (stockIndex > -1) {
                stock.price = product.builder.stocks[stockIndex].price2; //price2 is bunnings price
                stock.allowDiscount = product.builder.stocks[stockIndex].allowDiscountStockStockGroup;
            }

            stock.finalQty = stock.swishCalculatedQty * (1.0 + stock.wastagePercent);
            stock.finalPrice = (stock.finalQty * stock.price) + stock.flatPrice;

            stock.discount = stock.allowDiscount ? pricing.discount : 0;
            stock.discVal = stock.finalPrice * (stock.discount / 100);
            //--------------
            pricing.unitPrice += stock.finalPrice;
            pricing.unitDiscVal += stock.discVal;
        });

        pricing.price += pricing.unitPrice * productQuantity;
        pricing.discVal += pricing.unitDiscVal * productQuantity;
        return pricing;
    }

    calculatePercentage(number, percent) {
        return (number * (percent / 100));
        //return ((number * markUp) / 100);
    }

    calculateValueWithPercentage(number, percent) {
        let value = this.calculatePercentage(number, percent);
        return number + value;
    }


    calculateMarkupDiscount(pricing) {
        let maximumApplicableDiscount = 0;
        let costPrice = pricing.price;
        let margin = pricing.retailMarkupValue;
        let sellingPrice = costPrice + margin;
        /*
        * P = ((SP-CP) * 100)/SP
        * */
        maximumApplicableDiscount = ((sellingPrice - costPrice) * 100 / sellingPrice);
        return maximumApplicableDiscount;
    }

    getPrice(order, product, isExternalUser, price, discountByProductCode) {
        let finalPrice = price, margin = 0, discount = 0, discVal = 0, discountedPrice = 0, tax = 0;
        if (order.isRetail && isExternalUser) {
            // calculating ww cost

            //calc discount
            discount = (discountByProductCode && discountByProductCode[product.code]) ? discountByProductCode[product.code].discount : 0;
            discVal = this.calculatePercentage(price, discount);
            discountedPrice = price - discVal;

            //calculate tax
            tax = discountedPrice * taxRate;
            finalPrice = discountedPrice + tax;

            //calc retailMargin of finalPrice
            margin = this.calculatePercentage(finalPrice, product.pricing.retailMarkup);
            finalPrice = finalPrice + margin;
        }
        return finalPrice;
    }

    prepareSalesOrderItem(order, p, packagingAndHandling, discountByProductCode, request, errors, productMarkupByProductCode) {
        let salesOrderItem = {};
        switch (p.code) {
            case productConstant.ROLLER_BLINDS.code:
                (p.items || []).forEach((item, itemIndex) => {
                    p = rollerBlindUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode, true);
                    if (!p.items[itemIndex].isValid) {
                        errors = rollerBlindUtil.validateItem(p, itemIndex, order, errors)
                    }
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemRollerBlindSingle = rollerBlindUtil.toSalesOrderItemRollerBlindRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemRollerBlindSingle.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.VENETIANS.code:
                (p.items || []).forEach((item, itemIndex) => {
                    p = venetianUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode, true);
                    if (!p.items[itemIndex].isValid) {
                        errors = venetianUtil.validateItem(p, itemIndex, order, errors)
                    }
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemVenetian = venetianUtil.toSalesOrderItemVenetianRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemVenetian.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.BISTRO.code:
                (p.items || []).forEach((item, itemIndex) => {
                    p = bistroUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode, true);
                    if (!p.items[itemIndex].isValid) {
                        errors = bistroUtil.validateItem(p, itemIndex, order, errors)
                    }
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemBistro = bistroUtil.toSalesOrderItemBistroRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemBistro.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.Quiklok.code:
                (p.items || []).forEach((item, itemIndex) => {
                    p = quiklokUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode, true);
                    if (!p.items[itemIndex].isValid) {
                        errors = quiklokUtil.validateItem(p, itemIndex, order, errors)
                    }
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemQuiklok = quiklokUtil.toSalesOrderItemQuiklokRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemQuiklok.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
            case productConstant.VERTICAL_INDOOR.code:
                (p.items || []).forEach((item, itemIndex) => {
                    p = verticalIndoorUtil.updateProductItem(p, itemIndex, order, packagingAndHandling, discountByProductCode, true);
                    if (!p.items[itemIndex].isValid) {
                        errors = verticalIndoorUtil.validateItem(p, itemIndex, order, errors)
                    }
                    salesOrderItem = this.getSalesOrderItemInstance(order, p, itemIndex, productMarkupByProductCode);
                    salesOrderItem.salesOrderItemVerticalIndoor = verticalIndoorUtil.toSalesOrderItemVerticalIndoorRequest(p, itemIndex, order);
                    salesOrderItem.prodCodes = (salesOrderItem.salesOrderItemVerticalIndoor.stocks || []).map(stock => stock.prodCode);
                    request.salesOrderItems.push(salesOrderItem);
                });
                break;
        }
    }

    prepareCustomerSalesSummaryReportJson(soi, item, customerParams, items) {
        if (soi.salesOrderItemRollerBlindSingle) {
            soi.salesOrderItemRollerBlindSingle.stocks.forEach(stockItem => {
                item = this.getOrderSummaryItemInstance(stockItem, "Roller", soi.salesOrderItemRollerBlindSingle.width, soi.salesOrderItemRollerBlindSingle.drop, soi.salesOrderItemRollerBlindSingle.quantity, soi.salesOrderItemRollerBlindSingle.mount);
                item = {
                    ...customerParams,
                    ...item,
                };
                items.push(cloneDeep(item));
            });
        }
        if (soi.salesOrderItemVenetian) {
            soi.salesOrderItemVenetian.stocks.forEach(stockItem => {
                item = this.getOrderSummaryItemInstance(stockItem, "Venetian", soi.salesOrderItemVenetian.width, soi.salesOrderItemVenetian.drop, soi.salesOrderItemVenetian.quantity, soi.salesOrderItemVenetian.mount);
                item = {
                    ...customerParams,
                    ...item,
                };
                items.push(cloneDeep(item));
            });
        }
        if (soi.salesOrderItemBistro) {
            soi.salesOrderItemBistro.stocks.forEach(stockItem => {
                item = this.getOrderSummaryItemInstance(stockItem, "Bistro", soi.salesOrderItemBistro.width, soi.salesOrderItemBistro.drop, stockItem.finalQty, "");
                item = {
                    ...customerParams,
                    ...item,
                };
                items.push(cloneDeep(item));
            });
        }
        if (soi.salesOrderItemQuiklok) {
            soi.salesOrderItemQuiklok.stocks.forEach(stockItem => {
                item = this.getOrderSummaryItemInstance(stockItem, "Quiklok", soi.salesOrderItemQuiklok.width, soi.salesOrderItemQuiklok.drop, soi.salesOrderItemQuiklok.quantity, soi.salesOrderItemQuiklok.mount);
                item = {
                    ...customerParams,
                    ...item,
                };
                items.push(cloneDeep(item));
            });
        }
        if (soi.salesOrderItemVerticalIndoor) {
            soi.salesOrderItemVerticalIndoor.stocks.forEach(stockItem => {
                item = this.getOrderSummaryItemInstance(stockItem, "Vertical Indoor", soi.salesOrderItemVerticalIndoor.width, soi.salesOrderItemVerticalIndoor.drop, soi.salesOrderItemVerticalIndoor.quantity, soi.salesOrderItemVerticalIndoor.mount);
                item = {
                    ...customerParams,
                    ...item,
                };
                items.push(cloneDeep(item));
            });
        }
    }

    getSalesOrderItemInstance(order, product, itemIndex, productMarkupByProductCode) {
        return {
            id: product.items[itemIndex].id ? product.items[itemIndex].id : null,
            productGroupID: product.productGroupID,
            productID: product.id,
            discount: product.items[itemIndex].pricing.discount,
            salesOrderStatus: null,
            attachmentIDs: "",
            salesOrderItemRollerBlindSingle: null,
            salesOrderItemVenetian: null,
            salesOrderItemBistro: null,
            salesOrderItemQuiklok: null,
            prodCodes: [],
            retailMarkup: order.isRetail ? this.getRetailMarkupByProduct(product, productMarkupByProductCode) : 0
        }
    }

    getOrderSummaryItemInstance(stockItem, blindType, width, drop, qty, mount) {
        return {
            "prodCode": stockItem.prodCode,
            "fineline": stockItem.dimStockItem ? stockItem.dimStockItem.stockItemCd : product.builder.stocks[stockIndex].xRefCode,
            "finelineDescription": stockItem.dimStockItem ? stockItem.dimStockItem.stockItemDescription : (stockItem.shpStockItem ? stockItem.shpStockItem.description : ""),
            "blindType": blindType,
            "mount": mount,
            "qty": qty,
            "width": width,
            "drop": drop,
            "barcode": stockItem.shpStockItem ? stockItem.shpStockItem.upc : "",
        };
    }

    updateProductItemCount(products, productIndex) {
        let product = products[productIndex];

        product.itemCount = 0;
        //update associated parent product count
        products.forEach(p => {
            if (product.id === p.id || product.id === p.parentProductID) {
                product.itemCount += (p.items.length + p.savedItems.length);
            }
        });

        return product;
    }

    isProductHasItems(products, productCode, type) {
        let hasItems = products.some(p => {
            let result = false;
            switch (type) {
                case "include":
                    result = p.code === productCode;
                    break;
                case "exclude":
                    result = p.code !== productCode;
                    break;
            }
            return ( result && (p.items.length > 0));
        });
        return hasItems;
    }

    getItemFactoryInstance(id) {
        return {
            customID: uuidv4(),
            pricing: {
                unitPrice: 0,
                price: 0,
            }
        }
    }

    getStockFactoryInstance(id) {
        return {
            id: null,
            prodCode: "",
            price: 0,
            flatPrice: 0,
            quantityMultiplier: 1,
            qtyFormulaId: null,
            productConfigurationOptionId: null,
            productConfigurationOptionSetId: null,
            swishQuantityFormula: null,
            wastageFormula: null,
            wastagePercent: 0,
            calculatedQty: 1,
            swishCalculatedQty: 1,
            keywayCalculatedQty: 1,
            stockPickSlipQty: 0,
            keywayMeasurementUnit: "unit",
            swishMeasurementUnit: "unit",
            keywayConversionFactor: 1,
            swishConversionFactor: 1,
            isVisibleInPartList: true,
            isParentItem: false,
            isDeductionApplicable: false,
            description: "",
            attribute: "",
            label: "",
            attributeRowSpan: 1,
            unitPrice: 0,
            isCustom: false,
            discontinued: false,
            soldOut: false,
            qoh: 0
        }
    }

    convertToCustom(product, itemIndex) {
        return {
            pricing: {
                unitPrice: 0,
                price: 0,
            }
        }
    }

    getValueOrDefault(product, itemIndex, valueType, defaultValue, key) {
        let result = defaultValue;
        switch (valueType) {
            case "value":
                if (itemIndex > -1) {
                    if (product.items[itemIndex].configuration[key].selected.value) {
                        result = product.items[itemIndex].configuration[key].selected.value;
                    }
                }
                break;
            case "optionKey":
                if (itemIndex > -1) {
                    if (!isEmpty(product.items[itemIndex].configuration[key].selected.value)) {
                        result = product.items[itemIndex].configuration[key].selected.value.optionKey;
                    }
                }
                break;
        }
        return result;
    }

    initDeduction() {
        let deduction = {
            deductionQty: 0,
            deductionWidth: 0,
            deductionDrop: 0,
            cutWidth: 0,
            cutDrop: 0,
        };
        return cloneDeep(deduction);
    }

    validateForm(formError, order, key, user) {
        let message = "", pattern, text, childAccountID;

        switch (key) {
            case "custOrdNum":
                if (!"00010,09009,09111,01005".includes(order.accountID)) {
                if (!order.custOrdNum) {
                    message = "PO Number can't be empty";
                    formError.custOrdNum = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                if ((!formError.custOrdNum) && (order.custOrdNum && order.custOrdNum.length > PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_CUST_ORD_NUM)) {
                    message = "PO Number: max " + PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_CUST_ORD_NUM + " characters allowed";
                    formError.custOrdNum = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                if ((!formError.custOrdNum) && (order.custOrdNum && order.custOrdNum.length < PRODUCT_BUILDER_VALIDATION_MIN_LENGTH_CUST_ORD_NUM)) {
                    message = "PO Number: min " + PRODUCT_BUILDER_VALIDATION_MIN_LENGTH_CUST_ORD_NUM + " characters allowed";
                    formError.custOrdNum = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                if ((!formError.custOrdNum)) {
                    pattern = /^[a-zA-Z]{1}[0-9]{8}$/;
                    text = "";
                    if (user.isExternalUser) {
                        text = order.custOrdNum; //not a submitted request, custOrdNum doesn't include store code
                    } else {
                        childAccountID = getChildAccountID(order.accountID);
                        childAccountID = childAccountID ? childAccountID.trim() : "";
                        text = order.custOrdNum.replace(childAccountID, "");  //not a submitted request, custOrdNum include store code, need to be removed
                    }
                    if (!pattern.test(text)) {
                        if (user.isExternalUser) {
                            message = "Please enter PO number in format C12345678. Must start with single alpha character, must have 8 digits.";
                        } else {
                            message = "Please enter PO number in format 1234C12345678. Must start with store code, then must have single alpha character, and then must have 8 digits.";
                        }
                        formError.custOrdNum = message;
                        formError.errors.push(message);
                        formError.isValid = false;
                    }
                }
                }
                break;
            default:
                if (!order.accountID) {
                    message = "Customer not selected";
                    formError.accountID = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                if (!"00010,09009,09111,01005".includes(order.accountID)) {
                if (!order.custOrdNum) {
                    message = "PO Number can't be empty";
                    formError.custOrdNum = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                if ((order.custOrdNum && order.custOrdNum.length > PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_CUST_ORD_NUM)) {
                    message = "PO Number: max " + PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_CUST_ORD_NUM + " characters allowed";
                    formError.custOrdNum = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                if ((order.custOrdNum && order.custOrdNum.length < PRODUCT_BUILDER_VALIDATION_MIN_LENGTH_CUST_ORD_NUM)) {
                    message = "PO Number: min " + PRODUCT_BUILDER_VALIDATION_MIN_LENGTH_CUST_ORD_NUM + " characters allowed";
                    formError.custOrdNum = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                if ((!formError.custOrdNum)) {
                    pattern = /^[a-zA-Z]{1}[0-9]{8}$/;
                    childAccountID = getChildAccountID(order.accountID);
                    childAccountID = childAccountID ? childAccountID.trim() : "";
                    text = order.custOrdNum.replace(childAccountID, "");   //submitted request, custOrdNum include store code, need to be removed

                    if (!pattern.test(text)) {
                        if (user.isExternalUser) {
                            message = "Please enter PO number in format C12345678. Must start with single alpha character, must have 8 digits.";
                        } else {
                            message = "Please enter PO number in format 1234C12345678. Must start with store code, then must have single alpha character, and then must have 8 digits.";
                        }
                        formError.custOrdNum = message;
                        formError.errors.push(message);
                        formError.isValid = false;
                    }
                }
                }
                if ((order.notes && order.notes.length > PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_NOTES)) {
                    message = "Notes: max " + PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_NOTES + " characters allowed";
                    formError.notes = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                if ((order.courierInstructions && order.courierInstructions.length > PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_COURIER_INSTRUCTIONS)) {
                    message = "Delivery Instructions: max " + PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_COURIER_INSTRUCTIONS + " characters allowed";
                    formError.courierInstructions = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                if ((!(order.salesOrderItems && order.salesOrderItems.length > 0)) && (!order.attachmentIDs)) {
                    message = "No items selected neither any file uploaded";
                    formError.salesOrderItems = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                if (!order.isAgreementSigned) {
                    message = "Please accept agreement by ticking the checkbox";
                    formError.isAgreementSigned = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                if (!order.retailFirstName) {
                    message = "Please enter customer name here";
                    formError.retailFirstName = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                if ((order.retailFirstName && order.retailFirstName.length > PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_RETAIL_NAME)) {
                    message = "Customer name: max " + PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_RETAIL_NAME + " characters allowed";
                    formError.retailFirstName = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                if (!order.retailEmail) {
                    message = "Please enter customer email here";
                    formError.retailEmail = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                if (order.retailEmail && !validateEmail(order.retailEmail)) {
                    message = "Customer email isn't valid";
                    formError.retailEmail = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                if ((order.retailEmail && order.retailEmail.length > PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_RETAIL_EMAIL)) {
                    message = "Customer email: max " + PRODUCT_BUILDER_VALIDATION_MAX_LENGTH_RETAIL_EMAIL + " characters allowed";
                    formError.retailEmail = message;
                    formError.errors.push(message);
                    formError.isValid = false;
                }
                break;
        }
        return formError;
    }

    validateFormOrderSummaryPreCreate(formError, order) {
        let message = "";

        if (!order.accountID) {
            message = "Customer not selected";
            formError.accountID = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if ((!(order.salesOrderItems && order.salesOrderItems.length > 0)) && (!order.attachmentIDs)) {
            message = "No items selected neither any file uploaded";
            formError.salesOrderItems = message;
            formError.errors.push(message);
            formError.isValid = false;
        }
        if (!order.retailFirstName) {
            message = "Please enter customer name here";
            formError.retailFirstName = message;
            formError.errors.push(message);
            formError.isValid = false;
        }

        return formError;
    }

    getFabricColorLabel(o) {
        let result = o.color;
        if ((!o.isActive) || (o.stock && o.stock.discontinued)) {
            result = result + " (Discontinued)";
        } else {
            if ((o.stock && o.stock.soldOut)) {
                result = result + " (Out of Stock)";
            }
        }
        return result;
    }

    findShpStockItem(product, prodCode) {
        let stockIndex = product.builder.stocks.findIndex(s => s.prodCode === prodCode);
        let result = null;
        if (stockIndex > -1) {
            result = product.builder.stocks[stockIndex];
        }
        return result;
    }

    findDimStockItem(shpStockItem) {
        let result = null;
        if (shpStockItem && shpStockItem.dimStockItem) {
            result = shpStockItem.dimStockItem;
        }
        return result;
    }

    createFinelineItemInstance(stockItem, attribute) {
        let dimStockItem = stockItem.dimStockItem;
        let shpStockItem = stockItem.shpStockItem;
        return {
            prodCode: stockItem.prodCode,
            stockItemCd: dimStockItem ? dimStockItem.stockItemCd : (shpStockItem ? shpStockItem.xRefCode : stockItem.prodCode),
            description: dimStockItem ? dimStockItem.stockItemDescription : (shpStockItem ? shpStockItem.description : stockItem.description),
            attribute: attribute,
            shpXRefCode: shpStockItem ? shpStockItem.xRefCode : "",
            shpDescription: shpStockItem ? shpStockItem.description : "",
            soldOut: shpStockItem ? shpStockItem.soldOut : "",
            discontinued: shpStockItem ? shpStockItem.discontinued : "",
        }
    }

    createOffRangeItemInstance(stockItem) {
        let shpStockItem = stockItem.shpStockItem;
        return {
            prodCode: shpStockItem ? shpStockItem.prodCode : '',
            price: shpStockItem ? shpStockItem.price2 : 0
        }
    }


    isSubmitButtonShouldBeVisible(order, role) {
        /*
        *
        * CASE NEW ORDER --CREATE
         (order.salesOrderJobStatus.id < 45)

        CASE EXISTING ORDER --UPDATE
         (ORDER IS NOT CANCELLED && (Admin and Customer service))
         OR ( (order.salesOrderJobStatus.id < 45) && ( Admin and Customer service))
         OR ((order.salesOrderJobStatus.id > 45) && (order is de-scheduled) && (Admin and Customer service) )
        */
        let result = true;

        if (order.debtorInvoiceOrdNum > 0) {
            // existing order
            result = (
                (['Admin', 'Customer Service'].includes(role))
                && (order.salesOrderJobStatus && order.salesOrderJobStatus.id !== 1)
                && (
                    (order.salesOrderJobStatus && order.salesOrderJobStatus.id < 45)
                    || (order.salesOrderJobStatus && order.salesOrderJobStatus.id > 45 && order.isDescheduleOrder)
                )
            )
        } else {
            // new  order, already true
        }
        return result;
    }

    getFormErrorInstance() {
        return cloneDeep({isValid: true, message: "", dom: null,});
    }
}

export default SalesOrderProductBuilderV1Service.Instance();