import React, { Component } from "react";
import { getWeeklyDateList } from '../services/CommonService';
import Select from 'react-select';
import { isEmpty } from 'lodash';

export default class WeeklyDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultSelectedWeekly: this.props.defaultSelectedWeekly,
            selectedWeekly: '',
            selected: null,
            option: []
        };

        this.getFetchWeekList = this.getFetchWeekList.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        this.getFetchWeekList();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.defaultSelectedWeekly !== nextProps.defaultSelectedWeekly || this.state.selectedWeekly !== nextProps.selectedWeekly) {
            this.setState({
                defaultSelectedWeekly: nextProps.defaultSelectedWeekly,
                selectedWeekly: nextProps.selectedWeekly
            }, () => {
                this.getFetchWeekList();
            });
        }
    }

    getFetchWeekList() {
        let options = [], option = {};
        let { selected } = this.state;
        let weeklyList = getWeeklyDateList(new Date(), 5);
        (weeklyList || []).forEach((item, index) => {//index as weekNumber how much weekNumber you should subtract
            option = { 'label': item, 'value': index };
            if (this.props.defaultSelectedWeekly !== null || this.props.defaultSelectedWeekly !== undefined) {
                if (option.value === this.props.defaultSelectedWeekly) {
                    if (isEmpty(selected) || (selected && selected.value !== option.value)) {
                        this.handleSelect(option, "default");
                    }
                }
            }
            if (this.props.selectedWeekly !== null || this.props.selectedWeekly !== undefined) {
                if (option.value === this.props.selectedWeekly) {
                    this.handleSelect(option, "selected");
                }
            }
            options.push(option);
        });
        this.setState({ options });
    }

    handleSelect(selectedOption, event) {
        switch (event) {
            case "selected":
                this.setState({ selected: selectedOption, selectedYear: selectedOption.value });
                break;
            case "onChange":
            case "default":
            default:
                this.setState({ selected: selectedOption, selectedYear: selectedOption.value }, () => {
                    this.props.handleChange(selectedOption.value);
                });
        }
    };

    render() {
        let { selected, options } = this.state;
        return (
            <Select
                options={options}
                value={selected}
                placeholder={"Select Week"}
                onChange={(selection) => this.handleSelect(selection, "onChange")}
                closeMenuOnSelect={true}
                isSearchable={false}
            />
        );
    }
}