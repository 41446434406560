import axios from '../axios';

export default class ProductionCapacityService {


    getDefaultProductionCapacity() {
        return axios.get('api/productionCapacity/getProductionCapacityForDefault')
    }

    getDefaultProductionCapacityForEachProductionLines() {
        return axios.get('api/productionCapacity/getProductionCapacityForDefault/productionLines')
    }

    getProductionLineDropDown() {
        return axios.get('api/productionCapacity/productionLine/dropdown')
    }

    saveDefaultProductionCapacity(productionCapacity) {
        return axios.post('api/productionCapacity/default', productionCapacity)
    }

    getProductionLineList() {
        return axios.get('api/productionCapacity/productionLine/list');
    }

    saveProductionCapacityDayWise(req) {
        return axios.post('api/productionCapacity/daywise/save', req);
    }

    getProductionCapacityWithDateAndProductionLineName() {
        return axios.get('api/productionCapacity/by-dates');
    }

    saveProductionCapacityBetweenDateRange(req) {
        return axios.post('api/productionCapacity/save', req);
    }

    getCustomProductionCapacityForTodayOnwards() {
        return axios('api/productionCapacity/custom/today-onwards')
    }

    saveDatedProductionCapacity(productionCapacity) {
        return axios.post('api/productionCapacity/dated', productionCapacity);
    }
}