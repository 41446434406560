import OrderService from './OrderService'
import SalesQuoteService from "./sales/SalesQuoteService";
import FileSaver from 'file-saver';
import retailService from './retail/RetailService';

//common functions that are used everywhere in project
// need to add more || change calls


// this function downloads (inv/quote) and returns success response if true else returns error Once api call is done.
export function downloadInvoice(OrdNum, type, callback) {
    let orderService = new OrderService();
    orderService.downloadInvoice(OrdNum).then(response => {
        let fileName = "";
        if (type === "quote") {
            fileName = OrdNum + " Quote.pdf";
        } else {
            fileName = OrdNum + " Invoice.pdf";
        }
        FileSaver.saveAs(response.data, fileName);
        callback(response);   // here is the return call
    }).catch(error => {
        callback(error);    // here is the return call
    });
}

export function convertQuoteToOrder(OrdNum, callback) {

    let salesQuoteService = new SalesQuoteService();
    salesQuoteService.convertSelfQuoteToOrder(OrdNum).then(response => {
        if (response.status === 200 || response.status === "200") {
            callback(response);   // here is the return call
        }
    }).catch(error => {
        console.log(error.data);
        callback(error);    // here is the return call
    });
}


export function getquotesBody() {
    let body = "Dear @Company , <br/> Please find attached quote # @OrdNum";
    body += " <br/> You can now login to the Windoware KEYWAY portal to submit orders, check order status, download invoices and statements anywhere, anytime.";
    body += " <br/> <a href='https://keyway.windoware.com.au/' target='_blank'>Click here</a> to login or signup today! ";

    return body;
}

export function isRetailBoardVisible(callback) {
    retailService.RetailBoardAllowed().then(response => {
        if (response.status === 200 || response.status === '200')
            callback(response);

    }).catch(error => {
        console.log(error.data);
        callback(error);    // here is the return call
    });
}

