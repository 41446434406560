import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Col, Row, Table } from "reactstrap";
import { toast, ToastContainer } from 'react-toastify';
import {
    getDistinctValues,
    handleErrorMessage,
    getDatesBetweenDateRange,
    getCurrentWeekDateFromMonToSun,
    getWeekDatesFromMonToSun
} from '../../services/CommonService';
import dashboardService from '../../services/DashboardService';
import { isEmpty, cloneDeep } from 'lodash';
import NumberFormat from "react-number-format";
import WeeklyDropDown from '../WeeklyDropDown';

export default class WeeklyPerformanceUnitPrice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productsDataArray: [],
            products: [],
            isLoadingProducts: false,
            isLoading: false,
            weekWiseTotalData: {},
            selected: ""
        };
        this.getOrderData = this.getOrderData.bind(this);
        this.getTotal = this.getTotal.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {
        let fromToDate = getCurrentWeekDateFromMonToSun(new Date());
        this.setState({ selected: 0 }, () => {
            this.getOrderData(fromToDate);
        })
    }

    getOrderData(fromToDate) {
        let productsDataArray = [];
        this.setState({ isLoading: true });
        let currentWeekMasterData = getDatesBetweenDateRange(fromToDate.startDate, fromToDate.endDate);
        dashboardService.getWeeklyPerformanceUnitPriceData(fromToDate).then(response => {
            let data = response.data;
            data = this.prepareDistinctOrderData(data);
            productsDataArray = this.prepareResultStructure(data, currentWeekMasterData);
            productsDataArray = this.fillResultStructureWithData(data, productsDataArray);
            this.getTotal(productsDataArray, currentWeekMasterData);
            this.setState({ productsDataArray, isLoading: false });
        }).catch(error => {
            this.setState({ isLoading: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
            console.error(error);
        });
    }

    prepareDistinctOrderData(data) {
        let orderData = cloneDeep(data);
        let distinctOrderData = [];
        (orderData || []).forEach(item => {
            if (data.filter(x => x.productName === item.productName && x.orderDate === item.orderDate).length > 1) {
                let units = 0, values = 0;
                (data || []).filter(x => x.productName === item.productName && x.orderDate === item.orderDate).forEach(d => {
                    units += d.totalUnits;
                    values += d.totalValue;
                });
                item.totalUnits = units;
                item.totalValue = values;
            }
            if (!distinctOrderData.find(x => x.productName === item.productName && x.orderDate === item.orderDate)) {
                distinctOrderData.push(item);
            }
        });
        return distinctOrderData;
    }

    prepareResultStructure(data, currentWeekMasterData) {
        let productTemp, weekDayDataTemp, productsDataArray = [];
        let productNamesMasterData = getDistinctValues(data, 'productName');

        productNamesMasterData.forEach(productName => {
            productTemp = {
                productName: productName,
                weekDayDataArray: [],
                units: 0,
                values: 0
            };
            currentWeekMasterData.forEach(weekDay => {
                weekDayDataTemp = {
                    orderDate: weekDay.dateString,
                    displayDayName: weekDay.displayDayName,
                    units: 0,
                    values: 0
                };
                productTemp.weekDayDataArray.push(cloneDeep(weekDayDataTemp));
            });
            productsDataArray.push(cloneDeep(productTemp));
        });
        return productsDataArray;
    }

    fillResultStructureWithData(data, productsDataArray) {
        let itemIndex;
        productsDataArray.forEach(productsData => {
            productsData.weekDayDataArray.forEach(weekDayData => {
                itemIndex = data.findIndex(d => productsData.productName === d.productName && weekDayData.orderDate === d.orderDate);
                if (itemIndex > -1) {
                    weekDayData.units = data[itemIndex].totalUnits;
                    weekDayData.values = data[itemIndex].totalValue;
                }
                productsData.units += weekDayData.units;
                productsData.values += weekDayData.values;
            });
        });
        return productsDataArray;
    }

    getTotal(productsDataArray, currentWeekMasterData) {
        let weekWiseTotalData = {}, weekDayWiseData = [], totalUnits = 0, totalValues = 0, temp;
        currentWeekMasterData.forEach(weekDay => {
            temp = {
                orderDate: weekDay.dateString,
                totalDayWiseUnits: 0,
                totalDayWiseValues: 0
            };
            productsDataArray.forEach(productsData => {
                let itemIndex = productsData.weekDayDataArray.findIndex(x => x.orderDate === weekDay.dateString);
                if (itemIndex > -1) {
                    temp.totalDayWiseUnits += productsData.weekDayDataArray[itemIndex].units;
                    temp.totalDayWiseValues += productsData.weekDayDataArray[itemIndex].values;
                }
            });
            weekDayWiseData.push(cloneDeep(temp));
        });
        productsDataArray.forEach(productsData => {
            totalUnits += productsData.units;
            totalValues += productsData.values;
        });
        weekWiseTotalData = { totalUnits: totalUnits, totalValues: totalValues, weekDayWiseData: weekDayWiseData };
        this.setState({ weekWiseTotalData: weekWiseTotalData });
    }

    handleChange(change) {
        let { selected } = this.state;
        if (selected !== change) {
            let fromToDate = getWeekDatesFromMonToSun(new Date(), change);
            this.setState({ selected: change }, () => {
                this.getOrderData(fromToDate);
            });
        }
    }

    render() {
        let { productsDataArray, isLoading, weekWiseTotalData, selected } = this.state;
        if (isLoading) {
            return null;
        } else if (isEmpty(productsDataArray)) {
            return (
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"p-2"}>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col xl={9} lg={9} md={6} className={"flex-fill align-self-center"}>
                                    <h6>Weekly Performance</h6>
                                </Col>
                                <Col xl={3} lg={3} md={6} className={"flex-fill align-self-center"}>
                                    <WeeklyDropDown handleChange={this.handleChange}
                                        defaultSelectedWeekly={selected}
                                        selectedWeekly={selected} />
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <h6 className="text-center text-danger">No data is available for the selected week.</h6>
                        </CardBody>
                    </Card>
                    <ToastContainer />
                </Col>
            );
        } else {
            return (
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"p-2"}>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col xl={9} lg={9} md={6} className={"flex-fill align-self-center"}>
                                    <h6>Weekly Performance</h6>
                                </Col>
                                <Col xl={3} lg={3} md={6} className={"flex-fill align-self-center"}>
                                    <WeeklyDropDown handleChange={this.handleChange}
                                        defaultSelectedWeekly={selected}
                                        selectedWeekly={selected} />
                                </Col>
                            </Row>
                        </CardHeader>
                        <div>
                            <Table striped bordered responsive hover size='sm' className={"mb-0"}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th colSpan={productsDataArray[0].weekDayDataArray.length + 1}
                                            className={"text-center"}>Units
                                        </th>
                                        <th colSpan={productsDataArray[0].weekDayDataArray.length + 1}
                                            className={"text-center"}>Value$
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Product/Week</th>
                                        {(productsDataArray[0].weekDayDataArray || []).map((item, index) => {
                                            return (<th key={index} className={"text-right"}>
                                                {item.displayDayName}
                                            </th>
                                            )
                                        })}
                                        <th className={"text-right"}>Total</th>
                                        {(productsDataArray[0].weekDayDataArray || []).map((item, index) => {
                                            return (<th key={index} className={"text-right"}>
                                                {item.displayDayName}
                                            </th>
                                            )
                                        })}
                                        <th className={"text-right"}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(productsDataArray || []).map((productsData, index) =>
                                        <tr key={index}>
                                            <th>{productsData.productName}</th>
                                            {(productsData.weekDayDataArray || []).map((weekDayData, index) =>
                                                <td className="text-right"
                                                    key={index}>{weekDayData.units ? weekDayData.units : '-'}</td>
                                            )}
                                            <th className="text-right">{productsData.units}</th>

                                            {(productsData.weekDayDataArray || []).map((weekDayData, index) =>
                                                <td className="text-right" key={index}>
                                                    {weekDayData.values ? <NumberFormat value={weekDayData.values} prefix={'$'}
                                                        displayType={'text'} decimalScale={0}
                                                        thousandSeparator={true} /> : '-'}
                                                </td>
                                            )}
                                            <th className="text-right">
                                                <NumberFormat value={productsData.values} prefix={'$'}
                                                    displayType={'text'} decimalScale={0}
                                                    thousandSeparator={true} />
                                            </th>
                                        </tr>
                                    )}
                                    <tr>
                                        <th>Total</th>
                                        {(weekWiseTotalData.weekDayWiseData || []).map((item, index) =>
                                            <th className="text-right" key={index}>{item.totalDayWiseUnits ?
                                                <NumberFormat value={item.totalDayWiseUnits}
                                                    displayType={'text'} decimalScale={0}
                                                    thousandSeparator={true} /> : '-'}
                                            </th>
                                        )}
                                        <th className="text-right"><NumberFormat value={weekWiseTotalData.totalUnits}
                                            displayType={'text'} decimalScale={0}
                                            thousandSeparator={true} /></th>

                                        {(weekWiseTotalData.weekDayWiseData || []).map((item, index) =>
                                            <th className="text-right" key={index}>{item.totalDayWiseValues ?
                                                <NumberFormat value={item.totalDayWiseValues} prefix={'$'}
                                                    displayType={'text'} decimalScale={0}
                                                    thousandSeparator={true} /> : '-'}
                                            </th>
                                        )}
                                        <th className="text-right"><NumberFormat value={weekWiseTotalData.totalValues}
                                            prefix={'$'}
                                            displayType={'text'} decimalScale={0}
                                            thousandSeparator={true} /></th>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Card>
                    <ToastContainer />
                </Col>
            );
        }
    }
}