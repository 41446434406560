import React, {Component} from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";
import UserRolePage from "./UserRolePage";
import PrivilegePage from "./PrivilegePage"
import {Link} from "react-router-dom";
import {ToastContainer} from "react-toastify";

const userRoleAndPrivilegeTabs = [
    {
        label: "Roles",
        key: "role"
    },
    {
        label: "Privilege",
        key: "privilege"
    }
];
export default class UserRolesPrivilegeTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: "role"
        }
    }

    toggleTab(tab) {
        this.setState({activeTab: tab});
    }

    render() {
        let {activeTab} = this.state;
        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Roles</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {userRoleAndPrivilegeTabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab === tab.key})}
                                        onClick={() => {
                                            this.toggleTab(tab.key);
                                        }}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"}
                                                color={"link"}>{tab.label}&nbsp;
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="role">
                            <UserRolePage/>
                        </TabPane>
                        <TabPane tabId="privilege">
                            <PrivilegePage/>
                        </TabPane>
                    </TabContent>
                </Card>
                <ToastContainer/>
            </div>
        )
    }
}