import {defaultLabelStore} from "../production/DefautLabelStore";
import {venetianLabelStore} from "../production/VenetianLabelStore";
import {bistroLabelStore} from "../production/BistroLabelStore";
import {quiklokLabelStore} from "../production/QuiklokLabelStore";
import {verticalIndoorLabelStore} from "../default/VerticalIndoorLabelStore";
import {rollerBlindLabelStore} from "../production/RollerBlindLabelStore";
import {productConstant} from "../../../../../ProductGroups";

export function getSalesOrderItemProductLabelStore(product) {
    let store = null;
    switch (product.code) {
        case productConstant.ROLLER_BLINDS.code:
            store = rollerBlindLabelStore;
            break;
        case productConstant.VENETIANS.code:
            store = venetianLabelStore;
            break;
        case productConstant.BISTRO.code:
            store = bistroLabelStore;
            break;
        case productConstant.Quiklok.code:
            store = quiklokLabelStore;
            break;
        case productConstant.VERTICAL_INDOOR.code:
            store = verticalIndoorLabelStore;
            break;
        default:
            store = defaultLabelStore;
            break;
    }
    return store;
}