import axios from './axios';


export default class RoleService {


    fetchRoleList() {
        return axios.get('api/admin/role/list');
    }

    fetchAllPrivilegeList() {
        return axios.get('api/admin/privilege/list');
    }

    saveRole(requestBody) {
        return axios.post('api/admin/role/save', requestBody);
    }
    getUserRoleList(request) {
        return axios.post("api/admin/user/roleList", request);
    }
    getUserList(roleId, request) {
        return axios.post("api/admin/user/list/byRoleId?roleId=" + roleId, request);
    }

}
