import React, {Component} from 'react';
import {
    Button,
    Col,
    Collapse,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from 'reactstrap';
import {isEmpty} from 'lodash';
import CreatableSelect from 'react-select/creatable';
import {getDistinctValues, validateEmail} from "../../services/CommonService";
import UploadFiles from "../attachment/UploadFiles";
import {colourStyles} from "../../store/ComposeEmailReactSelectStyle";
import {toast, ToastContainer} from 'react-toastify';

export default class ComposeEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: {
                id: "",
                from: [{label: "Windoware KEYWAY(keyway@windoware.com.au)", value: "keyway@windoware.com.au"}],
                fromOptions: [{label: "Windoware KEYWAY(keyway@windoware.com.au)", value: "keyway@windoware.com.au"}],
                to: [],
                toOptions: [],
                cc: [],
                ccOptions: [],
                bcc: [],
                bccOptions: [],
                subject: "",
                body: "",
                attachmentIDs: ""
            },
            isCcOpen: false,
            isBccOpen: false
        };
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({message: this.props.message});
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.message.id !== nextProps.message.id) {
            this.setState({message: nextProps.message});
        }
    }

    toggle(key, change) {
        this.setState({[key]: change});
    }

    handleMessageChange(key, change) {
        let {message} = this.state;
        message[key] = change;
        this.setState({message});
    }


    handleSubmit(e) {

        let message = this.state.message;
        let errors = [];

        this.validateMessage(message, errors);
        if (!isEmpty(errors)) {
            errors.forEach(errorMessage => {
                toast.info(errorMessage);
            });
            return;
        }

        e.preventDefault();
        this.props.handleSubmit(message);
    }

    validateMessage(message, errors) {
        let isValidEmailAddress = true;

        let tos = getDistinctValues(message.to ? message.to : [], 'value');
        let ccs = getDistinctValues(message.cc ? message.cc : [], 'value');
        let bccs = getDistinctValues(message.bcc ? message.bcc : [], 'value');

        if (isEmpty(tos)) {
            errors.push("No 'To' recipients!");
        }
        (tos || []).forEach(toEmailAddress => {
            isValidEmailAddress = validateEmail(toEmailAddress);
            if (!isValidEmailAddress) {
                errors.push("Not a valid email address!");
            }
        });
        (ccs || []).forEach(toEmailAddress => {
            isValidEmailAddress = validateEmail(toEmailAddress);
            if (!isValidEmailAddress) {
                errors.push("Not a valid email address!");
            }
        });
        (bccs || []).forEach(toEmailAddress => {
            isValidEmailAddress = validateEmail(toEmailAddress);
            if (!isValidEmailAddress) {
                errors.push("Not a valid email address!");
            }
        });
        return errors;
    }

    render() {
        let {message, isBccOpen, isCcOpen} = this.state;
        let {isOpen, toggle, isSendingEmail,isLoadingData} = this.props;

        return (
            <Modal isOpen={isOpen} size="lg" scrollable={true}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Compose Email
                </ModalHeader>
                <ModalBody style={{ minHeight: 300 }}>
                    {isLoadingData ? <Spinner color={"primary"} />
                        :
                        <Form>
                            <FormGroup>
                                <Row>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}><Label>From Email</Label></Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <div className="text-right">
                                            {
                                                isCcOpen
                                                    ? null
                                                    :
                                                    <Button onClick={() => this.toggle("isCcOpen", true)} color={"link"}
                                                        size={"sm"} className={"mr-2"}>
                                                        Cc
                                                </Button>
                                            }

                                            {
                                                isBccOpen
                                                    ? null
                                                    :
                                                    <Button onClick={() => this.toggle("isBccOpen", true)} color={"link"}
                                                        size={"sm"} className={"mr-2"}>
                                                        Bcc
                                                </Button>
                                            }
                                        </div>
                                    </Col>
                                </Row>

                                <CreatableSelect
                                    isMulti
                                    formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                    isValidNewOption={(inputValue, selectValue, selectOptions) => validateEmail(inputValue)}
                                    placeholder={"Type complete email address then press enter"}
                                    onChange={(value) => this.handleMessageChange("from", value)}
                                    value={message.from}
                                    options={message.fromOptions}
                                    styles={colourStyles}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>To Email</Label>
                                <CreatableSelect
                                    isMulti
                                    formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                    isValidNewOption={(inputValue, selectValue, selectOptions) => validateEmail(inputValue)}
                                    placeholder={"Type complete email address then press enter"}
                                    onChange={(value) => this.handleMessageChange("to", value)}
                                    value={message.to}
                                    options={message.toOptions}
                                    styles={colourStyles}
                                />
                            </FormGroup>
                            <Collapse isOpen={isCcOpen}>
                                <FormGroup>
                                    <Label>Cc Email</Label>
                                    <CreatableSelect
                                        isMulti
                                        formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                        isValidNewOption={(inputValue, selectValue, selectOptions) => validateEmail(inputValue)}
                                        placeholder={"Type complete email address then press enter"}
                                        onChange={(value) => this.handleMessageChange("cc", value)}
                                        value={message.cc}
                                        options={message.ccOptions}
                                    />
                                </FormGroup>
                            </Collapse>
                            <Collapse isOpen={isBccOpen}>
                                <FormGroup>
                                    <Label>Bcc Email</Label>
                                    <CreatableSelect
                                        isMulti
                                        formatCreateLabel={(inputValue) => "Add \"" + inputValue + "\""}
                                        isValidNewOption={(inputValue, selectValue, selectOptions) => validateEmail(inputValue)}
                                        placeholder={"Type complete email address then press enter"}
                                        onChange={(value) => this.handleMessageChange("bcc", value)}
                                        value={message.bcc}
                                        options={message.bccOptions}
                                    />
                                </FormGroup>
                            </Collapse>

                            <FormGroup>
                                <Label>Subject</Label>
                                <Input
                                    type="text"
                                    name="subject"
                                    value={message.subject}
                                    onChange={(e) => this.handleMessageChange(e.target.name, e.target.value)}
                                    placeholder="Type subject here..." />
                            </FormGroup>
                            <FormGroup>
                                <Label>{this.props.parentType === "Share" ?"Content": "Body"}</Label>
                                <Input
                                    type="textarea"
                                    name="body"
                                    value={message.body}
                                    disabled={this.props.parentType === "Quotes"}
                                    onChange={(e) => this.handleMessageChange(e.target.name, e.target.value)}
                                    placeholder="Type body here..." />
                            </FormGroup>
                            {(this.props.parentType === "Transactions" || this.props.parentType === "Quotes") ? null :
                                <FormGroup>
                                    <Label>Attachments</Label>
                                    <div className="border rounded px-2 py-1">
                                        <div className="mx-1 my-1">
                                            <UploadFiles
                                                isReadOnly={false}
                                                handleChange={(change) => this.handleMessageChange("attachmentIDs", change)}
                                                attachmentIDs={message.attachmentIDs} />
                                        </div>
                                    </div>
                                </FormGroup>
                            }
                            {(this.props.parentType === "Quotes") ?
                                <FormGroup>
                                    <FormText>
                                        <span className="text-info">
                                            Note : Quote is already attached in this email.
                                </span>
                                    </FormText>
                                </FormGroup>
                                : null
                            }
                        </Form>
}

                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color='primary'
                                disabled={isSendingEmail}
                                onClick={this.handleSubmit}>
                            {isSendingEmail
                                ? <Spinner size="sm" className="mr-2" color={"light"}/>
                                : <i className="fa fa-paper-plane-o mr-2"
                                     aria-hidden="true"/>
                            }
                            {isSendingEmail
                                ? "Sending"
                                : "Send"
                            }
                        </Button>
                        {' '}
                        <Button color={"secondary"} onClick={() => toggle(!isOpen)}>
                            Cancel
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        )
    }
}