import React, { Component } from 'react';
import { Button, Card, CardHeader, Col, Collapse, Row } from 'reactstrap';
import OrderEnquiryProductionModeOrderItemsProductLabelWrapper from "./OrderEnquiryProductionModeOrderItemsProductLabelWrapper";
//import OrderEnquiryProductionModeOrderWarehousePickItems from "./WarehousePick/OrderEnquiryProductionModeOrderWarehousePickItems";
import { cloneDeep, isEmpty } from 'lodash';

export default class OrderEnquiryProductionModeOrderItemsProduct extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            order: this.props.order
        };
        this.toggleProductCollapses = this.toggleProductCollapses.bind(this);
    }

    componentDidMount() {
        this.handleItemsQty();
    }

    handleItemsQty() {
        let { order } = this.state;
        (order.products || []).map((product, productIndex) => {
            let itemQty = 0;
            for (let key in product.items) {
                itemQty += product.items[key].qty;
            }
            product.itemQty = itemQty;
        });
        this.setState({ order });
    }

    toggleProductCollapses(productIndex, change) {
        let { order } = this.state;
        order.products[productIndex].isOpen = change;
        this.setState({ order });
    }

    render() {
        let { toggleProductCollapse, toggleProductItemCollapse, isPrinting, bomModal } = this.props;
        let { order } = this.state;
        let warehousePicksItems = order.items.filter(function (e) {
            return e.identifier === "W/H Pick";
        });
        return (
            <div>
                {(order.products || []).map((product, productIndex) => {
                    return <Card key={productIndex} className={"mt-1 mb-2"}>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <Button
                                        size={"sm"}
                                        color={"link"}
                                        onClick={() => this.toggleProductCollapses(productIndex, !product.isOpen)}
                                    >{product.name}
                                    </Button>
                                </Col>
                                <Col>
                                    <div className="text-right">
                                        <span className="lead mr-2">
                                            {product.itemQty}
                                        </span>
                                        {product.items.length > 1 ? "Items" : "Item"}
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Collapse isOpen={product.isOpen || isPrinting}>
                            <OrderEnquiryProductionModeOrderItemsProductLabelWrapper
                                order={order}
                                productIndex={productIndex}
                                toggleProductItemCollapse={toggleProductItemCollapse}
                                bom={bomModal}
                            />
                        </Collapse>
                    </Card>
                })}
                {/*warehouse pickk item card here */}
                <Card key={22} className={"mt-1 mb-2"}>
                    <CardHeader>
                        <Row>
                            <Col>
                                <Button size={"sm"} color={"link"}>
                                    {"W/H Picks"}
                                </Button>

                            </Col>
                            <Col>
                                <div className="text-right">
                                    <span className="lead mr-2">
                                        {warehousePicksItems.length}
                                    </span>
                                    {warehousePicksItems.length > 1 ? "Items" : "Item"}
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <Collapse isOpen={true}>
                        {/*<OrderEnquiryProductionModeOrderWarehousePickItems*/}
                        {/*    items={warehousePicksItems}*/}
                        {/*/>*/}
                    </Collapse>
                </Card>
            </div>
        );
    }
};