import React, {Component} from "react";
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,
    Row, Col, Label, FormGroup, Input, Table
} from 'reactstrap';
import ReactQuill from 'react-quill';

export default class ApiLogDetailModal extends Component {
    render() {
        let { log, isOpen, toggle } = this.props;
        
        log.message = log.message ? log.message : "";
        let messageHeight = (100 + ( (log.message.match(/<li>/g) || []).length * 25) ) + "px";
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Log
                </ModalHeader>
                <ModalBody>
                    <Table>
                        <tbody>
                        <tr>
                            <th>Case ID</th>
                            <td>{log.id}</td>
                        </tr>
                        <tr>
                            <th>Order No</th>
                            <td>{log.ordNum}</td>
                        </tr>
                        <tr>
                            <th>Create Date</th>
                            <td>{log.createDate}</td>
                        </tr>
                        <tr>
                            <th>Logging Process Name</th>
                            <td>{log.loggerName.replace(/_/g, " ")}</td>
                        </tr>
                        <tr>
                            <th>Logging Process Group Name</th>
                            <td>{log.loggerGroupName.replace(/_/g, " ")}</td>
                        </tr>
                        <tr>
                            <th>Created by Called API</th>
                            <td>{log.url}</td>
                        </tr>
                        <tr>
                            <th>User</th>
                            <td>{log.fullname}</td>
                        </tr>

                        <tr>
                            <th>Log Level</th>
                            <td>{log.logLevel}</td>
                        </tr>
                        <tr>
                            <th>Log Status</th>
                            <td>{log.status}</td>
                        </tr>
                        <tr>
                            <th>Comment</th>
                            <td>{log.comment}</td>
                        </tr>
                        <tr>
                            <th>Message</th>
                            <td>
                                <ReactQuill
                                    style={{minHeight: messageHeight}}
                                    readOnly={true}
                                    className="form-control"
                                    theme={"bubble"}
                                    rows="10"
                                    plaintext
                                    value={log.message}/>
                            </td>
                        </tr>
                        <tr>
                            <th>Payload</th>
                            <td>
                                <Input
                                    type="textarea"
                                    className="mr-4"
                                    name="payload"
                                    rows="10"
                                    value={log.payload}/>
                            </td>
                        </tr>
                        {
                            log.exception
                                ? <tr>
                                    <th>Exception</th>
                                    <td>
                                        <Input
                                            type="textarea"
                                            className="mr-4"
                                            name="exception"
                                            rows="10"
                                            value={log.exception}/>
                                    </td>
                                </tr>
                                : null
                        }

                        {
                            log.stackTrace
                                ?
                                <tr>
                                    <th>Stack Trace</th>
                                    <td>
                                        <Input
                                            type="textarea"
                                            className="mr-4"
                                            name="stackTrace"
                                            rows="10"
                                            value={log.stackTrace}/>
                                    </td>
                                </tr>
                                : null
                        }


                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"secondary"} size='sm' onClick={() => toggle(!isOpen)}><i
                            className="fa fa-times"/>&nbsp;
                            Close</Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}