import React, { Component } from 'react';
import UserService from '../services/UserService';
import Dashboard from '../components/dashboard/Dashboard';
import CustomerDashboardQuickLinks from '../components/CustomerDashboard/CustomerDashboardQuickLinks';
import { Redirect } from 'react-router-dom';

export default class HomePage extends Component {
    static displayName = HomePage.name;
    constructor(props) {
        super(props);
        this.userService = new UserService();
        this.state = {
            user: {}
        }
    }

    componentDidMount() {
        let user = this.userService.getLoggedInUser();
        this.setState({ user });
    }

    render() {
        let { user } = this.state;
        return (
            <div>

                {user && user.menu && user.menu.role === 'ExternalUser' ?
                    <><CustomerDashboardQuickLinks size={"sm"} /><Redirect to="/customer/order" /></> : null}
                <Dashboard {...this.props} />
            </div>
        );
    }
}
