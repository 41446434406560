import React, {Component} from 'react';
import {Badge, Col, ListGroup, ListGroupItem, Row, Button} from 'reactstrap';
import {Redirect} from "react-router-dom";
import {changeFormatOfDateString} from '../../services/CommonService';
import {isEmpty} from 'lodash';
import OrderEnquiryOrderItem from "./OrderEnquiryOrderItem";
import OrderEnquiryCustomerDetail from "./OrderEnquiryCustomerDetail";
import OrderEnquiryDocumentDownload from "./OrderEnquiryDocumentDownload";
import ReactToPrint from "react-to-print";
import OrderEnquiryPrintMode from "./OrderEnquiryPrintMode";
import UploadFiles from "../../components/attachment/UploadFiles";

let visibleColumnsInOrderItems = ["expandIcon", "stockItemCd", "stockItemDescription", "productName", "location", "fabric",
    "width", "drop", "qty", "price", "qtyprice", "orderedExtendedEx"];
const documentTypes = [];

export default class OrderEnquiryReadOnlyMode extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {
            order,
            toggleExpandRow, toggleAllRowExpanded,
            salesOrder,
            currentUser,
            viewMode
        } = this.props;

        if (order.salesOrderJobStatusId >= 95)  //  if order is invoiced then we can show the qty supplied column..
        {
            let visibleColumns = visibleColumnsInOrderItems;
            let index = visibleColumns.indexOf("qty");
            visibleColumns.splice(index + 1, 0, "qtySupplied");
            visibleColumnsInOrderItems = visibleColumns;
        }
        return (
            <div>
                <div>
                    <div>
                        <Row>
                            <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                <h4>#{order.ordNum}
                                    <small>
                                        <Badge color="warning"
                                               className={"ml-2"}>
                                            {order.salesOrderJobStatusDescription}
                                        </Badge>
                                    </small>
                                </h4>
                                <div>
                                    <span className="text-muted">Entered by- </span>
                                    {order.enteredByUser ? order.enteredByUser.firstName : order.enteredBy}
                                    <span className="text-muted">{' | '}</span>
                                    {changeFormatOfDateString(order.ordDate, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}
                                    {(order.highPriority) ?
                                        <Badge color="danger" className={"m-1 p-1"} pill>MUSTGO</Badge>
                                        : null
                                    }
                                    {(order.excludeFuelSurcharge) ?
                                        <Badge color="info" className={"m-1 p-1"} pill>No Fuel
                                            Surcharge</Badge>
                                        : null
                                    }
                                    {(order.isInstallation) ?
                                        <Badge color="info" className={"m-1 p-1"} pill>>Installation</Badge>
                                        : null
                                    }
                                    {(order.isRemake) ?
                                        <Badge color="info" className={"m-1 p-1"}>Remake</Badge> : null
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12} className={"text-right"}>
                                <OrderEnquiryDocumentDownload
                                    currentUser={currentUser}
                                    orderCreatedFrom={salesOrder ? salesOrder.createdFrom : ""}
                                    documentTypes={documentTypes}
                                    ordNum={order.ordNum}
                                    isExternalUser={currentUser.isExternalUser}
                                />
                                {!currentUser.isExternalUser ? <div className="mt-2">
                                    <ReactToPrint
                                        trigger={() => {
                                            return <Button color={"primary"} size={"sm"}>
                                                <i className="fa fa-print mr-2"
                                                   aria-hidden="true"/>Print
                                            </Button>;
                                        }}
                                        content={() => this.componentRef}
                                    />
                                </div> : null}
                            </Col>

                        </Row>
                    </div>
                    <hr/>
                    <div>
                        <div>
                            <OrderEnquiryCustomerDetail order={order} viewMode={viewMode}
                                                        salesOrder={salesOrder} currentUser={currentUser}/>
                        </div>
                        <hr/>
                        <div>
                            <ListGroup className={"list-group-horizontal-sm"}>
                                {
                                    (order.custOrdNum || order.custOrdDate)
                                        ? <ListGroupItem>
                                            <div style={{minWidth: 150, maxWidth: 250}}>
                                                {
                                                    order.custOrdNum ?
                                                        <div>
                                                            <p className={"mb-1"}>
                                                                <strong>Customer Order No.</strong>
                                                            </p>
                                                            <div>{order.custOrdNum}</div>
                                                        </div> : null
                                                }
                                                {
                                                    order.custOrdDate ?
                                                        <div className="mt-2">
                                                            <p className={"mb-1"}>
                                                                <strong>Customer Order Date</strong>
                                                            </p>
                                                            <div>
                                                                {changeFormatOfDateString(order.custOrdDate, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}
                                                            </div>
                                                        </div> : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                <ListGroupItem>
                                    <div style={{minWidth: 150, maxWidth: 250}}>
                                        <div>
                                            <p className={"mb-1"}><strong>Required on</strong></p>
                                            <div>
                                                {changeFormatOfDateString(order.required, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}

                                            </div>
                                        </div>
                                        {
                                            order.jobRef ?
                                                <div className="mt-2">
                                                    <p className={"mb-1"}>
                                                        <strong>Job Reference</strong>
                                                    </p>
                                                    <div>{order.jobRef}</div>
                                                </div> : null
                                        }
                                        {order.repArea ?
                                            <div className="mt-2">
                                                <p className={"mb-1"}><strong>Sales
                                                    Rep</strong>
                                                </p>
                                                <div>{order.repArea.name} ({order.repArea.code})</div>
                                            </div> : null
                                        }
                                    </div>
                                </ListGroupItem>
                                {
                                    (order.couriers || order.consignNum || order.courierInstructions || order.shipDate)
                                        ? <ListGroupItem>
                                            <div style={{minWidth: 150, maxWidth: 250}}>
                                                {order.couriers ?
                                                    <div>
                                                        <p className={"mb-1"}><strong>Shipment
                                                            by</strong></p>
                                                        <div>{order.couriers}</div>
                                                    </div> : null
                                                }
                                                {order.consignNum ?
                                                    <div className="mt-2">
                                                        <p className={"mb-1"}><strong>Consignment
                                                            no</strong>
                                                        </p>
                                                        <div>{order.consignNum}</div>
                                                    </div> : null
                                                }
                                                {order.courierInstructions ?
                                                    <div className="mt-2">
                                                        <p className={"mb-1"}><strong>Courier Instructions</strong></p>
                                                        <div>{order.courierInstructions}</div>
                                                    </div> : null
                                                }
                                                {
                                                    order.shipDate
                                                        ? <div className="mt-2">
                                                            <p className={"mb-1"}><strong>Shipped date</strong></p>
                                                            <div>{changeFormatOfDateString(order.shipDate, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}</div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }

                                <ListGroupItem>
                                    <div style={{minWidth: 150, maxWidth: 250, overflowY: 'auto'}}>
                                        <p className={"mb-1"}><strong>Notes</strong></p>
                                        {order.notes}
                                    </div>

                                </ListGroupItem>
                                {
                                    (salesOrder && !isEmpty(salesOrder.attachmentIDs))
                                        ? <ListGroupItem>
                                            <div style={{minWidth: 150, maxWidth: 250}}>
                                                <UploadFiles
                                                    isReadOnly={true}
                                                    attachmentIDs={salesOrder ? salesOrder.attachmentIDs : ""}/>
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }


                            </ListGroup>
                        </div>
                        <hr/>
                        <div>
                            <OrderEnquiryOrderItem
                                toggleExpandRow={toggleExpandRow}
                                toggleAllRowExpanded={toggleAllRowExpanded}
                                order={order}
                                currentUser={currentUser}
                                visibleColumnsInOrderItems={visibleColumnsInOrderItems}
                                salesOrder={salesOrder}
                            />
                        </div>
                    </div>
                </div>
                <div style={{display: "none"}}>
                    <OrderEnquiryPrintMode order={order}
                                           visibleColumnsInOrderItems={visibleColumnsInOrderItems}
                                           currentUser={currentUser}
                                           toggleExpandRow={toggleExpandRow} toggleAllRowExpanded={toggleAllRowExpanded}
                                           ref={el => (this.componentRef = el)}
                    />
                </div>
            </div>
        );

    }
}
