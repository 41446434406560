import React, {Component} from 'react';
import {Badge, Button, Col, ListGroup, ListGroupItem, Row, Spinner} from 'reactstrap';
import { Redirect} from "react-router-dom";
import {
    changeFormatOfDateString,
    handleErrorMessage
} from '../../services/CommonService';
import OrderService from '../../services/OrderService';
import orderEnquiryUtil from '../../Util/OrderEnquiryUtil';
import {isEmpty} from 'lodash';
import OrderEnquiryDocumentDownload from "./OrderEnquiryDocumentDownload";
import ReactToPrint from "react-to-print";
//import OrderEnquiryProductionModePrint from "./OrderEnquiryProductionModePrint";
import OrderEnquiryProductionModeOrderItems from "./OrderEnquiryProductionModeOrderItems";
//import SalesOrderNotes from "../../components/sales/orders/SalesOrderNotes";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
import OrderEnquiryProductionModePrint from "./OrderEnquiryProductionModePrint";
import OrderEnquiryCustomerDetail from "./OrderEnquiryCustomerDetail";
import SalesOrderNotes from "../../components/sales/SalesOrderNotes";

const documentTypes = ["Job Sheet", "Production Label", "Invoice", "Quote", "Dispatch Label", "Pick Slip", "Packing Slip", "Sales Order Summary"];


export default class OrderEnquiryProductionMode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: "partsByProductItem",
            isLoadingPartList: false
        };
        this.orderService = new OrderService();

        this.toggleTab = this.toggleTab.bind(this);
        this.toggleProductCollapse = this.toggleProductCollapse.bind(this);
        this.toggleProductItemCollapse = this.toggleProductItemCollapse.bind(this);
        this.getPartList = this.getPartList.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.order) && !isEmpty(this.props.order.items)) {
            this.getPartList(this.props.order.ordNum);
        }
    }

    toggleTab(tab) {
        this.setState({activeTab: tab});
    }

    toggleProductCollapse(productIndex, change) {
        this.props.updateOrder("toggleProductCollapse", {productIndex, isOpen: change});
    }

    toggleProductItemCollapse(productIndex, itemIndex, change) {
        this.props.updateOrder("toggleProductItemCollapse", {productIndex, itemIndex, isOpen: change});
    }


    getPartList(ordNum) {
        this.setState({isLoadingPartList: true});
        this.orderService.getOrderPartList(ordNum).then(response => {
            let {order} = this.props;
            order.parts = response.data.parts;
            order.partsConsolidated = orderEnquiryUtil.initOrderItemConsolidatedParts(order);
            order.products = orderEnquiryUtil.initOrderItemProductParts(order);

            this.props.updateOrder("order_parts", {
                parts: order.parts,
                partsConsolidated: order.partsConsolidated,
                products: order.products
            });
            this.setState({isLoadingPartList: false});
        }).catch(error => {
            console.error(error);
            this.setState({isLoadingPartList: false});
            toast.error(handleErrorMessage(error));
        });
    }

    render() {
        let {
            order, currentUser,salesOrder,viewMode
        } = this.props;


        let {activeTab, isLoadingPartList} = this.state;
        return (
            <div>
                <div>
                    <div>
                        <Row>
                            <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                <h4>#{order.ordNum}
                                    <small>
                                        <Badge color="warning"
                                               className={"ml-2"}>
                                            {order.salesOrderJobStatusDescription}
                                        </Badge>
                                    </small>
                                </h4>
                                <div>
                                    <span className="text-muted">Entered by- </span>
                                    {order.enteredByUser ? order.enteredByUser.firstName : order.enteredBy}
                                    <span className="text-muted">{' | '}</span>
                                    {changeFormatOfDateString(order.ordDate, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}
                                    {(order.highPriority) ?
                                        <Badge color="danger" className={"m-1 p-1"} pill>MUSTGO</Badge>
                                        : null
                                    }
                                    {(order.excludeFuelSurcharge) ?
                                        <Badge color="info" className={"m-1 p-1"} pill>No Fuel
                                            Surcharge</Badge>
                                        : null
                                    }
                                    {(order.isInstallation) ?
                                        <Badge color="info" className={"m-1 p-1"} pill>>Installation</Badge>
                                        : null
                                    }
                                    {(order.isRemake) ?
                                        <Badge color="info" className={"m-1 p-1"}>Remake</Badge> : null
                                    }
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={12} xs={12} className={"text-right"}>
                                <OrderEnquiryDocumentDownload currentUser={currentUser}
                                    orderCreatedFrom={order.createdFrom}
                                    documentTypes={documentTypes}
                                    ordNum={order.ordNum}/>
                                <div className="mt-2">
                                    <ReactToPrint
                                        trigger={() => {
                                            return <Button color={"primary"} size={"sm"}>
                                                <i className="fa fa-print mr-2"
                                                   aria-hidden="true"/>Print
                                            </Button>;
                                        }}
                                        content={() => this.componentRef}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <hr/>
                    <div>
                        {/*<div>*/}
                        {/*    <ListGroup className={"list-group-horizontal-sm"}>*/}
                        {/*        <ListGroupItem>*/}
                        {/*            <div style={{minWidth: 150}}>*/}
                        {/*                <p className={"pb-1 mb-1"}><strong>Customer</strong></p>*/}
                        {/*                <div>*/}
                        {/*                    <Link*/}
                        {/*                        className="btn btn-primary btn-sm"*/}
                        {/*                        style={{color: "white"}}*/}
                        {/*                        title={"Click here to see account details"}*/}
                        {/*                        to={"/customer/account/detail?" + queryString.stringify({accountID: order.accountID})}*/}
                        {/*                    >{order.accountID}</Link>*/}
                        {/*                </div>*/}
                        {/*                <div><h6>{order.delCompany}</h6></div>*/}
                        {/*            </div>*/}
                        {/*        </ListGroupItem>*/}
                        {/*        <ListGroupItem>*/}
                        {/*            <div style={{minWidth: 150, maxWidth: 250}}>*/}
                        {/*                <div>*/}
                        {/*                    <p className={"pb-1 mb-1"}><strong>Required on</strong></p>*/}
                        {/*                    <div>*/}
                        {/*                        {changeFormatOfDateString(order.required, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </ListGroupItem>*/}
                        {/*        <ListGroupItem>*/}
                        {/*            <div style={{minWidth: 150, maxWidth: 250, overflowY: 'auto'}}>*/}
                        {/*                <p className={"pb-1 mb-1"}><strong>Notes</strong></p>*/}
                        {/*                {order.notes}*/}
                        {/*            </div>*/}
                        {/*        </ListGroupItem>*/}
                        {/*        */}{/*<ListGroupItem>*/}
                        {/*        */}{/*    <div style={{ minWidth: 150, maxWidth: 250 }}>*/}
                        {/*        */}{/*        */}{/*<SalesOrderNotes ordNum={order.ordNum}*/}
                        {/*        */}{/*        */}{/*    isReadOnly={true} />*/}
                        {/*        */}{/*    </div>*/}
                        {/*        */}{/*</ListGroupItem>*/}
                        {/*    </ListGroup>*/}
                        {/*</div>*/}
                        <div>
                            <OrderEnquiryCustomerDetail order={order} salesOrder={salesOrder}
                                currentUser={currentUser} viewMode={viewMode} />
                        </div>
                        <hr />
                        <div>
                            <ListGroup className={"list-group-horizontal-sm"}>
                                {
                                    (order.custOrdNum || order.custOrdDate)
                                        ? <ListGroupItem>
                                            <div style={{ minWidth: 150, maxWidth: 250 }}>
                                                {
                                                    order.custOrdNum ?
                                                        <div>
                                                            <p className={"mb-1"}>
                                                                <strong>Customer Order No.</strong>
                                                            </p>
                                                            <div>{order.custOrdNum}</div>
                                                        </div> : null
                                                }
                                                {
                                                    order.custOrdDate ?
                                                        <div className="mt-2">
                                                            <p className={"mb-1"}>
                                                                <strong>Customer Order Date</strong>
                                                            </p>
                                                            <div>
                                                                {changeFormatOfDateString(order.custOrdDate, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}
                                                            </div>
                                                        </div> : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                <ListGroupItem>
                                    <div style={{ minWidth: 150, maxWidth: 250 }}>
                                        <div>
                                            <p className={"mb-1"}><strong>Required on</strong>
                                                <Button
                                                    className={"pl-0 float-right"}
                                                    color={"link"}
                                                    size={"sm"}
                                                    title={"click here to edit"}
                                                >
                                                </Button></p>
                                            <div>
                                                {changeFormatOfDateString(order.required, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}

                                            </div>
                                        </div>
                                        {
                                            order.jobRef ?
                                                <div className="mt-2">
                                                    <p className={"mb-1"}>
                                                        <strong>Job Reference</strong>
                                                    </p>
                                                    <div>{order.jobRef}</div>
                                                </div> : null
                                        }
                                        {order.repArea ?
                                            <div className="mt-2">
                                                <p className={"mb-1"}><strong>Sales
                                                    Rep</strong>
                                                </p>
                                                <div>{order.repArea.name} ({order.repArea.code})</div>
                                            </div> : null
                                        }
                                    </div>
                                </ListGroupItem>
                                {
                                    (order.couriers || order.consignNum || order.courierInstructions)
                                        ? <ListGroupItem>
                                            <div style={{ minWidth: 150, maxWidth: 250 }}>
                                                {order.couriers ?
                                                    <div>
                                                        <p className={"mb-1"}><strong>Shipment
                                                            by</strong></p>
                                                        <div>{order.couriers}</div>
                                                    </div> : null
                                                }
                                                {order.consignNum ?
                                                    <div className="mt-2">
                                                        <p className={"mb-1"}><strong>Consignment
                                                            no</strong>
                                                        </p>
                                                        <div>{order.consignNum}</div>
                                                    </div> : null
                                                }
                                                {order.courierInstructions ?
                                                    <div className="mt-2">
                                                        <p className={"mb-1"}><strong>Courier Instructions</strong></p>
                                                        <div>{order.courierInstructions}</div>
                                                    </div> : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                {
                                    (order.invPrinted || order.shipDate)
                                        ? <ListGroupItem>
                                            <div style={{ minWidth: 150, maxWidth: 250 }}>
                                                {
                                                    order.invPrinted
                                                        ? <div className="mt-2">
                                                            <p className={"mb-1"}><strong>Invoiced date</strong></p>
                                                            <div>{changeFormatOfDateString(order.invPrinted, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}</div>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    order.shipDate
                                                        ? <div className="mt-2">
                                                            <p className={"mb-1"}><strong>Shipped date</strong></p>
                                                            <div>{changeFormatOfDateString(order.shipDate, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}</div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                <ListGroupItem>
                                    <div style={{ minWidth: 150, maxWidth: 250, overflowY: 'auto' }}>
                                        <p className={"mb-1"}><strong>Notes</strong><Button
                                            color="link"
                                            className={"pl-0 float-right"}
                                            size={"sm"}
                                            title={"click here to edit"}
                                        >
                                        </Button></p>
                                        {order.notes}
                                    </div>
                                </ListGroupItem>
                                {
                                    (salesOrder && !isEmpty(salesOrder.attachmentIDs))
                                        ? <ListGroupItem>
                                            <div style={{ minWidth: 150, maxWidth: 250 }}>
                                                <UploadFiles
                                                    isReadOnly={true}
                                                    attachmentIDs={salesOrder ? salesOrder.attachmentIDs : ""} />
                                            </div>
                                        </ListGroupItem>
                                        : null
                                }
                                <ListGroupItem>
                                    <div style={{ minWidth: 200, maxWidth: 250 }}>
                                        <SalesOrderNotes ordNum={order.ordNum}
                                            isReadOnly={false} />
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </div>
                        <hr />
                        <div className="mt-3">
                            {
                                (isLoadingPartList)
                                    ? <Spinner color={"primary"}/>
                                    : <div>
                                        <OrderEnquiryProductionModeOrderItems
                                            toggleProductCollapse={this.toggleProductCollapse}
                                            toggleProductItemCollapse={this.toggleProductItemCollapse}
                                            prepareOrderModel={this.toggleProductItemCollapse}
                                            activeTab={activeTab}
                                            toggleTab={this.toggleTab}
                                            isPrinting={false}
                                            order={order}
                                        />

                                        <div style={{display: "none"}}>
                                            <OrderEnquiryProductionModePrint
                                                order={order}
                                                activeTab={activeTab}
                                                toggleTab={this.toggleTab}
                                                toggleProductCollapse={this.toggleProductCollapse}
                                                toggleProductItemCollapse={this.toggleProductItemCollapse}
                                                prepareOrderModel={this.toggleProductItemCollapse}
                                                ref={el => (this.componentRef = el)}
                                            />
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </div>
        );

    }
}
