import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import UserService from "../../services/UserService";
import { EventBus } from "../../components/events/event";
import { Alert, Card, CardBody, Col, Container, Input, Label, Row, Spinner, UncontrolledAlert } from "reactstrap";


export default class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            loginError: '',
            resetError: '',
            stage: 'LOGIN'
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.userService = new UserService()
    }

    componentDidMount() {
        EventBus.publish('login-logout', {})
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.stage === 'LOGIN') return this.handleSubmitLogin();
        if (this.state.stage === 'RESET') return this.handleSubmitReset();
    }

    handleSubmitReset() {
        this.setState({ submitted: true });
        const { username } = this.state;

        if (!(username)) {
            return
        }

        this.setState({ loading: true });
        this.userService.resetPassword(username).then(response => {
            this.setState({ loading: false });
            alert("Email having link for resetting password has been sent to you, please check!");
            this.props.history.push("/forgot");
        }, reason => {
            this.setState({ loading: false });
            alert(reason.Message); //Error
        });

    }

    handleSubmitLogin() {

        this.setState({ submitted: true });
        const { username, password } = this.state;

        if (!(username && password)) {
            return
        }

        this.setState({ loading: true });
        this.userService.login(username, password).then(
            user => {
                const { from } = this.props.location.state || { from: { pathname: "/" } };
                this.props.history.push(from);
                EventBus.publish('login-logout', {})
                //window.location.reload();
            },
            error => {
                this.setState({
                    loginError: "Sorry! We cannot find an account with provided credentials.",
                    loading: false
                })
            }
        )
    }

    render() {
        if (UserService.isLoggedIn())
            return <Redirect to={{ pathname: '/' }} />;
        if (this.state.stage === 'LOGIN') return this.renderLogin();
        if (this.state.stage === 'RESET') return this.renderReset();
    }

    renderReset() {
        const { username, password, submitted, loading, ResetError } = this.state;

        return (
            <div style={{ marginTop: "50px" }}>
                <Row>
                    <Col xl={4} lg={4} md={3} sm={0} xs={0} />
                    <Col xl={5} lg={5} md={6} sm={12} xs={12}>

                        <div className="text-center o-visible">
                            <Row style={{ "justify-content": "space-between" }}>
                                <Col xl={2} lg={2} md={2} sm={12} xs={12} className={"text-left ml-2"}>
                                    <img
                                        style={{ maxWidth: 200 }}
                                        alt=""
                                        src="https://winonline.blob.core.windows.net/winonline/company_logo/Windoware.png" />
                                </Col>
                                <Col xl={4} lg={4} md={4} sm={12} xs={12} className="text-center">
                                    <img
                                        style={{ maxWidth: 200 }}
                                        alt=""
                                        src="https://winonline.blob.core.windows.net/winonline/company_logo/SMARTHP.png" />
                                </Col>
                                <Col xl={4} lg={4} md={4} sm={12} xs={12} className="text-right" style={{ "padding-left": "65px" }}>
                                    <img
                                        style={{ maxWidth: 180 }}
                                        alt=""
                                        src="https://winonline.blob.core.windows.net/winonline/company_logo/Bistro.png" />
                                </Col>
                            </Row>
                        </div>
                        <hr />
                    </Col>
                </Row>
                        <Row>
                            <Col xl={4} lg={4} md={3} sm={0} xs={0} />
                            <Col xl={5} lg={5} md={6} sm={12} xs={12}>
                        <Card style={{ borderRadius: ".50rem" }} className={"ds-9"}>
                            <CardBody>
                                <Container>
                                    <h5>Forgot your password?</h5>
                                    <form name="form" className="theme-form m-t-15" onSubmit={this.handleSubmit}
                                        autoComplete="off">
                                        <div
                                            className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                            <label htmlFor="username">Email</label>
                                            <input type="text" className="form-control" name="username"
                                                value={username}
                                                onChange={this.handleChange} autoComplete="off" />
                                            {submitted && !username &&
                                                <div className="help-block">
                                                    <p className="text-danger  mb-0">
                                                        Email is required!</p>
                                                </div>
                                            }
                                        </div>
                                        <div className="form-group mt-3">
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={12} xs={12} className="mt-1 text-left">
                                                    <Link to="/login" onClick={() => this.setState({
                                                        stage: 'LOGIN',
                                                        loginError: ''
                                                    })}>Back to Login?</Link>
                                                </Col>
                                                <Col xl={7} lg={7} md={7} sm={12} xs={12} className="text-right">
                                                    <button className="btn btn-primary btn-block" type="submit"
                                                        disabled={loading}>
                                                        Reset
                                                        Password{loading &&
                                                            <img
                                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                        }
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </form>
                                </Container>

                            </CardBody>
                        </Card>
                        <Alert color={"info"} className={"mt-3"}>
                            Please confirm your identity above.
                            <hr />
                            If provided info is associated with an account,
                            you will get an email with password reset link.
                        </Alert>
                    </Col>
                </Row>
            </div>
        )
    }

    renderLogin() {
        const { username, password, submitted, loading, loginError } = this.state;

        return (
            <div style={{ marginTop: "30px" }}>

                <Row>
                    <Col xl={4} lg={4} md={3} sm={0} xs={0} />
                    <Col xl={5} lg={5} md={6} sm={12} xs={12}>

                        <div className="text-center o-visible">
                            <Row style={{ "justify-content": "space-between" }}>
                                <Col xl={2} lg={2} md={2} sm={12} xs={12} className={"text-left ml-2"}>
                                    <img
                                        style={{ maxWidth: 200 }}
                                        alt=""
                                        src="https://winonline.blob.core.windows.net/winonline/company_logo/Windoware.png" />
                                       </Col>
                                <Col xl={4} lg={4} md={4} sm={12} xs={12} className="text-center">
                                    <img
                                        style={{ maxWidth: 200 }}
                                        alt=""
                                        src="https://winonline.blob.core.windows.net/winonline/company_logo/SMARTHP.png" />
                                </Col>
                                <Col xl={4} lg={4} md={4} sm={12} xs={12} className="text-right" style={{"padding-left": "65px"}}>
                                    <img
                                        style={{ maxWidth: 180 }}
                                        alt=""
                                        src="https://winonline.blob.core.windows.net/winonline/company_logo/Bistro.png" />
                                </Col>
                            </Row>
                        </div>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col xl={4} lg={4} md={3} sm={0} xs={0} />
                    <Col xl={5} lg={5} md={6} sm={12} xs={12}>
                        <Card style={{ borderRadius: ".50rem" }} className={"ds-10"}>
                            <CardBody>
                                <Container>
                                    <h4 className={"mb-2"}>Sign In</h4>
                                    <form name="form" onSubmit={this.handleSubmit} autoComplete="off">
                                        <div>
                                            <Label htmlFor="username">Store Number or Email</Label>
                                            <Input type="text"
                                                placeholder={"Enter store number or email"}
                                                invalid={submitted && !username}
                                                className="form-control"
                                                name="username" value={username}
                                                onChange={this.handleChange} autoComplete="off" />
                                            {
                                                submitted && !username &&
                                                <p className="text-danger mb-0">
                                                    <small>Email is required!</small>
                                                </p>
                                            }
                                        </div>
                                        <div className={"mt-3"}>
                                            <Row>
                                                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                                    <Label htmlFor="password">Password</Label>
                                                </Col>
                                                <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                                    <div className={"text-right"}>
                                                        <Link to="#" onClick={() => this.setState({ stage: 'RESET' })}>Forgot
                                                            Password?</Link>
                                                    </div>

                                                </Col>
                                            </Row>

                                            <Input type="password"
                                                placeholder={"Enter password"}
                                                invalid={submitted && !password}
                                                className="form-control"
                                                name="password" value={password}
                                                onChange={this.handleChange} autoComplete="off" />
                                            {
                                                submitted && !password &&
                                                <p className="text-danger mb-0">
                                                    <small>Password is required!</small>
                                                </p>
                                            }
                                        </div>

                                        <div className="form-group mt-3">

                                            <div className={"mt-1"}>
                                                <button className="btn btn-primary btn-block"
                                                    disabled={loading}>
                                                    {loading
                                                        ? "Signing in..."
                                                        : "Sign in"
                                                    }
                                                    {
                                                        loading
                                                            ? <Spinner className={"ml-2"} size={"sm"} />
                                                            : <i className="fa fa-sign-in ml-2"
                                                                aria-hidden="true" />
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </Container>
                            </CardBody>
                        </Card>
                        {loginError &&
                            <UncontrolledAlert color={"danger"} className={"mt-3"}>{loginError}</UncontrolledAlert>
                        }

                    </Col>
                </Row>
            </div>
        );
    }
}



