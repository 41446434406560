import React, {Component} from 'react';
import {
    Badge, Button, Input, InputGroup, InputGroupAddon, InputGroupText, ListGroup, ListGroupItem, Table,
    UncontrolledTooltip, Row
} from 'reactstrap';

import {findIndex, getSorted, newID, scrollTo} from '../../../../../../services/CommonService';
import {widthExtenders, zipJoiners} from '../../../../../../store/AppConstants';
import {camelCase, cloneDeep, isEmpty} from 'lodash';
import classnames from 'classnames';

import bistroUtil from './BistroUtil';
import NumberFormat from "react-number-format";
import BistroBOMModal from "./BistroBOMModal";
import BistroConsolidatedBOMModal from "./BistroConsolidatedBOMModal";
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';
import Paragraph from "../../../../../tools/Paragraph";

const quantityOptions = [1, 2, 3, 4, 5];
const sizeOptions = [900, 1200, 1500, 1800, 2100, 2400, 2700, 3000];

export default class Bistro extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingBOMModal: {
                isOpen: false,
                itemIndex: -1
            },
            isOpenConsolidatedBOMModal: false,
            htmlIDs: {
                width: newID(),
                drop: newID(),
                accessories: newID(),
            },
            isHiddenExtendedColumn: true
        };

        this.handleItemAction = this.handleItemAction.bind(this);
        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updateItemConfigurationOptions = this.updateItemConfigurationOptions.bind(this);
        this.toggleBOMModal = this.toggleBOMModal.bind(this);
        this.toggleConsolidatedBOMModal = this.toggleConsolidatedBOMModal.bind(this);
        this.toggleExtendedColumn = this.toggleExtendedColumn.bind(this);
    }

    componentDidMount() {
        if (!isEmpty(this.props.product.savedItems)) {
            this.handleItemAction("init_savedItem", -1, null);
        }
    }

    toggleBOMModal(change, itemIndex) {
        let {workingBOMModal} = this.state;
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        if (change) {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = itemIndex;
        } else {
            workingBOMModal.isOpen = change;
            workingBOMModal.itemIndex = -1;
        }

        if (workingBOMModal.isOpen && workingBOMModal.itemIndex > -1) {
            product = bistroUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode, true);
            this.props.handleChange("product", product);
        }

        this.setState({workingBOMModal});
    }

    toggleConsolidatedBOMModal(change) {
        this.setState({isOpenConsolidatedBOMModal: change});
    }

    toggleExtendedColumn(isHiddenExtendedColumn) {
        this.setState({ isHiddenExtendedColumn });
    }

    handleItemAction(key, itemIndex, context) {
        let {product, products, order, packagingAndHandling, discountByProductCode} = this.props;
        let item;

        switch (key) {
            case "new":
                item = salesOrderProductBuilderV1Service.getItemFactoryInstance(0);
                product.items.push(item); //insert item
                itemIndex = product.items.length - 1; // get item index
                product = this.updateConfiguration(product, itemIndex, null, order); //update config for item
                product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                scrollTo("product-" + product.productGroupID + "-item-table-responsive-wrapper", "left");
                break;
            case "clone":
                product.items.push(cloneDeep(product.items[itemIndex]));
                product.items.forEach((item, itemIndex) => {
                    product = bistroUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "delete":
                product.items.splice(itemIndex, 1);
                product.items.forEach((item, itemIndex) => {
                    product = bistroUtil.updateFormError("location", product, itemIndex);
                });
                break;
            case "init_savedItem":
                product.savedItems.forEach(si => {
                    item = salesOrderProductBuilderV1Service.getItemFactoryInstance(si.id);
                    product.items.push(item); //insert item
                    itemIndex = product.items.length - 1; // get item index
                    product = this.updateConfiguration(product, itemIndex, si, order); //update config for item
                    product.items[itemIndex] = cloneDeep(product.items[itemIndex]); //update item at index with deep clone

                    product = bistroUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode, true);
                });
                product.savedItems = [];
                break;
        }
        if (product.items[itemIndex]) {
            product = bistroUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode, false);
        }
        product.init = true;
        this.props.handleChange("product", product);
    }

    updateConfiguration(product, itemIndex, context, order) {

        let {configurations, fabrics} = product.builder;

        let optionIndex, configurationIndex;
        product.items[itemIndex].configuration = {};
        product.items[itemIndex].isValid = true;

        //quantity
        configurationIndex = findIndex(configurations, "attributeKey", "quantity");
        product.items[itemIndex].configuration.quantity = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.quantity.min = 1;
        product.items[itemIndex].configuration.quantity.max = 999;
        product.items[itemIndex].configuration.quantity.selected = {};
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.quantity.selected.value = context.salesOrderItemBistro.quantity;
        } else {
            product.items[itemIndex].configuration.quantity.selected.value = 1;
        }
        product.items[itemIndex].configuration.quantity.formError = salesOrderProductBuilderV1Service.getFormErrorInstance();

        //width
        configurationIndex = findIndex(configurations, "attributeKey", "width");
        product.items[itemIndex].configuration.width = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.width.selected = {
            value: 0,
            finishWidth: 0,
            isSelectable: true,
            id: newID(),
        };

        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.width.selected.value = parseInt(context.salesOrderItemBistro.width);
        } else {
            product.items[itemIndex].configuration.width.selected.value = 0;
        }
        product.items[itemIndex].configuration.width.formError = salesOrderProductBuilderV1Service.getFormErrorInstance();


        //drop
        configurationIndex = findIndex(configurations, "attributeKey", "drop");
        product.items[itemIndex].configuration.drop = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.drop.selected = {
            value: 0,
            finishDrop: 0,
            isSelectable: true,
            id: newID(),
        };
        if (!isEmpty(context)) {
            product.items[itemIndex].configuration.drop.selected.value = parseInt(context.salesOrderItemBistro.drop);
        } else {
            product.items[itemIndex].configuration.drop.selected.value = 2400;
        }
        product.items[itemIndex].configuration.drop.formError = salesOrderProductBuilderV1Service.getFormErrorInstance();

        product.items[itemIndex].configuration.set = {
            selected: {
                value: {
                    widthSet: {},
                    dropSet: {},
                    priceMatrix: {}
                },
                stocks: []
            }
        };

        //model
        configurationIndex = findIndex(configurations, "attributeKey", "model");
        product.items[itemIndex].configuration.model = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.model.selected = {};
        product.items[itemIndex].configuration.model.options = getSorted(product.items[itemIndex].configuration.model.options, "primaryAttributeMin", true);
        product.items[itemIndex].configuration.model.finalOptions = product.items[itemIndex].configuration.model.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, 'optionKey', context.salesOrderItemBistro.model);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
        product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
        product.items[itemIndex].configuration.model.formError = salesOrderProductBuilderV1Service.getFormErrorInstance();


        //cutOn
        configurationIndex = findIndex(configurations, "attributeKey", "cutOn");
        product.items[itemIndex].configuration.cutOn = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.cutOn.selected = {};
        product.items[itemIndex].configuration.cutOn.finalOptions = product.items[itemIndex].configuration.cutOn.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.cutOn.finalOptions, 'optionKey', context.salesOrderItemBistro.cutOn);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = -1;
        }
        product.items[itemIndex].configuration.cutOn.selected.value = optionIndex != -1 ? product.items[itemIndex].configuration.cutOn.finalOptions[optionIndex] : "";
        product.items[itemIndex].configuration.cutOn.selected.dropdownValue = optionIndex != -1 ? product.items[itemIndex].configuration.cutOn.selected.value.optionKey : "";
        product.items[itemIndex].configuration.cutOn.formError = salesOrderProductBuilderV1Service.getFormErrorInstance();


        //Width Extenders
        configurationIndex = findIndex(configurations, "attributeKey", "Width Extenders");
        product.items[itemIndex].configuration.widthExtenders = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.widthExtenders.formError = salesOrderProductBuilderV1Service.getFormErrorInstance();

        //widthExtenders
        widthExtenders.forEach(attribute => {
            configurationIndex = findIndex(configurations, "attributeKey", attribute.key);
            product.items[itemIndex].configuration[attribute.key] = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
            product.items[itemIndex].configuration[attribute.key].finalOptions = product.items[itemIndex].configuration.widthExtenders.options;

            product.items[itemIndex].configuration[attribute.key].selected = {};
            if (!isEmpty(context) && context.salesOrderItemBistro[attribute.key]) {
                product.items[itemIndex].configuration[attribute.key].selected.value = context.salesOrderItemBistro[attribute.key];
            } else {
                product.items[itemIndex].configuration[attribute.key].selected.value = "";
            }
            product.items[itemIndex].configuration[attribute.key].formError = salesOrderProductBuilderV1Service.getFormErrorInstance();
        });

        //zipJoiners
        configurationIndex = findIndex(configurations, "attributeKey", "Zip Joiners");
        product.items[itemIndex].configuration.zipJoiners = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.zipJoiners.formError = salesOrderProductBuilderV1Service.getFormErrorInstance();

        zipJoiners.forEach(attribute => {
            configurationIndex = findIndex(configurations, "attributeKey", attribute.key);
            product.items[itemIndex].configuration[attribute.key] = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
            product.items[itemIndex].configuration[attribute.key].finalOptions = product.items[itemIndex].configuration.zipJoiners.options;

            product.items[itemIndex].configuration[attribute.key].selected = {};
            if (!isEmpty(context) && context.salesOrderItemBistro[attribute.key]) {
                product.items[itemIndex].configuration[attribute.key].selected.value = context.salesOrderItemBistro[attribute.key];
            } else {
                product.items[itemIndex].configuration[attribute.key].selected.value = "";
            }
            product.items[itemIndex].configuration[attribute.key].formError = salesOrderProductBuilderV1Service.getFormErrorInstance();
        });

        //wallAnchor
        configurationIndex = findIndex(configurations, "attributeKey", "Wall Anchors");
        product.items[itemIndex].configuration.wallAnchor = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.wallAnchor.finalOptions = product.items[itemIndex].configuration.wallAnchor.options;
        product.items[itemIndex].configuration.wallAnchor.selected = {};
        if (!isEmpty(context) && context.salesOrderItemBistro.wallAnchor) {
            product.items[itemIndex].configuration.wallAnchor.selected.value = context.salesOrderItemBistro.wallAnchor;
        } else {
            product.items[itemIndex].configuration.wallAnchor.selected.value = "";
        }
        product.items[itemIndex].configuration.wallAnchor.formError = salesOrderProductBuilderV1Service.getFormErrorInstance();


        //wallAnchorColour
        configurationIndex = findIndex(configurations, "attributeKey", "Wall Anchors Colour");
        product.items[itemIndex].configuration.wallAnchorColour = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.wallAnchorColour.selected = {};
        product.items[itemIndex].configuration.wallAnchorColour.finalOptions = product.items[itemIndex].configuration.wallAnchorColour.options;
        if (!isEmpty(context)) {
            optionIndex = findIndex(product.items[itemIndex].configuration.wallAnchorColour.finalOptions, 'optionKey', context.salesOrderItemBistro.wallAnchorColour);
            optionIndex = optionIndex > -1 ? optionIndex : 0;
        } else {
            optionIndex = 0;
        }
        product.items[itemIndex].configuration.wallAnchorColour.selected.value = product.items[itemIndex].configuration.wallAnchorColour.finalOptions[optionIndex];
        product.items[itemIndex].configuration.wallAnchorColour.selected.dropdownValue = product.items[itemIndex].configuration.wallAnchorColour.selected.value.optionKey;
        product.items[itemIndex].configuration.wallAnchorColour.formError = salesOrderProductBuilderV1Service.getFormErrorInstance();


        //bom
        configurationIndex = findIndex(configurations, "attributeKey", "bom");
        product.items[itemIndex].configuration.bom = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.bom.options = getSorted(product.items[itemIndex].configuration.bom.options, "primaryAttributeMin", true);
        product.items[itemIndex].configuration.bom.options.forEach(o => {
            o.sets = getSorted(o.sets, "min", true, "max", true, "secondaryMin", true, "secondaryMax", true);
        });
        product.items[itemIndex].configuration.bom.selected = {};

        //deduction
        product.items[itemIndex].configuration.deduction = {
            deductionRules: [],
            selected: {
                value: {
                    deduction: 0
                }
            }
        };


        //fineline
        configurationIndex = findIndex(configurations, "attributeKey", "fineline");
        product.items[itemIndex].configuration.fineline = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.fineline.selected = {};

        product.items[itemIndex].configuration.fineline.selected.value = {
            product: {},
            cutFee: {},
            makeBuild: {},
            wallAnchor: {standard: {}, make: {}},
            widthExtender50mm: {standard: {}, make: {}},
            widthExtender100mm: {standard: {}, make: {}},
            widthExtender150mm: {standard: {}, make: {}},
            zipJoiner100mm: {standard: {}, make: {}},
            zipJoiner200mm: {standard: {}, make: {}},
        };

        product.items[itemIndex].configuration.fineline.formError = salesOrderProductBuilderV1Service.getFormErrorInstance();

        //offRange
        configurationIndex = findIndex(configurations, "attributeKey", "offRange");
        product.items[itemIndex].configuration.offRange = configurationIndex > -1 ? cloneDeep(configurations[configurationIndex]) : {};
        product.items[itemIndex].configuration.offRange.selected = {};
        product.items[itemIndex].configuration.offRange.selected.value = {
            product: {},
            cutFee: {},
            makeBuild: {},
            wallAnchor: { standard: {}, make: {} },
            widthExtender50mm: { standard: {}, make: {} },
            widthExtender100mm: { standard: {}, make: {} },
            widthExtender150mm: { standard: {}, make: {} },
            zipJoiner100mm: { standard: {}, make: {} },
            zipJoiner200mm: { standard: {}, make: {} },
        };

        product.items[itemIndex].configuration.offRange.formError = { isValid: true, message: "", dom: null, };



        product = bistroUtil.updateRules("quantity", product, itemIndex, order);
        product = bistroUtil.updateRules("width", product, itemIndex, order);
        product = bistroUtil.updateRules("drop", product, itemIndex, order);

        product = this.updateItemConfigurationOptions("wallAnchorColour", product, itemIndex, order);

        /*  product = bistroUtil.updateFormError("quantity", product, itemIndex, order);
          product = bistroUtil.updateFormError("width", product, itemIndex, order);
          product = bistroUtil.updateFormError("drop", product, itemIndex, order);*/
        product = bistroUtil.updateFormError("wallAnchor", product, itemIndex, order);
        product = bistroUtil.updateFormError("widthExtenders", product, itemIndex, order);
        product = bistroUtil.updateFormError("zipJoiners", product, itemIndex, order);
        return product;
    }

    updateItemConfigurationOptions(key, product, itemIndex, context) {
        let optionIndex = -1, drop = "", condition1;
        drop = product.items[itemIndex].configuration.drop.selected.value;
        let isWallAnchorChecked = bistroUtil.isWallAnchorChecked(product, itemIndex);
        if (key) {
            switch (key) {
                case "quantity":
                    product.items[itemIndex].configuration.quantity.selected.value = 1;
                    break;
                case "wallAnchorColour":
                    optionIndex = -1;
                    if (isWallAnchorChecked) {
                        condition1 = product.items[itemIndex].configuration.model.selected.value.description;
                        product.items[itemIndex].configuration.wallAnchorColour.finalOptions = product.items[itemIndex].configuration.wallAnchorColour.options.filter(o =>
                            (
                                (drop >= o.primaryAttributeMin && drop <= o.primaryAttributeMax)
                                && (o.condition1.includes(condition1))
                            )
                        );
                        if (!isEmpty(product.items[itemIndex].configuration.wallAnchorColour.finalOptions)) {
                            if (!isEmpty(product.items[itemIndex].configuration.wallAnchorColour.selected.value)) {
                                optionIndex = findIndex(product.items[itemIndex].configuration.wallAnchorColour.finalOptions, 'optionKey', product.items[itemIndex].configuration.wallAnchorColour.selected.value.optionKey);
                            }
                            if (optionIndex === -1) {
                                if (!isEmpty(product.items[itemIndex].configuration.wallAnchorColour.finalOptions)) {
                                    optionIndex = 0;
                                }
                            }
                        }
                    }

                    if (optionIndex > -1) {
                        product.items[itemIndex].configuration.wallAnchorColour.selected.value = product.items[itemIndex].configuration.wallAnchorColour.finalOptions[optionIndex];
                        product.items[itemIndex].configuration.wallAnchorColour.selected.dropdownValue = product.items[itemIndex].configuration.wallAnchorColour.selected.value.optionKey;
                    } else {
                        product.items[itemIndex].configuration.wallAnchorColour.selected.value = {};
                        product.items[itemIndex].configuration.wallAnchorColour.selected.dropdownValue = "";
                    }
                    break;
                default:
                    break;
            }
        }
        return product;
    }

    handleChange(change, key, itemIndex, isComponentUpdateRequired) {
        let {product, order, packagingAndHandling, discountByProductCode} = this.props;
        let optionIndex = -1, element;
        switch (key) {
            case "quantity":
                if (change > -1 && change < 1000) {
                    product.items[itemIndex].configuration.quantity.selected.value = change;
                }
                product = bistroUtil.updateFormError("quantity", product, itemIndex);
                break;
            case "model":
                optionIndex = findIndex(product.items[itemIndex].configuration.model.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.model.selected.value = product.items[itemIndex].configuration.model.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.model.selected.dropdownValue = product.items[itemIndex].configuration.model.selected.value.optionKey;
                }
                product = this.updateItemConfigurationOptions("wallAnchorColour", product, itemIndex, order);

                product.items[itemIndex].configuration.width.selected.value = 0;
                product = this.handleChange(0, "width", itemIndex, false);
                product = this.handleChange("Width Cut", "cutOn", itemIndex, false);
                product = this.handleChange("", "wallAnchor", itemIndex, false);
                product = this.handleChange("", "wallAnchorColour", itemIndex, false);
                product = this.handleChange("", "widthExtenders", itemIndex, false);
                product = this.handleChange("", "zipJoiners", itemIndex, false);


                break;
            case "width":
                product.items[itemIndex].configuration.width.selected.value = parseInt(change);

                switch (product.items[itemIndex].configuration.cutOn.selected.value.optionKey) {
                    case "Accessory Only":
                    case "Width Cut":
                        break;
                    case "Drop Cut":
                        document.getElementById(product.items[itemIndex].configuration.drop.selected.id).focus();
                        break;
                }
                product = this.updateItemConfigurationOptions("wallAnchorColour", product, itemIndex, order);
                product = bistroUtil.updateFormError("width", product, itemIndex);
                break;
            case "drop":
                product.items[itemIndex].configuration.drop.selected.value = parseInt(change);

                product = this.updateItemConfigurationOptions("wallAnchorColour", product, itemIndex, order);
                product = bistroUtil.updateFormError("drop", product, itemIndex);
                break;
            case "cutOn":
                optionIndex = findIndex(product.items[itemIndex].configuration.cutOn.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.cutOn.selected.value = product.items[itemIndex].configuration.cutOn.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.cutOn.selected.dropdownValue = product.items[itemIndex].configuration.cutOn.selected.value.optionKey;

                    switch (product.items[itemIndex].configuration.cutOn.selected.value.optionKey) {
                        case "Accessory Only":
                            product.items[itemIndex].configuration.width.selected.value = 0;
                            product.items[itemIndex].configuration.drop.selected.value = 2400;
                            break;
                        case "Width Cut":
                            product.items[itemIndex].configuration.width.selected.value = 0;
                            element = document.getElementById(product.items[itemIndex].configuration.width.selected.id);
                            if (element) {
                                element.focus();
                            }

                            product.items[itemIndex].configuration.drop.selected.value = 2400;
                            break;
                        case "Drop Cut":
                            product.items[itemIndex].configuration.width.selected.value = 0;
                            element = document.getElementById(product.items[itemIndex].configuration.width.selected.id);
                            if (element) {
                                element.focus();
                            }

                            product.items[itemIndex].configuration.drop.selected.value = 0;

                            break;
                    }

                    product = this.updateItemConfigurationOptions("quantity", product, itemIndex, order);
                    product = this.updateItemConfigurationOptions("wallAnchorColour", product, itemIndex, order);

                    product = bistroUtil.updateRules("quantity", product, itemIndex, order);
                    product = bistroUtil.updateRules("width", product, itemIndex);
                    product = bistroUtil.updateRules("drop", product, itemIndex);

                    product = bistroUtil.updateFormError("quantity", product, itemIndex);
                    product = bistroUtil.updateFormError("width", product, itemIndex);
                    product = bistroUtil.updateFormError("drop", product, itemIndex);
                }
                break;
            case "wallAnchor":
                product.items[itemIndex].configuration.wallAnchor.selected.value = change;
                product = this.updateItemConfigurationOptions("wallAnchorColour", product, itemIndex, order);
                break;
            case "wallAnchorColour":
                optionIndex = findIndex(product.items[itemIndex].configuration.wallAnchorColour.finalOptions, "optionKey", change);
                if (optionIndex > -1) {
                    product.items[itemIndex].configuration.wallAnchorColour.selected.value = product.items[itemIndex].configuration.wallAnchorColour.finalOptions[optionIndex];
                    product.items[itemIndex].configuration.wallAnchorColour.selected.dropdownValue = product.items[itemIndex].configuration.wallAnchorColour.selected.value.optionKey;
                    product.items[itemIndex].configuration.wallAnchor.selected.value = 0;
                }
                break;
            case "widthExtenders":
                product.items[itemIndex].configuration.widthExtender50mm.selected.value = change;
                product.items[itemIndex].configuration.widthExtender100mm.selected.value = change;
                product.items[itemIndex].configuration.widthExtender150mm.selected.value = change;
                break;
            case "widthExtender50mm":
                product.items[itemIndex].configuration.widthExtender50mm.selected.value = change;
                break;
            case "widthExtender100mm":
                product.items[itemIndex].configuration.widthExtender100mm.selected.value = change;
                break;
            case "widthExtender150mm":
                product.items[itemIndex].configuration.widthExtender150mm.selected.value = change;
                break;
            case "zipJoiners":
                product.items[itemIndex].configuration.zipJoiner100mm.selected.value = change;
                product.items[itemIndex].configuration.zipJoiner200mm.selected.value = change;
                break;
            case "zipJoiner100mm":
                product.items[itemIndex].configuration.zipJoiner100mm.selected.value = change;
                break;
            case "zipJoiner200mm":
                product.items[itemIndex].configuration.zipJoiner200mm.selected.value = change;
                break;
            default:
                break;
        }
        if (isComponentUpdateRequired) {
            //immediately update component
            product = bistroUtil.updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode, true);

            this.props.handleChange("product", product);
        } else {
            return product;
        }
    }

    render() {
        let { workingBOMModal, isOpenConsolidatedBOMModal, htmlIDs, isHiddenExtendedColumn } = this.state;
        let {product, order, currentUser} = this.props;
        let items = product.items;

        return (<div>
                <div>
                    {(items && items.length > 0)
                        ?
                        <div className="table-responsive"
                             id={"product-" + product.productGroupID + "-item-table-responsive-wrapper"}>
                            <Table bordered style={{minHeight: 400}}>
                                <thead>
                                <tr>
                                    <th className="text-center" style={{minWidth: 45}}>
                                        #
                                    </th>
                                    <th className="text-center" style={{minWidth: 100}}>
                                        Quantity
                                    </th>
                                    <th className="text-center" style={{minWidth: 260}}>
                                        Colour
                                    </th>
                                    <th className="text-center" style={{minWidth: 200}}>
                                        <div>Width Cut</div>
                                        <div>Drop Cut</div>
                                        <div>Accessory Only</div>
                                    </th>
                                    <th className="text-center" style={{minWidth: 210}}>
                                        <div> Post to post</div>
                                        <div>Width(mm)</div>
                                        <small className="text-info text-justify"><i className="fa fa-info-circle  mr-2" aria-hidden="true" />Width of the main body of the blind INCLUDING the side zips. Not width of Head Rail.</small>
                                    </th>
                                    <th className="text-center" style={{minWidth: 200}}>
                                        <div>Drop(mm)</div>
                                        <small className="text-info text-justify"><i className="fa fa-info-circle  mr-2" aria-hidden="true" />Measured from the top of the Head Rail to the bottom of the included 200mm lower flap.</small>
                                    </th>
                                    <th className="text-center" style={{minWidth: 205}}>
                                        <div>Accessories</div>
                                        <small className="text-info text-justify"><i className="fa fa-info-circle  mr-2" aria-hidden="true" />Width Extender and Zip Joiner will be cut to suit the blind drop.</small>
                                    </th>
                                    <th className="text-center" style={{minWidth: 225}}>
                                        {
                                            currentUser.isExternalUser
                                                ? <div>Fineline</div>
                                                : <div>Ordered</div>
                                        }
                                        <div>Items</div>
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th className="text-center" style={{minWidth: 225}}>
                                                <div>Make/Offrange</div>
                                                <div>Items</div>
                                            </th>
                                            : null
                                    }
                                    {!isHiddenExtendedColumn ?
                                        <th className="text-center" style={{ minWidth: 225 }}>
                                            <div>Off Range Item</div>
                                            <div>Code</div>
                                        </th>
                                        : null
                                    }
                                    {!isHiddenExtendedColumn ?
                                        <th className="text-center" style={{ minWidth: 225 }}>
                                            <div>Off Range Price</div>
                                        </th>
                                        : null
                                    }
                                    <th className="text-center" style={{minWidth: 130}}>
                                        <div>Fineline</div>
                                        <div>Cut Fee</div>
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th className="text-center" style={{minWidth: 50}}>
                                                Unit Price
                                            </th>
                                            : null
                                    }
                                    {
                                        !currentUser.isExternalUser
                                            ? <th className="text-center" style={{minWidth: 50}}>
                                                Price
                                            </th>
                                            : null
                                    }
                                    <th className="text-center" style={{minWidth: 125}}>
                                        Action
                                    </th>
                                    {
                                        !currentUser.isExternalUser
                                            ? <th className="text-center" style={{minWidth: 90}}>
                                                <Button color={"link"}
                                                        onClick={() => this.toggleConsolidatedBOMModal(true)}>BOM</Button>
                                            </th>
                                            : null
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (items || []).map((item, itemIndex) => {
                                            let uniqueId = "product-" + product.productGroupID + "-item-" + itemIndex;
                                            let isAccessoryOnly = bistroUtil.isAccessoryOnly(product, itemIndex);
                                            let isWallAnchorChecked = bistroUtil.isWallAnchorChecked(product, itemIndex);
                                            let isWidthExtenderChecked = bistroUtil.isWidthExtenderChecked(product, itemIndex);
                                            let isZipJoinerChecked = bistroUtil.isZipJoinerChecked(product, itemIndex);

                                            return <tr key={uniqueId}
                                                       id={uniqueId}
                                                       className={classnames({"table-danger": !item.isValid})}>
                                                <td className="align-middle text-center">
                                                    <span>{itemIndex + 1}</span>
                                                </td>
                                                <td className="align-middle text-center">
                                                    {
                                                        isAccessoryOnly
                                                            ? "N/A"
                                                            : <Input type="number" name="quantity"
                                                                     invalid={!item.configuration.quantity.formError.isValid}
                                                                     value={item.configuration.quantity.selected.value}
                                                                     min={item.configuration.quantity.min}
                                                                     max={item.configuration.quantity.max}
                                                                     onChange={(e) => this.handleChange(e.target.value, "quantity", itemIndex, true)}
                                                                     onFocus={(event) => event.target.select()}
                                                                     disabled={!item.configuration.quantity.selected.isSelectable}
                                                                     placeholder="quantity"
                                                            />
                                                    }
                                                    {
                                                        item.configuration.quantity.formError.isValid
                                                            ? null
                                                            : <Badge color="danger">
                                                                {item.configuration.quantity.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="select" name="model"
                                                           value={item.configuration.model.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "model", itemIndex, true)}>
                                                        <option value={""} disabled={true}>Select</option>
                                                        {
                                                            (item.configuration.model.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Input type="select" name="cutOn"
                                                           value={item.configuration.cutOn.selected.dropdownValue}
                                                           onChange={(e) => this.handleChange(e.target.value, "cutOn", itemIndex, true)}>
                                                        <option value={""} disabled={true}>Select</option>
                                                        {
                                                            (item.configuration.cutOn.finalOptions || []).map((o, oIndex) => {
                                                                return <option
                                                                    key={oIndex}
                                                                    value={o.optionKey}>{o.optionLabel}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    {
                                                        item.configuration.cutOn.formError.isValid
                                                            ? null
                                                            : <Badge color="danger">
                                                                {item.configuration.cutOn.formError.message}
                                                            </Badge>
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    {item.configuration.cutOn.selected.value != "" ?
                                                        item.configuration.width.selected.isSelectable
                                                            ? <div>
                                                                {(() => {
                                                                    switch (item.configuration.cutOn.selected.value.optionKey) {
                                                                        case "Width Cut":
                                                                            return <Input type="number" name="width"
                                                                                invalid={!item.configuration.width.formError.isValid}
                                                                                value={item.configuration.width.selected.value}
                                                                                min={item.configuration.width.min}
                                                                                max={item.configuration.width.max}
                                                                                onChange={(e) => this.handleChange(e.target.value, "width", itemIndex, true)}
                                                                                onFocus={(event) => event.target.select()}
                                                                                placeholder="width"
                                                                                id={item.configuration.width.selected.id}
                                                                            />
                                                                            break;
                                                                        case "Drop Cut":
                                                                            return <Input type="select" name="width"
                                                                                invalid={!item.configuration.width.formError.isValid}
                                                                                value={item.configuration.width.selected.value}
                                                                                min={item.configuration.width.min}
                                                                                max={item.configuration.width.max}
                                                                                onChange={(e) => this.handleChange(e.target.value ? parseInt(e.target.value) : 0, "width", itemIndex, true)}
                                                                                disabled={!item.configuration.width.selected.isSelectable}
                                                                                placeholder="width"
                                                                                id={item.configuration.width.selected.id}>
                                                                                <option value={0} disabled={true}>Select
                                                                                </option>
                                                                                {
                                                                                    (sizeOptions).map((o, oIndex) => {
                                                                                        return <option
                                                                                            key={oIndex}
                                                                                            value={o}>{o}</option>
                                                                                    })
                                                                                }
                                                                            </Input>
                                                                            break;
                                                                    }
                                                                })()}
                                                            </div>
                                                            : <div>N/A</div>
                                                        : <div>
                                                            <Badge color={"info"}>
                                                                No Cut Type Specified.
                                                            </Badge>
                                                        </div>
                                                    }
                                                    {
                                                        item.configuration.width.formError.isValid
                                                            ? null
                                                            : <div>
                                                                <Badge color={"danger"}>
                                                                    {item.configuration.width.formError.message}
                                                                </Badge>
                                                                <Badge color={"danger"}>
                                                                    Please re-enter
                                                                </Badge>
                                                            </div>
                                                    }
                                                </td>
                                                <td className="align-middle text-center">
                                                    {item.configuration.cutOn.selected.value != "" ?
                                                        <Input type="number" name="drop"
                                                            invalid={!item.configuration.drop.formError.isValid}
                                                            value={item.configuration.drop.selected.value}
                                                            min={item.configuration.drop.min}
                                                            max={item.configuration.drop.max}
                                                            onChange={(e) => this.handleChange(e.target.value, "drop", itemIndex, true)}
                                                            onFocus={(event) => event.target.select()}
                                                            disabled={!item.configuration.drop.selected.isSelectable}
                                                            placeholder="drop"
                                                            id={item.configuration.drop.selected.id}
                                                        />
                                                        :
                                                        <div>
                                                            <Badge color={"info"}>
                                                                No Cut Type Specified.
                                                            </Badge>
                                                        </div>
                                                    }
                                                    
                                                    {
                                                        isAccessoryOnly
                                                            ? <small className="text-info">Enter the drop of the blind, we
                                                                will cut to suit</small>
                                                            : null
                                                    }
                                                    {
                                                        item.configuration.drop.formError.isValid
                                                            ? null
                                                            : <div>
                                                                <Badge color={"danger"}>
                                                                    {item.configuration.drop.formError.message}
                                                                </Badge>
                                                                <Badge color={"danger"}>
                                                                    Please re-enter
                                                                </Badge>
                                                            </div>
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    <div>
                                                        <div>
                                                            <Button
                                                                size={"sm"}
                                                                className={"mr-2"}
                                                                color={"link"}
                                                                onClick={() => this.handleChange(isWallAnchorChecked ? "" : 0, "wallAnchor", itemIndex, true)}>
                                                                <i className={classnames("fa", "fa-lg", {
                                                                        "fa-check-square-o": isWallAnchorChecked,
                                                                        "fa-square-o": !isWallAnchorChecked,
                                                                    }
                                                                )}/>
                                                            </Button>
                                                            Wall Anchor
                                                        </div>
                                                        {
                                                            isWallAnchorChecked
                                                                ? <div>
                                                                    {(item.configuration.wallAnchorColour.finalOptions || []).length > 0 ?
                                                                        <Input type="select" name="wallAnchorColour"
                                                                               value={item.configuration.wallAnchorColour.selected.dropdownValue}
                                                                               onChange={(e) => this.handleChange(e.target.value, "wallAnchorColour", itemIndex, true)}>
                                                                            <option value={""} disabled>Select Wall Anchor
                                                                                Colour
                                                                            </option>
                                                                            {
                                                                                (item.configuration.wallAnchorColour.finalOptions || []).map((o, oIndex) => {
                                                                                    return <option
                                                                                        key={"item" + itemIndex + "-option" + oIndex}
                                                                                        value={o.optionKey}>{o.optionLabel}</option>
                                                                                })
                                                                            }
                                                                        </Input> :
                                                                        null
                                                                    }
                                                                    <Input type="select" name={"wallAnchor"}
                                                                           className="mt-2"
                                                                           value={item.configuration.wallAnchor.selected.value}
                                                                           onChange={(e) => this.handleChange(e.target.value ? parseInt(e.target.value) : 0, "wallAnchor", itemIndex, true)}
                                                                           placeholder={"wallAnchor"}>
                                                                        <option value={0}>Select</option>
                                                                        {
                                                                            quantityOptions.map((o, oIndex) => {
                                                                                return <option key={oIndex}
                                                                                               value={o}>{o}</option>
                                                                            })
                                                                        }
                                                                    </Input>
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            item.configuration.wallAnchor.formError.isValid
                                                                ? null
                                                                : <div>
                                                                    <Badge color={"danger"}>
                                                                        {item.configuration.wallAnchor.formError.message}
                                                                    </Badge>
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className={"mt-2"}>
                                                        <div>
                                                            <Button
                                                                size={"sm"}
                                                                className={"mr-2"}
                                                                color={"link"}
                                                                onClick={() => this.handleChange(isWidthExtenderChecked ? "" : 0, (isWidthExtenderChecked ? "widthExtenders" : widthExtenders[0].key), itemIndex, true)}>
                                                                <i className={classnames("fa", "fa-lg", {
                                                                        "fa-check-square-o": isWidthExtenderChecked,
                                                                        "fa-square-o": !isWidthExtenderChecked,
                                                                    }
                                                                )}/>
                                                            </Button>
                                                            Width Extender
                                                        </div>
                                                        {
                                                            isWidthExtenderChecked
                                                                ? <div>
                                                                    {
                                                                        widthExtenders.map((attribute, attributeIndex) => {
                                                                            return <InputGroup className={"mt-1"}
                                                                                               key={attributeIndex}>
                                                                                <InputGroupAddon addonType="prepend">
                                                                                    <InputGroupText style={{minWidth: 80}}>
                                                                                        {attribute.label}
                                                                                    </InputGroupText>

                                                                                </InputGroupAddon>
                                                                                <Input type="select" name={attribute.key}
                                                                                       value={item.configuration[attribute.key].selected.value}
                                                                                       onChange={(e) => this.handleChange(e.target.value ? parseInt(e.target.value) : 0, attribute.key, itemIndex, true)}
                                                                                       placeholder={attribute.key}>
                                                                                    <option value={0}>Select</option>
                                                                                    {
                                                                                        quantityOptions.map((o, oIndex) => {
                                                                                            return <option key={oIndex}
                                                                                                           value={o}>{o}</option>
                                                                                        })
                                                                                    }
                                                                                </Input>

                                                                            </InputGroup>
                                                                        })
                                                                    }
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            item.configuration.widthExtenders.formError.isValid
                                                                ? null
                                                                : <div>
                                                                    <Badge color={"danger"}>
                                                                        {item.configuration.widthExtenders.formError.message}
                                                                    </Badge>
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className={"mt-2"}>
                                                        <div>
                                                            <Button
                                                                size={"sm"}
                                                                className={"mr-2"}
                                                                color={"link"}
                                                                onClick={() => this.handleChange(isZipJoinerChecked ? "" : 0, (isZipJoinerChecked ? "zipJoiners" : zipJoiners[0].key), itemIndex, true)}>
                                                                <i className={classnames("fa", "fa-lg", {
                                                                        "fa-check-square-o": isZipJoinerChecked,
                                                                        "fa-square-o": !isZipJoinerChecked,
                                                                    }
                                                                )}/>
                                                            </Button>
                                                            Zip Joiner
                                                        </div>
                                                        {
                                                            isZipJoinerChecked
                                                                ? <div>
                                                                    {
                                                                        zipJoiners.map((attribute, attributeIndex) => {
                                                                            return <InputGroup className={"mt-1"}
                                                                                               key={attributeIndex}>
                                                                                <InputGroupAddon addonType="prepend">
                                                                                    <InputGroupText style={{minWidth: 80}}>
                                                                                        {attribute.label}
                                                                                    </InputGroupText>
                                                                                </InputGroupAddon>
                                                                                <Input type="select" name={attribute.key}
                                                                                       value={item.configuration[attribute.key].selected.value}
                                                                                       onChange={(e) => this.handleChange(e.target.value ? parseInt(e.target.value) : 0, attribute.key, itemIndex, true)}
                                                                                       placeholder={attribute.key}>
                                                                                    <option value={0}>Select</option>
                                                                                    {
                                                                                        quantityOptions.map((o, oIndex) => {
                                                                                            return <option key={oIndex}
                                                                                                           value={o}>{o}</option>
                                                                                        })
                                                                                    }
                                                                                </Input>

                                                                            </InputGroup>
                                                                        })
                                                                    }
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            item.configuration.zipJoiners.formError.isValid
                                                                ? null
                                                                : <div>
                                                                    <Badge color={"danger"}>
                                                                        {item.configuration.zipJoiners.formError.message}
                                                                    </Badge>
                                                                </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <ListGroup flush>
                                                        <ListGroupItem>
                                                            {
                                                                product.items[itemIndex].configuration.fineline.selected.value.product.prodCode
                                                                    ? <div>
                                                                        {
                                                                            currentUser.isExternalUser
                                                                                ? <Paragraph
                                                                                    text={product.items[itemIndex].configuration.fineline.selected.value.product.stockItemCd}
                                                                                    isCopyable={true}
                                                                                    innerTag={"strong"}
                                                                                    innerTagColour={""}
                                                                                    outerTag={"div"}
                                                                                />
                                                                                : <div>
                                                                                    <Paragraph
                                                                                        text={product.items[itemIndex].configuration.fineline.selected.value.product.stockItemCd}
                                                                                        isCopyable={true}
                                                                                        innerTag={"strong"}
                                                                                        innerTagColour={""}
                                                                                        outerTag={"div"}
                                                                                    />
                                                                                    <Paragraph
                                                                                        text={product.items[itemIndex].configuration.fineline.selected.value.product.prodCode}
                                                                                        isCopyable={true}
                                                                                        innerTag={"strong"}
                                                                                        innerTagColour={""}
                                                                                        outerTag={"div"}
                                                                                    />
                                                                                </div>
                                                                        }
                                                                        <Paragraph
                                                                            text={product.items[itemIndex].configuration.fineline.selected.value.product.description}
                                                                            isCopyable={false}
                                                                            innerTag={""}
                                                                            innerTagColour={""}
                                                                            outerTag={"small"}
                                                                        />
                                                                    </div>
                                                                    : null
                                                            }
                                                            {/*<Paragraph
                                                                        text={product.items[itemIndex].configuration.fineline.selected.value.product.soldOut ? "Sold Out" : ""}
                                                                        isCopyable={false}
                                                                        innerTag={"badge"}
                                                                        innerTagColour={"danger"}
                                                                        outerTag={"div"}
                                                                    />*/}
                                                            {
                                                                item.configuration.fineline.formError.isValid
                                                                    ? null
                                                                    : <div>
                                                                        <Badge color={"danger"}>
                                                                            {item.configuration.fineline.formError.message}
                                                                        </Badge>
                                                                    </div>
                                                            }
                                                        </ListGroupItem>

                                                        {
                                                            (bistroUtil.getAccessoryList(product, itemIndex) || []).map(accessory => {
                                                                return <ListGroupItem>
                                                                    {
                                                                        currentUser.isExternalUser
                                                                            ? <Paragraph
                                                                                text={accessory.stockItemCd}
                                                                                isCopyable={true}
                                                                                innerTag={"strong"}
                                                                                innerTagColour={""}
                                                                                outerTag={"div"}
                                                                            />
                                                                            : <div>
                                                                                <Paragraph
                                                                                    text={accessory.stockItemCd}
                                                                                    isCopyable={true}
                                                                                    innerTag={"strong"}
                                                                                    innerTagColour={""}
                                                                                    outerTag={"div"}
                                                                                />
                                                                                <Paragraph
                                                                                    text={accessory.prodCode}
                                                                                    isCopyable={true}
                                                                                    innerTag={"strong"}
                                                                                    innerTagColour={""}
                                                                                    outerTag={"div"}
                                                                                />
                                                                            </div>
                                                                    }
                                                                    <small>
                                                                        {accessory.description}
                                                                    </small>
                                                                    {/*<Paragraph
                                                                        text={accessory.soldOut ? "Sold Out" : ""}
                                                                        isCopyable={false}
                                                                        innerTag={"badge"}
                                                                        innerTagColour={"danger"}
                                                                        outerTag={"div"}
                                                                    />*/}
                                                                </ListGroupItem>
                                                            })
                                                        }
                                                    </ListGroup>

                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ? <td className="align-middle text-center">
                                                            <ListGroup flush>
                                                                {
                                                                    product.items[itemIndex].configuration.fineline.selected.value.makeBuild.prodCode
                                                                        ? <ListGroupItem>
                                                                            <Paragraph
                                                                                text={product.items[itemIndex].configuration.fineline.selected.value.makeBuild.prodCode}
                                                                                isCopyable={true}
                                                                                innerTag={"strong"}
                                                                                innerTagColour={""}
                                                                                outerTag={"div"}
                                                                            />
                                                                            <small>
                                                                                {product.items[itemIndex].configuration.fineline.selected.value.makeBuild.shpDescription}
                                                                            </small>
                                                                        </ListGroupItem>
                                                                        : null
                                                                }
                                                                {
                                                                    (bistroUtil.getAccessoryListMake(product, itemIndex) || []).map(accessory => {
                                                                        return <ListGroupItem>
                                                                            <Paragraph
                                                                                text={accessory.prodCode}
                                                                                isCopyable={true}
                                                                                innerTag={"strong"}
                                                                                innerTagColour={""}
                                                                                outerTag={"div"}
                                                                            />
                                                                            <small>
                                                                                {accessory.shpDescription}
                                                                            </small>
                                                                        </ListGroupItem>
                                                                    })
                                                                }
                                                            </ListGroup>
                                                        </td>
                                                        : null
                                                }
                                                {!isHiddenExtendedColumn ?
                                                    <td className="align-middle text-center">
                                                        <ListGroup flush>
                                                            <ListGroupItem>
                                                                <div>
                                                                    <Paragraph
                                                                        text={product.items[itemIndex].configuration.offRange.selected.value.product.prodCode}
                                                                        isCopyable={true}
                                                                        innerTag={"strong"}
                                                                        innerTagColour={""}
                                                                        outerTag={"div"}
                                                                    />
                                                                </div>
                                                            </ListGroupItem>
                                                            {
                                                                (bistroUtil.getAccessoryListOffRange(product, itemIndex) || []).map(accessory => {
                                                                    return <ListGroupItem>
                                                                        <div>
                                                                            <Paragraph
                                                                                text={accessory.prodCode}
                                                                                isCopyable={true}
                                                                                innerTag={"strong"}
                                                                                innerTagColour={""}
                                                                                outerTag={"div"}
                                                                            />
                                                                        </div>
                                                                    </ListGroupItem>
                                                                })
                                                            }
                                                        </ListGroup>
                                                    </td>
                                                    : null
                                                }
                                                {!isHiddenExtendedColumn ?
                                                    <td className="align-middle text-center">
                                                        <ListGroup flush>
                                                            <ListGroupItem>
                                                                <div>
                                                                    <Row className="align-middle text-center justify-content-center">
                                                                        <strong>$</strong>
                                                                        <Paragraph
                                                                            text={product.items[itemIndex].configuration.offRange.selected.value.product.price ?
                                                                                product.items[itemIndex].configuration.offRange.selected.value.product.price.toFixed(2)
                                                                                : ''}
                                                                            isCopyable={true}
                                                                            innerTag={"strong"}
                                                                            innerTagColour={""}
                                                                            outerTag={"div"}
                                                                        />
                                                                    </Row>
                                                                </div>
                                                            </ListGroupItem>
                                                            {
                                                                (bistroUtil.getAccessoryListOffRange(product, itemIndex) || []).map(accessory => {
                                                                    return <ListGroupItem>
                                                                        <div>
                                                                            <Row className="align-middle text-center justify-content-center">
                                                                                <strong>$</strong>
                                                                                <Paragraph
                                                                                    text={accessory.price ? accessory.price.toFixed(2) : ''}
                                                                                    isCopyable={true}
                                                                                    innerTag={"strong"}
                                                                                    innerTagColour={""}
                                                                                    outerTag={"div"}
                                                                                />
                                                                            </Row>
                                                                        </div>
                                                                    </ListGroupItem>
                                                                })
                                                            }
                                                        </ListGroup>
                                                    </td>
                                                    : null
                                                }
                                                <td className="align-middle text-center">
                                                    {
                                                        product.items[itemIndex].configuration.fineline.selected.value.cutFee.stockItemCd
                                                            ? <Paragraph
                                                                text={product.items[itemIndex].configuration.fineline.selected.value.cutFee.stockItemCd}
                                                                isCopyable={true}
                                                                innerTag={"strong"}
                                                                innerTagColour={""}
                                                                outerTag={"div"}
                                                            />
                                                            : "N/A"
                                                    }
                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ? <td className="align-middle text-center">
                                                            <NumberFormat
                                                                prefix={'$'}
                                                                value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.unitPrice)}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}/>
                                                        </td>
                                                        : null
                                                }
                                                {
                                                    !currentUser.isExternalUser
                                                        ? <td className="align-middle text-center">
                                                            <NumberFormat
                                                                prefix={'$'}
                                                                value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, item.pricing.price)}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}/>
                                                        </td>
                                                        : null
                                                }
                                                <td className="align-middle text-center">
                                                    <Button color={"link"}
                                                            title={"click here to copy item"}
                                                            onClick={() => this.handleItemAction("clone", itemIndex)}>
                                                        <i className="fa fa-clone fa-lg"
                                                           aria-hidden="true"/>
                                                    </Button>
                                                    <span className={"text-muted"}>|</span>
                                                    <Button color={"link"}
                                                            title={"click here to delete"}
                                                            onClick={() => this.handleItemAction("delete", itemIndex)}>
                                                        <i className="fa fa-trash-o fa-lg text-danger"
                                                           aria-hidden="true"/>
                                                    </Button>
                                                </td>
                                                {
                                                    !currentUser.isExternalUser
                                                        ? <td className="align-middle text-center">
                                                            <Button color={"link"}
                                                                    title={"click here to open BOM"}
                                                                    onClick={() => this.toggleBOMModal(true, itemIndex)}>
                                                                BOM
                                                            </Button>
                                                        </td>
                                                        : null

                                                }

                                            </tr>
                                        }
                                    )
                                }
                                {
                                    (!currentUser.isExternalUser && items.length > 0)
                                        ? <tr>
                                            <td className="align-middle" colSpan={11}>Total price</td>
                                            <td className="align-middle text-center">
                                                <NumberFormat
                                                    prefix={'$'}
                                                    value={salesOrderProductBuilderV1Service.getPrice(order, product, currentUser.isExternalUser, product.pricing.price)}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}/>
                                            </td>
                                            <td className="align-middle text-center" colSpan={2}/>

                                        </tr>
                                        : null
                                }


                                </tbody>
                            </Table>
                            {
                                workingBOMModal.isOpen
                                    ? <BistroBOMModal
                                        order={order}
                                        itemIndex={workingBOMModal.itemIndex}
                                        product={product}
                                        isOpen={workingBOMModal.isOpen}
                                        toggle={this.toggleBOMModal}
                                        convertToCustom={(stocksByProdCode) => this.handleItemAction("convertToCustom", workingBOMModal.itemIndex, -1, stocksByProdCode)}
                                    />
                                    : null
                            }
                            {
                                isOpenConsolidatedBOMModal
                                    ? <BistroConsolidatedBOMModal
                                        order={order}
                                        product={product}
                                        isOpen={isOpenConsolidatedBOMModal}
                                        toggle={this.toggleConsolidatedBOMModal}/>
                                    : null
                            }
                        </div>
                        :
                        null

                    }
                <Row className={"d-flex align-center justify-content-between"}>
                    <Button
                        color={"primary"}
                        className={"mb-2 ml-1"}
                        onClick={() => this.handleItemAction("new", items.length)}>
                        <i className="fa fa-cart-plus mr-2" aria-hidden="true" />
                        Add new item
                    </Button>
                    {currentUser.isExternalUser && (items && items.length > 0) ?
                        <Button color={"primary"}
                            size={"sm"}
                            className={"mb-2 mr-1 text-right"}
                            outline={isHiddenExtendedColumn}
                            onClick={() => this.toggleExtendedColumn(!isHiddenExtendedColumn)}>
                            <i className={classnames("fa", "mr-2", {
                                "fa-eye": isHiddenExtendedColumn,
                                "fa-eye-slash": !isHiddenExtendedColumn,
                            })} aria-hidden="true" />
                            {isHiddenExtendedColumn ? "Show Off Range Detail" : "Hide Off Range Detail"}
                        </Button>
                        : null
                    }
                </Row>
                </div>
            </div>
        )
    }
};
