import React, { Component } from 'react';
import { Spinner } from 'reactstrap';
import { cloneDeep, isEmpty } from 'lodash';
import { productConstant } from '../../../../../store/ProductGroups'
import RollerBlind from "../../../../../components/sales/create/productBuilderKeywayVersion/products/rollerBlind/RollerBlindSingle";
import Venetian from "../../../../../components/sales/create/productBuilderKeywayVersion/products/venetian/Venetian";
import Bistro from "../../../../../components/sales/create/productBuilderKeywayVersion/products/bistro/Bistro";
import Quiklok from "../../../../../components/sales/create/productBuilderKeywayVersion/products/quiklok/Quiklok";
import VerticalIndoor from "../../../../../components/sales/create/productBuilderKeywayVersion/products/verticalIndoor/VerticalIndoor";


export default class SalesOrderProductBuilderNavigationProduct extends Component {

    render() {

        let { order, user, discountByProductCode, packagingAndHandling, product, products, handleOrderChange, productMarkupByProductCode } = this.props;

        if (product && product.isLoadingBuilder && !product.builder) {
            return <Spinner color={"primary"} />;
        }

        switch (true) {
            case productConstant.ROLLER_BLINDS.code === product.code:
                return <RollerBlind
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.VENETIANS.code === product.code:
                return <Venetian
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.BISTRO.code === product.code:
                return <Bistro
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.Quiklok.code === product.code:
                return <Quiklok
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            case productConstant.VERTICAL_INDOOR.code === product.code:
                return <VerticalIndoor
                    order={order}
                    product={product}
                    products={products}
                    currentUser={user}
                    packagingAndHandling={packagingAndHandling}
                    discountByProductCode={discountByProductCode}
                    productMarkupByProductCode={productMarkupByProductCode}
                    handleChange={handleOrderChange}
                />;
                break;
            default:
                return <h5 className="text-center" > This online ordering form is coming soon, please use the paper ordering form. </h5>;
                break;
        }

        return null;
    }

}