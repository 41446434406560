import React, { Component } from 'react';
import { Badge, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Table, Button, Spinner } from 'reactstrap';
import queryString from 'query-string';
import SalesCustomerOrderService from '../../services/SalesCustomerOrderService';
import OrderService from '../../services/OrderService';
import { Link } from "react-router-dom";
import { changeFormatOfDateString, getDateString, handleErrorMessage, getShipmentStatusColorName } from '../../services/CommonService';
import NumberFormat from "react-number-format";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import cloneDeep from 'lodash/cloneDeep';
import UserService from '../../services/UserService';
import { toast } from 'react-toastify';
import ShowShipmentStatusLogDetailModal from '../modal/ShowShipmentStatusLogDetailModal';
import SearchGatewayOrder from '../search/SearchGatewayOrder';
import classnames from "classnames";
import SalesOrderItemProductDetailModal from '../modal/SalesOrderItemProductDetailModal';

const date = new Date();

export default class SalesCustomerOrderList extends Component {

    constructor(props) {
        super(props);


        this.state = {
            orders: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "ordDate",
                        direction: false
                    },
                    filterRequest: {
                        consignNum: "",
                        ordNum: null,
                        custOrdNum: "",
                        accountID: "",
                        startDate: moment(date).subtract(90, 'day').toDate(),
                        endDate: date,
                        JobRef: "",
                        delCompany: "",
                        OrderRef: "",
                        isBunning: true
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            salesOrderJobStatuses: {
                statuses: [],
                statusesById: {}
            },
            loading: false,
            cancelReasonOption: [],
            showConfirmDelete: false,
            downloading: false,
            convertingToOrder: false,
            downloadQuote: false,
            loadingCancelledQuote: false,
            searchText: "",
            activeTab: props.activeTab,
            isOpenProductGroupModal: false,
            selectedOrder: {},
            user: {},
            emailModal: {
                isOpen: false,
                isLoadingEmailSend: false,
                mail: {
                    id: "",
                    from: [{
                        label: "Windoware KEYWAY(keyway@windoware.com.au)",
                        value: "keyway@windoware.com.au"
                    }],
                    fromOptions: [{
                        label: "Windoware KEYWAY(keyway@windoware.com.au)",
                        value: "keyway@windoware.com.au"
                    }],
                    to: [],
                    toOptions: [],
                    cc: [],
                    ccOptions: [],
                    bcc: [],
                    bccOptions: [],
                    subject: "",
                    body: "",
                    OrderNo: ""
                }
            },
            isOpenShipmentStatusLogModal: false,
            accountID: props.accountID
        };

        this.getOrders = this.getOrders.bind(this);
        this.getStore = this.getStore.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.orderService = new OrderService();
        this.userService = new UserService();
        this.salesCustomerOrderService = new SalesCustomerOrderService();
        this.openShipmentStatusLogModal = this.openShipmentStatusLogModal.bind(this);
        this.toggleShipmentStatusLogModal = this.toggleShipmentStatusLogModal.bind(this);
        this.openProductGroupModal = this.openProductGroupModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.getProductGroupString = this.getProductGroupString.bind(this);
    }

    componentDidMount() {
        this.getSalesOrderJobStatuses();
        if (this.props.accountID) {
            let accountID = this.props.accountID;
            this.setState({ accountID }, () => {
                this.getOrders(this.state.orders);
            })
        }
    }

    getSalesOrderJobStatuses() {
        this.orderService.getOrderStatuses().then(response => {
            let { salesOrderJobStatuses } = this.state;
            salesOrderJobStatuses.statuses = response.data;
            salesOrderJobStatuses.statuses.forEach(status => {
                salesOrderJobStatuses.statusesById[status.id] = status;
            });
            this.setState({ salesOrderJobStatuses });
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.activeTab !== nextProps.activeTab || this.state.accountID !== nextProps.accountID) {
            this.setState({ activeTab: nextProps.activeTab, accountID: nextProps.accountID }, () => {
                this.getOrders(this.state.orders);
            });
        }
    }

    getOrders(orders) {
        let { activeTab, accountID } = this.state;
        this.setState({ loading: true });
        let request = cloneDeep(orders.request);
        if (accountID) {
            request.filterRequest.accountID = accountID;
        }
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        this.salesCustomerOrderService.getAllCustomerOrders(request, activeTab.toUpperCase()).then(response => {
            orders.response = response.data;
            this.setState({ orders, loading: false });
        }).catch(error => {
            orders.response = [];
            this.setState({ loading: false, orders });
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    handleChange(change, key) {
        let { orders } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change }, () => {
                    this.getOrders(orders);
                });
                break;
            case "sortKey":
                if(change){
                    if (orders.request.sortRequest.key === change) {
                        orders.request.sortRequest.direction = !orders.request.sortRequest.direction;
                    } else {
                        orders.request.sortRequest.key = change;
                        orders.request.sortRequest.direction = false;
                    }
                    this.getOrders(orders);
                }
                break;
            case "pageSize":
                orders.request.pageRequest[key] = change;
                this.getOrders(orders);
                break;
            case "currentPage":
                orders.request.pageRequest[key] = change;
                this.getOrders(orders);
                break;
            case "consignNum":
                orders.request.filterRequest[key] = change;
                this.getOrders(orders);
                break;
            default:
                orders.request.filterRequest[key] = change;
                this.setState({ orders }, () => {
                    this.getOrders(orders);
                });
        }

    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.ordNum.toString().includes(searchText);

            if (!flag && item.ordTotal) {
                flag = item.ordTotal.toString().includes(searchText)
            }
            if (!flag && item.custOrdNum) {
                flag = item.custOrdNum.toLowerCase().includes(searchText)
            }
            if (!flag && item.consignNum) {
                flag = item.consignNum.toLowerCase().includes(searchText)
            }
            if (!flag && item.couriers) {
                flag = item.couriers.toLowerCase().includes(searchText)
            }
            if (!flag && item.ordDate) {
                flag = changeFormatOfDateString(item.ordDate, "DD/MM/YYYY", 'DD MMM YYYY').toLowerCase().includes(searchText)
            }
            if (!flag && item.shipDate) {
                flag = changeFormatOfDateString(item.shipDate, 'DD/MM/YYYY', 'DD MMM YYYY').toLowerCase().includes(searchText)
            }
        }
        return flag;
    }

    getStore(orderRequest) {
        let { pageRequest, filterRequest } = orderRequest;
        let { activeTab, salesOrderJobStatuses } = this.state;
        let store = [
            {
                key: "srno",
                label: "Sr. No.",
                type: "default",
                colSpan: 1,
                minWidth: 30,
                sorterApplicable: false,
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (recordIndex + 1)}</span>
                }

            },
            {
                key: "ordDate",
                label: "Order Date",
                type: "date",
                colSpan: 1,
                minWidth: "150px",
                sorterApplicable: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return changeFormatOfDateString(value, "YYYY-MM-DD hh:mm", 'DD MMM YYYY HH:mm');
                }

            },
            //{
            //    key: "ordNum",
            //    label: "SHP Order Number",
            //    type: "link",
            //    colSpan: 1,
            //    minWidth: "120px",
            //    sorterApplicable: true,
            //    valueClassName: "align-middle text-center",
            //    showColumn: true,
            //    labelClassName: "hoverableItem align-middle",
            //    searchNode: <div>
            //        <Input type={"text"}
            //            placeholder={"Search..."}
            //            name={"orderNum"}
            //            value={filterRequest.orderNum}
            //            onChange={(e) => this.handleChange(e.target.value, "ordNum")} />
            //    </div>,
            //    render: function (value, record, recordIndex, data, ref) {
            //        return <span><Link
            //            className={"btn btn-sm btn-primary"}
            //            style={{ color: "white" }}
            //            title={"Click here to view order in detail"}
            //            to={"/sales/order/enquiry?" + queryString.stringify({ ordNum: value })}>
            //            {value}
            //        </Link></span>;
            //    }
            //},
            {
                key: "custOrdNum",
                label: "PO Number",
                type: "reference",
                colSpan: 1,
                minWidth: "120px",
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"custOrdNum"}
                        value={filterRequest.custOrdNum}
                        onChange={(e) => this.handleChange(e.target.value, "custOrdNum")} />
                </div>,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                },
            },
            {
                key: "orderDetails",
                label: "Product",
                type: "product",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    if (value) {
                        return <div><Button className="btn btn-sm" color="link"
                            onClick={() => ref.openProductGroupModal(record)}>{ref.getProductGroupString(record)}</Button></div>;
                    }

                },
            },
            {
                key: "salesOrderJobStatusID",
                label: "Status",
                type: "statusByID",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                showColumn: true,
                render: function (value, record, recordIndex, data, ref) {
                    let color = "";
                    if (value) {
                        switch (value) {
                            case 90:
                            case 95:
                                color ="success";
                                break;
                            case 1:
                                color ="danger";
                                break;
                            default:
                                color ="primary";
                                break;
                        }
                        return <div className={"text-center"}>
                            <Badge size={'sm'} color={color}>{salesOrderJobStatuses.statusesById[value]? salesOrderJobStatuses.statusesById[value].statusDescription : ""}</Badge>
                        </div>;
                    }
                }
            },
            {
                key: "cancelReason",
                label: "Cancellation Reason",
                type: "default",
                colSpan: 1,
                minWidth: "50px",
                sorterApplicable: false,
                valueClassName: "align-middle text-left",
                labelClassName: "align-middle",
                showColumn: activeTab === "cancelled-ord/quot",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "ordTotal",
                label: "Order Total",
                type: "currency",
                colSpan: 1,
                minWidth: "80px",
                sorterApplicable: true,
                valueClassName: "text-right align-middle",
                labelClassName: "hoverableItem align-middle",
                showColumn: true,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <NumberFormat value={Math.floor(value)}
                        displayType={'text'}
                        decimalScale={2}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        prefix={'$'} />;
                }
            },
            {
                key: "shipDate",
                label: "Shipment Date",
                type: "date",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                showColumn: activeTab === "completed",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY');
                }
            },
            {
                key: "couriers",
                label: "Ship By",
                type: "text",
                colSpan: 1,
                minWidth: 130,
                sorterApplicable: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                showColumn: activeTab === "completed",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"couriers"}
                        value={filterRequest.couriers}
                        onChange={(e) => this.handleChange(e.target.value, "couriers")} />
                </div>,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "productionReadyDate",
                label: "ECD Date",
                type: "ECD",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle",
                showColumn: activeTab === "submitted",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY');
                }
            },
            {
                key: "consignNum",
                label: "Connote No",
                type: "default",
                colSpan: 1,
                minWidth: "125px",
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                showColumn: activeTab === "completed",
                searchNode: <div>
                    <Input type={"text"}
                        placeholder={"Search..."}
                        name={"consignNum"}
                        value={filterRequest.consignNum}
                        onChange={(e) => this.handleChange(e.target.value, "consignNum")} />
                </div>,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "shipmentETADate",
                label: "Shipment ETA",
                type: "default",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                valueClassName: "align-middle text-left",
                labelClassName: "hoverableItem align-middle",
                showColumn: activeTab === "completed" || activeTab ==="submitted",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY')}</span>
                }
            },
            {
                key: "shipmentStatusDesc",
                label: "Shipment Status",
                type: "default",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                showColumn: activeTab === "completed",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <Link size={"sm"} className={"ml-2"}
                        onClick={() => ref.openShipmentStatusLogModal(record)}
                        title={"view to see tracking status log"}>
                        <Badge color={getShipmentStatusColorName(record.shipmentStatusID)}>{value}</Badge>
                    </Link>;
                }
            },
        ];

        return store;
    }

    openShipmentStatusLogModal(order) {
        this.setState({ selectedOrder: order, isOpenShipmentStatusLogModal: true });
    }

    toggleShipmentStatusLogModal(change) {
        this.setState({ isOpenShipmentStatusLogModal: change });
    }

    getProductGroupString(order) {
        let productGroupString = [];
        let productGroups = order.orderDetails;

        (productGroups || []).map((data) => {
            productGroupString.push(data.product + "(" + data.qty + ")");
        });

        return productGroupString.toString();
    }

    openProductGroupModal(order) {
        this.setState({ selectedOrder: order, isOpenProductGroupModal: true });
    }

    toggleModal(change) {
        this.setState({ isOpenProductGroupModal: change });
    }

    render() {
        let { orders, loading, searchText, user, isOpenShipmentStatusLogModal, isOpenProductGroupModal, selectedOrder } = this.state;
        let { pageRequest, sortRequest, filterRequest } = orders.request;
        let store = this.getStore(orders.request);
        return (
            <div>
                <SearchGatewayOrder
                    handleChange={this.handleChange} type={"customerOrder"}
                    filterRequest={filterRequest} currentUser={user} />
                <br />
                {loading ? <Spinner size={"sm"} color={"primary"} /> : null}
                <Table hover bordered size={"sm"} responsive>
                    <thead>
                        <tr>
                            {(store || []).filter((item) => item.showColumn).map((item, index) => {
                                return (
                                    <th key={index}
                                        onClick={() => this.handleChange(item.sorterApplicable ? item.key : "", "sortKey")}
                                        colSpan={item.colSpan}
                                        className={item.labelClassName}
                                        style={{ minWidth: item.minWidth }}>
                                        {item.label}
                                        {
                                            item.sorterApplicable ?
                                                <i className={classnames("fa", "float-right", "pt-1", {
                                                    "fa-sort": (sortRequest.key !== item.key),
                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                }
                                                )} aria-hidden="true" /> : null
                                        }

                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {(orders.response.records || []).filter((item) => this.searchFunction(item, searchText)).map((order, i) => {
                            return (
                                <tr key={i}>
                                    {(store || []).filter(item => item.showColumn).map((storeItem, index) => {
                                        return (
                                            <td key={index} className={storeItem.valueClassName}>
                                                {storeItem.render(order[storeItem.key], order, i, orders.response.records, this)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>

                </Table>
                <Row>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"text-left"} style={{ maxWidth: 440 }}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Showing
                                        {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                        {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                        {' '}of {orders.response.totalRecords}
                                        {' '}entries</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type={"select"}
                                    name={"pageSize"}
                                    value={pageRequest.pageSize}
                                    disabled={loading}
                                    onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                    <option value={10}>10 Rows</option>
                                    <option value={25}>25 Rows</option>
                                    <option value={50}>50 Rows</option>
                                    <option value={100}>100 Rows</option>
                                    <option value={500}>500 Rows</option>
                                </Input>
                            </InputGroup>


                        </div>
                    </Col>

                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={"float-right"}>
                            <Pagination
                                activePage={pageRequest.currentPage}
                                itemsCountPerPage={pageRequest.pageSize}
                                totalItemsCount={orders.response.totalRecords}
                                pageRangeDisplayed={3}
                                onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                itemClass='page-item'
                                linkClass='page-link'
                                activeClass='active'
                                innerClass='pagination'
                                activeLinkClass='active'
                            />
                        </div>

                    </Col>
                </Row>

                {/*{*/}
                {/*    isOpenShipmentStatusLogModal ?*/}
                {/*        <ShowShipmentStatusLogDetailModal*/}
                {/*            isOpen={isOpenShipmentStatusLogModal}*/}
                {/*            toggle={this.toggleShipmentStatusLogModal}*/}
                {/*            shipment={selectedOrder} />*/}
                {/*        : null*/}
                {/*}*/}
                {isOpenProductGroupModal ?
                    <SalesOrderItemProductDetailModal
                        order={selectedOrder}
                        isOpen={isOpenProductGroupModal}
                        toggle={this.toggleModal} />
                    : null
                }
                {
                    isOpenShipmentStatusLogModal ?
                        <ShowShipmentStatusLogDetailModal
                            isOpen={isOpenShipmentStatusLogModal}
                            toggle={this.toggleShipmentStatusLogModal}
                            shipment={selectedOrder} />
                        : null
                }
            </div>
        );
    }
}