import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
//Pages
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/auth/LoginPage'
import RolePage from '../pages/auth/RolePage';
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage";
import AddressStandardPage from '../pages/address/AddressStandardPage';
import ComingSoonPage from '../pages/ComingSoonPage';

import UnAuthorized from '../pages/UnAuthorized';

import CustomerProfile from '../components/user/UserProfile';
import CustomerInvite from '../pages/auth/SignupPage';
import UserPage from '../pages/User/UserPage';
import UserManagePage from '../pages/User/UserManagePage';
import UserRolesPrivilegeTabs from '../pages/role/UserRolesPrivilegeTabs';
import OrderGatewayPage from '../pages/OrderGateway/OrderGatewayPage';
import MyOrder from '../pages/OrderGateway/MyOrders';
import CustomerDetailList from '../pages/Customer/CustomerDetailList';
import ProductionSchedule from '../pages/Production/ProductionSchedule';
import OrderEnquiry from "../pages/OrderEnquiry/OrderEnquiryPage";
import CustomerEnquiryPage from '../pages/Customer/CustomerEnquiryPage';
import ManageCustomerPage from '../pages/Customer/ManageCustomerPage';
import ProductionCapacityTabs from "../pages/Production/ProductionCapacityTabs";
import ProductsPage from '../pages/product/ProductsPage';
import ProductGroupPage from "../pages/product/ProductGroupPage";
import ProductPage from "../pages/product/ProductPage";
import ProductConfigurationPage from "../pages/product/ProductConfiguration";
import ProductConfigurationStockFormula from "../pages/product/ProductConfigurationStockFormula";
import ManageProductConfigurationStockFormula from "../pages/product/ManageProductConfigurationStockFormula";
import ProductConfigurationFabricNewPage from "../pages/product/ProductConfigurationFabricNewPage";
import ProductConfigurationOptionPage from "../pages/product/ProductConfigurationOptionPage";
import ProductConfigurationOptionSetPage from "../pages/product/ProductConfigurationOptionSetPage";
import ProductConfigurationPricingMatrixPage from "../pages/product/ProductConfigurationPricingMatrixPage";
import ProductConfigurationMatrixConverterImport from "../pages/product/ProductConfigurationMatrixConverterImport";
import SalesOrderProductBuilder from "../pages/sales/order/create/productBuilderKeywayVersion/SalesOrderProductBuilder";
import SalesOrderSubmittedPage from "../pages/sales/order/create/productBuilderKeywayVersion/SalesOrderSubmittedPage";
import EmailLogsPage from "../pages/EmailLog/EmailLogsPage";
import ApiLogPage from "../pages/ApiLogPage";

const RoutesConfig = props => {

    return (
        <div>
            <Route exact path="/login" component={LoginPage}/>
            <Route exact path="/forgot" component={ForgotPasswordPage}/>
            <Route exact path="/coming-soon" component={ComingSoonPage}/>
            <Route exact path="/not-allowed" component={UnAuthorized}/>
            <Route exact path="/sign-up" component={CustomerInvite}/>

            <PrivateRoute exact path="/" component={HomePage}/>
            <PrivateRoute exact path='/roles' component={RolePage}/>
            <PrivateRoute path='/profile' component={CustomerProfile}/>
            <PrivateRoute exact path='/users' component={UserPage} />
            <PrivateRoute exact path='/users/manage' component={UserManagePage} />
            <PrivateRoute exact path='/user/role/list' component={UserRolesPrivilegeTabs} />
            <PrivateRoute exact path='/sales/ordergateway' component={OrderGatewayPage} />
            <PrivateRoute exact path='/customer/order' component={MyOrder}/>
            <PrivateRoute exact path='/customer/list' component={CustomerDetailList} />
            <PrivateRoute exact path='/sales/order/enquiry' component={OrderEnquiry} />
            <PrivateRoute exact path='/customer/account/detail' component={CustomerEnquiryPage} />
            <PrivateRoute exact path='/customers/manage/account/one' component={ManageCustomerPage} />
            <PrivateRoute exact path='/productionCapacity' component={ProductionCapacityTabs} />
            <PrivateRoute exact path='/production/schedule' component={ProductionSchedule }/>
            <PrivateRoute exact path='/inventory/product/group/list'
                          component={ProductGroupPage}/>
            <PrivateRoute exact path='/inventory/product/list'
                          component={ProductPage} />
            <PrivateRoute exact path='/inventory/products/all/list' component={ProductsPage} />
            <PrivateRoute exact path='/inventory/product/configuration'
                          component={ProductConfigurationPage}/>
            <PrivateRoute exact path='/inventory/product/configuration/stock/formula'
                          component={ProductConfigurationStockFormula}/>
            <PrivateRoute exact path='/inventory/product/configuration/stock/formula/one'
                          component={ManageProductConfigurationStockFormula}/>
            <PrivateRoute exact path='/inventory/product/configuration/fabric'
                          component={ProductConfigurationFabricNewPage}/>
            <PrivateRoute exact path='/inventory/product/configuration/option'
                          component={ProductConfigurationOptionPage}/>
            <PrivateRoute exact path='/inventory/product/configuration/option/set'
                          component={ProductConfigurationOptionSetPage}/>
            <PrivateRoute exact path='/inventory/product/configuration/price/matrix'
                          component={ProductConfigurationPricingMatrixPage}/>
            <PrivateRoute exact path='/inventory/product/configuration/matrix/convertor'
                          component={ProductConfigurationMatrixConverterImport}/>
            <PrivateRoute exact path='/sales/order/create/product-builder/v1'
                          component={SalesOrderProductBuilder}/>
            <PrivateRoute exact path='/sales/order/submitted' component={SalesOrderSubmittedPage}/>

            <PrivateRoute exact path='/sales/order/view' component={OrderEnquiry} />
            <PrivateRoute exact path='/email' component={EmailLogsPage}/>
            <PrivateRoute exact path='/address/standards' component={AddressStandardPage} />


            <PrivateRoute exact path='/email' component={EmailLogsPage} />
            <PrivateRoute exact path='/tools/api/log' component={ApiLogPage} />
        </div>
    );
};

export default RoutesConfig;