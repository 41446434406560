import {rollerBlindLabelStore} from "../default/RollerBlindLabelStore";
import {venetianLabelStore} from "../default/VenetianLabelStore";
import {bistroLabelStore} from "../default/BistroLabelStore";
import {quiklokLabelStore} from "../default/QuiklokLabelStore";
import {verticalIndoorLabelStore} from "../default/VerticalIndoorLabelStore";
import {genericLabelStore} from "../default/GenericLabelStore";
import {productConstant} from "../../../../../ProductGroups";

export function getSalesOrderItemProductLabelStore(productCode) {
    let store = null;
    switch (productCode) {
        case productConstant.ROLLER_BLINDS.code:
        case "ROL":
            store = rollerBlindLabelStore;
            break;
        case productConstant.VENETIANS.code:
            store = venetianLabelStore;
            break;
        case productConstant.BISTRO.code:
            store = bistroLabelStore;
            break;
        case productConstant.Quiklok.code:
            store = quiklokLabelStore;
            break;
        case productConstant.VERTICAL_INDOOR.code:
            store = verticalIndoorLabelStore;
            break;
        default:
            store = genericLabelStore;
            break;
    }
    return store;
}