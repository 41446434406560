import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Button,
    Col,
    Input,
    Label,
    Row,
    Table,
    Spinner,
} from "reactstrap";
import ProductionCapacityService from '../../services/production/ProductionCapacityService';
import {toast} from 'react-toastify';
import {ifExist} from '../../services/CommonService';

export default class ProductionCapacity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productionLineNames: [],
            isCapacityByDateAvailable: false,
            workingProductionCapacity: {},
            defaultProductionCapacities: [],
            isOpenAddEditCard: false,
            saving: false,

        };
        this.productionCapacityService = new ProductionCapacityService();
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getProductionLineOptions = this.getProductionLineOptions.bind(this);
        this.handleAdd = this.handleAdd.bind(this);

    }

    componentDidMount() {
        this.productionCapacityService.getProductionLineDropDown().then(response => {
            this.setState({productionLineNames: response.data}, () => {
                this.refresh();
            });
        });
    }

    handleCancel() {
        this.setState({isOpenAddEditCard: false, workingProductionCapacity: {}});
    }

    handleEdit(productioncapacity) {
        this.setState({isOpenAddEditCard: true, workingProductionCapacity: productioncapacity});
    }

    handleAdd() {
        this.setState({isOpenAddEditCard: true, workingProductionCapacity: {}});
    }

    handleChange(value, key) {
        let {workingProductionCapacity} = this.state;
        workingProductionCapacity[key] = value;
        this.setState({workingProductionCapacity});
    }

    /*handleFocus(key, value) {
        let {workingProductionCapacity} = this.state;
        workingProductionCapacity[key] = value === "0" ? "" : value;
        this.setState({workingProductionCapacity});
    }

    handleBlur(key, value) {
        let {workingProductionCapacity} = this.state;
        workingProductionCapacity[key] = value === "0" ? "" : value;
        this.setState({workingProductionCapacity});
    }*/

    handleSubmit(event) {
        event.preventDefault();
        let {workingProductionCapacity} = this.state;
        this.setState({saving: true});
        this.productionCapacityService.saveDefaultProductionCapacity(workingProductionCapacity).then(res => {
            toast.success("Saved!");
            this.setState({isOpenAddEditCard: false, workingProductionCapacity: {}, saving: false,});
            this.props.refreshAgain(true);
            this.refresh();
        }).catch(error => {
            console.log(error);
            toast.error(error);
            this.setState({isOpenAddEditCard: false, workingProductionCapacity: {}, saving: false,});
        });
    }

    refresh() {
        this.setState({loading: true});
        this.productionCapacityService.getDefaultProductionCapacity().then(response => {
            let defaultProductionCapacities = response.data;
            this.setState({defaultProductionCapacities, loading: false});
        }).catch(error => {
            this.setState({loading: false});
        });
    }

    getProductionLineOptions(productionLineNames, defaultProductionCapacities) {
        let result = [];
        if (productionLineNames && productionLineNames.length > 0) {
            if (defaultProductionCapacities && defaultProductionCapacities.length > 0) {
                productionLineNames.map(productionLineName => {
                    if (!ifExist(defaultProductionCapacities, "productionLineName", productionLineName)) {
                        result.push(productionLineName);
                    }
                });
            } else {
                result = productionLineNames
            }
        }
        return result;
    }

    render() {
        let {workingProductionCapacity, defaultProductionCapacities, isOpenAddEditCard, saving} = this.state;
        let productionLineOptions = [];
        if (workingProductionCapacity.productionLineName) {
            productionLineOptions.push(workingProductionCapacity.productionLineName);
        } else {
            productionLineOptions = this.getProductionLineOptions(this.state.productionLineNames, this.state.defaultProductionCapacities);
        }
        return (
            <div>
                <Row>
                    {(defaultProductionCapacities) ?
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Table className='table table-striped' size={'sm'}>
                                <thead>
                                <tr>
                                    <th>Production Line</th>
                                    <th>Capacity</th>
                                    <th><Button size="sm" type="submit" color="primary"
                                                onClick={() => this.handleAdd()}>
                                        <i className="fa fa-plus mr-2"/>Add</Button></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (defaultProductionCapacities || []).map((productioncapacity, index) => {
                                            return (<tr key={index}>
                                                <td>{productioncapacity.productionLineName}
                                                </td>
                                                <td>{productioncapacity.capacity}</td>
                                                <td><Button size="sm" type="submit" color="primary"
                                                            onClick={() => this.handleEdit(productioncapacity)}>
                                                    <i className="fa fa-pencil mr-2"/>Edit</Button>
                                                </td>
                                            </tr>)
                                        }
                                    )
                                }
                                </tbody>
                            </Table>
                        </Col> : null
                    }

                    {isOpenAddEditCard ? <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className="card-absolute">
                            <CardHeader>
                                <h5> {workingProductionCapacity.id ? "Update Capacity" : "Add Capacity"} </h5>
                            </CardHeader>
                            <CardBody>
                                <div className="pb-2">
                                    <Label>Production Line</Label>
                                    <Input type="select" name="productionLineName"
                                           value={workingProductionCapacity.productionLineName ? workingProductionCapacity.productionLineName : ""}
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}>
                                        <option key={"default"} value="" disabled={true}>Select</option>
                                        {
                                            productionLineOptions.map(option =>
                                                <option key={option} value={option}>{option}</option>)
                                        }
                                    </Input>
                                </div>
                                <div className="pb-2">
                                    <Label>Capacity</Label>
                                    <Input type="number" name="capacity" value={workingProductionCapacity.capacity}
                                           placeHolder={"enter capacity"}
                                           onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                    />
                                </div>
                                <div className="text-right pb-2">
                                    <Button size="sm" color="secondary"
                                            onClick={this.handleCancel}
                                            hidden={!workingProductionCapacity}>
                                        <i className="fa fa-times mr-2"/> Cancel</Button>
                                    <Button size="sm" color="primary"
                                            disabled={saving} className={"ml-2"}
                                            onClick={this.handleSubmit}>
                                        {saving ? <Spinner size="sm"
                                                           className={"mr-2"}
                                                           style={{color: "white"}}/> :
                                            <i className="fa fa-floppy-o mr-2"/>}
                                        {workingProductionCapacity.id ? "Update" : "Save"}</Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col> : null}
                </Row>
            </div>
        );
    }
}

