import React, {Component} from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Spinner
} from 'reactstrap';

import DatePicker from "react-datepicker";
import classnames from 'classnames';
import { getDateString, getDateObj, dateToFromNowDaily } from '../../services/CommonService'
import { toast, ToastContainer } from 'react-toastify';

const minDate = new Date();
export default class ProductionScheduleScheduleJobsModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            order: props.form.order,
            scheduleOn: props.form.scheduleOn ? props.form.scheduleOn : getDateString(new Date(), "DD/MM/YYYY"),
            isUserSchedule: props.isUserSchedule,
            salesOrderJobs: props.form.salesOrderJobs,
        };

        this.selectSalesOrderJob = this.selectSalesOrderJob.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount() {

        this.setState({
            order: this.props.form.order,
            scheduleOn: this.props.form.scheduleOn ? this.props.form.scheduleOn : getDateString(new Date(), "DD/MM/YYYY"),
            isUserSchedule: this.props.isUserSchedule,
            salesOrderJobs: this.props.form.salesOrderJobs
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.form.order && nextProps.form.order.ordNum !== this.state.order.ordNum) {

            this.setState({
                order: nextProps.form.order,
                scheduleOn: nextProps.form.scheduleOn,
                isUserSchedule: nextProps.isUserSchedule,
                salesOrderJobs: nextProps.form.salesOrderJobs
            })
        }
    }


    handleChange(key, change) {
        switch (key) {
            case "scheduleOn":
                this.setState({scheduleOn: getDateString(change, "DD/MM/YYYY")});
                break;
            case "isUserSchedule":
                this.setState({isUserSchedule: change});
                break;
            default:
                break;
        }
    }

    selectSalesOrderJob(change, index) {
        let {salesOrderJobs} = this.state;
        salesOrderJobs[index].isSelected = change;
        this.setState({salesOrderJobs});
    }

    handleSubmit() {
        if (!this.state.scheduleOn) {
            toast.error("Please schedule the date");
            return false;
        }
        let request = {
            scheduleOn: this.state.scheduleOn,
            isUserSchedule: this.state.isUserSchedule,
            salesOrderJobIDs: this.state.salesOrderJobs.filter(x => x.isSelected).map(x => x.salesOrderJobID),
            productionDescheduleID:this.state.salesOrderJobs.filter(x => x.isSelected).map(x => x.productionDescheduleID),
            ordNum: this.state.order.ordNum
        };
        this.props.handleSubmit(request);
    }


    render() {
        let {isOpen, toggle, isLoadingSave} = this.props;
        let {order, salesOrderJobs, scheduleOn, isUserSchedule} = this.state;


        return (
            <Modal isOpen={isOpen} size="md" scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    Re-schedule #{order.ordNum} jobs
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex flex-row">
                        <div style={{marginTop: 2}}>
                            Schedule On
                        </div>

                        <div>
                            <DatePicker
                                className={"btn btn-link btn-sm schedule-datepicker " + this.props.getScheduleOnDatePickerCSSClassName(isUserSchedule)}
                                selected={getDateObj(scheduleOn, "DD/MM/YYYY")}
                                onChange={date => this.handleChange("scheduleOn", date)}
                                dateFormat="ddMMMyy"
                                placeholderText="Select date"
                                minDate={minDate}
                                withPortal
                            />
                        </div>
                        <div style={{ marginTop: 2 }}>({dateToFromNowDaily(getDateObj(scheduleOn, "DD/MM/YYYY"))})
                        </div>
                    </div>
                    <hr/>
                    <div>Allow Auto-Scheduling? <Button color={"link"}
                                                        onClick={() => this.handleChange("isUserSchedule", isUserSchedule === 1 ? 0 : 1)}>
                        <i className={classnames("fa", "fa-lg", {
                                "fa-check-square-o": !(isUserSchedule === 1),
                                "fa-square-o": (isUserSchedule === 1),
                            }
                        )}/></Button>
                    </div>
                    <small className="text-info">This will set the selected jobs to be ‘auto-scheduled’ on the next
                        Rescheduling run. This is used to undo a manual schedule on action.
                    </small>


                    <hr/>
                    <div>Please select the Jobs you wish to re-schedule</div>
                    <div>
                        {(salesOrderJobs || []).map((job, index) => {
                            job.salesOrderJobStatusColor = "light";
                            switch (job.salesOrderJobStatusID) {
                                case 57:
                                    job.salesOrderJobStatusColor = "danger";
                                    break;
                                case 58:
                                    job.salesOrderJobStatusColor = "warning";
                                    break;
                                case 59:
                                    job.salesOrderJobStatusColor = "success";
                                    break;
                                case 60:
                                    job.salesOrderJobStatusColor = "dark";
                                    break;
                                default:
                                    break;
                            }

                            return <Card className={"m-2"} key={index}>
                                <CardBody>
                                    <Button color={"link"}
                                            onClick={() => this.selectSalesOrderJob(!job.isSelected, index)}>
                                        <i className={classnames("fa", "fa-lg", "mr-2", {
                                                "fa-check-square-o": job.isSelected,
                                                "fa-square-o": !job.isSelected,
                                            }
                                        )}/>
                                    </Button>
                                    <hr/>
                                    <div>
                                        <strong className="mr-2">
                                            Production Line
                                        </strong>
                                        :&nbsp;{job.productionLineName}
                                    </div>
                                    <div>
                                        <strong className="mr-2">
                                            Status
                                        </strong>
                                        :&nbsp;
                                        <Badge
                                            color={job.salesOrderJobStatusColor}>
                                            {job.salesOrderJobStatusCode}
                                        </Badge>
                                        ({job.salesOrderJobStatusDescription})
                                    </div>
                                    {job.scheduleOn
                                        ? <div>
                                            <strong className="mr-2">
                                                Schedule On
                                            </strong>
                                            :&nbsp;<span
                                            className={this.props.getScheduleOnDatePickerCSSClassName(job.isUserSchedule)}>{job.scheduleOnLabel}</span>
                                        </div>
                                        : null
                                    }
                                    {job.followUpDate
                                        ? <div>
                                            <strong className="mr-2">
                                                Follow-up On
                                            </strong>
                                            :&nbsp;{job.followUpDateLabel}
                                        </div>
                                        : null
                                    }
                                    <div>
                                        <strong className="mr-2">
                                            Fabric
                                        </strong>
                                        :&nbsp;{job.fabric}</div>
                                    <div>
                                        <strong className="mr-2">
                                            Qty
                                        </strong>
                                        :&nbsp;{job.qty}</div>
                                </CardBody>
                            </Card>
                        })}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button color={"primary"}
                                size={"sm"}
                                disabled={isLoadingSave}
                                className={"mr-2"}
                                onClick={this.handleSubmit}>
                            {isLoadingSave
                                ? <Spinner color={"light"} size={"sm"} className={"mr-2"}/>
                                : <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>}
                            Save
                        </Button>
                        <Button color={"secondary"}
                                size={"sm"}
                                disabled={isLoadingSave}
                                onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2" aria-hidden="true"/>Cancel
                        </Button>
                    </div>

                </ModalFooter>
            </Modal>
        )
    }
}