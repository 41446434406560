import React, {Component} from 'react';
import {Badge, Button, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {cloneDeep, isEmpty} from 'lodash';
import classnames from 'classnames';
import SalesOrderProductBuilderNavigationProduct from "./SalesOrderProductBuilderNavigationProduct";
import SalesOrderProductBuilderNavigationSubTab from "./SalesOrderProductBuilderNavigationSubTab";
import OrderForm from "../../../../../components/sales/create/productBuilderKeywayVersion/OrderForm";
import {
    productConstant
} from "../../../../../store/ProductGroups";

export default class SalesOrderProductBuilderNavigationTab extends Component {

    renderLogo(product) {
        let result = null;
        switch (product.code) {
            case productConstant.ROLLER_BLINDS.code:
                result = (<img style={{height: 60, width: "auto"}}
                               src={"/img/product/roller-blind-icon.png"}/>);
                break;
            case productConstant.VENETIANS.code:
                result = <img style={{height: 60, width: "auto"}}
                              src={"/img/product/venetian-icon.png"}/>;
                break;
            case productConstant.VERTICAL_INDOOR.code:
                result = <img style={{height: 60, width: "auto"}}
                              src={"/img/product/vertical-indoor-icon.png"}/>;
                break;
            case productConstant.BISTRO.code:
                result = <img style={{height: 60, width: "auto"}}
                              src={"/img/product/bistro-icon.png"}/>;
                break;
            case productConstant.Quiklok.code:
                result = <img style={{height: 60, width: "auto"}}
                              src={"/img/product/quiklok.png"}/>;
                break;
            case productConstant.FOLDING_ARM_AWNING.code:
                result = <img style={{height: 60, width: "auto"}}
                              src={"/img/product/faa-icon.png"}/>;
                break;
        }
        return result;
    }

    render() {
        let {order, user, discountByProductCode, packagingAndHandling, products, navigation, handleOrderChange, toggleProductTab, productMarkupByProductCode} = this.props;

        return (
            <div>
                <CardHeader>
                    <Nav tabs card>
                        {navigation.tab.list.map((p, pgIndex) => {
                                return <NavItem
                                    className={classnames({"hoverableItem": p.isSelectable})}
                                    key={pgIndex}>
                                    <NavLink
                                        disabled={!p.isSelectable}
                                        className={classnames({active: navigation.tab.active.code === p.code})}
                                        onClick={() => toggleProductTab(p.code)}>
                                        <Button color={"link"}
                                                disabled={!p.isSelectable}>
                                            {this.renderLogo(p)}
                                            <h5>{p.displayName}</h5>
                                            {
                                                (p.itemCount > 0)
                                                    ? <Badge color="primary"
                                                             className={"ml-2"}>{p.itemCount}</Badge>
                                                    : null
                                            }
                                        </Button>
                                    </NavLink>
                                </NavItem>;
                            }
                        )}
                    </Nav>
                </CardHeader>
                <CardBody>
                    <TabContent
                        activeTab={navigation.tab.active.code}>
                        {navigation.tab.list.map((p, pgIndex) => {
                                return <TabPane tabId={p.code} key={pgIndex}>
                                    {
                                        products.some(x => x.parentProductID === p.id)
                                            ? <SalesOrderProductBuilderNavigationSubTab
                                                user={user}
                                                order={order}
                                                products={products}
                                                navigation={navigation}
                                                discountByProductCode={discountByProductCode}
                                                packagingAndHandling={packagingAndHandling}
                                                handleOrderChange={handleOrderChange}
                                                toggleProductTab={toggleProductTab}
                                                productMarkupByProductCode={productMarkupByProductCode}
                                            />
                                            : <SalesOrderProductBuilderNavigationProduct
                                                user={user}
                                                order={order}
                                                product={p}
                                                products={products}
                                                discountByProductCode={discountByProductCode}
                                                packagingAndHandling={packagingAndHandling}
                                                handleOrderChange={handleOrderChange}
                                                toggleProductTab={toggleProductTab}
                                                productMarkupByProductCode={productMarkupByProductCode}
                                            />
                                    }
                                </TabPane>
                            }
                        )}
                    </TabContent>
                </CardBody>
            </div>
        )
    }
}