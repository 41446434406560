import React, { Component } from 'react';
import UserService from "../../services/UserService";
//import AccountCard from "./AccountCard";
//import OrderCard from "./OrderCard";
//import QuoteCard from "./QuoteCard";
//import KeywayUserStatsCard from "./KeywayUserStats";
//import AssignedLeadActivitiesForMe from "./crm/AssignedLeadActivitiesForMe";
//import AssignedTasksForMe from "./crm/AssignedTasksForMe";
//import MyLeads from "./crm/MyLeads";
//import RecentlyCreatedLeads from "./crm/RecentlyCreatedLeads";
//import OutstandingProductOrderCount from "./OutstandingProductOrderCount";
//import CustomerDashboard from "./CustomerDashboard";
//import DailySaleSummary from "./admin/DailySaleSummary";
//import DailySaleSummaryReport from "./admin/DailySaleSummaryReport";
import { Card, Col, Row } from "reactstrap";
//import OutstandingAmountAndOrderCounts from './OutstandingAmountAndOrderCounts';
//import SubmittedOrderCountCard from './SubmittedOrderCount';
import OutstandingOrderSummaryCard from './OutstandingOrderSummaryCard';
//import PendingOrderCountCard from './PendingOrderCount';
// Dashboard Charts combined on one Card
//import ProductionDashboardSummaryCard from './production/ProductionDashboardSummaryCard';
// Dashboard Charts individually
//import ProductionDashboardProductionDifot from './production/ProductionDashboardProductionDifot';
//import ProductionDashboardProductionInvoicing from './production/ProductionDashboardProductionInvoicing';
//import ProductionDashboardProductionLinePerformance from './production/ProductionDashboardProductionLinePerformance';
//import ProductionDashboardProductionPipeline from './production/ProductionDashboardProductionPipeline';
//import OrderCountChart from '../../pages/Dashboard/OrderCountChart';
//import CustomerLeaderboard from './CustomerDashboard/CustomerLeaderBoard';
import WeeklyPerformanceUnitPrice from './WeeklyPerformanceUnitPrice';
import ProductBuilderUnitsByWeekComponent from './ProductBuilderUnitsByWeekComponent';
//import ProductBuilderOrderEnteredBy from './ProductBuilderOrderEnteredBy';
import ProductBuilderUnitPriceByCurrentWeek from './ProductBuilderUnitPriceByCurrentWeek';
import SallyUsersCard from './SallyUsersCard';
import CTSProductBuilderOrdersEnteredBy from './CTSProductBuilderOrdersEnteredBy';
// Main home page dashboard
export default class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.userService = new UserService();
        this.state = { loading: true, dashboards: [] }
    }

    componentDidMount() {

        this.userService.getUser().then(user => {
            let d = (user.menu.dashboards || "").split(",");
            this.setState({ loading: false, dashboards: d })
        });

    }

    render() {

        if (this.state.loading) return (<div>Loading...</div>);
        const dashboards = this.state.dashboards;
        return (
            <Row>
                {dashboards.map((dashboard, idx) =>
                    <DynamicComponent tag={dashboard} key={idx} />
                )}
            </Row>
        );
    }
}

class DynamicComponent extends Component {

    render() {
        const tag = this.props.tag;
        //if (tag.trim() === 'SubmittedOrderCount') return <SubmittedOrderCountCard />;
        //if (tag.trim() === 'PendingOrderCount') return <PendingOrderCountCard />;
        //if (tag.trim() === 'DailySaleSummaryReport') return <DailySaleSummaryReport />;
        if (tag.trim() === 'OutstandingOrderSummary') return <OutstandingOrderSummaryCard />;
        //if (tag.trim() === 'Accounts') return <AccountCard />;
        //if (tag.trim() === 'Users') return <KeywayUserStatsCard />;
        //if (tag.trim() === 'Order') return <OrderCard />;
        //if (tag.trim() === 'Quote') return <QuoteCard />;
        //if (tag.trim() === 'OutstandingProductOrderCount') return <OutstandingProductOrderCount />;
        //if (tag.trim() === 'AssignedLeadActivitiesForMe') return <AssignedLeadActivitiesForMe />;
        //if (tag.trim() === 'AssignedTasksForMe') return <AssignedTasksForMe />;
        //if (tag.trim() === 'MyLeads') return <MyLeads />;
        //if (tag.trim() === 'RecentlyCreatedLeads') return <RecentlyCreatedLeads />;
        //if (tag.trim() === 'OutstandingAmountAndOrderCounts') return <OutstandingAmountAndOrderCounts />;
        //if (tag.trim() === 'CustomerDashboard') return <CustomerDashboard />;
        //if (tag.trim() === 'DailySale') return <DailySaleSummary />;
        if (tag.trim() === 'ProductBuilderOrderEnteredBy') return <CTSProductBuilderOrdersEnteredBy />;
        //if (tag.trim() === 'ProductBuilderOrderCount') return <OrderCountChart />;
        if (tag.trim() === 'WeeklyPerformanceUnitPrice') return <WeeklyPerformanceUnitPrice />;
        if (tag.trim() === 'ProductBuilderOrderWeeklyTable') return <ProductBuilderUnitsByWeekComponent />;
        //if (tag.trim() === 'CustomerLeaderboard') return <CustomerLeaderboard />;
        if (tag.trim() === 'ProductBuilderUnitPriceByCurrentWeek') return <ProductBuilderUnitPriceByCurrentWeek />;
        if (tag.trim() === 'Users') return <SallyUsersCard />;

        // Dashboard Charts individually
        //if (tag.trim() === 'ProductionDashboardProductionDifot')
        //    return <Col xl={5} lg={5} md={12} sm={12} xs={12}>
        //        <Card>
        //            <ProductionDashboardProductionDifot />
        //        </ Card>
        //    </Col>;
        //if (tag.trim() === 'ProductionDashboardProductionInvoicing')
        //    return <Col xl={7} lg={7} md={12} sm={12} xs={12}>
        //        <Card>
        //            <ProductionDashboardProductionInvoicing />
        //        </ Card>
        //    </Col>;
        //if (tag.trim() === 'ProductionDashboardProductionLinePerformance')
        //    return <Col xl={5} lg={5} md={12} sm={12} xs={12}>
        //        <Card>
        //            <ProductionDashboardProductionLinePerformance />
        //        </ Card>
        //    </Col>;
        //if (tag.trim() === 'ProductionDashboardProductionPipeline')
        //    return <Col xl={7} lg={7} md={12} sm={12} xs={12}>
        //        <Card> <ProductionDashboardProductionPipeline /> </ Card>
        //    </Col>;
        //// Dashboard Charts combined on one Card 
        //if (tag.trim() === 'ProductionDashboardSummaryCard')
        //    return <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        //        <Card>
        //            <ProductionDashboardSummaryCard />
        //        </ Card>
        //    </Col>;
        return null;
    }
}