import React, {Component} from 'react';
import {Button, Card, CardHeader, Col, Collapse, Label, Row, Table} from "reactstrap";
import NumberFormat from "react-number-format";
import {OrderEnquiryProductionModeOrderItemsProductPartListItems} from "../../store/AppConstants";
import {getDistinct, getSorted, groupBy} from '../../services/CommonService';
import classnames from 'classnames';
export default class OrderEnquiryProductionModeOrderItemsProductPartList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: this.props.rows,
            order: this.props.order,
            selectedItem: false,
            activeTab: this.props.order && this.props.order.products && this.props.order.products.length !== 0 ? this.props.order.products[0].name : null,
            isOpen: this.props.order && this.props.order.products && this.props.order.products.length !== 0 ? this.props.order.products[0].name : null,
            sortRequest: {
                key: "location",
                direction: false
            }
        };
        this.handleSort = this.handleSort.bind(this);
    }
    componentDidMount() {
        this.buildProductTabs();
        this.rowsData();
    }

    buildProductTabs() {
        let { order } = this.state;
        if (order.products) {
            for (let product of order.products) {
                product.parts = []
                product.items.forEach((item) => {
                    if (item.parts && item.parts.length > 0) {
                        product.parts = [...product.parts, ...item.parts]
                    }
                });
                let partsGroupByProdCode = groupBy(product.parts, "prodCode");
                product.parts = getDistinct(product.parts, "prodCode");
                (product.parts || []).forEach((item, index) => {
                    let keywayQty = 0;
                    (partsGroupByProdCode[item.prodCode] || []).forEach((partItem, index) => {
                        keywayQty += (partItem.parentItemQty * partItem.keywayQtyWithDeduction);
                    });
                    item.totalkeywayQtyWithDeduction = keywayQty;
                });
            }

            for (let i in order.products) {
                if (i === 0 || i === '0') {
                    order.products[0].isOpen = true;
                } else {
                    order.products[i].isOpen = false;
                }
            }
        }
        this.setState({ order });
    }

    componentWillReceiveProps(nextprops) {
        if (this.state.rows !== nextprops.rows) {
            this.buildProductTabs();
            this.rowsData();
        }
    }

    handleChange(change, value) {
        let { order } = this.state;
        for (let productIndex in order.products) {
            for (let partsIndex in order.products[productIndex].parts) {
                if (order.products[productIndex].parts[partsIndex].prodCode === change) {
                    order.products[productIndex].parts[partsIndex].selected = !order.products[productIndex].parts[partsIndex].selected;
                }
            }
        }
        this.setState({ order });
    }
   
    getPartListHeading() {
        let { activeParts } = this.props;
        let partListHeading = [
            {
                key: "attribute",
                label: "Attribute",
                type: "text",
                rowSpan: 2,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "align-middle"
            },
            {
                key: "prodCode",
                label: "Item Code",
                type: "text",
                rowSpan: 2,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "align-middle"
            },
            {
                key: "description",
                label: "Description",
                type: "text",
                rowSpan: 2,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "align-middle"
            },
            {
                key: "keyway",
                label: "Keyway",
                type: "text",
                colSpan: 4,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-center"
            },
            {
                key: "location",
                label: "Location",
                type: "text",
                rowSpan: 2,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "align-middle"
            },
            {
                key: "qoh",
                label: "QOH",
                type: "text",
                rowSpan: 2,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle text-right"
            }
        ];
        return partListHeading;
    }
   
    toggleProductPartListCollapse(tabName, tabStatus) {
        let { activeTab, order } = this.state;
        activeTab = tabName;
        for (let productIndex in order.products) {
            if (order.products[productIndex].name === tabName) {
                order.products[productIndex].isOpen = tabStatus;
                this.handleSort("location", productIndex, "collapse")
            } else {
                order.products[productIndex].isOpen = !tabStatus;
                this.handleSort("location", productIndex, "collapse")
            }
        }
        this.setState({ activeTab, order });
    }

    handleSort(change, i, key) {
        let { sortRequest, order } = this.state;
        switch (key) {
            case "collapse":
                sortRequest.key = change;
                sortRequest.direction = true;
                break;
            default:
                if (sortRequest.key === change) {
                    sortRequest.direction = !sortRequest.direction;
                }
                else {
                    sortRequest.key = change;
                    sortRequest.direction = false;
                }
        }
        if (order.products && order.products.length > 0) {
            order.products[i].parts = getSorted(order.products[i].parts, change, sortRequest.direction);
        }
        this.setState({ order, sortRequest });
    }

    rowsData() {
        let { order } = this.state;
        if (order.products) {
            for (let productIndex in order.products) {
                for (let partsIndex in order.products[productIndex].parts) {
                    if (order.products[productIndex].parts[partsIndex].location !== null && Array.from(order.products[productIndex].parts[partsIndex].location)[0] === ',') {
                        order.products[productIndex].parts[partsIndex].location = order.products[productIndex].parts[partsIndex].location.substring(1);
                    }
                }
            }
        }
        this.setState({ order }, () => {
            if (!this.props.bom) {
                this.handleSort("location", 0 ,"location")
            }
        }
        );
    }

    render() {
        let { activeParts, bom } = this.props;
        let { sortRequest, activeTab, order } = this.state;
        let partListHeading = this.getPartListHeading();
        //let showKeywayPartListColumns = (rows || []).some(row => row.createdFrom === SALES_ORDER_CREATED_FROM_KEYWAY_PRODUCT_BUILDER_V1 && row.attributeRowSpan > 0);

        return (
            <div>
                {(order.products || []).map((item, itemIndex) => {
                return <Card className={"mt-1 mb-2"}>
                    <CardHeader>
                        <Row>
                            <Col>
                                <Button
                                    size={"sm"}
                                    color={"link"}
                                    onClick={() => this.toggleProductPartListCollapse(item.name, !item.isOpen)}
                                >{item.name}
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    {
                        item.name === activeTab && item.isOpen ?
                            <Collapse isOpen={item.isOpen}>
                                <Table bordered responsive size={"sm"} striped={activeParts ? false : true} className={"mb-0"}>
                                    <thead>
                                        <tr>
                                            {
                                                !bom && activeParts ?
                                                    <th rowSpan={2} className="align-middle text-center">Pick</th>
                                                    : null
                                            }
                                            {(partListHeading || []).map((item, index) => {
                                                return (
                                                    <th key={index}
                                                        onClick={item.sorterApplicable && !bom ? (() => this.handleSort(item.key, itemIndex ,"sortKey")) : ""}
                                                        rowSpan={item.rowSpan}
                                                        colSpan={item.colSpan}
                                                        className={item.labelClassName}
                                                        style={{ minWidth: item.minWidth }}>
                                                        {item.label}
                                                        {
                                                            item.sorterApplicable && !bom && activeParts ?
                                                                <i className={classnames("fa", "float-right", "pt-1", {
                                                                    "fa-sort": (sortRequest.key !== item.key),
                                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                                }
                                                                )} aria-hidden="true" /> : null
                                                        }
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <th className="align-middle text-right" colSpan="3">Qty</th>
                                            <th className="align-middle text-center">Measurement unit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (item.parts || []).map((row, rowIndex) => (
                                                 < tr key = { rowIndex } bgcolor = { row.selected ? "#C8C8C8" : "white" } >
                                                    {
                                                        !bom && activeParts ?
                                                            <td>
                                                                <div className='ml-2'>
                                                                    <Label>
                                                                        <Button color={"link"}
                                                                            size={"sm"}
                                                                            onClick={() => this.handleChange(row.prodCode, rowIndex)}>
                                                                            {
                                                                                row.selected
                                                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                                                        aria-hidden="true" />
                                                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                                                            }
                                                                        </Button>
                                                                    </Label>
                                                                </div>
                                                            </td> : null
                                                    }
                                                    <td className="align-middle">
                                                        <div>
                                                            <strong>{row.attribute}</strong>
                                                        </div>
                                                        <div>
                                                            {row.label}
                                                        </div>
                                                    </td>
                                                    <td>{row.prodCode}</td>
                                                    <td>{row.description}</td>
                                                    <td className="text-right" colSpan="3">
                                                            <NumberFormat
                                                                value={row.totalkeywayQtyWithDeduction}
                                                            displayType={'text'}
                                                            decimalScale={2} />
                                                    </td>
                                                    <td className={"text-center"}>{row.keywayMeasurementUnit}</td>
                                                    <td>
                                                        <span>{row.location}</span>
                                                    </td>
                                                    <td className={"text-right"}>
                                                        <NumberFormat
                                                            value={row.qoh}
                                                            displayType={'text'}
                                                            decimalScale={2} />
                                                    </td>
                                                    </tr> 
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Collapse>
                            : null
                    }
                </Card>
                })}
            </div>
        )
    }
}