import React, {Component} from 'react';

export let defaultLabelStore = [
    {
        key: "prodCode",
        label: "ProdCode",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data[this.key];
            //return data.productLabel[this.key];
        }
    }, {
        key: "description",
        label: "Description",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        render: function (data, componentRef) {
            return data[this.key];
           // return data.productLabel[this.key];
        }
    }, {
        key: "qty",
        label: "Qty(unit)",
        colSpan: 1,
        minWidth: 30,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data[this.key];
          //  return data.productLabel[this.key];
        }
    }, {
        key: "width",
        label: "Width(mm)",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle text-right",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data[this.key];
            //return data.productLabel[this.key];
        }
    }, {
        key: "drop",
        label: "Drop(mm)",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "align-middle",
        labelClassName: "align-middle",
        searchNode: null,
        render: function (data, componentRef) {
            return data[this.key];
            //return data.productLabel[this.key];
        }
    }

];