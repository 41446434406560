import React, {Component} from 'react';
import {Table} from "reactstrap";
import {getSalesOrderItemProductLabelStore} from "../../store/sales/order/item/labels/default/SalesOrderItemProductLabelStoreHandler";
import {productConstant} from "../../store/ProductGroups";

export default function OrderEnquiryPageOrderItemProductLabelWrapper(props) {
    let {orderItem} = props;

    let productCode = orderItem.product.code;
    if(orderItem.isCustom){
        productCode = productConstant.CUSTOM.code;
    }
    let store = getSalesOrderItemProductLabelStore(productCode);

    if (store) {
        return <Table striped={true} responsive={true} size={"sm"} borderless>
            <thead className="thead-light">
            <tr>
                {(store || []).map((item, index) => {
                    return (
                        <th key={index}
                            colSpan={item.colSpan}
                            className={item.labelClassName}
                            style={{minWidth: item.minWidth}}>
                            {item.label}
                        </th>
                    );
                })}
            </tr>
            </thead>
            <tbody>
            <tr className="bg-white">
                {(store || []).map((column, columnIndex) => {
                    return (
                        <td key={columnIndex} className={column.valueClassName}>
                            {column.render(orderItem, this)}
                        </td>
                    );
                })}
            </tr>
            </tbody>
        </Table>;
    }
}



