import React, {Component} from 'react';

import {Link} from "react-router-dom";
import {
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Row,
    Button,
    Tooltip,
    Table,
    Card,
    CardBody,
    CardFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Spinner, Badge, CardHeader, Nav, NavItem, TabContent, TabPane, NavLink
} from "reactstrap";
import SweetAlert from 'react-bootstrap-sweetalert';
import {ToastContainer, toast} from 'react-toastify';
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import FileSaver from 'file-saver';
import cloneDeep from 'lodash/cloneDeep';
import UserService from "../../services/UserService";
import { getLocalDateStringFromUtcDateString, handleErrorMessage, changeFormatOfDateString } from "../../services/CommonService";
import SearchDebtorAcccount from '../../components/search/SearchDebtorAcccount';
import ImportUsers from '../../components/modal/ImportUsers';
import { BUNNINGS_STORE_ACCOUNT_IDs } from "../../store/AppConstants";
import queryString from 'query-string';

const allTabs = [
    {
        label: "Staff",
        key: "staff"
    },
    {
        label: "Customers",
        key: "customer"
    }
];

export default class UserPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        firstname: '',
                        lastname: '',
                        email: '',
                        username: '',
                        status: 'All',
                        accountID: '',
                        signupMode: ''
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            showAlert: false,
            disableuser: null,
            searchText: '',
            tooltip: {},
            downloading: false,
            activeTab: 'staff',
            roles: [],
            isOpenImportUserModal: false
        };
        this.userService = new UserService();
        this.hideAlert = this.hideAlert.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.disableUser = this.disableUser.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.getRoles = this.getRoles.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.printUserSummaryInExcel = this.printUserSummaryInExcel.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.toggleImportUserModal = this.toggleImportUserModal.bind(this);
    }

    componentDidMount() {
        let {userData} = this.state;

        this.getUsers(userData);
        this.getRoles();
    }

    getRoles() {
        this.userService.listRoles().then(roles => {
            this.setState({roles: roles, loading: false});
        });
    }

    getUsers(userData) {
        let {tooltip} = this.state;
        for (let key in tooltip) {
            if (tooltip.hasOwnProperty(key)) {
                tooltip[key] = false;
            }
        }
        this.setState({loading: true, tooltip});
        this.userService.listUsers(userData.request, this.state.activeTab)
            .then(response => {
                userData.response = response.data;
                this.setState({userData, loading: false, tooltip});
            })
            .catch(error => {
                this.setState({loading: false, tooltip});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
    }

    toggle = targetName => {
        let {tooltip} = this.state;
        if (!tooltip[targetName]) {
            tooltip[targetName] = {};
            tooltip[targetName].tooltipOpen = true;
            this.setState({tooltip});

        } else {
            tooltip[targetName].tooltipOpen = !tooltip[targetName].tooltipOpen;
            this.setState({tooltip});
        }
    };

    isToolTipOpen = targetName => {
        return this.state.tooltip[targetName] ? this.state.tooltip[targetName].tooltipOpen : false;
    };

    showAlert(user) {
        this.setState({showAlert: true, disableuser: user})
    }

    disableUser() {
        this.userService.deleteUser(this.state.disableuser.userId).then(ok => {
            this.setState({showAlert: false});
            this.getUsers(this.state.userData);
        })
    }

    hideAlert = () => {
        this.setState({
            showAlert: false, disableuser: null
        });
    };

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.firstName.toLowerCase().includes(searchText);

            if (!flag && item.lastName) {
                flag = item.lastName.toLowerCase().includes(searchText)
            }

            if (!flag && item.firstName.concat(" ", item.lastName)) {
                flag = item.firstName.concat(" ", item.lastName).toLowerCase().includes(searchText)
            }

            if (!flag && item.emailAddress) {
                flag = item.emailAddress.toLowerCase().includes(searchText)
            }

            if (!flag && item.username) {
                flag = item.username.toLowerCase().includes(searchText)
            }

            if (!flag && item.accountID) {
                flag = item.accountID.toLowerCase().includes(searchText)
            }

            if (!flag && item.company) {
                flag = item.company.toLowerCase().includes(searchText)
            }

        }
        return flag;
    }

    handleChange(change, key) {
        let {userData} = this.state;
        switch (key) {
            case "searchText":
                this.setState({searchText: change});
                break;
            case "sortKey":
                if (userData.request.sortRequest.key === change) {
                    userData.request.sortRequest.direction = !userData.request.sortRequest.direction;
                } else {
                    userData.request.sortRequest.key = change;
                    userData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo" || change === "status")) {
                    this.getUsers(userData);
                }
                break;
            case "pageSize":
                userData.request.pageRequest[key] = change;
                userData.request.pageRequest.currentPage = 1;
                this.getUsers(userData);
                break;
            case "currentPage":
                userData.request.pageRequest[key] = change;
                this.getUsers(userData);
                break;


            default:
                userData.request.filterRequest[key] = change;
                userData.request.pageRequest.currentPage = 1;
                this.getUsers(userData);
                this.setState({userData});
        }

    }

    handleAccountChange(accountID) {
        let {userData} = this.state;
        userData.request.filterRequest.accountID = accountID;
        userData.request.pageRequest.currentPage = 1;
        this.getUsers(userData);
        this.setState({userData});
    }

    printUserSummaryInExcel() {
        this.setState({downloading: true});
        let request = cloneDeep(this.state.userData.request);
        this.userService.generateUserSummaryInExcelReport(request).then(response => {
            this.setState({downloading: false});
            FileSaver.saveAs(response.data, "USER-SUMMARY" + ".xlsx");
        }).catch(error => {
            this.setState({downloading: false});
            console.log(error.data)
        });
    }

    getUsersHeading({filterRequest}) {
        let usersHeading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode: null


            },
            {
                key: "firstName",
                label: "Name",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: <div><Input type="text" id="firstname" name="firstname"
                                        value={filterRequest.firstname}
                                        onChange={(e) => this.handleChange(e.target.value, "firstname")}
                                        placeholder="Search..."/></div>

            },
            {
                key: "email",
                label: "Email",
                type: "text",
                colSpan: 1,
                minWidth: 130,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: <div>
                    <Input type={"text"}
                           placeholder={"Search..."}
                           name={"email"}
                           value={filterRequest.email}
                           onChange={(e) => this.handleChange(e.target.value, "email")}/>
                </div>
            },
            {
                key: "username",
                label: "Username",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: <div><Input type="text" id="username" name="username"
                                        value={filterRequest.username}
                                        onChange={(e) => this.handleChange(e.target.value, "username")}
                                        placeholder="Search..."/></div>

            },
            {
                key: "accountID",
                label: "Account",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 2,
                searchNode: <div>
                    <SearchDebtorAcccount
                        handleAccountChange={this.handleAccountChange}
                        selectedAccountID={filterRequest.accountID}
                        includeChildren={true}
                        excludeClosedandInactive={false}
                        parentAccountIDs={BUNNINGS_STORE_ACCOUNT_IDs}
                    />
                </div>
            },
            {
                key: "company",
                label: "Company",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 0,
                searchNode: null
            },
            {
                key: "storeCode",
                label: "Store Code",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode: null
            },
            {
                key: "role",
                label: "Roles",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode: <Input type='select' value={filterRequest.role}
                                   onChange={(e) => this.handleChange(e.target.value, "role")}>
                    <option value="">All</option>
                    {this.state.roles.map(role =>
                        <option key={role.roleID} value={role.name}>{role.name}</option>
                    )}
                </Input>
            },
            {
                key: "lastLoginDate",
                label: "Last Login",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: null
            },
            {
                key: "dateCreated",
                label: "Signup On",
                type: "text",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                valueClassName: "",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: null
            },
            {
                key: "signupMode",
                label: "Signup Mode",
                type: "signupMode",
                colSpan: 1,
                minWidth: 90,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode: <div>
                    <Input type={"select"}
                           name={"signupMode"}
                           value={filterRequest.signupMode}
                           onChange={(e) => this.handleChange(e.target.value, "signupMode")}>
                        <option value="">All</option>
                        <option value="SELF">Self</option>
                        <option value="ADMIN">Admin</option>
                    </Input>
                </div>
            },
            {
                key: "status",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: <div>
                    <Input type={"select"}
                           name={"status"}
                           value={filterRequest.status}
                           onChange={(e) => this.handleChange(e.target.value, "status")}>
                        <option value="All">All</option>
                        <option value="Active">Active</option>
                        <option value="InActive">InActive</option>

                    </Input>
                </div>
            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: <div className="text-center"><Link className="btn btn-primary btn-sm"
                    to={{ pathname: "/users/manage", state: { user: {}, activeTab: this.state.activeTab }}}><i
                    className="fa fa-plus"/>&nbsp;
                    Add </Link></div>

            }];

        return usersHeading;
    }

    getRoleBadgeColor(role) {
        let color = '';
        if (role.isPrimary) {
            color = 'success';
        }
        else {
            color = 'secondary';
        }
        return color;
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            }, () => {
                this.getUsers(this.state.userData);
            });
        }
    }

    toggleImportUserModal(change) {
        this.setState({isOpenImportUserModal: change});
    }

    render() {

        let {userData, loading, searchText, downloading, activeTab, isOpenImportUserModal} = this.state;
        let {pageRequest, sortRequest, filterRequest} = userData.request;
        let userHeading = this.getUsersHeading(userData.request);
        return (
            <div>
                {this.state.showAlert ? <SweetAlert
                    showCancel
                    confirmBtnText="Continue"
                    confirmBtnBsStyle="primary"
                    type="Default"
                    title="Are you sure?"
                    onCancel={this.hideAlert}
                    onConfirm={this.disableUser}>
                    Do you want to make user {this.state.disableuser.isActive ? "InActive ?" : " Active ?"}
                </SweetAlert> : null}

                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                                    onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Users</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {allTabs.map((tab, index) =>
                                <NavItem className={"hoverableItem"} key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab === tab.key})}
                                        onClick={() => {
                                            this.toggleTab(tab.key);
                                        }}>
                                        <Button style={{textDecoration: "none"}}
                                                size={"sm"}
                                                color={"link"}>{tab.label}&nbsp;
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={activeTab}>
                            {
                                allTabs.map((tab, index) => (
                                    <TabPane tabId={tab.key} key={index}>
                                        <Row>
                                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                                <div className={"text-left"}>
                                                    {
                                                        loading
                                                            ? <Spinner size="sm" color={"primary"}/>
                                                            : <span>
                                                                Showing
                                                                {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                                                {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                                                {' '}of {userData.response.totalRecords}
                                                                {' '}entries
                                                                
                                                            </span>
                                                    }
                                                </div>
                                            </Col>
                                            {activeTab === "customer" ?
                                                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                                    <div className={"text-right mb-2"}>
                                                        <Button color={"primary"} size={"sm"} className='ml-2'
                                                                onClick={() => this.toggleImportUserModal(true, false)}>
                                                            <i className="fa fa-upload mr-2" aria-hidden="true"/>Import
                                                        </Button>
                                                    </div>
                                                </Col>
                                                : null}
                                        </Row>
                                        <div>
                                            <Table striped bordered responsive hover size='sm'>
                                                <thead>
                                                <tr>
                                                    {(userHeading || []).map((item, index) => {
                                                        return (
                                                            <th key={index}
                                                                onClick={() => this.handleChange(item.key, "sortKey")}
                                                                colSpan={item.colSpan}
                                                                className={item.labelClassName}
                                                                style={{minWidth: item.minWidth}}>
                                                                {item.label}
                                                                {
                                                                    item.sorterApplicable ?
                                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                                                "fa-sort": (sortRequest.key !== item.key),
                                                                                "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                                "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                                            }
                                                                        )} aria-hidden="true"/> : null
                                                                }

                                                            </th>
                                                        );
                                                    })}
                                                </tr>
                                                <tr>
                                                    {(userHeading || []).map((item, index) => {
                                                        if (item.searchNodeColSpan > 0) {
                                                            return (
                                                                <td key={index} colSpan={item.searchNodeColSpan}
                                                                    className={"align-middle"}>
                                                                    {item.searchNode}
                                                                </td>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </tr>

                                                </thead>

                                                <tbody>
                                                {(userData.response.records || []).filter((user) => this.searchFunction(user, searchText)).map((user, index) =>

                                                    <tr key={user.userId}>
                                                        <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                                        <td>{user.firstName}&nbsp;{user.lastName}</td>
                                                        <td>{user.emailAddress}</td>
                                                        <td>{user.username}</td>
                                                        <td>
                                                            {user.accountID ? <Link
                                                                className="btn btn-sm btn-primary"
                                                                style={{color: "white"}}
                                                                title={"Click here to see account details"}
                                                                to={"/customer/account/detail?" + queryString.stringify({ accountID: user.accountID })}>{user.accountID}</Link> : null}
                                                        </td>
                                                        <td>
                                                            <small>{user.company}</small>
                                                        </td>
                                                        <td>
                                                            {user.storeCode}
                                                        </td>
                                                        <td>
                                                            {
                                                                (user.roles || []).map((role, i) =>
                                                                    <Badge color={this.getRoleBadgeColor(role)}
                                                                           key={i}>{role.roleName} {role.account ? '- ' + role.account : null}</Badge>
                                                                )
                                                            }
                                                        </td>
                                                        <td>
                                                            {changeFormatOfDateString(user.lastLoginDate,"YYYY-MM-DD hh:mm:ss", "DD MMM YYYY HH:mm")}
                                                        </td>
                                                        <td>
                                                            {changeFormatOfDateString(user.dateCreated, "YYYY-MM-DD hh:mm:ss", "DD MMM YYYY HH:mm")}
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <Badge
                                                                color={this.getSignUpModeColor(user.signupMode)}>{user.signupMode}</Badge>
                                                        </td>
                                                        <td className="text-center"><span><Button
                                                            color={user.isActive ? "success" : "danger"} size="sm"
                                                            id={`btn-${index}`}
                                                            onClick={this.showAlert.bind(this, user)}>{user.isActive ? "Active" : "InActive"}</Button>
                                                                <Tooltip placement="right"
                                                                         isOpen={this.isToolTipOpen(`btn-${index}`)}
                                                                         target={`btn-${index}`}
                                                                         toggle={() => this.toggle(`btn-${index}`)}>
                                                                    Click here to  make user {user.isActive ? "InActive!" : "Active!"}
                                                                </Tooltip></span>
                                                        </td>
                                                        <td className="text-center"><Link
                                                            className="btn btn-sm btn-primary"
                                                            to={{
                                                                pathname: "/users/manage",
                                                                state: {user: user,activeTab: this.state.activeTab}
                                                            }}>Manage</Link></td>
                                                    </tr>
                                                )}

                                                </tbody>
                                            </Table>
                                        </div>
                                    </TabPane>
                                ))}
                        </TabContent>

                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={userData.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                <ToastContainer/>
                {
                    isOpenImportUserModal ?
                        <ImportUsers isOpen={isOpenImportUserModal}
                                     toggle={this.toggleImportUserModal}
                                     refresh={() => this.getUsers(userData)}
                        />
                        : null
                }
            </div>
        );
    }

    getSignUpModeColor(signupMode) {
        switch (signupMode) {
            case "SELF":
                return "warning";
            case "INVITE":
                return "info";
            case "ADMIN":
            default:
                return "light";
        }
    }

}
