import classnames from 'classnames';
import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import {
    Breadcrumb,
    BreadcrumbItem, Button, Card,
    CardBody,
    CardFooter, Col, Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Row, Table, Spinner, Badge
} from "reactstrap";
import { handleErrorMessage } from '../../services/CommonService';
import CustomerService from '../../services/CustomerService';
import queryString from 'query-string';

export default class CustomerDetailList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false,
            extraColumn: true,
            userData: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: "",
                        direction: false
                    },
                    filterRequest: {
                        accountID: '',
                        company: '',
                        state: '',
                        email: '',
                        repName: ''
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: true,
            showAlert: false,
            disableuser: null,
            searchText: '',
            tooltip: {},
            downloading: false,
            activeTab: 'staff',
            exclude: true,
            roles: [],
            selectedRows:[]
        };
        this.customerService = new CustomerService();
        this.getUsers = this.getUsers.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.getDebtorsHeading = this.getDebtorsHeading.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this)
    }

    componentDidMount() {
        let { userData } = this.state;
        this.getUsers(userData);
    }

    getUsers(data) {
        let { tooltip } = this.state;
        for (let key in tooltip) {
            if (tooltip.hasOwnProperty(key)) {
                tooltip[key] = false;
            }
        }
        this.setState({ loading: true, tooltip });
        this.customerService.getCustomerDetailList(data.request).then(response => {
            data.response = response.data;
    this.setState({ data, loading: false, tooltip });
            })
            .catch(error => {
                this.setState({ loading: false, tooltip });
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });   
    }

    searchFunction(item, searchText) {
        let flag = true;
        if (searchText) {
            searchText = searchText.toLowerCase();

            flag = item.firstName.toLowerCase().includes(searchText);

            if (!flag && item.lastName) {
                flag = item.lastName.toLowerCase().includes(searchText)
            }

            if (!flag && item.firstName.concat(" ", item.lastName)) {
                flag = item.firstName.concat(" ", item.lastName).toLowerCase().includes(searchText)
            }

            if (!flag && item.emailAddress) {
                flag = item.emailAddress.toLowerCase().includes(searchText)
            }

            if (!flag && item.username) {
                flag = item.username.toLowerCase().includes(searchText)
            }

            if (!flag && item.accountID) {
                flag = item.accountID.toLowerCase().includes(searchText)
            }

            if (!flag && item.company) {
                flag = item.company.toLowerCase().includes(searchText)
            }

        }
        return flag;
    }

    handleChange(change, key) {
        let { userData } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (userData.request.sortRequest.key === change) {
                    userData.request.sortRequest.direction = !userData.request.sortRequest.direction;
                } else {
                    userData.request.sortRequest.key = change;
                    userData.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo" || change === "status")) {
                    this.getUsers(userData);
                }
                break;
            case "pageSize":
                userData.request.pageRequest[key] = change;
                userData.request.pageRequest.currentPage = 1;
                this.getUsers(userData);
                break;
            case "currentPage":
                userData.request.pageRequest[key] = change;
                this.getUsers(userData);
                break;


            default:
                userData.request.filterRequest[key] = change;
                userData.request.pageRequest.currentPage = 1;
                this.getUsers(userData);
                this.setState({ userData });
        }
        
    }

    handleClick(change) {
    this.setState({
            extraColumn: change,
            exclude: change
        });
    }

    handleOnChange(e, accountID) {
        const checked = e.target.checked;
        let accountIDs = [...this.state.selectedRows]; 
        if (checked) {
            accountIDs.push(accountID)
        } else {
            accountIDs = accountIDs.filter((accId) => accId !== accountID)
        }
        this.setState({
            selectedRows: accountIDs
        });
        
    };

    getDebtorsHeading(request) {
        let { filterRequest, pageRequest } = request;
        let headings = [
         //{
         //       key: "select",
         //       label: "",
         //       type: "",
         //       colSpan: 1,
         //       minWidth: 50,
         //       sorterApplicable: false,
         //       exclude: false,
         //       valueClassName: "align-middle text-center",
         //       labelClassName: "hoverableItem align-middle",
         //       searchNodeColSpan: 0,
         //       searchNode: null,
         //       render: function (value, record, recordIndex, data, ref) {
         //           const isChecked = ref.state.selectedRows.includes(record.accountID)
         //           return <input checked={isChecked} onChange={(e) => ref.handleOnChange(e, record.accountID)} type="checkbox" />
         //       }
         //   },
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                exclude: false,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 0,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (recordIndex + 1)}</span>
                }
            },
            {
                key: "accountID",
                label: "Account",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                exclude: false,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: <div><Input type="text" id="accountID" name="accountID"
                    value={filterRequest.accountID}
                    onChange={(e) => this.handleChange(e.target.value, "accountID")}
                    placeholder="Search..." /></div>,
                render: function (value, record, recordIndex, data, ref) {
                    return <Link
                        className={"btn btn-sm btn-primary"}
                        to={"/customer/account/detail?" + queryString.stringify({ accountID: value })}
                        title={"Click here to see account details"}>{value}</Link>
                }

            },
            {
                key: "company",
                label: "Name",
                type: "text",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                exclude: false,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: <div><Input type="text" id="company" name="company"
                    value={filterRequest.company}
                    onChange={(e) => this.handleChange(e.target.value, "company")}
                    placeholder="Search..." /></div>,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value }</span>
                }
            },
            {
                key: "address1",
                label: "Address",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                exclude: this.state.exclude,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 0,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }

            },
            {
                key: "bankSuburb",
                label: "Suburb",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                exclude: this.state.exclude,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 0,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }

            },
            {
                key: "state",
                label: "State",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                exclude: false,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 0,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }

            },
            {
                key: "postCode",
                label: "Post code",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                exclude: this.state.exclude,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 0,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }

            },
            {
                key: "phone",
                label: "Phone No",
                type: "text",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                exclude: this.state.exclude,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 0,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }

            },
            {
                key: "debtorCategoryName",
                label: "Category",
                type: "text",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                exclude: false,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 0,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    let colorName = '';
                    switch (value) {
                        case "Blue":
                            colorName = 'primary';
                            break;
                        case "Silver":
                            colorName = 'secondary';
                            break;
                        case "Gold":
                            colorName = "warning";
                            break;
                    }
                   return <Badge color={colorName}>{value}</Badge>
                }

            },
            {
                key: "paymentTerm",
                label: "Payment Terms",
                type: "text",
                colSpan: 1,
                minWidth: 90,
                sorterApplicable: true,
                exclude: this.state.exclude,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 0,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }

            },
            {
                key: "accountStatusDescription",
                label: "Status",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                exclude: this.state.exclude,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 0,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    let color = '';
                    switch (value) {
                        case "Active":
                            color = "success";
                            break;
                        case "COD":
                            color = "warning";
                            break;
                        case "Inactive":
                            color = "warning";
                            break;
                        case "Cash Before Build":
                            color = "light";
                            break;
                        case "Cash Before Dispatch":
                            color = "info";
                            break;
                        case "Legal Action":
                        case "Bad Debt":
                        case "Closed":
                        case "Rep Not To Call":
                            color = "danger";
                            break;

                    }
                    return <Badge color={color }>{value}</Badge>
                }

            },
            {
                key: "repName",
                label: "Rep",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: true,
                exclude: false,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 0,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "abn",
                label: "ABN",
                type: "text",
                colSpan: 1,
                minWidth: 120,
                sorterApplicable: true,
                exclude: this.state.exclude,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 0,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }

            },
            {
                key: "email",
                label: "Email",
                type: "text",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                exclude: false,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 0,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    
                    return <span>{value}</span>
                }
            },
            {
                key: "invite",
                label: "Invite",
                type: "text",
                colSpan: 1,
                minWidth: 80,
                sorterApplicable: false,
                exclude: this.state.exclude,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 0,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }

            },
           
        ];
    return headings;
      
    }
     
    render() {
  
        let { userData, loading, searchText, extraColumn } = this.state;
        let { pageRequest, sortRequest, filterRequest } = userData.request;
        let debtorsHeading = this.getDebtorsHeading(userData.request);
       
       
            return (
                <div>
                    <Breadcrumb tag="nav" listTag="div">
                        <BreadcrumbItem tag="a" href="javascript:void(0)"
                            onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                        <BreadcrumbItem active tag="span">Customer</BreadcrumbItem>
                    </Breadcrumb>
                    <Card>
                       
                        <CardBody>
                            <Row >
                                <Col xl={8} lg={8} md={10} sm={12} xs={12}>
                                  
                                            <div className={"text-left"}>
                                                {
                                                    loading
                                                        ? <Spinner size="sm" color={"primary"} />
                                                        : <span>
                                                            Showing
                                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                                            {' '}of {userData.response.totalRecords}
                                                            {' '}entries

                                                        </span>
                                                }
                                            </div>
                                   
                                </Col>
                                
                                <Col xl={4} lg={4} md={6} sm={12} xs={12} className="mb-2">
                                   
                                    <div className={"text-right"}>
                                       
                                        {this.state.selectedRows.length > 0 ? (
                                            <Button
                                                className={"mr-3" }
                                                color={"primary"} >
                                                Create Login
                                            </Button>
                                        ) : null}
                                        <Button
                                            onClick={() => this.handleClick(!extraColumn)}

                                            outline={extraColumn} color={"primary"} >
                                            {
                                                !extraColumn ? <i class="fa mr-2 fa-eye-slash" aria-hidden="true"></i>
                                        : <i class="fa mr-2 fa-eye" aria-hidden="true"></i>
                                                 
                                     
                                            }
                                            Extended Columns
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                            <Table striped bordered responsive hover size='sm' >
                                <thead style={{ backgroundColor: "white" }}>
                                
                                    <tr>
                                        {(debtorsHeading || []).map((item, index) => {
                                            if (item.exclude === false) {
                                                return (
                                                    <th key={index}
                                                        onClick={() => this.handleChange(item.key, "sortKey")}
                                                        colSpan={item.colSpan}
                                                        className={item.labelClassName}
                                                        style={{ minWidth: item.minWidth }}>
                                                        {item.label}
                                                        {
                                                            item.sorterApplicable ?
                                                                <i className={classnames("fa", "float-right", "pt-1", {
                                                                    "fa-sort": (sortRequest.key !== item.key),
                                                                    "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                                    "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                                }
                                                                )} aria-hidden="true" /> : null
                                                        }

                                                    </th>
                                                )
                                               
                                            }
                                           
                                        })}

                                    </tr>

                                    {
                                        extraColumn ? (<tr>
                                            {(debtorsHeading || []).map((item, index) => {
                                                if (item.exclude === false) {
                                                    return (
                                                        <td key={index} className={"align-middle"}>
                                                            {item.searchNode}
                                                        </td>
                                                    );
                                                }
                                            })}
                                        </tr>) : null
                                    }
                                    
                                </thead>
                                <tbody>
                                    {(userData.response.records || []).filter((item) => this.searchFunction(item, searchText)).map((user, i) => {
                                        return (
                                            <tr key={i}>
                                         
                                                {(debtorsHeading || []).map((storeItem, index) => {
                                                    if (storeItem.exclude === false) {
                                                        return (
                                                            <td key={index} className={storeItem.valueClassName}>
                                                                {storeItem.render(user[storeItem.key], user, i, userData.response.records, this)}
                                                            </td>

                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </tr>
                                        );
                                    })}

                                </tbody>
                            </Table>
                            <CardFooter>
                                <Row>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <div className={"text-left"} style={{ maxWidth: 200 }}>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>Show</InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type={"select"}
                                                    name={"pageSize"}
                                                    value={pageRequest.pageSize}
                                                    disabled={loading}
                                                    onChange={(e) => this.handleChange(e.target.value, "pageSize")}>>
                                                    <option value={10}>10 Rows</option>
                                                    <option value={25}>25 Rows</option>
                                                    <option value={50}>50 Rows</option>
                                                    <option value={100}>100 Rows</option>
                                                    <option value={500}>500 Rows</option>
                                                </Input>
                                            </InputGroup>


                                        </div>
                                    </Col>
                                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                        <div className={"float-right"}>
                                            <Pagination
                                                activePage={pageRequest.currentPage}
                                                itemsCountPerPage={pageRequest.pageSize}
                                                totalItemsCount={userData.response.totalRecords}
                                                pageRangeDisplayed={3}
                                                onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                                itemClass='page-item'
                                                linkClass='page-link'
                                                activeClass='active'
                                                innerClass='pagination'
                                                activeLinkClass='active'
                                            />
                                        </div>

                                    </Col>
                                </Row>
                            </CardFooter>
                        </CardBody>
                    </Card>
                </div>
                );
        }
 };