import React, {Component} from 'react';
import {Table,} from 'reactstrap';
import NumberFormat from "react-number-format";
import '../../../../../../assets/css/app/Print.css';


export default class VerticalIndoorConsolidatedBOMModalPrint extends Component {

    render() {
        let {order, parts} = this.props;
        return (
            <div>
                <h5>
                    {order.debtorInvoiceOrdNum ? <span className="mr-2">#{order.debtorInvoiceOrdNum}</span> : null}Roman Blind
                    consolidated part list
                </h5>
                <Table size={"sm"} striped responsive bordered hover>
                    <thead>
                    <tr>
                        <th className="align-middle" rowSpan={2}>
                            Prodcode
                        </th>
                        <th className="align-middle" rowSpan={2}>
                            Description
                        </th>
                        <th className="text-center" colspan={3}>
                            SWISH
                        </th>
                    </tr>
                    <tr>
                        <th className="text-right">
                            Qty(without wastage calculated)
                        </th>
                        <th className="text-right">
                            Qty(with wastage calculated)
                        </th>
                        <th className="text-center">
                            Measurement unit
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        (parts || []).map((part, partIndex) => {
                            return <tr key={partIndex}>
                                <td>{part.prodCode}</td>
                                <td>{part.stockDescription}</td>
                                <td className="text-right">
                                    <NumberFormat
                                        value={part.consolidatedSwishQty}
                                        displayType={'text'}
                                        decimalScale={4}
                                        thousandSeparator={true}/>
                                </td>
                                <td className="text-right">
                                    <NumberFormat
                                        value={(part.consolidatedSwishQty * (1.0 + part.wastagePercent) )}
                                        displayType={'text'}
                                        decimalScale={4}
                                        thousandSeparator={true}/>
                                </td>
                                <td className="text-center">
                                    {part.swishMeasurementUnit}
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </Table>
            </div>
        )
    }
}