import React, { Component } from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Spinner,
    Table, UncontrolledButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import stockService from '../../services/StockService';
import productService from '../../services/product/ProductService';
import classnames from 'classnames';
import queryString from 'query-string';
import cloneDeep from 'lodash/cloneDeep';
import ProductConfigurationService from '../../services/product/ProductConfigurationService';
import ProductConfigurationStockModal from '../../components/modal/ProductConfigurationStockModal';
import { findIndex, handleErrorMessage } from '../../services/CommonService';
import DeleteModal from '../../components/modal/DeleteModal';
import StockSearch from '../../components/search/SearchStock';
import UserService from '../../services/UserService';
import { toast, ToastContainer } from 'react-toastify';
import ProductConfigurationOptionSetImportModal from '../../components/modal/ProductConfigurationOptionSetImportModal';
import { v4 as uuidv4 } from 'uuid';
import Pagination from "react-js-pagination";
import ProductConfigurationStockDefaultValuesModal from '../../components/modal/ProductConfigurationStockDefaultValuesModal';
import * as FileSaver from 'file-saver';
import ManageProductConfigurationStockFormulaModal from "../../components/modal/ManageProductConfigurationStockFormulaModal";


export default class ProductConfigurationOptionSetPage extends Component {

    constructor(props) {
        super(props);


        this.state = {
            newItem: {},
            configurationOption: {
                id: 0
            },
            product: {},
            configuration: {},
            sets: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: '',
                        direction: false,
                    },
                    filterRequest: {
                        productConfigurationOptionId: '',
                        setKey: '',
                        setLabel: '',
                        description: '',
                        attribute: '',
                        min: '',
                        max: '',
                        secondaryAttribute: '',
                        secondaryMin: '',
                        secondaryMax: '',
                        condition1: '',
                        condition2: '',
                        condition3: '',
                        prodCodes: '',
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: {
                save: false,
                sets: false,
                configurationOption: false,
                product: false,
            },
            workingSet: {},
            isOpenStockModal: false,
            isOldProductBuilderView: false,
            canDeleteProductionConfiguration: false,
            isOpenImportModal: false,
            isOpenAddFormulaModal:false,
            isOpenDefaultStockModal: false,
            defaultStockValue: {},
        };
        this.userService = new UserService();

        this.getProduct = this.getProduct.bind(this);
        this.getProductConfiguration = this.getProductConfiguration.bind(this);
        this.getProductConfigurationOptionSets = this.getProductConfigurationOptionSets.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.saveProductConfigurationOptionSet = this.saveProductConfigurationOptionSet.bind(this);
        this.refreshAgain = this.refreshAgain.bind(this);
        this.handleProdCodeData = this.handleProdCodeData.bind(this);
        this.handleDeleteProductConfiguration = this.handleDeleteProductConfiguration.bind(this);
        this.toggleDeleteProductConfigurationModal = this.toggleDeleteProductConfigurationModal.bind(this);
        this.toggleImportModal = this.toggleImportModal.bind(this);
        this.toggleDefaultStockModal = this.toggleDefaultStockModal.bind(this);
        this.handleSubmitDefaultStockValue = this.handleSubmitDefaultStockValue.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.openMultiDeleteConfirmationModal = this.openMultiDeleteConfirmationModal.bind(this);
        this.toggleMultiDeleteProductConfigurationModal = this.toggleMultiDeleteProductConfigurationModal.bind(this);
        this.handleMultiDeleteProductConfiguration = this.handleMultiDeleteProductConfiguration.bind(this);
        this.handleMultipleShallowCopyProductConfiguration = this.handleMultipleShallowCopyProductConfiguration.bind(this);
        this.handleMultipleDeepCopyProductConfiguration = this.handleMultipleDeepCopyProductConfiguration.bind(this);
        this.toggleDefaultAddFormulaModal = this.toggleDefaultAddFormulaModal.bind(this);
    }

    componentDidMount() {
        let { configurationOption, configuration, product } = this.state;
        let currentUser = this.userService.getLoggedInUser();
        let canDeleteProductionConfiguration = this.userService.hasPrivilege(currentUser, 'product-delete');
        this.setState({ canDeleteProductionConfiguration });


        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);

            configurationOption.id = searchParams.configurationOptionId;
            configuration.id = searchParams.configurationId;
            product.id = searchParams.productID;

            if (configurationOption && configurationOption.id) {
                this.getProductConfigurationOption(configurationOption);
            }
            if (configuration && configuration.id) {
                this.getProductConfiguration(configuration);
            }
            if (product && product.id) {
                this.getProduct(product);
            }
        } else {
            this.props.history.push('/inventory/product/list');
        }
    }


    getProduct(product) {
        let { loading } = this.state;
        loading.product = true;
        this.setState({ loading });

        productService.getProduct(product.id).then(response => {
            loading.product = false;
            product = response.data;
            this.setState({ product, loading });
        }).catch(error => {
            loading.product = false;
            this.setState({ loading });
            toast.error(handleErrorMessage(error))
        })
    }

    getProductConfiguration(configuration) {
        let { loading } = this.state;
        loading.configuration = true;
        this.setState({ loading });

        ProductConfigurationService.getProductConfiguration(configuration.id).then(response => {
            loading.configuration = false;
            configuration = response.data;
            this.setState({ configuration, loading });
        }).catch(error => {
            loading.configuration = false;
            this.setState({ loading });
            toast.error(handleErrorMessage(error))
        })
    }

    getProductConfigurationOption(configurationOption) {
        let { loading } = this.state;
        loading.configurationOption = true;
        this.setState({ loading });

        ProductConfigurationService.getProductConfigurationOption(configurationOption.id).then(response => {
            loading.configurationOption = false;
            configurationOption = response.data;
            this.setState({ configurationOption, loading });

            let { sets } = this.state;
            sets.request.filterRequest.productConfigurationOptionId = configurationOption.id;
            this.setState({ sets });

            this.getProductConfigurationOptionSets(sets);
        }).catch(error => {
            loading.configurationOption = false;
            this.setState({ loading });
            toast.error(handleErrorMessage(error))
        })
    }


    getProductConfigurationOptionSets(sets) {
        let { loading } = this.state;
        loading.sets = true;
        this.setState({ loading });
        ProductConfigurationService.getProductConfigurationOptionSets(sets.request).then(response => {
            loading.sets = false;
            sets.response = response.data;
            (sets.response.records || []).map((item, index) => {
                item.uuid = 'set' + uuidv4();
                item.prodCodes = item.prodCodes ? item.prodCodes.split(',') : [];
            });

            this.setState({ sets, loading });
        }).catch(error => {
            loading.sets = false;
            this.setState({ loading });
            toast.error(handleErrorMessage(error))
        })
    }

    refreshAgain() {
        this.setState({ isOpenStockModal: false });
        this.getProductConfigurationOptionSets(this.state.sets);
    }

    addNewItem() {
        let { sets, configurationOption } = this.state;
        let item = {
            id: 0,
            productConfigurationOptionId: configurationOption.id,
            setNum: 0,
            description: "",
            attribute: "",
            min: 0,
            max: 0,
            secondaryAttribute: "",
            secondaryMin: 0,
            secondaryMax: 0,
            condition1: "",
            condition2: "",
            condition3: "",
            tempStock: {},
            prodCodes: [],
            parentSetId: undefined,
            setLabel: "",
            setKey: "",
            uuid: 'set' + uuidv4(),
        };
        sets.response.records.push(item);
        this.setState({ sets });
    }

    handleChange(value, key, index) {
        let { sets, workingSet } = this.state;

        let i = undefined;
        switch (key) {
            case "tempStock":
                sets.response.records[index][key] = value;

                i = sets.response.records[index].prodCodes.indexOf(value.prodCode);
                if (i > -1) {
                    sets.response.records[index].prodCodes.splice(i, 1);
                } else {
                    sets.response.records[index].prodCodes.push(value.prodCode);
                }
                break;
            case "prodCode":

                i = sets.response.records[index].prodCodes.indexOf(value);
                if (i > -1) {
                    sets.response.records[index].prodCodes.splice(i, 1);
                } else {
                    sets.response.records[index].prodCodes.push(value);
                }
                break;
            case "productConfigurationSetStocks":

                i = findIndex(sets.response.records[index].stocks, 'prodCode', value.prodCode);
                if (i > -1) {
                    sets.response.records[index].stocks.splice(i, 1);
                } else {
                    sets.response.records[index].stocks.push(value);
                }
                break;

            case "productConfigurationSetStocks_isOpen":
                workingSet = sets.response.records[index];
                this.setState({ workingSet, isOpenStockModal: value });
                break;
            case "productConfigurationSetStocks_toggle":
                if (!value) {
                    workingSet = {};
                }
                this.setState({ workingSet, isOpenStockModal: value });
                break;
            case "isAllSelected":
                sets.response.records.forEach(item => {
                    item.isSelected = value;
                })
                break;
            default:
                sets.response.records[index][key] = value;


        }
        this.setState({ sets });
    }

    handleChangeRequest(change, key) {
        let { sets } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (sets.request.sortRequest.key === change) {
                    sets.request.sortRequest.direction = !sets.request.sortRequest.direction;
                } else {
                    sets.request.sortRequest.key = change;
                    sets.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getProductConfigurationOptionSets(sets);
                }
                break;
            case "pageSize":
                sets.request.pageRequest[key] = parseInt(change);
                sets.request.pageRequest.currentPage = 1;
                this.getProductConfigurationOptionSets(sets);
                break;
            case "currentPage":
                sets.request.pageRequest[key] = change;
                this.getProductConfigurationOptionSets(sets);
                break;


            default:
                sets.request.filterRequest[key] = change;

                sets.request.pageRequest.currentPage = 1;
                this.getProductConfigurationOptionSets(sets);
                this.setState({ sets });
        }

    }

    saveProductConfigurationOptionSet(item, index) {
        let { sets, loading } = this.state;
        loading.save = index;

        item = cloneDeep(item);
        let prodCodesArray = [];
        (item.prodCodes || []).map((p, pIndex) => {
            prodCodesArray.push(p);
        });
        item.prodCodes = prodCodesArray.join();

        prodCodesArray = [];

        (item.stocks || []).map((p, pIndex) => {
            prodCodesArray.push(p.prodCode);
        });

        item.productStockOptionProdCodes = prodCodesArray.join();
        this.setState({ loading });
        ProductConfigurationService.saveProductConfigurationOptionSet(item).then(response => {
            if (response.status === 200 || response.status === '200') {
                toast.success("Updated");
                response.data.prodCodes = response.data.prodCodes ? response.data.prodCodes.split(',') : [];
                sets.response.records[index] = response.data;
                sets.response.records[index].uuid = 'set' + uuidv4();
                loading.save = '';
            }
            this.setState({ sets, loading });
        }).catch(error => {
            loading.save = '';
            this.setState({ loading });
            toast.error(handleErrorMessage(error))
        })
    }

    openDeleteConfirmationModal(item) {
        if (item.id) {
            this.setState({
                isOpenDeleteProductConfigurationModal: true,
                selectedItemProductConfigurationOptionSet: item
            });
        }
        else {
            let { sets } = this.state;
            let index = findIndex(sets.response.records, "uuid", item.uuid);
            sets.response.records.splice(index, 1);
            this.setState({ sets });
        }
    }

    toggleDeleteProductConfigurationModal(data) {
        this.setState({ isOpenDeleteProductConfigurationModal: data });
    }

    openMultiDeleteConfirmationModal() {
        this.setState({ isOpenMultiDeleteProductConfigurationModal: true })
    }

    toggleMultiDeleteProductConfigurationModal(data) {
        this.setState({ isOpenMultiDeleteProductConfigurationModal: data });
    }

    handleDeleteProductConfiguration() {
        let { selectedItemProductConfigurationOptionSet, sets } = this.state;
        let index = findIndex(sets.response.records, 'id', selectedItemProductConfigurationOptionSet.id);
        this.setState({ deletingProductConfiguration: true });
        ProductConfigurationService.deleteProductConfigurationOptionSet(selectedItemProductConfigurationOptionSet.id).then(response => {
            if (response.status === 200) {
                toast.success("Deleted");
                sets.response.records.splice(index, 1);
                this.setState({
                    sets,
                    deletingProductConfiguration: false,
                    isOpenDeleteProductConfigurationModal: false
                });
            }
        }).catch(error => {
            this.setState({ deletingProductConfiguration: false, isOpenDeleteProductConfigurationModal: false });
            toast.error(handleErrorMessage(error));
        })
    }

    handleMultiDeleteProductConfiguration() {
        let { sets } = this.state;
        let setCommaSeparatedIds = '';
        this.setState({ deletingProductConfiguration: true });
        setCommaSeparatedIds = (sets.response.records || []).filter(x => x.isSelected).map(set => set.id).join(",");
        ProductConfigurationService.deleteMultiProductConfigurationOptionSet(setCommaSeparatedIds).then(response => {
                if (response.status === 200) {
                        this.setState({
                            sets,
                            deletingProductConfiguration: false,
                            isOpenMultiDeleteProductConfigurationModal: false
                        });
                    toast.success("Deleted");
                        this.refreshAgain();
                }
            }).catch(error => {
                this.setState({ deletingProductConfiguration: false });
                toast.error(handleErrorMessage(error));
            })
        
    }

    handleMultipleShallowCopyProductConfiguration() {
        let { sets, loading } = this.state;
        loading.clone = true;
        this.setState({ loading });
        (sets.response.records || []).filter(x => x.isSelected).forEach(set => {
            set.isSelected = false;
            let copiedOption = cloneDeep(set);
            copiedOption.uuid = 'option' + uuidv4();
            copiedOption.id = 0;
            sets.response.records.push(copiedOption);
            if (sets.response.records.every(x => !x.isSelected)) {
                loading.clone = false;
                this.setState({ sets, loading });
            }
        });
    }

    handleMultipleDeepCopyProductConfiguration() {
        let { sets, loading } = this.state;
        let copiedOptions = [];
        loading.clone = true;
        this.setState({ loading });
        (sets.response.records || []).filter(x => x.isSelected).forEach(set => {
            let copiedOption = cloneDeep(set);
            let prodCodesArray = [];
            copiedOption.id = 0;
            (copiedOption.prodCodes || []).map((p, pIndex) => {
                prodCodesArray.push(p);
            });
            copiedOption.prodCodes = prodCodesArray.join();
            copiedOptions.push(copiedOption);
        })
        ProductConfigurationService.saveProductConfigurationOptionSetList(copiedOptions).then(response => {
            if (response.status === 200) {
                loading.clone = false;
                toast.success("Copied!")
                this.setState({ sets, loading });
                this.refreshAgain();
            }
        }).catch(error => {
            loading.clone = false;
            this.setState({ loading });
            console.error(error);
            toast.error(handleErrorMessage(error));
        })
    }

    handleProdCodeData(prodCode) {
        let { sets, workingSet } = this.state;
        let index = findIndex(sets.response.records, 'id', workingSet.id);
        let i = findIndex(sets.response.records[index].stocks, 'prodCode', prodCode);
        if (i > -1) {
            sets.response.records[index].stocks.splice(i, 1);
        }
        this.setState({ sets });
    }

    openImportModal() {
        this.setState({ isOpenImportModal: true });
    }

    toggleImportModal(data, isRefresh) {
        this.setState({ isOpenImportModal: data });
        if (isRefresh) {
            this.getProductConfigurationOptionSets(this.state.sets)
        }
    }

    handleItemAction(key, itemIndex) {
        let { sets } = this.state;

        switch (key) {
            case "clone":
                let copiedSet = cloneDeep(sets.response.records[itemIndex]);
                copiedSet.uuid = 'set' + uuidv4();
                copiedSet.id = 0;
                sets.response.records.push(copiedSet);
                break;
        }

        this.setState({ sets });
    }

    openDefaultStockModal() {
        this.setState({ isOpenDefaultStockModal: true });
    }

    toggleDefaultStockModal(data) {
        this.setState({ isOpenDefaultStockModal: data });
    }

    handleSubmitDefaultStockValue(data) {
        this.setState({ defaultStockValue: data, isOpenDefaultStockModal: false });
    }

    handleSelection(selected) {
        let prodCodes = "";
        if(selected){
             prodCodes = selected.prodCode;
        }
        this.handleChangeRequest(prodCodes, "prodCodes");

    }

    openAddFormulaModal() {
        this.setState({ isOpenAddFormulaModal: true });
    }

    toggleDefaultAddFormulaModal(data) {
        this.setState({ isOpenAddFormulaModal: data });
    }

    exportProductConfigurationOptionSetInExcel() {
        let { loading, configurationOption } = this.state;
        loading.export = true;
        this.setState({ loading });
        ProductConfigurationService.exportProductConfigurationOptionSetInExcel(configurationOption.id).then(response => {
            FileSaver.saveAs(response.data, "ProductConfigurationOptionSetReport.xlsx");
            loading.export = false;
            this.setState({ loading });
        }).catch(error => {
            loading.export = false;
            this.setState({ loading });
            toast.error(handleErrorMessage(error));
        })
    }

    render() {
        let { configurationOption, configuration, loading, sets, isOpenDefaultStockModal, isOpenStockModal, workingSet, canDeleteProductionConfiguration, isOpenDeleteProductConfigurationModal, isOpenImportModal, isOpenMultiDeleteProductConfigurationModal, isOpenAddFormulaModal } = this.state;
        let { filterRequest, pageRequest, sortRequest } = sets.request;
        let isAllSelected = sets.response.records.every(x => x.isSelected);

        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/inventory/products/all/list')}>Products</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push("/inventory/product/configuration?" + queryString.stringify({ productID: configuration.productId }))}>Configuration</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push("/inventory/product/configuration/option?" + queryString.stringify({
                            productID: configuration.productId,
                            configurationId: configuration.id
                        }))}>Option</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Sets</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader>

                        <Row>
                            <Col xl='4' lg='4' md='4' sm='12' xs='12'>
                                <h5>{(loading.configurationOption || loading.configuration) ? <Spinner
                                    color="primary" /> : configuration.attributeLabel + " | " + configurationOption.optionLabel}</h5>
                            </Col>

                            <Col xl='8' lg='8' md='8' sm='12' xs='12'>
                                <div className='text-right'>
                                    <Button color={"primary"} size={"sm"} className='mr-2'
                                        onClick={() => this.openAddFormulaModal()}>
                                        <i class="fa fa-calculator mr-2" aria-hidden="true" />
                                        Add Formulas
                                    </Button>
                                    <Button color={"primary"} size={"sm"}
                                        onClick={() => this.openDefaultStockModal()}>
                                        Default Stock Configurations
                                    </Button>
                                    <Button color={"primary"} size={"sm"} className='ml-2'
                                        onClick={() => this.exportProductConfigurationOptionSetInExcel()} disabled={loading.export}>
                                        {
                                            loading.export ?
                                                <Spinner size="sm"
                                                    className={"mr-2"}
                                                    color={"light"} /> :
                                                <i className="fa fa-file-excel-o mr-2" aria-hidden="true" />
                                        }Export
                                    </Button>
                                    <Button className='ml-2' color={"primary"} size={"sm"}
                                        onClick={() => this.openImportModal()}>
                                        <i className="fa fa-upload mr-2" aria-hidden="true" />Import
                                    </Button>
                                </div>
                            </Col>
                        </Row>


                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading.sets ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {sets.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    {sets.response.records.some(x => x.isSelected) ?
                                        <>
                                            <Button color={"danger"} size={"sm"} className='mr-2'
                                                onClick={() => this.openMultiDeleteConfirmationModal()}>
                                                <i className="fa fa-trash mr-2" aria-hidden="true" />Delete
                                        </Button>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle caret color={"secondary"}
                                                    size={"sm"}
                                                    disabled={loading.clone}>
                                                    {
                                                        loading.clone ?
                                                            <Spinner size="sm"
                                                                className={"mr-2"}
                                                                color={"light"} /> :
                                                            <i className="fa fa-clone fa-lg mr-2" aria-hidden="true" />
                                                    }
                                                    Copy
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem
                                                        onClick={() => this.handleMultipleShallowCopyProductConfiguration()}>
                                                        <i className="fa fa-clone fa-lg mr-2" aria-hidden="true" /> Shallow
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => this.handleMultipleDeepCopyProductConfiguration()}>
                                                        <i className="fa fa-clone fa-lg mr-2" aria-hidden="true" />Deep
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </> : null}
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <Table responsive={true} striped bordered>
                                <thead>

                                    <tr>
                                        <th className={"text-center"}>Select All</th>
                                        <th>S.No</th>
                                        <th style={{ minWidth: '100px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("setNum", "sortKey")}
                                            className={'hoverableItem align-middle'}>Set num
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "setNum"),
                                                "fa-sort-amount-asc": (sortRequest.key === "setNum" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "setNum" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '200px', cursor: 'pointer', maxWidth: '450px' }}
                                            onClick={() => this.handleChangeRequest("setLabel", "sortKey")}
                                            className={'hoverableItem align-middle'}>Set-name
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "setLabel"),
                                                "fa-sort-amount-asc": (sortRequest.key === "setLabel" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "setLabel" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                            <small>
                                                <div>It will be displayed as label</div>
                                            </small>
                                        </th>
                                        <th style={{ minWidth: '200px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("setKey", "sortKey")}
                                            className={'hoverableItem align-middle'}>Set-value
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "setKey"),
                                                "fa-sort-amount-asc": (sortRequest.key === "setKey" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "setKey" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                            <small>
                                                <div>It should be exact final value</div>
                                            </small>
                                        </th>
                                        <th style={{ minWidth: '200px' }}>Parent set
                                            <small>
                                                <div>Set-name</div>
                                            </small>
                                        </th>
                                        <th style={{ minWidth: '200px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("description", "sortKey")}
                                            className={'hoverableItem align-middle'}>Description
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "description"),
                                                "fa-sort-amount-asc": (sortRequest.key === "description" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "description" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '150px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("attribute", "sortKey")}
                                            className={'hoverableItem align-middle'}>
                                            Attribute
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "attribute"),
                                                "fa-sort-amount-asc": (sortRequest.key === "attribute" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "attribute" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '125px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("min", "sortKey")}
                                            className={'hoverableItem align-middle'}>Min
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "min"),
                                                "fa-sort-amount-asc": (sortRequest.key === "min" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "min" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '125px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("max", "sortKey")}
                                            className={'hoverableItem align-middle'}>Max
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "max"),
                                                "fa-sort-amount-asc": (sortRequest.key === "max" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "max" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '150px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("secondaryAttribute", "sortKey")}
                                            className={'hoverableItem align-middle'}>Sec-Attribute
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "secondaryAttribute"),
                                                "fa-sort-amount-asc": (sortRequest.key === "secondaryAttribute" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "secondaryAttribute" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '125px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("secondaryAttributeMin", "sortKey")}
                                            className={'hoverableItem align-middle'}>Sec-Min
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "secondaryAttributeMin"),
                                                "fa-sort-amount-asc": (sortRequest.key === "secondaryAttributeMin" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "secondaryAttributeMin" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '125px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("secondaryAttributeMax", "sortKey")}
                                            className={'hoverableItem align-middle'}>Sec-Max
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "secondaryAttributeMax"),
                                                "fa-sort-amount-asc": (sortRequest.key === "secondaryAttributeMax" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "secondaryAttributeMax" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '300px' }}>Prod-code</th>
                                        <th style={{ minWidth: '200px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("condition1", "sortKey")}
                                            className={'hoverableItem align-middle'}>Condition1
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "condition1"),
                                                "fa-sort-amount-asc": (sortRequest.key === "condition1" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "condition1" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '200px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("condition2", "sortKey")}
                                            className={'hoverableItem align-middle'}>Condition2
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "condition2"),
                                                "fa-sort-amount-asc": (sortRequest.key === "condition2" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "condition2" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '200px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("condition3", "sortKey")}
                                            className={'hoverableItem align-middle'}>Condition3
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "condition3"),
                                                "fa-sort-amount-asc": (sortRequest.key === "condition3" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "condition3" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '250px' }} className={"text-center"}>Action</th>
                                    </tr>
                                    <tr>
                                        <td><a href={"javascript:void(0)"} className="ml-3"
                                            onClick={() => this.handleChange(!isAllSelected, "isAllSelected")}>
                                            <i className={classnames("mt-2", "fa", "fa-lg", {
                                                "fa-check-square-o": isAllSelected,
                                                "fa-square-o": !isAllSelected,
                                            }
                                            )} />
                                        </a></td>
                                        <td />
                                        <td />
                                        <td>
                                            <Input type='text' name='setLabel'
                                                value={filterRequest.setLabel}
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td>
                                            <Input type='text' name='setKey'
                                                value={filterRequest.setKey}
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td />
                                        <td>
                                            <Input type='text' name='description'
                                                value={filterRequest.description}
                                                placeholder='description'
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td>
                                            <Input type='text' name='attribute'
                                                value={filterRequest.attribute}
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td>
                                            <Input type="text" name="min"
                                                placeholder="Search"
                                                value={filterRequest.min || ''}

                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td>
                                            <Input type="text" name="max"
                                                placeholder="Search"
                                                value={filterRequest.max || ''}
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td>
                                            <Input type='text' name='secondaryAttribute'
                                                placeholder='Search'
                                                value={filterRequest.secondaryAttribute}
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td>
                                            <Input type="text" name="secondaryMin"
                                                placeholder="Search"
                                                value={filterRequest.secondaryMin || ''}
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>

                                        <td>
                                            <Input type="text" name="secondaryMax"
                                                placeholder="Search"
                                                value={filterRequest.secondaryMax || ''}
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>

                                        <td>
                                            <StockSearch
                                                disabled={false}
                                                handleSelection={this.handleSelection}
                                                selected={filterRequest.prodCodes}
                                                filter={{ bmFlag: null, discontinued: null, soldOut: null, stockGroups: []}}
                                            />
                                        </td>
                                        <td>
                                            <Input type='text' name='condition1'
                                                value={filterRequest.condition1}
                                                placeholder='condition1'
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td>
                                            <Input type='text' name='condition2'
                                                value={filterRequest.condition2}
                                                placeholder='condition2'
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td>
                                            <Input type='text' name='condition3'
                                                value={filterRequest.condition3}
                                                placeholder='condition3'
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(sets.response.records || []).map((item, index) => {
                                        return <tr key={index}>
                                            <td className={"text-center"}>
                                                {
                                                    item.id ?
                                                        <a href={"javascript:void(0)"}
                                                            onClick={() => this.handleChange(!item.isSelected, "isSelected", index)}>
                                                            <i className={classnames("mt-2", "fa", "fa-lg", {
                                                                "fa-check-square-o": item.isSelected,
                                                                "fa-square-o": !item.isSelected,
                                                            }
                                                            )} />
                                                        </a>
                                                        : null
                                                }
                                            </td>
                                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            <td>
                                                <Input
                                                    type={"number"}
                                                    style={{ height: 38 }}
                                                    name={"setNum"}
                                                    placeholder={"write set num"}
                                                    value={item.setNum}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    style={item.setLabel ? { minWidth: '350px' } : {}}
                                                    type={"text"}
                                                    name={"setLabel"}
                                                    placeholder={"write set name"}
                                                    value={item.setLabel}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"text"}
                                                    name={"setKey"}
                                                    placeholder={"write set value"}
                                                    value={item.setKey}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input type={"select"}
                                                    name={"parentSetId"}
                                                    value={item.parentSetId}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                >
                                                    <option value={""}>Select</option>
                                                    {(sets.response.records || [])
                                                        .filter(set => (set.id && (set.id !== item.id)))
                                                        .map(set => {
                                                            return <option key={set.id}
                                                                value={set.id}>{set.setLabel}</option>
                                                        }
                                                        )
                                                    }
                                                </Input>
                                            </td>
                                            <td>
                                                <Input
                                                    type={"textarea"}
                                                    name={"description"}
                                                    placeholder={"write description"}
                                                    value={item.description}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"text"}
                                                    name={"attribute"}
                                                    placeholder={"write attribute"}
                                                    value={item.attribute}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"number"}
                                                    name={"min"}
                                                    placeholder={"write min value"}
                                                    value={item.min}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"number"}
                                                    name={"max"}
                                                    placeholder={"write max value"}
                                                    value={item.max}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"text"}
                                                    name={"secondaryAttribute"}
                                                    placeholder={"write secondary attribute"}
                                                    value={item.secondaryAttribute}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"number"}
                                                    name={"secondaryMin"}
                                                    placeholder={"write secondary min value"}
                                                    value={item.secondaryMin}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"number"}
                                                    name={"secondaryMax"}
                                                    placeholder={"write secondary max value"}
                                                    value={item.secondaryMax}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                {item.id
                                                    ? <div>
                                                        {
                                                            (item.stocks || []).map((stock, pIndex) => {
                                                                return <Button key={pIndex}
                                                                    color="primary"
                                                                    size={"sm"}
                                                                    className={"m-1"}
                                                                    outline={true}
                                                                    onClick={() => this.handleChange(stock, 'productConfigurationSetStocks', index)}
                                                                >{stock.prodCode}&nbsp;&nbsp;
                                                                    <i className="fa fa-times"
                                                                        aria-hidden="true" />
                                                                </Button>
                                                            }
                                                            )
                                                        }
                                                        <Button
                                                            color="link"
                                                            size={"sm"}
                                                            className={"m-1"}
                                                            onClick={() => this.handleChange(true, "productConfigurationSetStocks_isOpen", index)}
                                                        ><i className="fa fa-plus mr-2" aria-hidden="true" />Add
                                                        </Button>
                                                    </div>
                                                    : null
                                                }
                                            </td>

                                            <td>
                                                <Input
                                                    type={"textarea"}
                                                    name={"condition1"}
                                                    placeholder={"write condition1"}
                                                    value={item.condition1}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"textarea"}
                                                    name={"condition2"}
                                                    placeholder={"write condition2"}
                                                    value={item.condition2}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"textarea"}
                                                    name={"condition3"}
                                                    placeholder={"write condition3"}
                                                    value={item.condition3}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td className={"text-center"}>
                                                <Button color={"primary"}
                                                    size={"sm"}
                                                    className={"mr-2"}
                                                    disabled={loading.save === index}
                                                    onClick={() => this.saveProductConfigurationOptionSet(item, index)}>
                                                    <i className="fa fa-floppy-o mr-2"
                                                        aria-hidden="true" />{loading.save === index ?
                                                            <Spinner size="sm"
                                                                style={{ color: "white" }} /> : (item.id ? "Update" : "Save")}
                                                </Button>
                                                <Button color={"primary"}
                                                    className={"mr-2"}
                                                    size={"sm"}
                                                    title={"click here to copy item"}
                                                    onClick={() => this.handleItemAction("clone", index)}>
                                                    <i className="fa fa-clone fa-lg"
                                                        aria-hidden="true" />
                                                </Button>

                                                {(canDeleteProductionConfiguration) ? <Button color='danger'
                                                    size={"sm"}
                                                    onClick={() => this.openDeleteConfirmationModal(item)}><i
                                                        className='fa fa-trash' /></Button>
                                                    : null}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                            <Button onClick={this.addNewItem}>+ Add</Button>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading.sets}
                                            onChange={(e) => this.handleChangeRequest(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={sets.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChangeRequest(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>

                {
                    isOpenStockModal
                        ? <ProductConfigurationStockModal
                            isOpen={isOpenStockModal}
                            toggle={(change) => this.handleChange(change, "productConfigurationSetStocks_toggle", null)}
                            header={workingSet.setNum}
                            items={workingSet.stocks}
                            productConfigurationOptionSetId={workingSet.id}
                            type={'set'}
                            handleRefresh={this.refreshAgain}
                            maxStocksAllowed={50}
                            canDeleteProductionConfiguration={this.state.canDeleteProductionConfiguration}
                            handlerData={this.handleProdCodeData}
                            defaultItem={this.state.defaultStockValue}
                        />
                        : null

                }


                {isOpenDeleteProductConfigurationModal ?
                    <DeleteModal
                        header={'Are your sure you want to delete product configuration option set'}
                        isOpen={isOpenDeleteProductConfigurationModal}
                        toggle={this.toggleDeleteProductConfigurationModal}
                        handleDelete={this.handleDeleteProductConfiguration}
                        deleting={this.state.deletingProductConfiguration}
                    /> : null}
                {isOpenMultiDeleteProductConfigurationModal ?
                    <DeleteModal
                        header={'Are your sure you want to delete selected product configuration option set'}
                        isOpen={isOpenMultiDeleteProductConfigurationModal}
                        toggle={this.toggleMultiDeleteProductConfigurationModal}
                        handleDelete={this.handleMultiDeleteProductConfiguration}
                        deleting={this.state.deletingProductConfiguration}
                    /> : null}
                {isOpenImportModal ?
                    <ProductConfigurationOptionSetImportModal
                        isOpen={isOpenImportModal}
                        toggle={this.toggleImportModal}
                        configurationOptionId={configurationOption.id}
                    /> : null}

                <ProductConfigurationStockDefaultValuesModal
                    isOpen={isOpenDefaultStockModal}
                    toggle={this.toggleDefaultStockModal}
                    handleStockDefaultValue={this.handleSubmitDefaultStockValue}
                />
                { isOpenAddFormulaModal ?
                    <ManageProductConfigurationStockFormulaModal
                        isOpen={isOpenAddFormulaModal}
                        toggle={this.toggleDefaultAddFormulaModal}
                        refreshAgain={this.refreshAgain}
                    /> : null}
                <ToastContainer />
            </div>
        );
    }
}