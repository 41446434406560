import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Table, Button, Spinner } from "reactstrap";
import { toast, ToastContainer } from 'react-toastify';
import NumberFormat from "react-number-format";
import { handleErrorMessage } from '../../services/CommonService';
import dashboardService from '../../services/DashboardService';
import classnames from 'classnames';
import * as FileSaver from 'file-saver';
import SalesOrderService from '../../services/sales/SalesOrderService'

export default class CTSProductBuilderOrdersEnteredBy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            stats: {},
            exportLoading: false
        };
        this.salesOrderService = new SalesOrderService();
        this.generatePBOrderEnteredByExcelExport = this.generatePBOrderEnteredByExcelExport.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true });
        dashboardService.getProductBuilderOrderEnteredBy().then(response => {
            let stats = response.data;
            stats.orderPercentage = stats.orderCreatedBybunningStoreCount / (stats.orderCreatedByStaffCount + stats.orderCreatedBybunningStoreCount) * 100;
            this.setState({ loading: false, stats });
        }).catch(error => {
            toast.error(handleErrorMessage(error));
            this.setState({ loading: false })
        });
    }

    generatePBOrderEnteredByExcelExport() {
        this.setState({ exportLoading: true });
        dashboardService.generatePBOrderEnteredBy().then(response => {
            FileSaver.saveAs(response.data, "Product Builder Order Entered By Report.xlsx");
            this.setState({ exportLoading: false });
        }).catch(error => {
            this.setState({ exportLoading: false });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        })
    }

    render() {

        const { stats, loading, exportLoading } = this.state;

        if (loading) {
            return null;
        }

        return (
            <Col xl={4} lg={5} md={7} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>PB Orders Entered By</h6>
                    </CardHeader>
                    <CardBody>
                        <Table size={"sm"} className={"mb-0"} responsive>
                            <caption>

                                <span className={"float-right"}>Orders in last 3 months
                                    <Button size={"sm"} color={"success"} onClick={this.generatePBOrderEnteredByExcelExport}
                                        className={"ml-2"}>
                                        {exportLoading ? <Spinner color={"light"} size={"sm"} className={"mr-2"} />
                                            : <i className={"fa fa-file-excel-o mr-2"} />
                                        }
                                        Export</Button>
                                </span></caption>
                            <tbody>
                                <tr>
                                    <td>Staff</td>
                                    <td className={"text-right"}>
                                        <h5>
                                            <NumberFormat
                                                value={stats.orderCreatedByStaffCount}
                                                displayType={'text'}
                                                thousandSeparator={true} />
                                        </h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Customer</td>
                                    <td className={"text-right"}>
                                        <h5>
                                            <NumberFormat
                                                className={classnames("mr-2", {
                                                    "text-success": stats.orderPercentage >= 90,
                                                    "text-danger": stats.orderPercentage < 90,
                                                })}
                                                value={stats.orderPercentage}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                decimalScale={0}
                                                prefix={'('}
                                                suffix={'%)'}
                                            />
                                            <NumberFormat
                                                value={stats.orderCreatedBybunningStoreCount}
                                                displayType={'text'}
                                                thousandSeparator={true} />
                                        </h5>
                                    </td>
                                </tr>
                                <tr className="bg-light">
                                    <td>Total</td>
                                    <td className={"text-right"}>
                                        <h5>
                                            <NumberFormat
                                                value={stats.orderCreatedByStaffCount + stats.orderCreatedBybunningStoreCount}
                                                displayType={'text'}
                                                thousandSeparator={true} />
                                        </h5>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <ToastContainer />
            </Col>
        );
    }
}