import React, { Component } from 'react';
import UploadFiles from '../../../attachment/UploadFiles';
import { Link } from 'react-router-dom';

export default class OrderForm extends Component {

    render() {
        const { order, handleOrderChange, isExtUser } = this.props;
        return(
        <div>
            <p className={"mb-1"}>Go to
                <strong className={"ml-1 mr-1"}>
                    <Link
                        style={{ textDecoration: "none" }}
                        to={isExtUser?"/product/catalogues": "/manage/product/catalogues?activeTab=productOrderForms"}
                    >Products
                    </Link>
                </strong>
               tab to access and download Order Forms.
            </p>
            <p className={"mb-0"}>Multiple products can be ordered on a single order.</p>

            <br />

            <UploadFiles
                handleChange={(change) => handleOrderChange("attachmentIDs", change)}
                attachmentIDs={order.attachmentIDs} />

        </div>
        );

    }
}