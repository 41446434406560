import React, {Component} from 'react';
import CustomerService from "../../services/CustomerService";
import AsyncSelect from 'react-select/async';
import cloneDeep from 'lodash/cloneDeep';


export default class SearchDebtorAcccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: {},
            defaultAccountID: props.defaultAccountID,
            accountID: props.selectedAccountID,
            loading: false,
            allAccounts: [],
            inputValue: "",
            excludeClosedandInactive: props.excludeClosedandInactive,
            includeChildren: props.includeChildren
        };

        this.customerService = new CustomerService();
        this.loadOptions = this.loadOptions.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

    }


    componentDidMount() {
        //defaultOptions on load
        this.fetchOptions(this.state.defaultAccountID || this.state.accountID);
    }

    componentWillReceiveProps(nextProps) {

        if (this.state.defaultAccountID !== nextProps.defaultAccountID) {
            if (nextProps.defaultAccountID) {
                this.setState({defaultAccountID: nextProps.defaultAccountID}, () => {
                    this.fetchOptions(nextProps.defaultAccountID);
                })
            }

        }

        if (this.state.accountID !== nextProps.selectedAccountID) {
            if (!nextProps.selectedAccountID) {
                this.setState({selected: null})
            }
            else {
                this.setState({accountID: nextProps.selectedAccountID})
            }
            //load externally selectedItem
            this.fetchOptions(nextProps.selectedAccountID);
        }

        if (
            this.state.includeChildren !== nextProps.includeChildren
            || this.state.includeClosed !== nextProps.includeClosed
            || this.state.includeInactive !== nextProps.includeInactive
            || this.state.parentAccountIDs !== nextProps.parentAccountIDs
        ) {
            this.setState({ includeChildren: nextProps.includeChildren, includeClosed: nextProps.includeClosed, includeInactive: nextProps.includeInactive, parentAccountIDs:nextProps.parentAccountIDs }
                , () => { this.fetchOptions(this.state.defaultAccountID) });
        }
    }


    handleSelect(selectedAccount, event) {

        let {selected, accountID} = this.state;
        selected = selectedAccount;
        accountID = selectedAccount ? selectedAccount.accountID : "";

        switch (event) {
            case "selected":
                this.setState({selected, accountID});
                break;
            case "onChange":
            case "default":
            default:
                this.setState({selected, accountID});
                this.props.handleAccountChange(accountID);
        }

        if (!accountID) {
            this.fetchOptions("");
        }

    };


    fetchOptions(inputValue, callback) {
        let allAccounts = [];
        let requestBody = {
            accountID: inputValue,
            includeChildren: !!this.props.includeChildren,
            includeClosed: !!this.props.includeClosed,
            includeInactive: !!this.props.includeInactive,
            parentAccountIDs:this.props.parentAccountIDs
        };

      
        this.customerService.searchDebtorAccountCompany(requestBody).then(response => {
            let data = response.data;
          
            for (let i in data) {
                let account = {
                    label: i,
                    value: data[i].accountID,
                    id: data[i].accountID,
                    accountID: data[i].accountID,
                    storeCode: data[i].storeCode
                };
                allAccounts.push(cloneDeep(account));
                if (this.props.defaultAccountID) {
                    if (data[i].accountID === this.props.defaultAccountID) {
                        this.handleSelect(account, "default");
                    }
                }
                if (this.props.selectedAccountID) {
                    if (data[i].accountID === this.props.selectedAccountID) {
                        this.handleSelect(account, "selected");
                    }
                }
            }
            this.setState({allAccounts});
            if (callback) {
                return callback(allAccounts)
            } else {
                return allAccounts;
            }
        }).catch(error => {
            console.log(error)
        });
    }

    loadOptions(inputValue, callback) {
        if (inputValue && callback) {
            if (inputValue && inputValue.length > 2) {
                return this.fetchOptions(inputValue, callback)
            } else {
                callback(this.state.allAccounts);
            }
        }
    };

    render() {

        return (
            <div>
                <AsyncSelect
                    cacheOptions
                    isLoading={this.state.loading}
                    isClearable={true}
                    defaultOptions={this.state.allAccounts}
                    loadOptions={this.loadOptions}
                    onChange={(selection) => this.handleSelect(selection, "onChange")}
                    value={this.state.selected}
                    isDisabled={this.props.disabled}
                />
            </div>
        );
    }
}
