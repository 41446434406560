import axios from './axios';

export default class PrivilegeService {
    getPrivilegeList(request) {
        return axios.post("api/privilege/list", request);
    }

    updatePrivilegeData(request) {
        return axios.post("api/privilege/update", request);
    }

    getPrivilege() {
        return axios.get("api/privilege/list");
    }
}