import React, {Component} from 'react';

import {Button, Modal, ModalBody, ModalHeader, Spinner,} from 'reactstrap';
import StockService from '../../../../../../services/StockService'
import {findIndex, getDistinctValues, groupBy, handleErrorMessage} from "../../../../../../services/CommonService";
import ReactToPrint from "react-to-print";
import {toast, ToastContainer} from "react-toastify";
import BistroConsolidatedBOMModalPrint from "./BistroConsolidatedBOMModalPrint";


export default class RollerBlindConsolidatedBOMModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            finalParts: []
        };
        this.stockService = new StockService();
    }

    componentDidMount() {
        let {product} = this.props;

        let parts = [];


        //extract all stocks
        product.items.forEach((item, itemIndex) => {
            product.items[itemIndex].stocks.forEach((stock, stockIndex) => {
                parts.push({
                    ...stock,
                    blindQty: product.items[itemIndex].configuration.quantity.selected.value
                });
            });
        });

        parts = parts.filter(x => x.isVisibleInPartList);

        let finalParts = [];
        let partsByProdCode = groupBy(parts, 'prodCode');
        let finalPartsIndex = -1;
        if (partsByProdCode) {
            for (let prodCode in partsByProdCode) {
                partsByProdCode[prodCode].forEach((part, partIndex) => {
                    //consolidated with qty
                    part.consolidatedSwishQty = part.swishCalculatedQty * part.blindQty;

                    finalPartsIndex = findIndex(finalParts, "prodCode", part.prodCode);
                    if (finalPartsIndex > -1) {
                        if (this.checkIfPartCanBeMerged(finalParts[finalPartsIndex], part)) {
                            finalParts[finalPartsIndex].consolidatedSwishQty += part.consolidatedSwishQty;
                        } else {
                            finalParts.push(part);
                        }
                    } else {
                        finalParts.push(part);
                    }
                });
            }
        }

        let prodCodes = getDistinctValues(finalParts, 'prodCode');

        this.setState({finalParts, isLoadingStocks: true});
        this.stockService.getStocksByProdCode(prodCodes.join()).then(response => {
            finalParts = this.state.finalParts;
            let stocksByProdCode = response.data;
            finalParts.forEach((part, partIndex) => {
                part.stockDescription = stocksByProdCode[part.prodCode] ? stocksByProdCode[part.prodCode].description : "";
            });
            this.setState({finalParts, isLoadingStocks: false});
        }).catch(error => {
            this.setState({isLoadingStocks: false});
            toast(handleErrorMessage(error))
        })
    }

    checkIfPartCanBeMerged = function (x, y) {
        return (x.swishMeasurementUnit === y.swishMeasurementUnit);
    };


    render() {
        let {isLoadingStocks, finalParts} = this.state;
        let {isOpen, toggle, order} = this.props;

        return (
            <Modal isOpen={isOpen} size="xl" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                    <ReactToPrint
                        trigger={() => {
                            return <Button color={"primary"} size={"sm"}>
                                <i className="fa fa-print mr-2"
                                   aria-hidden="true"/>Print
                            </Button>;
                        }}
                        content={() => this.componentRef}
                    />
                </ModalHeader>
                <ModalBody>
                    {
                        isLoadingStocks
                            ? <Spinner color={"primary"}/>
                            : <div>
                                <BistroConsolidatedBOMModalPrint parts={finalParts}
                                                                  order={order}
                                                                  ref={el => (this.componentRef = el)}/>
                            </div>
                    }
                </ModalBody>
                <ToastContainer/>
            </Modal>
        )
    }
}