import axiosFileDownloader from './axiosFileDownloader';
import axiosFileUploader from './axiosFileUploader';
import axios from './axios';

export default class AttachmentService {

    getAttachments(Ids) {
        return axios.post("api/attachment/list?attachmentIDs=" + Ids);
    }

    deleteAttachment(ID) {
        return axios.delete("api/attachment/delete?attachmentID=" + ID);
    }

    uploadAttachments(requestBody, onUploadProgress) {
        let config = {
            onUploadProgress:onUploadProgress
        };
        return axiosFileUploader.post("api/attachment/upload", requestBody, config);
    }

    downloadAttachment(id, options) {
        if (options && options.onDownloadProgress) {
            return axiosFileDownloader.get('api/file/Generate?AttachmentID=' + id, options);
        } else {
            return axiosFileDownloader.get('api/file/Generate?AttachmentID=' + id);
        }
    }
}