import axios from './axios';
import axiosFileDownloader from './axiosFileDownloader';


export default class CustomerService {

    searchDebtorAccountCompany(requestBody) {
        return axios.post('api/customer/account/company', requestBody);
    }

    getCustomerDetailList(request) {
        return axios.post('api/customer/list', request);
    }

    getCompanyName(accoundID) {
        return axios.get('api/customer/account/name?accoundId=' + accoundID);
    }

    searchFirstDebtorAccountCompany(request) {
        return axios.post('api/customer/account/company/first', request);
    }

    searchCustomer(accountID) {
        return axios.get('api/customer/details?accountID=' + accountID);
    }

    getDebtorAccountStatus() {
        return axios.get('api/customer/getDebtorAccountStatus');
    }

    updateDebtor(req) {
        return axios.post('api/customer/update', req);
    }

    getCustomer_recentStatements(requestBody) {
        return axios.post('api/customer/RecentStatements/Customer', requestBody);
    }

    getDebtorCategory() {
        return axios.get('api/customer/getDebtorCategory');
    }

    getDebtorClassification() {
        return axios.get('api/customer/getDebtorClassification');
    }

    getCustomerInformation(id) {
        return axios.get('api/customer/getCustomerInformation?AccountID=' + id);
    }

    getCustomerTrading_salesInformation(id) {
        return axios.get('api/customer/getCustomerTradingandSales?AccountID=' + id);
    }

    getDebtorPaymentTerms() {
        return axios.get('api/customer/getDebtorPaymentTerms');
    }

    getDebtorCreditTerms() {
        return axios.get('api/customer/getDebtorCreditTerms');
    }

    downloadTransactionStatement(reqeustBody, options) {
        
        if (options && options.onDownloadProgress) {
            return axiosFileDownloader.post('api/customer/transaction/statement/download', reqeustBody, options);
        } else {
            return axiosFileDownloader.post('api/customer/transaction/statement/download', reqeustBody);
        }
    }

    GenerateDebtorStatementInExcelReport(id, yearmonth) {
    return axiosFileDownloader.post('api/customer/statement/export/excel/download?AccountID=' + id + '&YearMonth=' + yearmonth);
    }

    getDiscount(accountID) {
        return axios.get('api/customer/discount/product/all/self?accountID=' + accountID);
    }

    getDebtorStatementEmails(accountID) {
        return axios.get('api/customer/statement/email?AccountID=' + accountID);
    }
}