import React, {Component} from 'react';
import {
    Row, Col,
    Card, CardBody, CardFooter,
    Input, InputGroup, InputGroupAddon, InputGroupText,
    Modal,
    ModalBody,
    ModalHeader,
    Spinner, Button, ModalFooter
} from 'reactstrap';
import {isEmpty} from 'lodash';
import {toast, ToastContainer} from 'react-toastify';
import addressService from '../../services/AddressService';
import {
    getCommonAddressString, getSorted,
    handleErrorMessage
} from '../../services/CommonService';
import DeliveryAddressCreateModal from "../modal/DeliveryAddressCreateModal";

export default class DebtorAddressModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accountID: "",
            addresses: [],
            searchText: "",
            loadingAddresses: false,
            isOpenDeliveryAddressCreateModal: false,
            selectedAddress: {},
            addressForm: {}
        };
        this.getDebtorAddresses = this.getDebtorAddresses.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleAddressFormModal = this.toggleAddressFormModal.bind(this);
        this.handleAction = this.handleAction.bind(this);
    }

    componentDidMount() {
        this.setState({accountID: this.props.accountID, selectedAddress: this.props.selected}, () => {
            this.getDebtorAddresses();
        });
    }

    getDebtorAddresses() {
        if (!this.state.accountID) {
            return;
        }
        let {addressForm, selectedAddress} = this.state;
        this.setState({loadingAddresses: true});
        addressService.getDebtorAddresses(this.state.accountID).then(response => {
            let addresses = response.data;
            if (addresses) {
                addresses = getSorted(addresses, 'addressID', false);
            }

            let selectedAddressIndex = addresses.findIndex(address => this.isSelected(selectedAddress, address));
            if (selectedAddressIndex > -1) {
                addressForm = addresses[selectedAddressIndex];
                addresses.splice(selectedAddressIndex, 1);
            } else {
                addressForm = selectedAddress;
            }
            addresses.splice(0, 0, addressForm);
            this.setState({addresses, loadingAddresses: false});
        }).catch(error => {
            this.setState({loadingAddresses: true});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.TOP_CENTER
            });
            console.log(error);
        });
    }

    handleChange(value, key) {
        this.setState({[key]: value});
    }


    handleAction(key, change) {
        switch (key) {
            case "deliveryAddress-select":
                this.setState({selectedAddress: change});
                break;
            case "deliveryAddress-add":
                let addressForm = {
                    debtorAccountID: this.state.accountID,
                    company: '',
                    contactName: '',
                    phone: '',
                    fax: '',
                    email: '',
                    address1: '',
                    address2: '',
                    address3: '',
                    address4: '',
                    city: '',
                    state: '',
                    postCode: '',
                    area_code: '',
                    country: 'AUSTRALIA',
                };
                this.setState({isOpenDeliveryAddressCreateModal: true, addressForm});
                break;
            case "deliveryAddress-modify":
                this.setState({
                    isOpenDeliveryAddressCreateModal: true,
                    addressForm: {...change, debtorAccountID: this.state.accountID}
                });
                break;
            case "deliveryAddress-created":
                this.setState({isOpenDeliveryAddressCreateModal: false, selectedAddress: change}, () => {
                    this.props.handleChange(change);
                });

                break;
        }
    }


    toggleAddressFormModal(isOpen) {
        this.setState({
            isOpenDeliveryAddressCreateModal: isOpen
        });
    }


    searchFunction(f, searchText) {
        let flag = true;
        if (searchText) {
            flag = f.contactName.toLowerCase().includes(searchText.toLowerCase());

            if (!flag && f.company && searchText) {
                flag = f.company.toLowerCase().includes(searchText.toLowerCase())
            }
            if (!flag && f.phone && searchText) {
                flag = f.phone.toLowerCase().includes(searchText.toLowerCase())
            }
            if (!flag && f.fax && searchText) {
                flag = f.fax.toLowerCase().includes(searchText.toLowerCase())
            }
            if (!flag && f.email && searchText) {
                flag = f.email.toLowerCase().includes(searchText.toLowerCase())
            }
            if (!flag && f.address1 && searchText) {
                flag = f.address1.toLowerCase().includes(searchText.toLowerCase())
            }
            if (!flag && f.address2 && searchText) {
                flag = f.address2.toLowerCase().includes(searchText.toLowerCase())
            }
            if (!flag && f.address3 && searchText) {
                flag = f.address3.toLowerCase().includes(searchText.toLowerCase())
            }
            if (!flag && f.address4 && searchText) {
                flag = f.address4.toLowerCase().includes(searchText.toLowerCase())
            }
            if (!flag && f.city && searchText) {
                flag = f.city.toLowerCase().includes(searchText.toLowerCase())
            }
            if (!flag && f.state && searchText) {
                flag = f.state.toLowerCase().includes(searchText.toLowerCase())
            }
            if (!flag && f.postCode && searchText) {
                flag = f.postCode.toLowerCase().includes(searchText.toLowerCase())
            }
        }
        return flag;
    }

    isSelected(selected, item) {
        return (
            selected.company === item.company
            && selected.contactName === item.contactName
            && selected.phone === item.phone
            && selected.email === item.email
            && selected.fax === item.fax
            && selected.address1 === item.address1
            && selected.address2 === item.address2
            && selected.address3 === item.address3
            && selected.address4 === item.address4
            && selected.city === item.city
            && selected.state === item.state
            && selected.postCode === item.postCode
        )
    }

    render() {
        let {addresses, loadingAddresses, searchText, selectedAddress, addressForm} = this.state;
        let {isOpen, toggleModel, handleChange} = this.props;


        return (<Modal isOpen={isOpen}
                       size="xl"
                       scrollable={false}
                       toggle={() => toggleModel(!isOpen)}>
                <ModalHeader toggle={() => toggleModel(!isOpen)} cssModule={{'modal-title': 'w-100 text-left mb-0'}}>
                    Select an address for delivery
                    {/*<Button color="primary" size={"sm"} className="float-right"*/}
                    {/*        onClick={() => this.handleAction("deliveryAddress-add", {})}><i*/}
                    {/*    className="fa fa-plus mr-2" aria-hidden="true"/> Create new address</Button>*/}
                </ModalHeader>
                <ModalBody>
                    {loadingAddresses
                        ? <Spinner color={"primary"}/>
                        : <div>
                            {isEmpty(addresses)
                                ? <div className="text-center">
                                    <h5 className="text-danger"><i className="fa c mr-2" aria-hidden="true"/>
                                        Oops! You have not saved any addresses yet.</h5>
                                </div>
                                : <div>
                                    <div className={"text-right"}>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-search"
                                                       aria-hidden="true"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type={"text"}
                                                name={"searchText"}
                                                placeholder={"Search..."}
                                                value={searchText}
                                                onChange={(e) => this.handleChange(e.target.value, e.target.name)}
                                            />
                                        </InputGroup>
                                    </div>
                                    <Row>
                                        {
                                            (addresses || []).filter((item) => this.searchFunction(item, searchText)).map((address, index) =>
                                                <Col key={index} xl={4} lg={4} md={6} sm={12} xs={12} className={"p-2"}>
                                                    <Card style={{minHeight: 235}}>
                                                        <CardBody title={"Click here to select this address"}>
                                                            <div>
                                                                {
                                                                    address.contactName
                                                                        ? <p className="mb-0">
                                                                            <i className="text-muted fa fa-fw fa-user mr-2"
                                                                               aria-hidden="true"/>
                                                                            {address.contactName}
                                                                        </p>
                                                                        : null
                                                                }
                                                                {address.phone
                                                                    ? <div>
                                                                        <a href={"tel:" + address.phone}>
                                                                            <i className="text-muted fa fa-fw fa-phone mr-2"
                                                                               aria-hidden="true"/>
                                                                            {address.phone}
                                                                        </a>
                                                                    </div>
                                                                    : null
                                                                }
                                                                {address.email
                                                                    ? <div>
                                                                        <a href={"mailto:" + address.email}>
                                                                            <i className="text-muted fa fa-fw fa-envelope-o mr-2"
                                                                               aria-hidden="true"/>

                                                                            {address.email}
                                                                        </a>
                                                                    </div>
                                                                    : null
                                                                }
                                                                {
                                                                    address.company
                                                                        ? <p className="mb-0">
                                                                            <i className="text-muted fa fa-fw fa-building mr-2"
                                                                               aria-hidden="true"/>
                                                                            {address.company}
                                                                        </p>
                                                                        : null
                                                                }
                                                                <div>
                                                                    <a href={"http://maps.google.com/?q=" + getCommonAddressString(address)}
                                                                       target="_blank">
                                                                        <i className="text-muted fa fa-fw fa-map-marker mr-2"
                                                                           aria-hidden="true"/>
                                                                        {getCommonAddressString(address)}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <CardFooter className={"p-2"}>
                                                            {/*<Button color="link" size={"sm"}*/}
                                                            {/*        className={"mr-2"}*/}
                                                            {/*        onClick={() => this.handleAction("deliveryAddress-modify", address)}>*/}
                                                            {/*    <i className="fa fa-pencil fa-fw"*/}
                                                            {/*       aria-hidden="true"/> Edit*/}
                                                            {/*</Button>*/}
                                                            <span style={{float: "right"}} className={"mr-1"}>
                                                                {
                                                                    this.isSelected(selectedAddress, address)
                                                                        ? <span>
                                                                        <i className="fa fa-check-circle fa-fw mr-1"
                                                                           style={{color: "#28a745"}}
                                                                           aria-hidden="true"/>Currently selected for delivery
                                                                    </span>
                                                                        : <Button color="link" size={"sm"}
                                                                                  onClick={() => this.handleAction("deliveryAddress-select", address)}>
                                                                            <i className="fa fa-truck fa-fw mr-1"
                                                                               aria-hidden="true"/>Use this address for
                                                                            delivery
                                                                        </Button>
                                                                }
                                                            </span>
                                                        </CardFooter>
                                                    </Card>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </div>
                            }
                        </div>
                    }

                    {
                        this.state.isOpenDeliveryAddressCreateModal
                            ? <DeliveryAddressCreateModal
                                isOpen={this.state.isOpenDeliveryAddressCreateModal}
                                toggle={this.toggleAddressFormModal}
                                address={addressForm}
                                onCreation={(createdAddress) => this.handleAction("deliveryAddress-created", createdAddress)}
                                usage={"Product Builder"}
                            />
                            : null
                    }
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button size={"sm"} color={"primary"} className={"mr-2"}
                                onClick={() => handleChange(selectedAddress)}>
                            <i className="fa fa-check mr-2" aria-hidden="true"/>Confirm</Button>
                        <Button size={"sm"} color={"secondary"} className={"mr-2"}
                                onClick={() => toggleModel(!isOpen)}>
                            <i className="fa fa-times mr-2" aria-hidden="true"/>Cancel</Button>
                    </div>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        );
    }
}