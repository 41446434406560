import React, {Component} from 'react';

export let quiklokLabelStore = [
    {
        key: "model",
        label: "Model",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel ? data.productLabel[this.key] : "";
        }
    }, {
        key: "colour",
        label: "Colour",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel ? data.productLabel[this.key] : "";
        }
    }, {
        key: "mount",
        label: "Mount",
        colSpan: 1,
        minWidth: 25,
        sorterApplicable: true,
        valueClassName: "",
        labelClassName: "",
        searchNode: null,
        render: function (data, componentRef) {
            return data.productLabel ? data.productLabel[this.key] : "";
        }
    }
];