import React, {Component} from 'react';
import {Spinner} from 'reactstrap';
import queryString from 'query-string';
import {Redirect} from "react-router-dom";
import OrderService from "../../services/OrderService";
import UserService from "../../services/UserService";
import {
    getDeliveryAddressString,
    getParentCompanyAddress,
    handleErrorMessage
} from '../../services/CommonService';

import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {isEmpty} from 'lodash';
import orderEnquiryUtil from '../../Util/OrderEnquiryUtil';
import {isAllRowsExpanded} from '../../store/sales/order/item/SalesOrderEnquiryPageOrderItemStore';
import SearchSalesOrder from "../../components/search/SearchSalesOrder";
import OrderEnquiryEditMode from "./OrderEnquiryEditMode";
import OrderEnquiryReadOnlyMode from "./OrderEnquiryReadOnlyMode";
import OrderEnquiryProductionMode from "./OrderEnquiryProductionMode";

const canModifyOrderPrivilege = 'debtor-invoice-order-write';

export default class OrderEnquiryPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: {},
            isLoadingOrder: false,
            ordNum: 0,
            order: {},
            salesRepOptions: [],
            canModifyOrder: false,
            salesOrder: null,
            convertingToOrder: false,
            viewMode: "default",
            isLoadingSalesOrderRetailConsumerDetailUpdate: false,
        };

        this.orderService = new OrderService();
        this.userService = new UserService();
        this.getOrder = this.getOrder.bind(this);
        this.getDebtorInvoice = this.getDebtorInvoice.bind(this);
        this.refresh = this.refresh.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
        this.updateOrder = this.updateOrder.bind(this);
        this.toggleExpandRow = this.toggleExpandRow.bind(this);
        this.toggleAllRowExpanded = this.toggleAllRowExpanded.bind(this);
    }

    componentDidMount() {

        let currentUser = this.userService.getLoggedInUser();
        let canModifyOrder = (currentUser.isExtUser || this.userService.hasPrivilege(currentUser, canModifyOrderPrivilege));

        let ordNum = 0;
        let viewMode = "default";
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            ordNum = searchParams.ordNum ? parseInt(searchParams.ordNum) : 0;
            viewMode = searchParams.viewMode ? searchParams.viewMode : viewMode;
        }

        this.setState({currentUser, canModifyOrder, ordNum, viewMode}, () => {
            this.refresh();
        });
    }

    updateUrl(ordNum) {
        if (this.props.location) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("ordNum", ordNum);

            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({path: newurl}, '', newurl);
        }
    }

    refresh() {
        if (this.state.ordNum) {
            this.getOrder(this.state.ordNum, "current");
        } else {
            this.getOrder(0, "last");
        }
    }

    getOrder(ordNum, navigation) {
        this.getDebtorInvoice(ordNum, navigation);
    }

    getDebtorInvoice(ordNum, navigation) {
        this.setState({ordNum, isLoadingOrder: true});
        this.orderService.getOrder(ordNum, true, true, true, navigation).then(response => {
            let order = response.data;
            if (order) {
                order.shippingAdrress = getDeliveryAddressString(order);
                order.parentCompanyAdrress = getParentCompanyAddress(order);
                order.products = orderEnquiryUtil.initOrderProducts(order);
                let salesOrder = order.salesOrder;
                if (salesOrder) {
                    if (salesOrder.attachmentIDs) {
                        let attachmentIDs = salesOrder.attachmentIDs;
                        salesOrder.attachmentIDs = [];
                        salesOrder.attachmentIDs.push(attachmentIDs);
                    }
                    else if (!salesOrder.attachmentIDs) {
                        salesOrder.attachmentIDs = [];
                    }

                    if (salesOrder.salesOrderItems && salesOrder.salesOrderItems.length > 0) {
                        salesOrder.salesOrderItems.forEach(soi => {
                            if (soi.attachmentIDs) {
                                salesOrder.attachmentIDs.push(soi.attachmentIDs);
                            }
                        });
                    }

                    if (salesOrder.attachmentIDs && salesOrder.attachmentIDs.length > 0) {
                        salesOrder.attachmentIDs = salesOrder.attachmentIDs.join();
                    }
                    order.createdFrom = salesOrder.createdFrom;
               }
                //Default open Product Table
                if (order.products) {
                    if (order.products[0]) {
                        order.products[0].isOpen = true;
                    }
                }
                this.setState({
                    order: order,
                    isLoadingOrder: false,
                    salesOrder: salesOrder,
                    ordNum: order.ordNum
                }, () => {
                    this.updateUrl(order.ordNum);
                });
            } else {
                this.setState({isLoadingOrder: false});
            }
        }).catch(error => {
            console.error(error);
            this.setState({isLoadingOrder: false});
            toast.error(handleErrorMessage(error));
        });
    }

    toggleExpandRow(orderItemIndex, change) {
        let {order} = this.state;
        if (order.items[orderItemIndex].isExpandable) {
            order.items[orderItemIndex].isExpanded = change;
            this.setState({order});
        }
    }

    toggleAllRowExpanded() {
        let {order} = this.state;

        let change = isAllRowsExpanded(order.items);
        order.items.forEach((item, itemIndex) => {
            if (order.items[itemIndex].isExpandable) {
                order.items[itemIndex].isExpanded = !change;
            }
        });
        this.setState({order});
    }

    updateOrder(key, change) {
        let {order} = this.state;
        let productIndex, products, itemIndex, isOpen, parts, partsConsolidated;
        switch (key) {
            case "order_parts":
                ({products, parts, partsConsolidated} = change);
                order.products = products;
                order.parts = parts;
                order.partsConsolidated = partsConsolidated;
                break;
            case "toggleProductCollapse":
                ({ productIndex, isOpen } = change);
                order.products[productIndex].isOpen = isOpen;
                break;
            case "toggleProductItemCollapse":
                ({productIndex, itemIndex, isOpen} = change);

                if (itemIndex > -1) {
                    order.products[productIndex].items[itemIndex].isOpen = isOpen;
                }
                if (itemIndex === -1) {
                    order.products[productIndex].items.forEach(productItem => {
                        productItem.isOpen = isOpen;
                    })
                }
                break;
        }
        this.setState({order});
    }

    render() {
        let {
            currentUser, viewMode, ordNum, isLoadingOrder, order, canModifyOrder, salesOrder
        } = this.state; 

        if (viewMode !== "production") {
            viewMode = canModifyOrder ? "editMode" : "readOnlyMode";
        }
        let result = null;
        if (isLoadingOrder) {
            result = <Spinner color={"primary"}/>;
        } else {
            if (!isEmpty(order)) {
                switch (viewMode) {
                    case "production":
                        result = <OrderEnquiryProductionMode
                            order={order} currentUser={currentUser} salesOrder={salesOrder} viewMode={viewMode}
                            updateOrder={this.updateOrder}/>;
                        break;
                    case "readOnlyMode":
                        result = <OrderEnquiryReadOnlyMode
                            isLoadingOrder={isLoadingOrder}
                            order={order}
                            salesOrder={salesOrder}
                            getOrder={this.getOrder}
                            toggleExpandRow={this.toggleExpandRow}
                            toggleAllRowExpanded={this.toggleAllRowExpanded}
                            currentUser={currentUser} viewMode={viewMode}
                        />;
                        break;
                    case "editMode":
                        result = <OrderEnquiryEditMode
                            isLoadingOrder={isLoadingOrder}
                            order={order}
                            salesOrder={salesOrder}
                            getOrder={this.getOrder}
                            toggleExpandRow={this.toggleExpandRow}
                            toggleAllRowExpanded={this.toggleAllRowExpanded}
                            currentUser={currentUser} viewMode={viewMode}
                        />;
                        break;
                    default:
                        result = null;
                }
            }
        }
        return (
            <div>
                <div>
                    <SearchSalesOrder ordNum={ordNum}
                                      history={this.props.history}
                                      isLoadingOrder={isLoadingOrder}
                                      getOrder={this.getOrder}/>
                </div>
                <hr/>
                {result}
                <ToastContainer/>
            </div>
        );
    }
}
