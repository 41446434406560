import React, {Component} from 'react';
import {Badge, Card, CardBody, CardHeader, Col, Row, Spinner, Table} from 'reactstrap';
import NumberFormat from "react-number-format";
import DebtorOutstandingBalance from './DebtorOutstandingBalance';
import CustomerService from '../../services/CustomerService';
import DebtorCreditTermsAndLimitModal from "../modal/DebtorCreditTermsAndLimitModal";
import CustomerSalesByMonth from './CustomerSalesByMonth';
import {toast, ToastContainer} from 'react-toastify';
import {
    handleErrorMessage, getDebtorCategoryColor,
    changeFormatOfDateString
} from "../../services/CommonService";
import DebtorPreviewEditModal from '../modal/DebtorPreviewEditModal';
import CustomerCreditTradingTermModal from '../modal/CustomerCreditTradingTermModal';
import RepService from '../../services/RepService';

export default class CustomerCredit extends Component {
    constructor(props) {
        super(props);
        const previousYears = ((new Date()).getFullYear()) - 20;
        this.state = {
            previousYears: previousYears,
            accountID: this.props.accountID,
            category: '',
            classification: '',
            credit_Limit: 0.00,
            DateAdded: '',
            credit_Terms: '',
            CloseBal: 0.00,
            OpenBal: 0.00,
            Open90Days: 0.00,
            Open60Days: 0.00,
            Open30Days: 0.00,
            OpenCurrent: 0.00,
            FwdDateAmount: 0.00,
            AllowDiscount: false,
            ChargeOnlyAccount: false,
            isOpenCreditTermModal: false,
            isOpenCreditOtherDetailsModal: false,
            creditStatusEditType: "",
            editDetailsType: "",
            selectedMonth: {
                label: "May",
                value: "5"
            },
            Discount: 0,
            SalesbyMonth: {
                jan: 0.00,
                feb: 0.00,
                mar: 0.00,
                apr: 0.00,
                may: 0.00,
                jun: 0.00,
                jul: 0.00,
                aug: 0.00,
                sep: 0.00,
                oct: 0.00,
                nov: 0.00,
                dec: 0.00
            },
            tradingTerms: '',
            specialReq: '',
            debtor: {},
            printStatement: false,
            emailStatement: false,
            discountData: [],
            loadingDiscount: false,
            loadingCustomerInfo: false,
            loadingSalesByMonth: false,
            loadingDebtor: false,
            debtorCourier: {},
            isLoadingDebtorCourier: false,
            reps: [],
            debtorAccountStatuses: [],
            debtorCategories: [],
            debtorPaymentTerms: [],
            debtorClassifications: []
        };
        this.customerService = new CustomerService();
        this.convertToDecimalplaces = this.convertToDecimalplaces.bind(this);
        this.GetTradingAndSalesInfo = this.GetTradingAndSalesInfo.bind(this);
        this.handleDebtorAccountDetails = this.handleDebtorAccountDetails.bind(this);
        this.getCustomerInfo = this.getCustomerInfo.bind(this);
        this.toggleCreditTermModal = this.toggleCreditTermModal.bind(this);
        this.refreshAgainPreviewModal = this.refreshAgainPreviewModal.bind(this);
        this.togglePreviewEditModal = this.togglePreviewEditModal.bind(this);
        this.openPreviewEditModal = this.openPreviewEditModal.bind(this);
        this.toggleCreditOtherDetailsModal = this.toggleCreditOtherDetailsModal.bind(this);

    }

    componentDidMount() {
        this.refresh();
        this.getReps();
        this.getDebtorClassifications();
        this.getDebtorAccountStatus();
        this.getDebtorPaymentTerms();
        this.getDebtorCategory();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.accountID !== nextProps.accountID) {
            this.setState({accountID: nextProps.accountID}, function () {
                this.refresh();
            });
        }
    }

    refresh() {
        this.GetTradingAndSalesInfo(this.state.accountID);
        this.handleDebtorAccountDetails(this.state.accountID);
        this.getCustomerInfo(this.state.accountID);
    }

    getReps() {
        RepService.fetchReps().then(res => {
            this.setState({reps: res.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error.message), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorAccountStatus() {
        this.customerService.getDebtorAccountStatus().then(response => {
            this.setState({debtorAccountStatuses: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorClassifications() {
        this.customerService.getDebtorClassification().then(response => {
            this.setState({debtorClassifications: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorCategory() {
        this.customerService.getDebtorCategory().then(response => {
            this.setState({debtorCategories: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getDebtorPaymentTerms() {
        this.customerService.getDebtorPaymentTerms().then(response => {
            this.setState({debtorPaymentTerms: response.data});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getCustomerInfo(accountID) {
        this.setState({loadingCustomerInfo: true});
        this.customerService.getCustomerInformation(accountID).then(response => {
            let data = response.data;
            this.setState({
                loadingCustomerInfo: false,
                category: data.category,
                classification: data.classification,
                credit_Limit: data.credit_Limit,
                credit_Terms: data.credit_Terms,
                CloseBal: data.closeBal,
                OpenBal: data.openBal,
                Open90Days: data.open90Days,
                Open60Days: data.open60Days,
                Open30Days: data.open30Days,
                OpenCurrent: data.openCurrent,
                FwdDateAmount: data.fwdDateAmount,
                isOpenCreditOtherDetailsModal: false
            });
        }).catch(error => {
            this.setState({loadingCustomerInfo: false});
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    handleDebtorAccountDetails(accountID) {
        this.setState({loadingDebtor: true});
        this.customerService.searchCustomer(accountID).then(response => {
            let debtor = response.data;
            if (debtor) {
                this.setState({debtor: debtor, loadingDebtor: false});
            }
        }).catch(error => {
            this.setState({loadingDebtor: false});
            toast.error(handleErrorMessage(error));
        });
    }


    toggleCreditTermModal(val, type) {
        this.setState({isOpenCreditTermModal: val, creditStatusEditType: type});
    }

    toggleCreditOtherDetailsModal(val, type) {
        this.setState({isOpenCreditOtherDetailsModal: val, editDetailsType: type});
    }

    GetTradingAndSalesInfo() {
        if (this.state.accountID) {
            this.setState({loadingSalesByMonth: true});
            this.customerService.getCustomerTrading_salesInformation(this.state.accountID).then(response => {
                let data = response.data.item1;
                let SalesbyMonth = {...this.state.SalesbyMonth};
                SalesbyMonth.jan = data.jan;
                SalesbyMonth.feb = data.feb;
                SalesbyMonth.mar = data.mar;
                SalesbyMonth.apr = data.apr;
                SalesbyMonth.may = data.may;
                SalesbyMonth.jun = data.jun;
                SalesbyMonth.jul = data.jul;
                SalesbyMonth.aug = data.aug;
                SalesbyMonth.sep = data.sep;
                SalesbyMonth.oct = data.oct;
                SalesbyMonth.nov = data.nov;
                SalesbyMonth.dec = data.dec;
                this.setState({
                    loadingSalesByMonth: false,
                    SalesbyMonth: SalesbyMonth,
                    tradingTerms: data.tradingTerms,
                    specialReq: data.specialReq,
                    DateAdded: data.dateAdded,
                    Discount: response.data.item2,
                    AllowDiscount: data.allowDiscountOnSpecialPrices,
                    ChargeOnlyAccount: data.isChargeOnlyAccount,
                    emailStatement: data.statementEmail,
                    printStatement: data.statementPrint
                });
            }).catch(error => {
                this.setState({loadingSalesByMonth: false});
                toast.error(handleErrorMessage(error), {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    convertToDecimalplaces(num) {
        if (num !== undefined && num !== null) {
            return (<NumberFormat value={num}
                                  displayType={'text'}
                                  decimalScale={0}
                                  thousandSeparator={true}
                                  prefix={'$'}/>);
        } else {
            return num;
        }
    }

    openPreviewEditModal() {
        this.setState({isOpenPreviewEditModal: true});
    }

    togglePreviewEditModal(data) {
        this.setState({isOpenPreviewEditModal: data});
    }

    refreshAgainPreviewModal() {
        this.handleDebtorAccountDetails(this.state.accountID);
        this.getCustomerInfo(this.state.accountID);
        this.setState({isOpenPreviewEditModal: false});
        this.props.refresh(this.state.accountID);
    }

    render() {
        const {
            previousYears, debtor, accountID, loadingCustomerInfo, loadingSalesByMonth, loadingDebtor,
            SalesbyMonth, category, isOpenCreditTermModal, isOpenCreditOtherDetailsModal, editDetailsType,
            reps, debtorAccountStatuses, debtorPaymentTerms, debtorClassifications, debtorCategories, isOpenPreviewEditModal
        } = this.state;

        let {hasDebtorWritePrivilege} = this.props;
        const options = [];
        for (let i = 0; i <= 15; i++) {
            const year = previousYears + i;
            options.push({'label': year, 'value': year});
        }
        return (
            <div>

                <Row>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12} className={"mt-2"}>
                        <Card>
                            <CardHeader><h6 className={"mb-0"}>
                                Preview
                                {
                                    hasDebtorWritePrivilege ? <span style={{float: "right"}}>
                                         <a href={"javascript:void(0)"}
                                            onClick={this.openPreviewEditModal}>
                                            <i className={"fa fa-pencil"}/>
                                        </a>
                                    </span> : null}
                            </h6></CardHeader>
                            <CardBody>
                                {loadingCustomerInfo ? <Spinner color="primary"/> :
                                    <Table size={"sm"} responsive>
                                        <tbody>
                                        <tr>
                                            <th>Category</th>
                                            <td><Badge color={getDebtorCategoryColor(category)}>{category}</Badge></td>
                                        </tr>
                                        <tr>
                                            <th>Status</th>
                                            <td>{debtor.accountStatusDescription}</td>
                                        </tr>
                                        <tr>
                                            <th>Rep</th>
                                            <td>{debtor.repName}</td>
                                        </tr>
                                        <tr>
                                            <th>Established</th>
                                            <td>{changeFormatOfDateString(debtor.dateAdded, "DD/MM/YYYY HH:mm:ss", "DD MMM YYYY")}</td>
                                        </tr>
                                        <tr>
                                            <th>Classification</th>
                                            <td>{this.state.classification}</td>
                                        </tr>
                                        <tr>
                                            <th>Payment Terms</th>
                                            <td>{debtor.paymentTerm}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12} className={"mt-2"}>
                        <Card>
                            <CardHeader><h6 className={"mb-0"}>
                                Outstanding Balance
                            </h6></CardHeader>
                            <CardBody>
                                <DebtorOutstandingBalance outstandingBalance={debtor} isLoading={loadingDebtor}/>
                            </CardBody>
                        </Card>

                        <Card className={"mt-2"}>
                            <CardHeader><h6 className={"mb-0"}>Credit status
                                {hasDebtorWritePrivilege ?
                                    <span style={{float: "right"}}>
                                        <a href={"javascript:void(0)"}
                                           onClick={(e) => this.toggleCreditTermModal(e, "CreditLimit")}>
                                            <i className={"fa fa-pencil"}/>
                                        </a>
                                    </span>
                                    : null}
                            </h6></CardHeader>
                            <CardBody>
                                {loadingCustomerInfo ? <Spinner color="primary"/> :
                                    <Table size={"sm"} responsive>
                                        <tbody>
                                        <tr>
                                            <th>Credit limit</th>
                                            <td>
                                                {this.convertToDecimalplaces(this.state.credit_Limit)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Credit terms</th>
                                            <td>{this.state.credit_Terms}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12} className={"mt-2"}>
                        <CustomerSalesByMonth
                            salesbyMonth={SalesbyMonth}
                            convertToDecimalplaces={this.convertToDecimalplaces}
                            isLoading={loadingSalesByMonth}/>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Card>
                            <CardHeader><h6 className={"mb-0"}>
                                Trading Terms
                                {hasDebtorWritePrivilege ?
                                    <span style={{float: "right"}}>
                                                <a href={"javascript:void(0)"}
                                                   onClick={(e) => this.toggleCreditOtherDetailsModal(e, "tradingTerms")}>
                                                    <i className={"fa fa-pencil"}/>
                                                </a>
                                    </span>
                                    : null}
                            </h6></CardHeader>
                            <CardBody>
                                {loadingSalesByMonth ? <Spinner color="primary"/> :
                                    <p>{this.state.tradingTerms ? this.state.tradingTerms : "No Trading Terms"}</p>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Card>
                            <CardHeader>
                                <h6 className={"mb-0"}>Special Requirements
                                    {hasDebtorWritePrivilege ?
                                        <span style={{ float: "right" }}>
                                            <a href={"javascript:void(0)"}
                                                onClick={(e) => this.toggleCreditOtherDetailsModal(e, "specialRequirements")}>
                                                <i className={"fa fa-pencil"} />
                                            </a>
                                        </span>
                                        : null}
                                </h6>
                            </CardHeader>
                            <CardBody>
                                {loadingSalesByMonth ? <Spinner color="primary" /> :
                                    <p>{this.state.specialReq ? this.state.specialReq : "No Special Requirements"}</p>
                                }
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
                {
                    isOpenCreditTermModal
                        ? <DebtorCreditTermsAndLimitModal
                            accountID={accountID}
                            isOpen={isOpenCreditTermModal}
                            toggleModal={this.toggleCreditTermModal}
                            refresh={() => this.refresh()}
                            debtor={debtor}
                        />
                        : null
                }

                {
                    isOpenPreviewEditModal
                        ? <DebtorPreviewEditModal
                            accountID={accountID}
                            reps={reps}
                            debtorAccountStatuses={debtorAccountStatuses}
                            debtorCategories={debtorCategories}
                            debtorPaymentTerms={debtorPaymentTerms}
                            debtorClassifications={debtorClassifications}
                            isOpen={isOpenPreviewEditModal}
                            toggleModal={this.togglePreviewEditModal}
                            refresh={this.refreshAgainPreviewModal}
                        />
                        : null
                }

                {
                    isOpenCreditOtherDetailsModal
                        ? <CustomerCreditTradingTermModal
                            accountID={accountID}
                            debtor={debtor}
                            isOpen={isOpenCreditOtherDetailsModal}
                            toggleModal={this.toggleCreditOtherDetailsModal}
                            editDetailsType={editDetailsType}
                            refresh={() => this.refresh()}/>
                        : null
                }
                <ToastContainer/>
            </div>
        );
    }
}