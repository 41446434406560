import React, {Component} from 'react';
import {Table} from "reactstrap";
import NumberFormat from "react-number-format";
import {SALES_ORDER_CREATED_FROM_KEYWAY_PRODUCT_BUILDER_V1} from "../../store/AppConstants";
import {sortObjects} from '../../services/CommonService';


export default class OrderEnquiryProductionModeOrderItemsProductSubPartList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: this.props.rows
        };
    }

    componentDidMount() {
        this.rowsData();
    }
    rowsData() {
        let { rows } = this.state;
        for (var i in rows) {
            if (rows[i].location !== null && Array.from(rows[i].location)[0] === ',') {
                rows[i].location = rows[i].location.substring(1);
            }
        }
        this.setState({ rows });
    }
    render() {
        let { rows } = this.state;
        let showKeywayPartListColumns = (rows || []).some(row => row.createdFrom === SALES_ORDER_CREATED_FROM_KEYWAY_PRODUCT_BUILDER_V1 && row.attributeRowSpan > 0);
        return (
            <div>
                <Table bordered responsive size={"sm"} striped={true} className={"mb-0"}>
                    <thead>
                        <tr>
                            {
                                showKeywayPartListColumns
                                    ? <th rowSpan={2} className="align-middle">Attribute</th>
                                    : null
                            }
                            <th rowSpan={2} className="align-middle">Item Code</th>
                            <th rowSpan={2} className="align-middle">Description</th>
                            {
                                showKeywayPartListColumns
                                    ? <th colSpan={2} className="align-middle text-center">Keyway</th>
                                    : null
                            }
                            <th colSpan={showKeywayPartListColumns ? 3 : 2}
                                className="align-middle text-center">SWISH
                            </th>
                            <th rowSpan={2} className="align-middle text-center">QOH</th>
                            <th rowSpan={2} className="align-middle text-center">Location</th>
                        </tr>
                        <tr>
                            {showKeywayPartListColumns ?
                                <th className="align-middle text-right">Qty (with deduction calculated)</th> : null}
                            {showKeywayPartListColumns ?
                                <th className="align-middle text-center">Measurement unit</th> : null}
                            <th className="align-middle text-right">Qty (without wastage calculated)</th>
                            <th className="align-middle text-right">Qty (with wastage calculated)</th>
                            {showKeywayPartListColumns ?
                                <th className="align-middle text-center">Measurement unit</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (rows || []).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {showKeywayPartListColumns && row.attributeRowSpan > 0
                                        ? <td rowSpan={row.attributeRowSpan}
                                            className="align-middle">
                                            <div>
                                                <strong>{row.attribute}</strong>
                                            </div>
                                            <div>
                                                {row.label}
                                            </div>
                                        </td>
                                        : null
                                    }
                                    <td>{row.prodCode}</td>
                                    <td>{row.description}</td>
                                    {
                                        showKeywayPartListColumns
                                            ? <td className={"text-right"}>
                                                <NumberFormat
                                                    value={row.keywayQtyWithDeduction}
                                                    displayType={'text'}
                                                    decimalScale={2} />
                                            </td>
                                            : null
                                    }
                                    {showKeywayPartListColumns
                                        ? <td className={"text-center"}>{row.keywayMeasurementUnit}</td>
                                        : null
                                    }
                                    <td className={"text-right"}>
                                        <NumberFormat
                                            value={row.swishQty}
                                            displayType={'text'}
                                            decimalScale={2} />
                                    </td>
                                    <td className={"text-right"}>
                                        <NumberFormat
                                            value={row.swishQtyWithWastage}
                                            displayType={'text'}
                                            decimalScale={2} />
                                    </td>
                                    {
                                        showKeywayPartListColumns
                                            ? <td className={"text-center"}>{row.swishMeasurementUnit}</td>
                                            : null
                                    }
                                    <td className={"text-right"}>
                                        <NumberFormat
                                            value={row.qoh}
                                            displayType={'text'}
                                            decimalScale={2} />
                                    </td>
                                    <td>
                                        <small>{row.location}</small>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}