import React, { Component } from 'react';
//import Notification from './notification.component'
import UserMenuDropdown from './UserMenuDropdown.component'
import { NavLink as RouterNavLink } from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem
} from 'reactstrap';

export default class HeaderContent extends Component {

    constructor(props) {
        super(props);
    }

    isCustomerUser(user) {
        return (user && user.menu && (user.menu.role === "ExternalAdmin" || user.menu.role === "ExternalUser" || user.menu.role === "ExternalSalesRep"));
    }
    isExternalAdmin(user) {
        return (user && user.menu && (user.menu.role === "ExternalAdmin"));
    }
    isTestEnvironment(user) {
        return (user && user.isTestEnvironment && user.environmentName !== "PRODUCTION");
    }

    render() {

        if (this.props.isLoggedIn) {
            const testStyle = this.isTestEnvironment(this.props.user);
            return <Navbar light expand="md" className="border-bottom shadow-sm" style={{ backgroundColor: testStyle ? 'lightpink' : 'white' }} >
                <span className={"navbar-brand"}>
                    {
                        this.isCustomerUser(this.props.user)
                            ? null
                            : <button onClick={this.props.toggleOpen} className="custom-navbar-toggler mr-2">
                                <span className="custom-navbar-toggler-icon" />
                            </button>
                    }
                    
                    <RouterNavLink
                        exact
                        style={{ textDecoration: "none" }}
                        to="/"
                    ><img
                            style={{ maxHeight: 35 }}
                            className="img-fluid"
                            alt=""
                            src="https://winonline.blob.core.windows.net/winonline/company_logo/SMARTHP.png" />
                    </RouterNavLink> 

                    <RouterNavLink exact style={{ textDecoration: "none" }} to="/" className="ml-3">
                        <img
                            style={{ maxHeight: 35 }}
                            alt=""
                            src="/img/logo/Windoware.png" />
                    </RouterNavLink>
                    <RouterNavLink className="ml-3"
                        exact
                        style={{ textDecoration: "none" }}
                        to="/"
                    ><img
                            style={{ maxHeight: 35 }}
                            className="img-fluid"
                            alt=""
                            src="https://winonline.blob.core.windows.net/winonline/company_logo/Bistro.png" />
                    </RouterNavLink>
                    
                    {testStyle ? <span className="font-weight-bold text-dark align-bottom px-2">{this.props.user.environmentName}</span> : null}
                </span>


                <NavbarToggler onClick={this.props.toggleNavbar} />
                <Collapse isOpen={!this.props.navBarCollapsed} navbar>

                    <Nav className="ml-auto" navbar>
                        {/*{*/}
                        {/*    this.isCustomerUser(this.props.user)*/}
                        {/*        ? <NavItem className={"hoverableItem"}>*/}
                        {/*            <span className={"nav-link"}>*/}
                        {/*                <RouterNavLink*/}
                        {/*                    exact*/}
                        {/*                    style={{textDecoration: "none"}}*/}
                        {/*                    activeClassName="font-weight-bold"*/}
                        {/*                    to="/">Home*/}
                        {/*                </RouterNavLink>*/}
                        {/*            </span>*/}
                        {/*        </NavItem>*/}
                        {/*        : null*/}
                        {/*}*/}
                        {/*{*/}
                        {/*    this.isCustomerUser(this.props.user)*/}
                        {/*        ? <NavItem className={"hoverableItem"}>*/}
                        {/*            <span className={"nav-link"}>*/}
                        {/*                <RouterNavLink*/}
                        {/*                    exact*/}
                        {/*                    style={{textDecoration: "none"}}*/}
                        {/*                    activeClassName="font-weight-bold"*/}
                        {/*                    to="/product/catalogues"*/}
                        {/*                >Products*/}
                        {/*                </RouterNavLink>*/}
                        {/*            </span>*/}
                        {/*        </NavItem>*/}
                        {/*        : null*/}
                        {/*}*/}
                        {/*{*/}
                        {/*    this.isCustomerUser(this.props.user)*/}
                        {/*        ? <NavItem className={"hoverableItem"}>*/}
                        {/*            <span className={"nav-link"}>*/}
                        {/*                <RouterNavLink*/}
                        {/*                    exact*/}
                        {/*                    style={{ textDecoration: "none" }}*/}
                        {/*                    activeClassName="font-weight-bold"*/}
                        {/*                    to="/customer/order"*/}
                        {/*                >Order*/}
                        {/*                </RouterNavLink>*/}
                        {/*            </span>*/}
                        {/*        </NavItem>*/}
                        {/*        : null*/}
                        {/*}*/}
                        {/*{*/}
                        {/*    this.isCustomerUser(this.props.user)*/}
                        {/*        ? <NavItem className={"hoverableItem"}>*/}
                        {/*            <span className={"nav-link"}>*/}
                        {/*                <RouterNavLink*/}
                        {/*                    exact*/}
                        {/*                    style={{textDecoration: "none"}}*/}
                        {/*                    activeClassName="font-weight-bold"*/}
                        {/*                    to="/company/Faq"*/}
                        {/*                >Support*/}
                        {/*                </RouterNavLink>*/}
                        {/*            </span>*/}
                        {/*        </NavItem>*/}
                        {/*        : null*/}
                        {/*}*/}
                        {
                            this.isExternalAdmin(this.props.user)
                                ? <NavItem className={"hoverableItem"}>
                                    <span className={"nav-link"}>
                                        <RouterNavLink
                                            exact
                                            style={{ textDecoration: "none" }}
                                            activeClassName="font-weight-bold"
                                            to="/company/accounts"
                                        >
                                            Account
                                        </RouterNavLink>
                                    </span>
                                </NavItem>
                                : null
                        }
                        {
                            this.isExternalAdmin(this.props.user)
                                ? <NavItem className={"hoverableItem"}>
                                    <span className={"nav-link"}>
                                        <RouterNavLink
                                            exact
                                            style={{ textDecoration: "none" }}
                                            activeClassName="font-weight-bold"
                                            to="/company/admin"
                                        >
                                            Admin
                                        </RouterNavLink>
                                    </span>
                                </NavItem>
                                : null
                        }
                        <UserMenuDropdown user={this.props.user} />
                        {/*   <Notification/>*/}
                    </Nav>
                </Collapse>
            </Navbar >
        } else {
            return null;
        }
    }
}

