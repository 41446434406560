import React, { Component } from 'react';
import { Modal, ModalBody, Button, Row, Col, Spinner } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

export default class RivaModal extends Component {
    render() {
        let { isOpen, toggle, handleCancel, handleSubmit, loading, primaryMessage, secondaryMessage, cancelColor, submitColor, icon, size, isCancelRequired, isSubmitRequired } = this.props;
        return (
            <Modal isOpen={isOpen} size={size} scrollable={true} toggle={() => toggle(!isOpen)}>
                <ModalBody>
                    <div className={"p-4 text-center"}>
                        {loading
                            ? <Spinner color={"primary"} />
                            : icon ? <i className={icon} aria-hidden="true" /> : null
                        }
                        {primaryMessage ? <h5>{ReactHtmlParser(primaryMessage)}</h5> : null}
                        {secondaryMessage ? <div>{ReactHtmlParser(secondaryMessage)}</div> : null}
                    </div>
                    <Row>
                        {isCancelRequired &&
                            <Col>
                                <div className={"text-center"}>
                                    <Button color={cancelColor}
                                        onClick={() => handleCancel()}>
                                        Close
                                    </Button>
                                </div>
                            </Col>
                        }
                        {isSubmitRequired &&
                            <Col>
                                <div className={"text-center"}>
                                    <Button color={submitColor} disabled={loading}
                                        onClick={() => handleSubmit()}>
                                        Submit
                                    </Button>
                                </div>
                            </Col>
                        }
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}