import React, { Component } from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,
    Row,Col,Label,FormGroup,Form,Input
} from 'reactstrap';
import UploadFiles from '../attachment/UploadFiles';
import SearchDebtorAcccount from '../search/SearchDebtorAcccount';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {BUNNINGS_STORE_ACCOUNT_IDs} from "../../store/AppConstants";

export default class EmailLogModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            emailLog: props.emailLog
        };
    }


    render() {
        let { isOpen, toggle } = this.props;
        let { emailLog } = this.state;

        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}>
                   View Statement Email
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            {emailLog.accountID ? <Col lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label className='mb-0'><strong>Customer</strong></Label>
                                    <SearchDebtorAcccount
                                        disabled={true}
                                        selectedAccountID={emailLog.accountID}
                                        defaultAccountID={emailLog.accountID}
                                        includeChildren={true}
                                        excludeClosedandInactive={false}
                                        parentAccountIDs={BUNNINGS_STORE_ACCOUNT_IDs}
                                    />
                                </FormGroup>
                            </Col> : null}
                            {/*<Col lg={4} md={4} sm={12} xs={12}>
                                <FormGroup>
                                    <Label className='mb-0'><strong>Email Job Name</strong></Label>
                                    <Input
                                        className="form-control"
                                        name="emailJobName"
                                        type="text"
                                        plaintext
                                        value={emailLog.emailJobName} />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={4} sm={12} xs={12}>
                                <FormGroup>
                                    <Label className='mb-0'><strong>Email Job Type</strong></Label>
                                    <Input
                                        className="form-control"
                                        name="emailJobType"
                                        type="text"
                                        plaintext
                                        value={emailLog.emailJobType} />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={4} sm={12} xs={12}>
                                <FormGroup>
                                    <Label className='mb-0'><strong>Email Job Description</strong></Label>
                                    <Input
                                        className="form-control"
                                        name="emailJobDescription"
                                        type="text"
                                        plaintext
                                        value={emailLog.emailJobDescription} />
                                </FormGroup>
                            </Col>*/}
                        </Row>
                        <Row>
                           
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label className='mb-0'><strong>To</strong></Label>
                                    <Input
                                        className="form-control"
                                        name="toEmail"
                                        type="text"
                                        plaintext
                                        value={emailLog.tos}/>
                                </FormGroup>
                            </Col>
                           
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label className='mb-0'><strong>Subject</strong></Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="subject"
                                        plaintext
                                        value={emailLog.subject} />
                                </FormGroup>
                            </Col>

                            {emailLog.attachmentIDs ? <Col lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label className='mb-0'><strong>Attachments</strong></Label>
                                    <UploadFiles
                                        isReadOnly={true}
                                        attachmentIDs={emailLog.attachmentIDs} />
                                </FormGroup>
                            </Col> : null}

                            {emailLog.isError ? <Col lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label className='mb-0'><strong>Error message</strong></Label>
                                    <Input
                                        type="textarea"
                                        rows='2'
                                        className="form-control"
                                        name="errorMessage"
                                        plaintext
                                        value={emailLog.errorMessage} />
                                </FormGroup>
                            </Col> : null}

                            
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <FormGroup>
                                    <Label className='mb-0'><strong>Body</strong></Label>
                                    <ReactQuill
                                        value={emailLog.body}
                                        readOnly={true}
                                        theme={"bubble"}
                                        className="pl-0"
                                    />

                                </FormGroup>
                            </Col>
                           

                            
                        </Row>
                    </Form>

                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"secondary"} size='sm' onClick={()=>toggle(!isOpen)}><i className="fa fa-times" />&nbsp;
                            Close</Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}