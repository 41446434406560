import React, { Component } from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Card,
    CardBody,
    CardFooter,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    Row,
    Spinner,
    Table,
    Badge,
    CardHeader,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import {
    toast, ToastContainer
} from "react-toastify";
import apiLogService from '../services/ApiLogService';
import { handleErrorMessage, findIndex, changeFormatOfDateString } from "../services/CommonService";
import { Link } from "react-router-dom";
import Pagination from 'react-js-pagination';
import classnames from 'classnames';
import ApiLogDetailModal from '../components/modal/ApiLogDetailModal';
import UpdateApiLogStatusModal from "../components/modal/UpdateApiLogStatusModal";
import UpdateApiLogCommentModal from "../components/modal/UpdateApiLogCommentModal";
import UpdateApiLogBulkStatusComment from "../../src/components/modal/UpdateApiLogBulkStatusComment";
import ApiRetryModal from "../components/modal/ApiRetryModal";
import queryString from 'query-string';

export default class ApiLogPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logDataTable: {
                request: {
                    pageRequest: { pageSize: 50, currentPage: 1 },
                    sortRequest: { key: '', direction: false },
                    filterRequest: { ordNum: '', status: '', loglevel: '', loggerName: '', id: '', fullname: '' }
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
                isLoading: false
            },
            selectedLog: {},
            isLoadingLog: false,
            isOpenLogModal: false,
            isOpenLogStatusModal: false,
            isOpenLogCommentModal: false,
            isOpenApiRetryModal: false,
            isOpenUpdateBulkModal: false,
            isUpdateStatus: false,
            searchText:""
           
        };
        this.getLogs = this.getLogs.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getStore = this.getStore.bind(this);
        this.toggleApiLogModal = this.toggleApiLogModal.bind(this);
        this.toggleLogStatusModal = this.toggleLogStatusModal.bind(this);
        this.toggleLogCommentModal = this.toggleLogCommentModal.bind(this);
        this.toggleApiRetryModal = this.toggleApiRetryModal.bind(this);
        this.toggleBulkUpdateModal = this.toggleBulkUpdateModal.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        this.getLogs();
        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            let caseID = parseInt(searchParams.caseID);
            this.getLog(caseID);
        }
    }

    clearSelection() {
        this.getLogs();
    }

    getLogs() {
        let { logDataTable } = this.state;
        logDataTable.isLoading = true;
        this.setState({ logDataTable });
        apiLogService.getLogs(logDataTable.request).then(response => {
            logDataTable.response = response.data;
            logDataTable.isLoading = false;

            // add matchId to data
            for (let i = 0; i < logDataTable.response.records.length; i++) {
                let record = logDataTable.response.records[i];
                let optionIndex = (logDataTable.response.records || [])
                    .findIndex(
                        r => r.payload === record.payload
                            && r.url === record.url
                            && r.loggerName === record.loggerName
                            && r.requestType === record.requestType
                    );
                if (optionIndex > -1) {
                    record.matchId = logDataTable.response.records[optionIndex].id;
                } else {
                    record.matchId = -1;
                }

            }
            logDataTable.response.originalRecords = [...logDataTable.response.records];
            this.setState({ logDataTable });
        }).catch(error => {
            logDataTable.isLoading = false;
            this.setState({ logDataTable });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        });
    }

    getLog(caseID, key, value) {
        let { selectedLog } = this.state;
        this.setState({ isLoadingLog: true });
        apiLogService.getLog(caseID).then(response => {
            selectedLog = response.data;
            this.setState({ isLoadingLog: false, selectedLog, [key]: value });
        }).catch(error => {
            this.setState({ isLoadingLog: false, selectedLog: {} });
            toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
        });
    }

    handleChange(change, key) {
        let { logDataTable } = this.state;
        switch (key) {
            case "pageSize":
                logDataTable.request.pageRequest[key] = parseInt(change);
                logDataTable.request.pageRequest.currentPage = 1;
                this.setState({ logDataTable }, () => {
                    this.getLogs();
                });
                break;
            case "currentPage":
                logDataTable.request.pageRequest[key] = change;
                this.setState({ logDataTable }, () => {
                    this.getLogs();
                });
                break;
            case "sortKey":
                if (logDataTable.request.sortRequest.key === change) {
                    logDataTable.request.sortRequest.direction = !logDataTable.request.sortRequest.direction
                }
                else {
                    logDataTable.request.sortRequest.direction = false;
                    logDataTable.request.sortRequest.key = change;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.setState({ logDataTable }, () => {
                        this.getLogs();
                    });
                }
                break;
            case "status":
                if (change === "All") {
                    logDataTable.request.filterRequest.status = '';
                    this.setState({ logDataTable });
                    this.getLogs();
                } else {
                    logDataTable.request.filterRequest[key] = change;
                    logDataTable.request.pageRequest.currentPage = 1;
                    this.setState({ logDataTable }, () => {
                        this.getLogs();
                    });
                }
                break;
            case "loglevel":
                if (change === "All") {
                    logDataTable.request.filterRequest.loglevel = '';
                    this.setState({ logDataTable });
                    this.getLogs();
                } else {
                    logDataTable.request.filterRequest[key] = change;
                    logDataTable.request.pageRequest.currentPage = 1;
                    this.setState({ logDataTable }, () => {
                        this.getLogs();
                    });
                }
                break;
            case "loggerName":
                if (change === "All") {
                    logDataTable.request.filterRequest.loggerName = '';
                    this.setState({ logDataTable });
                    this.getLogs();
                } else {
                    logDataTable.request.filterRequest[key] = change;
                    logDataTable.request.pageRequest.currentPage = 1;
                    this.setState({ logDataTable }, () => {
                        this.getLogs();
                    });
                }
                break;
            case "isLogSelected":
                if (!logDataTable.response.records[change].isSelected) {
                    logDataTable.response.records[change].isSelected = true;
                } else {
                    logDataTable.response.records[change].isSelected = false;
                }
                this.setState({ logDataTable });
                break;
            case "isAllSelected":
                logDataTable.response.records.forEach(item => {
                    item.isSelected = change;
                })
                this.setState({ logDataTable });
                break;
            default:
                logDataTable.request.filterRequest[key] = change;
                logDataTable.request.pageRequest.currentPage = 1;
                this.setState({ logDataTable }, () => {
                    this.getLogs();
                });
        }
    }

    getStore(logDataTable) {
        let { pageRequest, sortRequest, filterRequest } = logDataTable.request;
        let handleChange = this.handleChange;
        let isAllSelected = this.state.logDataTable.response.records.every(x => x.isSelected);
        return [
            {
                key: "multiSelect",
                label: <a href={"javascript:void(0)"}
                    onClick={() => handleChange(!isAllSelected, "isAllSelected")}>
                    <i className={classnames("mt-2", "fa", "fa-lg", {
                        "fa-check-square-o": isAllSelected,
                        "fa-square-o": !isAllSelected,
                    }
                    )} />
                </a>,
                type: 'multiSelect',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>
                        <a href={"javascript:void(0)"}
                            onClick={(e) => handleChange(recordIndex, "isLogSelected")}>
                            <i className={classnames("mt-2", "fa", "fa-lg", {
                                "fa-check-square-o": record.isSelected,
                                "fa-square-o": !record.isSelected,
                            }
                            )} /> </a></span>
                }
            },
            {
                key: "id",
                label: "Case ID",
                type: 'caseID',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 1,
                width: '100px',
                searchNode: <Input type={"text"} name={"id"}
                    placeholder={"Search"} value={filterRequest.id}
                    onChange={(e) => this.handleChange(e.target.value, e.target.name)} />,
                render: function (value, record, recordIndex, data, ref) {
                    return <Button color={"primary"} size={"sm"}
                        onClick={() => ref.getLog(value, "isOpenLogModal", true)}>{value}
                    </Button>;
                }
            },
            {
                key: "matchId",
                label: "Matches with Case ID",
                type: 'matchId',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 1,
                width: '100px',
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    if (record.matchId && record.id != record.matchId) {
                        return <Button color={"primary"} size={"sm"}
                            onClick={() => ref.getLog(record.matchId)}>{record.matchId}
                        </Button>;
                    } else {
                        return <span>-</span>;
                    }

                }
            },
            {
                key: "ordNum",
                label: "OrdNum",
                type: 'ordNum',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 1,
                width: '100px',
                searchNode: <Input type={"number"} name={"ordNum"}
                    placeholder={"Search"} value={filterRequest.ordNum}
                    onChange={(e) => this.handleChange(e.target.value, e.target.name)} />,
                render: function (value, record, recordIndex, data, ref) {
                    return <Link className="btn btn-primary btn-sm"
                        title={"Order No."}
                        to={"/sales/order/view?" + queryString.stringify({ ordNum: value })}
                    >{value}</Link>;
                }
            },
            {
                key: "loggerName",
                label: "Name",
                type: 'loggerName',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 1,
                searchNode: <Input type="select" name="loggerName" value={filterRequest.loggerName}
                    onChange={(e) => this.handleChange(e.target.value, "loggerName")}>
                    <option select value="" disabled>Select Name</option>
                    <option value="All">All</option>
                    <option value="Product_Builder_Order_Submitted">Product Builder Order Submitted</option>
                    <option value="Product_Builder_Order_Stock_Allocated_Error">Product Builder Order Stock Allocated
                        Error
                    </option>
                </Input>,
                render: function (value, record, recordIndex, data, ref) {
                    return value.replace(/_/g, " ");
                }
            },
            {
                key: "createDate",
                label: "Create Date",
                type: 'createDate',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{changeFormatOfDateString(value, 'DD/MM/YYYY HH:mm:ss', 'DD MMM YYYY HH:mm')}</span>
                }
            },
            {
                key: "fullname",
                label: "User",
                type: 'text',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 1,
                searchNode: <Input type={"text"} name={"fullname"}
                    placeholder={"Search"} value={filterRequest.fullname}
                    onChange={(e) => this.handleChange(e.target.value, e.target.name)} />,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "logLevel",
                label: "Log Level",
                type: 'logLevel',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 1,
                searchNode: <Input type="select" name="loglevel" value={filterRequest.loglevel}
                    onChange={(e) => this.handleChange(e.target.value, "loglevel")}>
                    <option select value="" disabled>Select Log Level</option>
                    <option value="All">All</option>
                    <option value="Warning">Warning</option>
                    <option value="Information">Information</option>
                    <option value="Error">Error</option>
                </Input>,
                render: function (value, record, recordIndex, data, ref) {
                    let color = "primary";
                    switch (value) {
                        case "Error":
                            color = "danger";
                            break;
                        case "Information":
                            color = "primary";
                            break;
                        case "Warning":
                            color = "warning";
                            break;
                    }
                    return <Badge color={color}>{value}</Badge>;
                }
            },
            {
                key: "status",
                label: "Status",
                type: 'status',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: true,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 1,
                searchNode: <Input type="select" name="status" value={filterRequest.status}
                    onChange={(e) => this.handleChange(e.target.value, "status")}>
                    <option select value="" disabled>Select Status</option>
                    <option value="All">All</option>
                    <option value="InProcess">InProcess</option>
                    <option value="Resolved">Resolved</option>
                    <option value="Pending">Pending</option>
                </Input>,
                render: function (value, record, recordIndex, data, ref) {
                    let colour = "warning";
                    if (value === "Pending") {
                        colour = "warning";
                    }
                    else if (value === "InProcess") {
                        colour = "info";
                    }
                    else {
                        colour = "success";
                    }

                    return <Badge color={colour}
                        href="javascript:void(0)"
                        onClick={(e) => ref.toggleLogStatusModal(true, record)}
                        className={"ml-2"}>
                        {value}
                        <i className="fa fa-pencil ml-1"
                            aria-hidden="true" />
                    </Badge>
                }
            },
            {
                key: "comment",
                label: "Comment",
                type: 'comment',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <div>
                        <span className="text-center">{value}</span>
                        <Button
                            color="link"
                            size={"sm"}
                            title={"click here to edit"}
                            onClick={() => ref.toggleLogCommentModal(true, record)}>
                            <i className="fa fa-pencil"
                                aria-hidden="true" />
                        </Button></div>
                }
            },
            {
                key: "trigger",
                label: "Retry",
                type: 'trigger',
                colSpan: 1,
                minWidth: 25,
                sortApplicable: false,
                valueClassName: "text-center align-middle",
                labelClassName: "text-center align-middle",
                searchNodeColSpan: 1,
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    if (record.isRetry) {
                        return <Button color={"primary"} size={"sm"}
                            onClick={(e) => ref.toggleApiRetryModal(true, record)}>Retry
                        </Button>;
                    } else {
                        return <span>N/A</span>
                    }
                }
            }
        ];
    }


    toggleApiLogModal(change, selectedLog) {
        this.setState({ isOpenLogModal: change, selectedLog });
    }

    toggleApiRetryModal(change, selectedLog) {
        if (change) {
            this.getLog(selectedLog.id, "isOpenApiRetryModal", true)
        } else {
            this.setState({ isOpenApiRetryModal: false, selectedLog: {} });
        }

    }

    toggleLogStatusModal(change, selectedLog) {
        this.setState({ isOpenLogStatusModal: change, selectedLog });
    }

    toggleLogCommentModal(change, selectedLog) {
        this.setState({ isOpenLogCommentModal: change, selectedLog });
    }

    toggleBulkUpdateModal(change, isUpdateStatus) {
        this.setState({ isUpdateStatus: isUpdateStatus, isOpenUpdateBulkModal: change })
    }

    handleSearch(e) {
        this.setState({ searchText: e.target.value });
    }



    render() {
        let {
            logDataTable, isOpenLogModal, selectedLog, isOpenLogStatusModal, isOpenLogCommentModal,
            isOpenApiRetryModal, isOpenUpdateBulkModal, isUpdateStatus, searchText
        } = this.state;
        let { request, response } = logDataTable;
        let store = this.getStore(logDataTable);
        return (
            <div>
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active> Api Logs</BreadcrumbItem>
                </Breadcrumb>
                <Card>

                    <CardHeader>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    <h5>Api Logs</h5>
                                </div>
                            </Col>

                        </Row>
                    </CardHeader>
                    <CardBody>
                    
                        <div className="d-flex align-items-center justify-content-between">
                        
                            <div className={"text-left ml-3"}>
                                {logDataTable.isLoading ? <p>Loading...</p> :
                                    <p>Showing
                                        {' '}{((logDataTable.request.pageRequest.currentPage - 1) * logDataTable.request.pageRequest.pageSize) + 1}
                                        {' '}to {((logDataTable.request.pageRequest.currentPage) * logDataTable.request.pageRequest.pageSize)}
                                        {' '}of {logDataTable.response.totalRecords}
                                        {' '}entries</p>
                                }
                                </div>
                         
                    
                    
                                <div className={"text-right"}>
                                    {
                                        logDataTable.response.records.some(x => x.isSelected) ?
                                            <>
                                                <UncontrolledButtonDropdown className="p-2">
                                                    <DropdownToggle caret color={"primary"} size='md' disabled={isOpenUpdateBulkModal}
                                                        outline={true}>
                                                        {isOpenUpdateBulkModal
                                                            ? <Spinner size="sm"
                                                                className={"mr-2"}
                                                                style={{ color: "blue" }} />
                                                            : <i className="fa fa-floppy-o ml-1" aria-hidden="true" />}
                                                        {" "}Update Selected
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem title={'Click here to update status of orders in bulk'}
                                                            onClick={() => this.toggleBulkUpdateModal(!isOpenUpdateBulkModal, true)}>
                                                            <i className="fa fa-floppy-o ml-1" aria-hidden="true" />{" "}Status
                                                        </DropdownItem>
                                                        <DropdownItem title={'Click here to update comment of orders in bulk'}
                                                            onClick={() => this.toggleBulkUpdateModal(!isOpenUpdateBulkModal, false)}>
                                                            <i className="fa fa-floppy-o ml-1" aria-hidden="true" /> {" "}Comment
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </>
                                        : null}
                                </div>
                       
                        </div>
                        <Table bordered responsive size={"sm"} striped={true}>
                            <thead>
                                <tr>
                                    {(store || []).map((column, index) => {
                                        return (
                                            <th key={index}
                                                onClick={(e) => column.sortApplicable && this.handleChange(column.key, "sortKey")}
                                                colSpan={column.colSpan}
                                                className={column.labelClassName}
                                                style={{ minWidth: column.minWidth, width: column.width }}>
                                                {column.label}
                                                {
                                                    column.sortApplicable ?
                                                        <i className={classnames("fa", "float-right", "pt-1", {
                                                            "fa-sort": (request.sortRequest.key !== column.key),
                                                            "fa-sort-amount-asc": (request.sortRequest.key === column.key && request.sortRequest.direction),
                                                            "fa-sort-amount-desc": (request.sortRequest.key === column.key && !request.sortRequest.direction)
                                                        })} aria-hidden="true" /> : null
                                                }

                                            </th>
                                        )
                                    })}
                                </tr>
                                <tr>

                                    {(store || []).map((column, index) => {
                                        return (
                                            <td key={index} colSpan={column.colSpan}
                                                className={column.valueClassName}>{column.searchNode}</td>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    logDataTable.isLoading
                                        ? <tr>
                                            <td colSpan={(store || []).length}><Spinner color={"primary"} /></td>
                                        </tr>
                                        : (response.records || []).map((row, rowIndex) =>
                                            <tr key={rowIndex} className={"align-middle"}>
                                                {(store || []).map((column, columnIndex) => {
                                                    return (
                                                        <td key={columnIndex} className={column.valueClassName}>
                                                            {column.render(row[column.key], row, rowIndex, logDataTable, this)}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        )}
                            </tbody>
                        </Table>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType={"prepend"}>
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input type={"select"}
                                            name={"pageSize"}
                                            value={request.pageRequest.pageSize}
                                            disabled={logDataTable.isLoading}
                                            onChange={(e) => this.handleChange(e.target.value, e.target.name)}>
                                            <option value={10}>10 Rows</option>
                                            <option value={20}>20 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={12} sm={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={request.pageRequest.currentPage}
                                        itemsCountPerPage={request.pageRequest.pageSize}
                                        totalItemsCount={response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(e) => this.handleChange(e, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active' />
                                </div>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
                {
                    isOpenLogModal ?
                        <ApiLogDetailModal
                            isOpen={isOpenLogModal}
                            toggle={this.toggleApiLogModal}
                            log={selectedLog} />
                        : null
                }
                {
                    isOpenApiRetryModal ?
                        <ApiRetryModal
                            isOpen={isOpenApiRetryModal}
                            toggle={this.toggleApiRetryModal}
                            log={selectedLog} />
                        : null
                }
                {
                    isOpenLogStatusModal ?
                        <UpdateApiLogStatusModal
                            isOpen={isOpenLogStatusModal}
                            toggleModal={this.toggleLogStatusModal}
                            log={selectedLog}
                            refreshAgain={this.getLogs}
                        />
                        : null
                }
                {
                    isOpenLogCommentModal ?
                        <UpdateApiLogCommentModal
                            isOpen={isOpenLogCommentModal}
                            toggleModal={this.toggleLogCommentModal}
                            log={selectedLog}
                            refreshAgain={this.getLogs}
                        />
                        : null
                }
                {
                    isOpenUpdateBulkModal ?
                        <UpdateApiLogBulkStatusComment
                            isUpdateStatus={isUpdateStatus}
                            isOpen={isOpenUpdateBulkModal}
                            toggleModal={this.toggleBulkUpdateModal}
                            logs={logDataTable.response.records}
                            refreshAgain={this.clearSelection}
                        />
                        : null
                }
                <ToastContainer />
            </div>
        );
    }
}