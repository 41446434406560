import React from 'react';

import {cloneDeep, isEmpty} from 'lodash';
import salesOrderProductBuilderV1Service from '../../../../../../services/sales/SalesOrderProductBuilderV1Service';

const sizeOptions = [900, 1200, 1500, 1800, 2100, 2400, 2700, 3000];
import {widthExtenders, zipJoiners} from '../../../../../../store/AppConstants';

class BistroUtil {

    static Instance() {
        return new BistroUtil();
    }


    updateFormError(key, product, itemIndex, order) {
        let width, drop, isAccessoryOnly, isWallAnchorChecked, isWidthExtenderChecked, isZipJoinerChecked;
        width = product.items[itemIndex].configuration.width.selected.value;
        drop = product.items[itemIndex].configuration.drop.selected.value;
        isAccessoryOnly = this.isAccessoryOnly(product, itemIndex);
        isWallAnchorChecked = this.isWallAnchorChecked(product, itemIndex);
        isWidthExtenderChecked = this.isWidthExtenderChecked(product, itemIndex);
        isZipJoinerChecked = this.isZipJoinerChecked(product, itemIndex);

        if (product.items.length > 0 && product.items[itemIndex]) {
            let formError = {
                isValid: true,
                message: ""
            };
            switch (key) {
                case "quantity":
                    if ((!product.items[itemIndex].configuration.quantity.selected.value) || (product.items[itemIndex].configuration.quantity.selected.value < product.items[itemIndex].configuration.quantity.min) || (product.items[itemIndex].configuration.quantity.selected.value > product.items[itemIndex].configuration.quantity.max)) {
                        formError = {
                            isValid: false,
                            message: "Expected quantity between " + product.items[itemIndex].configuration.quantity.min + " and " + product.items[itemIndex].configuration.quantity.max,
                        };
                    }
                    product.items[itemIndex].configuration.quantity.formError = formError;
                    break;
                case "width":
                    if(product.items[itemIndex].configuration.cutOn.selected.value == ""){
                        formError = {
                            isValid: false,
                            message: "Expected a selection of the Cut Type.",
                        };
                    }
                    else if (product.items[itemIndex].configuration.width.selected.isSelectable && ((!width) || (width < product.items[itemIndex].configuration.width.min) || (width > product.items[itemIndex].configuration.width.max))) {
                        formError = {
                            isValid: false,
                            message: "Expected width between " + product.items[itemIndex].configuration.width.min + " and " + product.items[itemIndex].configuration.width.max,
                        };
                    }
                    product.items[itemIndex].configuration.width.formError = formError;
                    break;
                case "drop":
                    if(product.items[itemIndex].configuration.cutOn.selected.value == ""){
                        formError = {
                            isValid: false,
                            message: "Expected a selection of the Cut Type.",
                        };
                    }
                    else if ((!drop) || (drop < product.items[itemIndex].configuration.drop.min) || (drop > product.items[itemIndex].configuration.drop.max)) {
                        formError = {
                            isValid: false,
                            message: "Expected drop between " + product.items[itemIndex].configuration.drop.min + " and " + product.items[itemIndex].configuration.drop.max,
                        };
                    }
                    product.items[itemIndex].configuration.drop.formError = formError;
                    break;
                case "wallAnchor":
                    if (isWallAnchorChecked) {
                        if (product.items[itemIndex].configuration.wallAnchor.selected.value < 1) {
                            formError = {
                                isValid: false,
                                message: "Wall Anchor is ticked but quantity not selected!",
                            };
                        }
                        /*if(formError.isValid){
                            if (!isEmpty(product.items[itemIndex].configuration.fineline.selected.value.wallAnchor.standard)) {
                               if( product.items[itemIndex].configuration.fineline.selected.value.wallAnchor.standard.soldOut){
                                   formError = {
                                       isValid: false,
                                       message: "Wall Anchor sold out!",
                                   };

                               }
                            }
                        }*/
                    }
                    product.items[itemIndex].configuration.wallAnchor.formError = formError;
                    break;
                case "widthExtenders":
                    if (isWidthExtenderChecked) {
                        if (!(widthExtenders.some(attribute => product.items[itemIndex].configuration[attribute.key].selected.value > 0))) {
                            formError = {
                                isValid: false,
                                message: "Width Extenders is ticked but quantity not selected!",
                            };
                        }
                        /*if(formError.isValid){
                            widthExtenders.forEach(widthExtender => {
                                if (!isEmpty(product.items[itemIndex].configuration.fineline.selected.value[widthExtender.key].standard)) {
                                    if(product.items[itemIndex].configuration.fineline.selected.value[widthExtender.key].standard.soldOut){
                                        formError = {
                                            isValid: false,
                                            message: "Width Extenders sold out!",
                                        };
                                    }
                                }
                            });
                        }*/
                    }
                    product.items[itemIndex].configuration.widthExtenders.formError = formError;
                    break;
                case "zipJoiners":
                    if (isZipJoinerChecked) {
                        if (!(zipJoiners.some(attribute => product.items[itemIndex].configuration[attribute.key].selected.value > 0))) {
                            formError = {
                                isValid: false,
                                message: "Zip Joiners is ticked but quantity not selected!",
                            };
                        }
                        /*if(formError.isValid){
                            zipJoiners.forEach(zipJoiner => {
                                if (!isEmpty(product.items[itemIndex].configuration.fineline.selected.value[zipJoiner.key].standard)) {
                                    if(product.items[itemIndex].configuration.fineline.selected.value[zipJoiner.key].standard.soldOut){
                                        formError = {
                                            isValid: false,
                                            message: "Zip Joiners sold out!",
                                        };
                                    }
                                }
                            });
                        }*/
                    }
                    product.items[itemIndex].configuration.zipJoiners.formError = formError;
                    break;
                case "parentStockItem":
                    if (!isAccessoryOnly) {
                        if (!(product.items[itemIndex].stocks.some(s => s.isParentItem))) {
                            formError = {
                                isValid: false,
                                message: "Fineline item missing",
                            };
                        }
                        /*if(formError.isValid){
                            if (product.items[itemIndex].configuration.fineline.selected.value.product && product.items[itemIndex].configuration.fineline.selected.value.product.soldOut) {
                                formError = {
                                    isValid: false,
                                    message: "Sold out!",
                                };
                            }
                        }*/
                    }
                    product.items[itemIndex].configuration.fineline.formError = formError;
                    break;
                case "cutOn":
                    if (product.items[itemIndex].configuration.cutOn.selected.value == "") {
                        formError = {
                            isValid: false,
                            message: "Expected a selection of the Cut Type. Please specify the type of cut from the given options.",
                        };
                    }
                    product.items[itemIndex].configuration.cutOn.formError = formError;
                    break;
                default:
                    break;
            }

            product.items[itemIndex].isValid = this.isFormErrorItemValid(product, itemIndex);
        }

        return product;
    }

    isFormErrorItemValid(product, itemIndex) {
        return product.items[itemIndex].configuration.width.formError.isValid
            && product.items[itemIndex].configuration.drop.formError.isValid
            && product.items[itemIndex].configuration.quantity.formError.isValid
            && product.items[itemIndex].configuration.fineline.formError.isValid
            && product.items[itemIndex].configuration.wallAnchor.formError.isValid
            && product.items[itemIndex].configuration.widthExtenders.formError.isValid
            && product.items[itemIndex].configuration.zipJoiners.formError.isValid
            && product.items[itemIndex].configuration.cutOn.formError.isValid
    }

    updateRules(key, product, itemIndex, order) {
        let isAccessoryOnly = this.isAccessoryOnly(product, itemIndex);
        switch (key) {
            case "quantity":
                product.items[itemIndex].configuration.quantity.min = 1;
                product.items[itemIndex].configuration.quantity.max = 1;
                product.items[itemIndex].configuration.quantity.selected.isSelectable = false;
                break;
            case "width":
                switch (product.items[itemIndex].configuration.cutOn.selected.value.optionKey) {
                    case "Accessory Only":
                        product.items[itemIndex].configuration.width.min = 0;
                        product.items[itemIndex].configuration.width.max = 0;
                        product.items[itemIndex].configuration.width.selected.isSelectable = false;
                        break;
                    case "Width Cut":
                        product.items[itemIndex].configuration.width.min = 450;
                        product.items[itemIndex].configuration.width.max = 3000;
                        product.items[itemIndex].configuration.width.selected.isSelectable = true;
                        break;
                    case "Drop Cut":
                        product.items[itemIndex].configuration.width.min = 900;
                        product.items[itemIndex].configuration.width.max = 3000;
                        product.items[itemIndex].configuration.width.selected.isSelectable = true;
                        break;
                }
                break;
            case "drop":
                switch (product.items[itemIndex].configuration.cutOn.selected.value.optionKey) {
                    case "Accessory Only":
                        product.items[itemIndex].configuration.drop.min = 300;
                        product.items[itemIndex].configuration.drop.max = 3000;
                        product.items[itemIndex].configuration.drop.selected.isSelectable = true;
                        break;
                    case "Width Cut":
                        product.items[itemIndex].configuration.drop.min = 2400;
                        product.items[itemIndex].configuration.drop.max = 2400;
                        product.items[itemIndex].configuration.drop.selected.isSelectable = false;
                        break;
                    case "Drop Cut":
                        product.items[itemIndex].configuration.drop.min = 300;
                        product.items[itemIndex].configuration.drop.max = 3000;
                        product.items[itemIndex].configuration.drop.selected.isSelectable = true;
                        break;
                }
                break;
        }
        return product;
    }

    isAccessoryOnly(product, itemIndex) {
        return (product.items[itemIndex].configuration.cutOn.selected.value.optionKey === "Accessory Only")
    }

    updateStocks(key, product, itemIndex, order) {
        let comment, stocks, stockIndex, label, attribute, width, drop, condition1 = "", condition2 = "",
            condition3 = "", setIndex,
            isStandardSize, isStandardDrop, isStandardWidth, isAccessoryOnly, isStandardWidthExtenderDrop;
        width = product.items[itemIndex].configuration.width.selected.value;
        drop = product.items[itemIndex].configuration.drop.selected.value;
        isStandardSize = this.isStandardSize("size", product, itemIndex);
        isStandardDrop = this.isStandardSize("drop", product, itemIndex);
        isStandardWidth = this.isStandardSize("width", product, itemIndex);
        isStandardWidthExtenderDrop = this.isStandardSize("widthExtenderDrop", product, itemIndex);
        isAccessoryOnly = this.isAccessoryOnly(product, itemIndex);
        switch (key) {
            case "model":
                attribute = "model";
                label = "";
                comment = "";
                stocks = [];

                if (!isAccessoryOnly) {
                    if (!isEmpty(product.items[itemIndex].configuration.model.selected.value)) {
                        product.items[itemIndex].configuration.model.selected.value.sets.forEach(set => {
                            stocks = [...stocks, ...set.stocks]
                        });
                    }
                    stocks.forEach((stockItem, stockIndex) => {
                        this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                    });
                }

                product.items[itemIndex].configuration.model.selected.stocks = stocks;
                break;
            case "wallAnchor":
                attribute = "Wall Anchor";
                label = "";
                comment = "";
                stocks = [];
                condition1 = product.items[itemIndex].configuration.model.selected.value.description;
                condition2 = isStandardDrop ? "Standard" : "OffRange";
                condition3 = product.items[itemIndex].configuration.wallAnchorColour.selected.value.optionKey;
                if (product.items[itemIndex].configuration.wallAnchor.selected.value) {
                    product.items[itemIndex].configuration.wallAnchor.finalOptions.forEach(o => {
                        switch (o.optionKey) {
                            case "standardProdCode":
                                o.sets.forEach(set => {
                                    if (set.condition1 === condition1) {
                                        if (set.condition2 === condition3) {
                                            if ((drop >= set.secondaryMin && drop <= set.secondaryMax)) {
                                                (set.stocks || []).forEach(stockItem => {
                                                    stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                                    stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                                    product.items[itemIndex].configuration.fineline.selected.value.wallAnchor.standard = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem);
                                                    product.items[itemIndex].configuration.offRange.selected.value.wallAnchor.standard = salesOrderProductBuilderV1Service.createOffRangeItemInstance(stockItem);
                                                    stocks.push(stockItem);
                                                });
                                            }
                                        }
                                    }

                                });
                                break;
                            case "makeProdCode":
                                o.sets.forEach(set => {
                                    if (set.condition1 === condition1) {
                                        if (set.description === condition2) {
                                            if (set.condition2 === condition3) {
                                                if ((drop >= set.secondaryMin && drop <= set.secondaryMax)) {
                                                    (set.stocks || []).forEach(stockItem => {
                                                        stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                                        stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                                        product.items[itemIndex].configuration.fineline.selected.value.wallAnchor.make = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem);
                                                        product.items[itemIndex].configuration.offRange.selected.value.wallAnchor.make = salesOrderProductBuilderV1Service.createOffRangeItemInstance(stockItem);
                                                    });
                                                }
                                            }
                                        }
                                    }
                                });
                                break;
                        }
                    })
                }

                stocks.forEach((stockItem, stockIndex) => {
                    stockItem.quantityMultiplier = product.items[itemIndex].configuration.wallAnchor.selected.value;
                    stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                    stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                    stockItem.makeProdCode = product.items[itemIndex].configuration.fineline.selected.value.wallAnchor.make.prodCode;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.wallAnchor.selected.stocks = stocks;
                break;
            case "widthExtender50mm":
                attribute = "Width Extender 50mm";
                label = "";
                comment = "";
                stocks = [];
                condition1 = product.items[itemIndex].configuration.model.selected.value.description;
                condition2 = "widthExtender50mm";
                condition3 = isStandardWidthExtenderDrop ? "Standard" : "OffRange";
                if (product.items[itemIndex].configuration.widthExtender50mm.selected.value) {
                    product.items[itemIndex].configuration.widthExtender50mm.finalOptions.forEach(o => {
                        switch (o.optionKey) {
                            case "standardProdCode":
                                o.sets.forEach(set => {
                                    if (set.condition1 === condition1) {
                                        if (set.condition2 === condition2) {
                                            if ((drop >= set.secondaryMin && drop <= set.secondaryMax)) {
                                                (set.stocks || []).forEach(stockItem => {
                                                    stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                                    stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                                    product.items[itemIndex].configuration.fineline.selected.value.widthExtender50mm.standard = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem);
                                                    product.items[itemIndex].configuration.offRange.selected.value.widthExtender50mm.standard = salesOrderProductBuilderV1Service.createOffRangeItemInstance(stockItem);
                                                    stocks.push(stockItem);
                                                });
                                            }
                                        }
                                    }
                                });
                                break;
                            case "makeProdCode":
                                o.sets.forEach(set => {
                                    if (set.condition1 === condition1) {
                                        if (set.condition2 === condition2) {
                                            if (set.description === condition3) {
                                                if ((drop >= set.secondaryMin && drop <= set.secondaryMax)) {
                                                    (set.stocks || []).forEach(stockItem => {
                                                        stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                                        stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                                        product.items[itemIndex].configuration.fineline.selected.value.widthExtender50mm.make = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem);
                                                        product.items[itemIndex].configuration.offRange.selected.value.widthExtender50mm.make = salesOrderProductBuilderV1Service.createOffRangeItemInstance(stockItem);
                                                    });
                                                }
                                            }
                                        }
                                    }
                                });
                                break;
                        }
                    })
                }

                stocks.forEach((stockItem, stockIndex) => {
                    stockItem.quantityMultiplier = product.items[itemIndex].configuration.widthExtender50mm.selected.value;
                    stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                    stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                    stockItem.makeProdCode = product.items[itemIndex].configuration.fineline.selected.value.widthExtender50mm.make.prodCode;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.widthExtender50mm.selected.stocks = stocks;
                break;
            case "widthExtender100mm":
                attribute = "Width Extender 100mm";
                label = "";
                comment = "";
                stocks = [];
                condition1 = product.items[itemIndex].configuration.model.selected.value.description;
                condition2 = "widthExtender100mm";
                condition3 = isStandardWidthExtenderDrop ? "Standard" : "OffRange";
                if (product.items[itemIndex].configuration.widthExtender100mm.selected.value) {
                    product.items[itemIndex].configuration.widthExtender100mm.finalOptions.forEach(o => {
                        switch (o.optionKey) {
                            case "standardProdCode":
                                o.sets.forEach(set => {
                                    if (set.condition1 === condition1) {
                                        if (set.condition2 === condition2) {
                                            if ((drop >= set.secondaryMin && drop <= set.secondaryMax)) {
                                                (set.stocks || []).forEach(stockItem => {
                                                    stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                                    stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                                    product.items[itemIndex].configuration.fineline.selected.value.widthExtender100mm.standard = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem);
                                                    product.items[itemIndex].configuration.offRange.selected.value.widthExtender100mm.standard = salesOrderProductBuilderV1Service.createOffRangeItemInstance(stockItem);
                                                    stocks.push(stockItem);
                                                });
                                            }
                                        }
                                    }
                                });
                                break;
                            case "makeProdCode":
                                o.sets.forEach(set => {
                                    if (set.condition1 === condition1) {
                                        if (set.condition2 === condition2) {
                                            if (set.description === condition3) {
                                                if ((drop >= set.secondaryMin && drop <= set.secondaryMax)) {
                                                    (set.stocks || []).forEach(stockItem => {
                                                        stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                                        stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                                        product.items[itemIndex].configuration.fineline.selected.value.widthExtender100mm.make = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem);
                                                        product.items[itemIndex].configuration.offRange.selected.value.widthExtender100mm.make = salesOrderProductBuilderV1Service.createOffRangeItemInstance(stockItem);
                                                    });
                                                }
                                            }
                                        }
                                    }
                                });
                                break;
                        }
                    })
                }

                stocks.forEach((stockItem, stockIndex) => {
                    stockItem.quantityMultiplier = product.items[itemIndex].configuration.widthExtender100mm.selected.value;
                    stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                    stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                    stockItem.makeProdCode = product.items[itemIndex].configuration.fineline.selected.value.widthExtender100mm.make.prodCode;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.widthExtender100mm.selected.stocks = stocks;
                break;
            case "widthExtender150mm":
                attribute = "Width Extender 150mm";
                label = "";
                comment = "";
                stocks = [];
                condition1 = product.items[itemIndex].configuration.model.selected.value.description;
                condition2 = "widthExtender150mm";
                condition3 = isStandardWidthExtenderDrop ? "Standard" : "OffRange";
                if (product.items[itemIndex].configuration.widthExtender150mm.selected.value) {
                    product.items[itemIndex].configuration.widthExtender150mm.finalOptions.forEach(o => {
                        switch (o.optionKey) {
                            case "standardProdCode":
                                o.sets.forEach(set => {
                                    if (set.condition1 === condition1) {
                                        if (set.condition2 === condition2) {
                                            if ((drop >= set.secondaryMin && drop <= set.secondaryMax)) {
                                                (set.stocks || []).forEach(stockItem => {
                                                    stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                                    stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                                    product.items[itemIndex].configuration.fineline.selected.value.widthExtender150mm.standard = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem);
                                                    product.items[itemIndex].configuration.offRange.selected.value.widthExtender150mm.standard = salesOrderProductBuilderV1Service.createOffRangeItemInstance(stockItem);
                                                    stocks.push(stockItem);
                                                });
                                            }
                                        }
                                    }
                                });
                                break;
                            case "makeProdCode":
                                o.sets.forEach(set => {
                                    if (set.condition1 === condition1) {
                                        if (set.condition2 === condition2) {
                                            if (set.description === condition3) {
                                                if ((drop >= set.secondaryMin && drop <= set.secondaryMax)) {
                                                    (set.stocks || []).forEach(stockItem => {
                                                        stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                                        stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                                        product.items[itemIndex].configuration.fineline.selected.value.widthExtender150mm.make = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem);
                                                        product.items[itemIndex].configuration.offRange.selected.value.widthExtender150mm.make = salesOrderProductBuilderV1Service.createOffRangeItemInstance(stockItem);
                                                    });
                                                }
                                            }
                                        }
                                    }
                                });
                                break;
                        }
                    })
                }

                stocks.forEach((stockItem, stockIndex) => {
                    stockItem.quantityMultiplier = product.items[itemIndex].configuration.widthExtender150mm.selected.value;
                    stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                    stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                    stockItem.makeProdCode = product.items[itemIndex].configuration.fineline.selected.value.widthExtender150mm.make.prodCode;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.widthExtender150mm.selected.stocks = stocks;
                break;
            case "zipJoiner100mm":
                attribute = "Zip Joiner 100mm";
                label = "";
                comment = "";
                stocks = [];
                condition1 = product.items[itemIndex].configuration.model.selected.value.description;
                condition2 = "zipJoiner100mm";
                condition3 = isStandardDrop ? "Standard" : "OffRange";
                if (product.items[itemIndex].configuration.zipJoiner100mm.selected.value) {
                    product.items[itemIndex].configuration.zipJoiner100mm.finalOptions.forEach(o => {
                        switch (o.optionKey) {
                            case "standardProdCode":
                                o.sets.forEach(set => {
                                    if (set.condition1 === condition1) {
                                        if (set.condition2 === condition2) {
                                            if ((drop >= set.secondaryMin && drop <= set.secondaryMax)) {
                                                (set.stocks || []).forEach(stockItem => {
                                                    stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                                    stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                                    product.items[itemIndex].configuration.fineline.selected.value.zipJoiner100mm.standard = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem);
                                                    product.items[itemIndex].configuration.offRange.selected.value.zipJoiner100mm.standard = salesOrderProductBuilderV1Service.createOffRangeItemInstance(stockItem);
                                                    stocks.push(stockItem);
                                                });
                                            }
                                        }
                                    }
                                });
                                break;
                            case "makeProdCode":
                                o.sets.forEach(set => {
                                    if (set.condition1 === condition1) {
                                        if (set.condition2 === condition2) {
                                            if (set.description === condition3) {
                                                if ((drop >= set.secondaryMin && drop <= set.secondaryMax)) {
                                                    (set.stocks || []).forEach(stockItem => {
                                                        stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                                        stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                                        product.items[itemIndex].configuration.fineline.selected.value.zipJoiner100mm.make = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem);
                                                        product.items[itemIndex].configuration.offRange.selected.value.zipJoiner100mm.make = salesOrderProductBuilderV1Service.createOffRangeItemInstance(stockItem);
                                                    });
                                                }
                                            }
                                        }
                                    }
                                });
                                break;
                        }
                    })
                }

                stocks.forEach((stockItem, stockIndex) => {
                    stockItem.quantityMultiplier = product.items[itemIndex].configuration.zipJoiner100mm.selected.value;
                    stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                    stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                    stockItem.makeProdCode = product.items[itemIndex].configuration.fineline.selected.value.zipJoiner100mm.make.prodCode;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.zipJoiner100mm.selected.stocks = stocks;
                break;
            case "zipJoiner200mm":
                attribute = "Zip Joiner 200mm";
                label = "";
                comment = "";
                stocks = [];
                condition1 = product.items[itemIndex].configuration.model.selected.value.description;
                condition2 = "zipJoiner200mm";
                condition3 = isStandardDrop ? "Standard" : "OffRange";
                if (product.items[itemIndex].configuration.zipJoiner200mm.selected.value) {
                    product.items[itemIndex].configuration.zipJoiner200mm.finalOptions.forEach(o => {
                        switch (o.optionKey) {
                            case "standardProdCode":
                                o.sets.forEach(set => {
                                    if (set.condition1 === condition1) {
                                        if (set.condition2 === condition2) {
                                            if ((drop >= set.secondaryMin && drop <= set.secondaryMax)) {
                                                (set.stocks || []).forEach(stockItem => {
                                                    stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                                    stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                                    product.items[itemIndex].configuration.fineline.selected.value.zipJoiner200mm.standard = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem);
                                                    product.items[itemIndex].configuration.offRange.selected.value.zipJoiner200mm.standard = salesOrderProductBuilderV1Service.createOffRangeItemInstance(stockItem);
                                                    stocks.push(stockItem);
                                                });
                                            }
                                        }
                                    }
                                });
                                break;
                            case "makeProdCode":
                                o.sets.forEach(set => {
                                    if (set.condition1 === condition1) {
                                        if (set.condition2 === condition2) {
                                            if (set.description === condition3) {
                                                if ((drop >= set.secondaryMin && drop <= set.secondaryMax)) {
                                                    (set.stocks || []).forEach(stockItem => {
                                                        stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                                        stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                                        product.items[itemIndex].configuration.fineline.selected.value.zipJoiner200mm.make = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem);
                                                        product.items[itemIndex].configuration.offRange.selected.value.zipJoiner200mm.make = salesOrderProductBuilderV1Service.createOffRangeItemInstance(stockItem);
                                                    });
                                                }
                                            }
                                        }
                                    }
                                });
                                break;
                        }
                    })
                }

                stocks.forEach((stockItem, stockIndex) => {
                    stockItem.quantityMultiplier = product.items[itemIndex].configuration.zipJoiner200mm.selected.value;
                    stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                    stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                    stockItem.makeProdCode = product.items[itemIndex].configuration.fineline.selected.value.zipJoiner200mm.make.prodCode;
                    this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                });
                product.items[itemIndex].configuration.zipJoiner200mm.selected.stocks = stocks;
                break;
            case "bom":
                attribute = "Bistro";
                label = "";
                comment = "";
                stocks = [];

                if (!isAccessoryOnly) {
                    (product.items[itemIndex].configuration.bom.options || []).forEach(o => {
                        switch (o.optionKey) {
                            case "MakeBuild":

                                condition1 = product.items[itemIndex].configuration.model.selected.value.description;
                                condition2 = isStandardSize ? "" : (isStandardWidth ? (isStandardDrop ? "" : "Drop Cut") : "Width Cut");
                                condition3 = isStandardSize ? "Standard" : "OffRange";

                                o.sets.forEach(s => {
                                    if ((s.condition1 ? s.condition1 === (condition1) : true)) {
                                        if ((s.description ? s.description === (condition3) : true)) {
                                            if ((s.condition2 ? s.condition2 === (condition2) : true)) {
                                                if ((width >= s.min && width <= s.max)) {
                                                    if ((drop >= s.secondaryMin && drop <= s.secondaryMax)) {
                                                        (s.stocks || []).forEach(stockItem => {
                                                            stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                                            stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                                            product.items[itemIndex].configuration.fineline.selected.value.makeBuild = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem, "makeBuild");
                                                        });
                                                    }
                                                }
                                            }
                                        }
                                    }
                                });
                                break;
                            case "BunningsBuild":
                                condition1 = product.items[itemIndex].configuration.model.selected.value.description;
                                condition2 = isStandardSize ? "Standard" : "OffRange";

                                setIndex = o.sets.findIndex(s => {
                                    if ((s.condition1 ? s.condition1 === (condition1) : true)) {
                                        switch (condition2) {
                                            case "Standard":
                                                if ((width >= s.min) && (width <= s.max)) {
                                                    if ((drop >= s.secondaryMin) && (drop <= s.secondaryMax)) {
                                                        return true;
                                                    }
                                                }
                                                break;
                                            case "OffRange":
                                                if (width > 0 && drop > 0) {
                                                    if (width <= parseInt(s.setKey)) {
                                                        if (drop <= parseInt(s.setLabel)) {
                                                            return true;
                                                        }
                                                    }
                                                }
                                                break;
                                        }
                                    }
                                });
                                if (setIndex > -1) {
                                    (o.sets[setIndex].stocks || []).forEach(stockItem => {
                                        stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                        stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                        stockItem.makeProdCode = product.items[itemIndex].configuration.fineline.selected.value.makeBuild.prodCode;
                                        product.items[itemIndex].configuration.fineline.selected.value.product = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem, "product");
                                        product.items[itemIndex].configuration.offRange.selected.value.product = salesOrderProductBuilderV1Service.createOffRangeItemInstance(stockItem);
                                    });
                                    stocks = [...stocks, ...o.sets[setIndex].stocks];
                                }
                                break;
                            case "Cut Fee":
                                condition1 = product.items[itemIndex].configuration.cutOn.selected.value.optionKey;

                                if (!isStandardSize) {
                                    o.sets.forEach(s => {
                                        if ((s.condition1 ? s.condition1 === (condition1) : true)) {
                                            (s.stocks || []).forEach(stockItem => {
                                                stockItem.shpStockItem = salesOrderProductBuilderV1Service.findShpStockItem(product, stockItem.prodCode);
                                                stockItem.dimStockItem = salesOrderProductBuilderV1Service.findDimStockItem(stockItem.shpStockItem);
                                                product.items[itemIndex].configuration.fineline.selected.value.cutFee = salesOrderProductBuilderV1Service.createFinelineItemInstance(stockItem, "cutFee");
                                                product.items[itemIndex].configuration.offRange.selected.value.cutFee = salesOrderProductBuilderV1Service.createOffRangeItemInstance(stockItem, "cutFee");
                                            });
                                            stocks = [...stocks, ...s.stocks];
                                        }
                                    });
                                }
                                break;
                        }
                    });

                    stocks.forEach((stockItem, stockIndex) => {
                        this.calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, product.builder.stockByProdCode, comment);
                    });
                }

                product.items[itemIndex].configuration.bom.selected.stocks = stocks;
                break;
        }
        return product;
    }

    doCalculation(key, product, itemIndex) {
        let width = product.items[itemIndex].configuration.width.selected.value;
        let drop = product.items[itemIndex].configuration.drop.selected.value;

        let deduction = salesOrderProductBuilderV1Service.initDeduction();
        switch (key) {
            case "keyway":
                deduction.deductionWidth = -65;
                break;
            case "hooding":
                deduction.deductionWidth = -35;
                break;
            case "hoodRailHole":
                deduction.deductionWidth = -30;
                break;
            case "bottomRail":
                deduction.deductionWidth = -45;
                break;

            case "fabric":
                deduction.deductionWidth = -65;
                break;
        }
        deduction.cutWidth = width + (deduction.deductionWidth);
        deduction.cutDrop = drop + (deduction.deductionDrop);
        return deduction;
    }

    updateProductLevelData(product, packagingAndHandling) {
        let quantity = 0;
        product.maxWidth = 0;
        product.pricing.price = 0;
        product.pricing.discVal = 0;
        product.pricing.discount = 0;
        product.pricing.packagingAndHandlingCharges = 0;
        product.items.forEach((item, itemIndex) => {
            product.pricing.price += product.items[itemIndex].pricing.price;
            product.pricing.discVal += product.items[itemIndex].pricing.discVal;
            product.pricing.discount = product.items[itemIndex].pricing.discount;
            quantity = product.items[itemIndex].configuration.quantity.selected.value;
            product.pricing.packagingAndHandlingCharges += salesOrderProductBuilderV1Service.calculatePackagingAndHandlingCharges(product, quantity, packagingAndHandling);
            if (product.maxWidth < product.items[itemIndex].configuration.width.selected.value) {
                product.maxWidth = product.items[itemIndex].configuration.width.selected.value;
            }
        });
        return product;
    }

    updateItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {

            product.items[itemIndex].configuration.fineline.selected.value = {
                product: {},
                cutFee: {},
                makeBuild: {},
                wallAnchor: {standard: {}, make: {}},
                widthExtender50mm: {standard: {}, make: {}},
                widthExtender100mm: {standard: {}, make: {}},
                widthExtender150mm: {standard: {}, make: {}},
                zipJoiner100mm: {standard: {}, make: {}},
                zipJoiner200mm: {standard: {}, make: {}},
            };

            product = this.updateStocks("model", product, itemIndex, order);
            product = this.updateStocks("bom", product, itemIndex, order);
            product = this.updateStocks("wallAnchor", product, itemIndex, order);
            widthExtenders.forEach(widthExtender => {
                product = this.updateStocks(widthExtender.key, product, itemIndex, order);
            });
            zipJoiners.forEach(zipJoiner => {
                product = this.updateStocks(zipJoiner.key, product, itemIndex, order);
            });

        }
        return product;
    }

    getItemStocks(product, itemIndex, order) {
        if (product.items[itemIndex]) {
            let stocks = [];
            stocks = [...stocks, ...product.items[itemIndex].configuration.model.selected.stocks];
            stocks = [...stocks, ...product.items[itemIndex].configuration.wallAnchor.selected.stocks];
            stocks = [...stocks, ...product.items[itemIndex].configuration.bom.selected.stocks];
            widthExtenders.forEach(widthExtender => {
                stocks = [...stocks, ...product.items[itemIndex].configuration[widthExtender.key].selected.stocks];
            });
            zipJoiners.forEach(zipJoiner => {
                stocks = [...stocks, ...product.items[itemIndex].configuration[zipJoiner.key].selected.stocks];
            });
            if (this.isAccessoryOnly(product, itemIndex)) {
                if (stocks && stocks.length > 0) {
                    stocks[0].isParentItem = true;
                }
            }
            product.items[itemIndex].stocks = stocks;
        }
        return product;
    }

    getItemPrice(product, itemIndex, discountByProductCode) {
        if (product.items[itemIndex]) {
            let discount = discountByProductCode[product.code] ? discountByProductCode[product.code].discount : 0;
            let qty = product.items[itemIndex].configuration.quantity.selected.value;
            product.items[itemIndex].pricing = salesOrderProductBuilderV1Service.calculatePricing(product, qty, product.items[itemIndex].stocks, product.items[itemIndex].pricing, discount);
        }
        return product;
    }

    updateProductItem(product, itemIndex, order, packagingAndHandling, discountByProductCode, showError) {
        product = this.updateItemStocks(product, itemIndex, order);
        product = this.getItemStocks(product, itemIndex);
        if (showError) {
            product = this.updateFormError("width", product, itemIndex, order);
            product = this.updateFormError("drop", product, itemIndex, order);
            product = this.updateFormError("quantity", product, itemIndex, order);
            product = this.updateFormError("wallAnchor", product, itemIndex, order);
            product = this.updateFormError("widthExtenders", product, itemIndex, order);
            product = this.updateFormError("zipJoiners", product, itemIndex, order);
            product = this.updateFormError("parentStockItem", product, itemIndex, order);
        }
        product = this.getItemPrice(product, itemIndex, discountByProductCode);
        product = this.updateProductLevelData(product, packagingAndHandling, discountByProductCode);
        return product;
    }

    replaceFormulaParamsWithValues(stockQtyExpression, params) {
        stockQtyExpression = stockQtyExpression.replaceAll("@Width", params.width);
        stockQtyExpression = stockQtyExpression.replaceAll("@Drop", params.drop);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutWidth", params.cutWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@CutDrop", params.cutDrop);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLength", params.stockLength);
        stockQtyExpression = stockQtyExpression.replaceAll("@StockLinearWidth", params.stockLinearWidth);
        stockQtyExpression = stockQtyExpression.replaceAll("@SwishConversionFactor", params.swishConversionFactor);
        return stockQtyExpression;
    };

    resolveFormulaExpression(result, expression, params) {
        try {
            expression = this.replaceFormulaParamsWithValues(expression, params);
            eval(expression);
        }
        catch (err) {
            console.error(expression);
            result = 1;
        }
        return result;
    }

    calculateStockQtyExpressionResult(stockItem, stockIndex, stocks, product, itemIndex, attribute, label, stockByProdCode, comment) {
        let result = 1;
        let width = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.width.selected.value : 0;
        let drop = (itemIndex !== null && itemIndex > -1) ? product.items[itemIndex].configuration.drop.selected.value : 0;
        let stockInventoryItem = stockByProdCode[stockItem.prodCode];
        let deduction = {};
        stockItem.width = 0;
        stockItem.drop = 0;
        stockItem.length = (stockInventoryItem && stockInventoryItem.length > 0) ? stockInventoryItem.length * 1.0 : 1;
        stockItem.stockLinearWidth = (stockInventoryItem && stockInventoryItem.linearWidth > 0) ? stockInventoryItem.linearWidth * 1.0 : 1;

        let params = {
            width: width,
            drop: drop,
            stockLength: stockItem.length,
            stockLinearWidth: stockItem.stockLinearWidth,
            swishConversionFactor: stockItem.swishConversionFactor,
        };

        switch (attribute) {
            default:
                stockItem.width = stockItem.isParentItem ? width : 0;
                stockItem.drop = stockItem.isParentItem ? drop : 0;
                stockItem.swishCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.swishQuantityFormula ? stockItem.swishQuantityFormula.formula : "", params)) * stockItem.swishConversionFactor;
                stockItem.keywayCalculatedQty = (stockItem.quantityMultiplier * this.resolveFormulaExpression(result, stockItem.keywayQuantityFormula ? stockItem.keywayQuantityFormula.formula : "", params)) * stockItem.keywayConversionFactor;
                stockItem.wastagePercent = stockItem.wastageFormula ? stockItem.wastageFormula.wastagePercent : 0;
                stockItem.attribute = attribute;
                stockItem.label = label;
                stockItem.attributeRowSpan = stockIndex === 0 ? stocks.length : 0;
                stockItem.deductionQty = 0;
                stockItem.deductionWidth = 0;
                stockItem.deductionDrop = 0;
                stockItem.cutWidth = 0;
                stockItem.cutDrop = 0;
        }
    }

    validateItem(pg, itemIndex, order, errors) {
        let errorMessagePrefix = pg.name + " | Row-" + (itemIndex + 1) + " | ";

        if (!pg.items[itemIndex].configuration.quantity.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.quantity.formError.message);
        }
        if (!pg.items[itemIndex].configuration.width.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.width.formError.message);
        }
        if (!pg.items[itemIndex].configuration.drop.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.drop.formError.message);
        }
        if (!pg.items[itemIndex].configuration.fineline.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.fineline.formError.message);
        }
        if (!pg.items[itemIndex].configuration.wallAnchor.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.wallAnchor.formError.message);
        }
        if (!pg.items[itemIndex].configuration.widthExtenders.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.widthExtenders.formError.message);
        }
        if (!pg.items[itemIndex].configuration.zipJoiners.formError.isValid) {
            errors.push(errorMessagePrefix + pg.items[itemIndex].configuration.zipJoiners.formError.message);
        }

        return errors;
    }

    isStandardSize(key, product, itemIndex) {
        let result = false;
        let width = product.items[itemIndex].configuration.width.selected.value;
        let drop = product.items[itemIndex].configuration.drop.selected.value;
        switch (key) {
            case "size":
                result = (this.isStandardSize("width", product, itemIndex) && this.isStandardSize("drop", product, itemIndex));
                break;
            case "width":
                result = (sizeOptions.includes(width));
                break;
            case "drop":
                result = (drop === 2400);
                break;
            case "widthExtenderDrop":
                result = (drop <= 2400);
                break;
        }
        return result;
    }

    getAccessoryList(product, itemIndex) {
        let accessories = [];

        if (!isEmpty(product.items[itemIndex].configuration.fineline.selected.value.wallAnchor.standard)) {
            accessories.push(product.items[itemIndex].configuration.fineline.selected.value.wallAnchor.standard)
        }
        widthExtenders.forEach(widthExtender => {
            if (!isEmpty(product.items[itemIndex].configuration.fineline.selected.value[widthExtender.key].standard)) {
                accessories.push(product.items[itemIndex].configuration.fineline.selected.value[widthExtender.key].standard)
            }
        });
        zipJoiners.forEach(zipJoiner => {
            if (!isEmpty(product.items[itemIndex].configuration.fineline.selected.value[zipJoiner.key].standard)) {
                accessories.push(product.items[itemIndex].configuration.fineline.selected.value[zipJoiner.key].standard)
            }
        });
        return accessories;
    }

    getAccessoryListOffRange(product, itemIndex) {
        let accessories = [];

        if (!isEmpty(product.items[itemIndex].configuration.offRange.selected.value.wallAnchor.standard)) {
            accessories.push(product.items[itemIndex].configuration.offRange.selected.value.wallAnchor.standard)
        }
        widthExtenders.forEach(widthExtender => {
            if (!isEmpty(product.items[itemIndex].configuration.offRange.selected.value[widthExtender.key].standard)) {
                accessories.push(product.items[itemIndex].configuration.offRange.selected.value[widthExtender.key].standard)
            }
        });
        zipJoiners.forEach(zipJoiner => {
            if (!isEmpty(product.items[itemIndex].configuration.offRange.selected.value[zipJoiner.key].standard)) {
                accessories.push(product.items[itemIndex].configuration.offRange.selected.value[zipJoiner.key].standard)
            }
        });
        return accessories;
    }

    getAccessoryListMake(product, itemIndex) {
        let accessories = [];

        if (!isEmpty(product.items[itemIndex].configuration.fineline.selected.value.wallAnchor.make)) {
            accessories.push(product.items[itemIndex].configuration.fineline.selected.value.wallAnchor.make)
        }
        widthExtenders.forEach(widthExtender => {
            if (!isEmpty(product.items[itemIndex].configuration.fineline.selected.value[widthExtender.key].make)) {
                accessories.push(product.items[itemIndex].configuration.fineline.selected.value[widthExtender.key].make)
            }
        });
        zipJoiners.forEach(zipJoiner => {
            if (!isEmpty(product.items[itemIndex].configuration.fineline.selected.value[zipJoiner.key].make)) {
                accessories.push(product.items[itemIndex].configuration.fineline.selected.value[zipJoiner.key].make)
            }
        });
        return accessories;
    }

    isWallAnchorChecked(product, itemIndex) {
        return product.items[itemIndex].configuration.wallAnchor.selected.value !== "";
    }

    isWidthExtenderChecked(product, itemIndex) {
        return widthExtenders.some(attribute => product.items[itemIndex].configuration[attribute.key].selected.value !== "")
    }

    isZipJoinerChecked(product, itemIndex) {
        return zipJoiners.some(attribute => product.items[itemIndex].configuration[attribute.key].selected.value !== "");
    }

    toSalesOrderItemBistroRequest(product, itemIndex, order) {
        let isStandardSize = this.isStandardSize("size", product, itemIndex);

        let salesOrderItemBistro = {
            ID: product.items[itemIndex].configuration.ID ? product.items[itemIndex].ID : null,
            salesOrderItemID: product.items[itemIndex].configuration.salesOrderItemID ? product.items[itemIndex].salesOrderItemID : null,
            salesOrderID: product.items[itemIndex].configuration.salesOrderID ? product.items[itemIndex].salesOrderID : null,
            quantity: product.items[itemIndex].configuration.quantity.selected.value,
            width: product.items[itemIndex].configuration.width.selected.value,
            drop: product.items[itemIndex].configuration.drop.selected.value,
            model: product.items[itemIndex].configuration.model.selected.value.optionKey,
            colour: product.items[itemIndex].configuration.model.selected.value.description,
            standardProdCode: product.items[itemIndex].configuration.fineline.selected.value.product.prodCode,
            makeProdCode: product.items[itemIndex].configuration.fineline.selected.value.makeBuild.prodCode,
            type: isStandardSize ? "Standard" : "OffRange",
            cutOn: product.items[itemIndex].configuration.cutOn.selected.value.optionKey,
            wallAnchor: salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, "value", 0, "wallAnchor"),
            wallAnchorColour: salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, "optionKey", "", "wallAnchorColour"),
            wallAnchorStandardProdCode: product.items[itemIndex].configuration.fineline.selected.value.wallAnchor.standard.prodCode,
            wallAnchorMakeProdCode: product.items[itemIndex].configuration.fineline.selected.value.wallAnchor.make.prodCode,
            widthExtender50mm: salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, "value", 0, "widthExtender50mm"),
            widthExtender50mmStandardProdCode: product.items[itemIndex].configuration.fineline.selected.value.widthExtender50mm.standard.prodCode,
            widthExtender50mmMakeProdCode: product.items[itemIndex].configuration.fineline.selected.value.widthExtender50mm.make.prodCode,
            widthExtender100mm: salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, "value", 0, "widthExtender100mm"),
            widthExtender100mmStandardProdCode: product.items[itemIndex].configuration.fineline.selected.value.widthExtender100mm.standard.prodCode,
            widthExtender100mmMakeProdCode: product.items[itemIndex].configuration.fineline.selected.value.widthExtender100mm.make.prodCode,
            widthExtender150mm: salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, "value", 0, "widthExtender150mm"),
            widthExtender150mmStandardProdCode: product.items[itemIndex].configuration.fineline.selected.value.widthExtender150mm.standard.prodCode,
            widthExtender150mmMakeProdCode: product.items[itemIndex].configuration.fineline.selected.value.widthExtender150mm.make.prodCode,
            zipJoiner100mm: salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, "value", 0, "zipJoiner100mm"),
            zipJoiner100mmStandardProdCode: product.items[itemIndex].configuration.fineline.selected.value.zipJoiner100mm.standard.prodCode,
            zipJoiner100mmMakeProdCode: product.items[itemIndex].configuration.fineline.selected.value.zipJoiner100mm.make.prodCode,
            zipJoiner200mm: salesOrderProductBuilderV1Service.getValueOrDefault(product, itemIndex, "value", 0, "zipJoiner200mm"),
            zipJoiner200mmStandardProdCode: product.items[itemIndex].configuration.fineline.selected.value.zipJoiner200mm.standard.prodCode,
            zipJoiner200mmMakeProdCode: product.items[itemIndex].configuration.fineline.selected.value.zipJoiner200mm.make.prodCode,
            stocks: product.items[itemIndex].stocks,
        };

        return salesOrderItemBistro;
    }

}

export default BistroUtil.Instance();