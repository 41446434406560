import React, { Component } from "react";
import {
    Modal, ModalBody, ModalHeader,
    ModalFooter, Spinner,
    Button, FormGroup, Input,
    Row, Col, Label, FormText
} from 'reactstrap';
import { toast, ToastContainer } from "react-toastify";
import { handleErrorMessage } from '../../services/CommonService';
import stockService from '../../services/StockService';
import { cloneDeep } from 'lodash';
export default class StockGroupManageModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStockGroup: cloneDeep(this.props.selectedStockGroup),
            stockMasterGroupCode: this.props.stockMasterGroupCode,
            formErrors: {}
        } 
    }

    handleChange(key, change) {
        let { selectedStockGroup } = this.state;
        selectedStockGroup[key] = change;
        this.setState({ selectedStockGroup });
    }

    validateForm(item) {
        let formErrors = {}, valid = true;
        if (!item.name) {
            formErrors.name = "Enter Stock Group Name";
            valid = false;
        }
        if (!item.code) {
            formErrors.code = "Enter Stock Group Code";
            valid = false;
        }
        if (!item.salesAccountGL) {
            formErrors.salesAccountGL = "Enter Sales Account GL Name";
            valid = false;
        }
        if (isNaN(item.categoryAttributeId)) {
            formErrors.categoryAttributeId = "Category Attribute Id Must be Number";
            valid = false;
        }
        return ({
            valid: valid,
            formErrors: formErrors
        })
    }

    saveStockGroup() {
        let { selectedStockGroup } = this.state;
        let validateResult = this.validateForm(selectedStockGroup);
        if (!validateResult.valid) {
            this.setState({ formErrors: validateResult.formErrors });
            return;
        }
        this.setState({ formErrors: {} });
        stockService.saveStockGroupAllDetail(selectedStockGroup).then(response => {
            if (response.status === 200 || response.status === "200") {
                toast.success("Saved Successfully", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.props.refreshAgain();
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            })
        })
    }
  

    render() {
        let { isOpen, toggle, stockMasterGroupCode, productGroupCode, productCode } = this.props;
        let { formErrors, selectedStockGroup } = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" scrollable={false} toggle={() => toggle(!isOpen)}>
                <ModalHeader toggle={() => toggle(!isOpen)}> 
                    {selectedStockGroup.stockGroupID ? 'Edit' : 'Add'} Stock Group
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="name">Name*</Label>
                                <Input type="text" name="name" 
                                    value={selectedStockGroup.name}
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    placeholder="Enter name here" />
                                <FormText color="danger">{formErrors.name}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="fullName">Code*</Label>
                                <Input type="text" name="code" 
                                    value={selectedStockGroup.code}
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    placeholder="Enter Stock Group Code here" />
                                <FormText color="danger">{formErrors.code}</FormText>
                            </FormGroup>
                        </Col>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="fullName">Stock Master Group Code*</Label>
                                <Input type={"select"} id="stockMasterGroupCode" name={"masterGroupID"}
                                    value={selectedStockGroup.masterGroupID}
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}> 
                                    {stockMasterGroupCode.map((option, index) =>
                                        <option key={index} value={option.masterGroupID}>{option.code}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="name">Product Group Code*</Label>
                                <Input type={"select"} id="productGroupCode" name="productGroupId"
                                    value={selectedStockGroup.productGroupId || ""}
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}>
                                    <option value={""}>Select Product Group Code</option>
                                    {productGroupCode.map((option, index) =>
                                        <option key={index} value={option.productGroupId}>{option.code}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="name">Product Code*</Label>
                                <Input type={"select"} id="productCode" name="productId"
                                    value={selectedStockGroup.productId}
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}>
                                    <option value={""}>Select Product Code</option>
                                    {productCode.map((option, index) =>
                                        <option key={index} value={option.id}>{option.code}</option>
                                    )}
                                    </Input>
                            </FormGroup>
                        </Col>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="salesAccountGL">Sales Account GL*</Label>
                                <Input type="text" name="salesAccountGL"
                                    value={selectedStockGroup.salesAccountGL}
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    placeholder="Enter SalesAccountGL here" />
                                <FormText color="danger">{formErrors.salesAccountGL}</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <FormGroup>
                                <Label for="categoryAttributeId">Category Attribute Id*</Label>
                                <Input type="text" name="categoryAttributeId"
                                    value={selectedStockGroup.categoryAttributeId}
                                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                    placeholder="Enter Category Attribute Id here" />
                                <FormText color="danger">{formErrors.categoryAttributeId}</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={'4'} lg={'4'} md={'6'} sm={'12'} xs={'12'}>
                            <div className='ml-2'>
                                <h5>Charges</h5>
                                <div>
                                    <Label>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("allowDiscount", !selectedStockGroup.allowDiscount)}>
                                            {
                                                selectedStockGroup.allowDiscount
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                        AllowDiscount
                                    </Label>
                                </div>
                                <div>
                                    <Label>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("freightWarning", !selectedStockGroup.freightWarning)}>
                                            {
                                                selectedStockGroup.freightWarning
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                        FreightWarning
                                    </Label>
                                </div>
                                <div>
                                    <Label>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("cod", !selectedStockGroup.cod)}>
                                            {
                                                selectedStockGroup.cod
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                        COD
                                    </Label>
                                </div>
                                <div>
                                    <Label>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("freight", !selectedStockGroup.freight)}>
                                            {
                                                selectedStockGroup.freight
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                        Freight
                                    </Label>
                                </div>
                                <div>
                                    <Label>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("excludeValue", !selectedStockGroup.excludeValue)}>
                                            {
                                                selectedStockGroup.excludeValue
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                        Exclude Value
                                    </Label>
                                </div>
                                <div>
                                    <Label>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("fuelSurcharge", !selectedStockGroup.fuelSurcharge)}>
                                            {
                                                selectedStockGroup.fuelSurcharge
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                        Fuel Surcharge
                                    </Label>
                                </div>
                            </div>
                        </Col>
                        <Col xl={'6'} lg={'6'} md={'6'} sm={'12'} xs={'12'}>
                            <div className='ml-2'>
                                <h5>Miscellaneous</h5>
                                <div>
                                    <Label>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("noCrossRefMessage", !selectedStockGroup.noCrossRefMessage)}>
                                            {
                                                selectedStockGroup.noCrossRefMessage
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                        No Cross Ref Message
                                    </Label>
                                </div>
                                <div>
                                    <Label>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("allowDuplicateOnPurchaseOrder", !selectedStockGroup.allowDuplicateOnPurchaseOrder)}>
                                            {
                                                selectedStockGroup.allowDuplicateOnPurchaseOrder
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                        Allow Duplicate On Purchase Order
                                    </Label>
                                </div>
                                <div>
                                    <Label>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("excludeFromPOSuggestions", !selectedStockGroup.excludeFromPOSuggestions)}>
                                            {
                                                selectedStockGroup.excludeFromPOSuggestions
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                        Exclude From PO Suggestions
                                    </Label>
                                </div>
                                <div>
                                    <Label>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("allowNegativeQOH", !selectedStockGroup.allowNegativeQOH)}>
                                            {
                                                selectedStockGroup.allowNegativeQOH
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                        Allow Negative QOH
                                    </Label>
                                </div>
                                <div>
                                    <Label>
                                        <Button color={"link"}
                                            size={"sm"}
                                            onClick={() => this.handleChange("doNotTrackQOH", !selectedStockGroup.doNotTrackQOH)}>
                                            {
                                                selectedStockGroup.doNotTrackQOH
                                                    ? <i className="fa fa-lg fa-fw fa-check-square-o"
                                                        aria-hidden="true" />
                                                    : <i className="fa fa-lg fa-fw fa-square-o" aria-hidden="true" />
                                            }
                                        </Button>
                                        Do Not Track QOH
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                          
                </ModalBody>
                <ModalFooter>
                    <div className="text-right">
                        <Button color={"primary"} size="sm" onClick={() => this.saveStockGroup()}>
                            <i className="fa fa-floppy-o mr-2" />
                            Save</Button>
                        <Button color={"secondary"} size="sm" className="ml-2" onClick={() => toggle(!isOpen)}>
                            <i className="fa fa-times mr-2" />
                            Cancel</Button>

                    </div>
                </ModalFooter>
                <ToastContainer/>
            </Modal>
        );
    }
}