import React, {Component} from 'react';
import {
    Card, CardHeader, CardBody,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Button
} from 'reactstrap';
import classnames from 'classnames';
import ProductionCapacity from './ProductionCapacity';
import ProductionCapacityCalender from './ProductionCapacityCalender';
import {ToastContainer} from 'react-toastify';
import ProductionCapacityDayWise from './ProductionCapacityDayWise';
//import ProductionConfiguration from "./ProductionConfiguration";

const allTabs = [
    {
        label: "Default",
        key: "default"
    },
    {
        label: "By Day",
        key: "dayWise"
    },
    {
        label: "Calendar",
        key: "calender"
    }
];

export default class ProductionCapacityTabs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            activeTab: allTabs[0].key,
            refreshAgainCalenderTab: false,
        };
        this.refreshAgain = this.refreshAgain.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab, refreshAgainCalenderTab: false
            });
        }
    }

    refreshAgain(data) {
        this.setState({refreshAgainCalenderTab: data});
    }

    render() {
        let {activeTab} = this.state;

        return (
            <div>
                <h4>Production Capacity</h4>
                <Card>
                    <CardHeader>
                        <Nav tabs card>
                            {allTabs.map((tab, index) =>
                                <NavItem
                                    className={"hoverableItem"}
                                    key={index}>
                                    <NavLink
                                        className={classnames({active: activeTab === tab.key})}
                                        onClick={() => {
                                            this.toggle(tab.key);
                                        }}>
                                        <Button size={"sm"} color={"link"} style={{textDecoration: "none"}}>
                                            {tab.label}&nbsp;
                                        </Button>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </CardHeader>
                    <CardBody>

                        <TabContent activeTab={activeTab}>
                            <br/>
                            <TabPane tabId="default">
                                <div>
                                    <ProductionCapacity refreshAgain={this.refreshAgain}/>
                                </div>
                            </TabPane>
                            <TabPane tabId="calender">
                                <ProductionCapacityCalender refreshAgain={this.state.refreshAgainCalenderTab}/>
                            </TabPane>
                            <TabPane tabId="dayWise">
                                <ProductionCapacityDayWise refreshAgain={this.refreshAgain}/>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>

                <ToastContainer/>
            </div>
        );
    }
}