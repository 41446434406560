

export const DebtorInvoiceStatusByID = {
    "": "",
    "0": "Active",
    "1": "Ready To Pick",
    "2": "Being Picked",
    "3": "Being Despatched",
    "4": "Invoiced",
    "5": "Completed",
    "-1": "Cancelled",
};

export const Debtors_Categories = [
    { label: "Gold", value: "1" },
    { label: "Silver", value: "2" },
    { label: "Blue", value: "3" },
];

export const Debtors_Order_types = {
    "1": "Order",
    "2": "Invoice",
    "3": "Quote"
};