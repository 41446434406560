import {authHeader} from '../../helpers/auth-header';
import {config} from '../../helpers/config'
import axios from '../axios';


class ProductionDescheduleService {


    static Instance() {
        return new ProductionDescheduleService();
    }

    getProductionDeschedule() {
        return axios.post('api/production/deschedule/list');
    }

    getProductionDescheduleReasons() {
        return axios.post('api/production/deschedule/reasons/list');
    }

    descheduleOrder(request) {
        return axios.post('api/production/schedule/deschedule/order', request);
    }

    scheduleOrder(request) {
        return axios.post('api/production/deschedule/schedule/order', request);
    }

    getProductionLines() {
        return axios.post('api/production/lines/list');
    }

    getStore() {
        return [
            {
                key: "ordNum",
                label: "Order No.",
                type: "orderNumLink"
            }, {
                key: "productionLineID",
                label: "Production Line",
                type: "productionLineID"
            }, {
                key: "fabric",
                label: "Fabric",
                type: "default"
            }, {
                key: "qty",
                label: "Qty",
                type: "number"
            }, {
                key: "itemCount",
                label: "Item Count",
                type: "number"
            }, {
                key: "isMultiProductOrder",
                label: "Multi-Product",
                type: "boolean"
            }, {
                key: "deScheduleReasonId",
                label: "Reason",
                type: "deScheduleReasonId"
            }, {
                key: "followUpDate",
                label: "Follow-Up Date",
                type: "utcDateString"
            }, {
                key: "notes",
                label: "Notes",
                type: "default"
            }, {
                key: "scheduleOn",
                label: "Schedule",
                type: "scheduleOn"
            }
        ]
    }

}

export default ProductionDescheduleService.Instance();