import React, {Component} from "react";
import {
    Button,
    CardBody,
    CardFooter,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Spinner,
    Table,
} from "reactstrap";
import RoleService from "../../services/RoleService";
import {handleErrorMessage} from "../../services/CommonService";
import {toast, ToastContainer} from "react-toastify";
import classnames from 'classnames';
import Pagination from "react-js-pagination";
import ManageUserRoleModal from '../../components/modal/ManageUserRoleModal';
import RoleUserModal from '../../components/modal/RoleUserModal';

export default class UserRolePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userRole: {
                request: {
                    pageRequest: {pageSize: 50, currentPage: 1},
                    sortRequest: {key: '', direction: false},
                    filterRequest: {name: '', description: ''}
                },
                response: {
                    records: [],
                    totalRecords: 0
                }
            },
            isLoading: false,
            isOpenModal: false,
            selectedItem: {},
            isUserCountOpenModal: false,
            isEdit: false
        };
        this.roleService = new RoleService();
        this.getHeadingList = this.getHeadingList.bind(this);
        this.openModal = this.openModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.refreshAgain = this.refreshAgain.bind(this);
        this.toggleUserCountModal = this.toggleUserCountModal.bind(this);
        this.openUserCountModal = this.openUserCountModal.bind(this);

    }

    componentDidMount() {
        this.getRoleList();
    }

    getHeadingList({filterRequest}) {
        let heading = [
            {
                key: "srNo",
                label: "Sr. No",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "",
                labelClassName: "align-middle",
                searchNodeColSpan: 1,
                searchNode: null


            },
            {
                key: "name",
                label: "Name",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: <div><Input type="text" id="name" name="name" value={filterRequest.name}
                                        onChange={(e) => this.handleChange(e.target.value, "name")}
                                        placeholder="Search... "/>
                </div>


            },
            {
                key: "description",
                label: "Description",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: true,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: <div><Input type="text" id="description" name="description"
                                        value={filterRequest.description}
                                        onChange={(e) => this.handleChange(e.target.value, "description")}
                                        placeholder="Search... "/>
                </div>


            },
            {
                key: "userCount",
                label: "User Count",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: null


            },
            {
                key: "dashboardCount",
                label: "Dashboard Card Count",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: null


            },
            {
                key: "privilegeCount",
                label: "Privilege's Count",
                type: "text",
                colSpan: 1,
                minWidth: 25,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "hoverableItem align-middle",
                searchNodeColSpan: 1,
                searchNode: null


            },
            {
                key: "action",
                label: "Action",
                type: "action",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: false,
                valueClassName: "text-center",
                labelClassName: "align-middle text-center",
                searchNodeColSpan: 1,
                searchNode: <div className="text-center"><Button size={"sm"} color={"primary"}
                                                                 onClick={() => this.openModal({})}>
                    <i className="fa fa-plus mr-2"
                       aria-hidden="true"/>Add </Button></div>

            }];
        return heading;
    }

    openModal(userRole, isEdit) {
        this.setState({isOpenModal: true, selectedItem: userRole, isEdit: isEdit});
    }

    toggleModal(change) {
        this.setState({isOpenModal: change});
    }

    toggleUserCountModal(change) {
        this.setState({isUserCountOpenModal: change});
    }

    openUserCountModal(userRole) {
        this.setState({isUserCountOpenModal: true, selectedItem: userRole});
    }

    refreshAgain() {
        this.setState({isOpenModal: false});
        this.getRoleList();
    }

    handleChange(change, key) {
        let {userRole} = this.state;
        switch (key) {
            case "sortKey":
                if (userRole.request.sortRequest.key === change) {
                    userRole.request.sortRequest.direction = !userRole.request.sortRequest.direction;
                } else {
                    userRole.request.sortRequest.key = change;
                    userRole.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getRoleList(userRole);
                }
                break;
            case "pageSize":
                userRole.request.pageRequest[key] = parseInt(change);
                userRole.request.pageRequest.currentPage = 1;
                this.getRoleList(userRole);
                break;
            case "currentPage":
                userRole.request.pageRequest[key] = change;
                this.getRoleList(userRole);
                break;


            default:
                userRole.request.filterRequest[key] = change;
                userRole.request.pageRequest.currentPage = 1;
                this.getRoleList(userRole);
                this.setState({userRole});
        }
    }

    getRoleList() {
        this.setState({isLoading: true});
        let {userRole} = this.state;
        this.roleService.getUserRoleList(userRole.request).then(response => {
            userRole.response = response.data;
            this.setState({userRole, isLoading: false});
        }).catch(error => {
            toast.error(handleErrorMessage(error), {position: toast.POSITION.BOTTOM_CENTER});
        })
    }

    render() {
        let {userRole, isLoading, isOpenModal, selectedItem, isUserCountOpenModal, isEdit} = this.state;
        let {pageRequest, sortRequest} = userRole.request;
        let heading = this.getHeadingList(userRole.request);
        return (
            <div>
                <CardBody>
                    <Row>
                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                            <div className={"text-left"}>
                                {isLoading ? <Spinner className="primary"/> :
                                    <p>Showing
                                        {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                        {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                        {' '}of {userRole.response.totalRecords}
                                        {' '}entries</p>
                                }
                            </div>
                        </Col>
                    </Row>
                    <div>
                        <Table striped bordered responsive hover size='sm'>
                            <thead>
                            <tr>
                                {(heading || []).map((item, index) => {
                                    return (
                                        <th key={index}
                                            onClick={() => this.handleChange(item.key, "sortKey")}
                                            colSpan={item.colSpan}
                                            className={item.labelClassName}
                                            style={{minWidth: item.minWidth}}>
                                            {item.label}
                                            {
                                                item.sorterApplicable ?
                                                    <i className={classnames("fa", "float-right", "pt-1", {
                                                            "fa-sort": (sortRequest.key !== item.key),
                                                            "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                            "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                        }
                                                    )} aria-hidden="true"/> : null
                                            }

                                        </th>
                                    );
                                })}
                            </tr>
                            <tr>
                                {(heading || []).map((item, index) => {
                                    return (
                                        <td key={index} colSpan={item.colSpan} className={"align-middle"}>
                                            {item.searchNode}
                                        </td>
                                    );
                                })}
                            </tr>

                            </thead>

                            <tbody>
                            {(userRole.response.records || []).map((userRole, index) =>

                                <tr key={index}>
                                    <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                    <td>{userRole.role.name}</td>
                                    <td>{userRole.role.description}</td>
                                    <td className="text-center"><Button size={"sm"} color={"primary"}
                                                                        onClick={() => this.openUserCountModal(userRole)}>
                                        {userRole.userCount}
                                    </Button></td>
                                    <td className="text-center">{userRole.dashboardCount}</td>
                                    <td className="text-center">{userRole.role.privileges.length}</td>
                                    <td className="text-center"><Button size={"sm"} color={"primary"}
                                                                        onClick={() => this.openModal(userRole, true)}>
                                        <i className="fa fa-pencil mr-2"
                                           aria-hidden="true"/>
                                        Edit
                                    </Button></td>
                                </tr>
                            )}

                            </tbody>
                        </Table>
                    </div>
                </CardBody>
                {isOpenModal ?
                    <ManageUserRoleModal
                        toggle={this.toggleModal}
                        selectedItem={selectedItem}
                        isOpen={isOpenModal}
                        isEdit={isEdit}
                        refreshAgain={this.refreshAgain}
                        roles={this.state.userRole.response.records}
                    /> : null}
                {isUserCountOpenModal ?
                    <RoleUserModal
                        toggle={this.toggleUserCountModal}
                        selectedItem={selectedItem}
                        isOpen={isUserCountOpenModal}
                    />
                    : null
                }

                <CardFooter>
                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <div className={"text-left"} style={{maxWidth: 200}}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Show</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type={"select"}
                                        name={"pageSize"}
                                        value={pageRequest.pageSize}
                                        disabled={isLoading}
                                        onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                        <option value={10}>10 Rows</option>
                                        <option value={25}>25 Rows</option>
                                        <option value={50}>50 Rows</option>
                                        <option value={100}>100 Rows</option>
                                        <option value={500}>500 Rows</option>
                                    </Input>
                                </InputGroup>


                            </div>
                        </Col>
                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                            <div className={"float-right"}>
                                <Pagination
                                    activePage={pageRequest.currentPage}
                                    itemsCountPerPage={pageRequest.pageSize}
                                    totalItemsCount={userRole.response.totalRecords}
                                    pageRangeDisplayed={3}
                                    onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                    itemClass='page-item'
                                    linkClass='page-link'
                                    activeClass='active'
                                    innerClass='pagination'
                                    activeLinkClass='active'
                                />
                            </div>

                        </Col>
                    </Row>
                </CardFooter>
                <br/>
                <ToastContainer/>
            </div>
        );
    }
}