import React, { Component } from 'react';
import { Card, CardHeader, Col, Table } from "reactstrap";
import NumberFormat from "react-number-format";
import SalesOrderService from '../../services/sales/SalesOrderService';
import { getSorted } from '../../services/CommonService';
import { Link } from "react-router-dom";

export default class OutstandingOrderSummaryCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderSummaryData: [],
            loading: true
        };

        this.salesOrderService = new SalesOrderService();
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.salesOrderService.getOutstandingOrderSummary().then(res => {
            let { orderSummaryData } = this.state;
            let data = res.data;

            if (data) {
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].sortIndex) {
                        data[i].sortIndex = (5000 + i);
                    }
                }

                orderSummaryData = getSorted(data, 'sortIndex', true)
            }
            this.setState({ orderSummaryData, loading: false })
        });
    }

    render() {
        const { orderSummaryData, loading } = this.state;
        if (loading) {
            return null;
        }
        return (
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className={"p-2"}>

                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Orders In Production
                            <span style={{ float: "right" }}>
                                <Link to="/production/schedule">
                                    <i className={"fa fa-pencil"} />
                                </Link>
                            </span></h6>
                    </CardHeader>
                    <div>
                        <Table striped hover size={"sm"} responsive bordered className={"mb-0"}>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th className="text-right">Total Orders</th>
                                    <th className="text-right">Total Units</th>
                                    <th className="text-right">Cuts</th>
                                    <th className="text-right">Days Outstanding</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (orderSummaryData || []).map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{data.productGroupDisplayName}</td>
                                                <td className="text-right"><NumberFormat value={data.totalOrders}
                                                    decimalScale={0}
                                                    displayType={'text'}
                                                    thousandSeparator={true} />
                                                </td>
                                                <td className="text-right"><NumberFormat value={data.totalUnits}
                                                    decimalScale={0}
                                                    displayType={'text'}
                                                    thousandSeparator={true} />
                                                </td>
                                                <td className="text-right"><NumberFormat value={data.cuts}
                                                    decimalScale={0}
                                                    displayType={'text'}
                                                    thousandSeparator={true} />
                                                </td>
                                                <td className="text-right"><NumberFormat value={data.outstandingDays}
                                                    decimalScale={0}
                                                    displayType={'text'}
                                                    thousandSeparator={true} />
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </Card>


            </Col>);

    }
}