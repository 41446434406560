import React, {Component} from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Collapse,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Spinner,
    Table,
} from 'reactstrap';
import Select from 'react-select';
import Pagination from "react-js-pagination";
import CRMService from "../../services/crm/CRMService";
import OrderService from "../../services/OrderService";
import UserService from "../../services/UserService";
import cloneDeep from 'lodash/cloneDeep';
import {CSVLink} from 'react-csv';
import NumberFormat from "react-number-format";
import {
    findIndex,
    getDateObj,
    getDateString,
    getDistinctValues,
    handleErrorMessage,
    scrollTo
} from '../../services/CommonService';
import FileSaver from 'file-saver';
import classnames from 'classnames';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import queryString from 'query-string';
import TransactionsFilter from '../filters/TransactionsFilter';
import ComposeEmail from "../modal/ComposeEmail";
import {isEmpty} from 'lodash';

const transactionDisplayModes = ["Outstanding", "By-Month", "All"];

export default class Transaction extends Component {
    constructor(props) {
        super(props);

        let now = new Date();
        const months = [
            { 'label': 'January', 'value': "1" }, { 'label': 'February', 'value': "2" },
            { 'label': 'March', 'value': "3" }, { 'label': 'April', 'value': "4" },
            { 'label': 'May', 'value': "5" }, { 'label': 'June', 'value': "6" },
            { 'label': 'July', 'value': "7" }, { 'label': 'August', 'value': "8" },
            { 'label': 'September', 'value': "9" }, { 'label': 'October', 'value': "10" },
            { 'label': 'November', 'value': "11" }, { 'label': 'December', 'value': "12" }
        ];
        const previousYears = (now.getFullYear()) - 20;
        let currentMonthIndex = findIndex(months, "value", (now.getMonth()) + "");
        if (!(currentMonthIndex > -1)) {
            currentMonthIndex = 0;
        }
        this.state = {
            accountID: this.props.accountID,
            CloseBal: 0.00,
            outstanding_balance: 0.00,
            OpenBal: 0.00,
            ByMonthTransactions: [],
            OutstadingTransactions: [],
            previousYears: previousYears,
            selectedYear: { 'label': (new Date()).getFullYear(), 'value': (new Date()).getFullYear() },
            Financialyear: (new Date()).getFullYear().toString().substr(-2),
            selectedMonth: {
                label: months[currentMonthIndex].label,
                value: months[currentMonthIndex].value
            },
            loading: {
                orderSummaryInvoice: false,
                orderDetailInvoice: false,
            },
            CustomerTransactionInfo_byMonth: false,
            CustomerTransactionInfo_byOutstanding: false,
            months: months,
            filteringOptions: {
                sortingKey: 'Date',
                sortOrder: 'desc'
            },

            RecordsLine: '',
            openAllMonthTrans: {
                Transactionsdata: [],
                selectedPage: 1,
                pageSize: 50,
                dataLoading: false,
                totalRecords: 0
            },
            transactionRequest: {
                AccountId: '',
                FilterColumn: '',
                SortOrder: '',
                YearMonth: '',
                SearchFieldName: '',
                StartDate: null,
                EndDate: null,
                TransID: 0,
                Type: '',
                CustrOrder: '',
                jobRef: ''
            },
            isLoading: false,
            currentPage: 1,
            activeTransactionDisplayMode: "Outstanding",
            searchParams: props.searchParams,
            selectedOrder: '',
            highlightedOrder: {},
            debtor: {},
            currentUser: {},
            emailModal: {
                isOpen: false,
                isLoadingEmailSend: false,
                mail: {
                    id: "",
                    from: [{
                        label: "Windoware KEYWAY(keyway@windoware.com.au)",
                        value: "keyway@windoware.com.au"
                    }],
                    fromOptions: [{
                        label: "Windoware KEYWAY(keyway@windoware.com.au)",
                        value: "keyway@windoware.com.au"
                    }],
                    to: [],
                    toOptions: [],
                    cc: [],
                    ccOptions: [],
                    bcc: [],
                    bccOptions: [],
                    subject: "",
                    body: "",
                    OrderNo: ""
                }
            }
        };
        this.crmService = new CRMService();
        this.OrderService = new OrderService();
        this.userService = new UserService();

        this.convertToDecimalplaces = this.convertToDecimalplaces.bind(this);
        this.printOrderDetail = this.printOrderDetail.bind(this);
        this.printOrderSummary = this.printOrderSummary.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getAllTransactions = this.getAllTransactions.bind(this);
        this.RemovePaginationObject = this.RemovePaginationObject.bind(this);
        this.handleTotalRecordsFetch = this.handleTotalRecordsFetch.bind(this);
        this.downloadInvoice = this.downloadInvoice.bind(this);
        this.toggleEmailModal = this.toggleEmailModal.bind(this);
        this.sendEmailModal = this.sendEmailModal.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        let { transactionRequest, searchParams, activeTransactionDisplayMode, currentPage, selectedMonth, selectedYear, openAllMonthTrans, filteringOptions, selectedOrder, emailModal, debtor, currentUser } = this.state;


        activeTransactionDisplayMode = searchParams.activeTransactionDisplayMode ? searchParams.activeTransactionDisplayMode : activeTransactionDisplayMode;
        transactionRequest.StartDate = searchParams.startDate ? getDateObj(searchParams.startDate, "DD/MM/YYYY") : transactionRequest.StartDate;
        transactionRequest.EndDate = searchParams.endDate ? getDateObj(searchParams.endDate, "DD/MM/YYYY") : transactionRequest.EndDate;
        transactionRequest.currentPage = searchParams.selectedPage ? parseInt(searchParams.selectedPage) : transactionRequest.currentPage;
        openAllMonthTrans.SelectedPage = searchParams.selectedPage ? parseInt(searchParams.selectedPage) : openAllMonthTrans.SelectedPage;
        openAllMonthTrans.pageSize = searchParams.pageSize ? parseInt(searchParams.pageSize) : openAllMonthTrans.pageSize;
        currentPage = searchParams.selectedPage ? parseInt(searchParams.selectedPage) : currentPage;
        transactionRequest.pageSize = searchParams.pageSize ? parseInt(searchParams.pageSize) : transactionRequest.pageSize;
        transactionRequest.TransID = searchParams.TransID ? parseInt(searchParams.TransID) : transactionRequest.TransID;
        transactionRequest.Type = searchParams.Type ? searchParams.Type : transactionRequest.Type;
        filteringOptions.sortingKey = searchParams.sortingKey ? searchParams.sortingKey : transactionRequest.sortingKey;
        filteringOptions.sortOrder = searchParams.sortOrder ? searchParams.sortOrder : transactionRequest.sortOrder;
        transactionRequest.CustrOrder = searchParams.CustrOrder ? searchParams.CustrOrder : transactionRequest.CustrOrder;
        transactionRequest.jobRef = searchParams.jobRef ? searchParams.jobRef : transactionRequest.jobRef;
        selectedOrder = searchParams.selectedOrder ? searchParams.selectedOrder : selectedOrder;

        if (searchParams.selectedmonth) {
            let currentMonthIndex = findIndex(this.state.months, "value", (searchParams.selectedmonth) + "");
            if (!(currentMonthIndex > -1)) {
                currentMonthIndex = 0;
            }
            selectedMonth = {
                label: this.state.months[currentMonthIndex].label,
                value: this.state.months[currentMonthIndex].value
            }
        }

        if (searchParams.selectedYear) {
            selectedYear = {
                label: parseInt(searchParams.selectedYear),
                value: parseInt(searchParams.selectedYear)
            };
        }

        currentUser = this.userService.getLoggedInUser();
        debtor = this.props.debtor;

        emailModal.mail.to[0] = {
            label: currentUser.emailAddress,
            value: currentUser.emailAddress
        };
        emailModal.mail.toOptions[0] = {
            label: currentUser.emailAddress,
            value: currentUser.emailAddress
        };
        if (!isEmpty(debtor) && debtor.email && emailModal.mail.to[0].value !== debtor.email) {
            emailModal.mail.to[1] = {
                label: debtor.email,
                value: debtor.email
            };
            emailModal.mail.toOptions[1] = {
                label: debtor.email,
                value: debtor.email
            };
        }

        this.setState({
            transactionRequest,
            currentPage,
            activeTransactionDisplayMode,
            filteringOptions,
            selectedOrder,
            openAllMonthTrans,
            selectedMonth,
            selectedYear,
            currentUser,
            emailModal,
            debtor
        }, function () {
            this.refresh();
        });
    }

    componentWillReceiveProps(nextProps) {
        let { transactionRequest, activeTransactionDisplayMode, currentPage, selectedMonth, selectedYear, openAllMonthTrans, filteringOptions, selectedOrder, debtor, emailModal, currentUser } = this.state;

        if (this.state.accountID !== nextProps.accountID || this.state.searchParams !== nextProps.searchParams) {
            if (this.state.searchParams !== nextProps.searchParams) {
                let searchParams = nextProps.searchParams;
                this.setState({ searchParams });

                activeTransactionDisplayMode = searchParams.activeTransactionDisplayMode ? searchParams.activeTransactionDisplayMode : activeTransactionDisplayMode;
                transactionRequest.StartDate = searchParams.startDate ? getDateObj(searchParams.startDate, "DD/MM/YYYY") : transactionRequest.StartDate;
                transactionRequest.EndDate = searchParams.endDate ? getDateObj(searchParams.endDate, "DD/MM/YYYY") : transactionRequest.EndDate;
                transactionRequest.currentPage = searchParams.selectedPage ? parseInt(searchParams.selectedPage) : transactionRequest.currentPage;
                openAllMonthTrans.SelectedPage = searchParams.selectedPage ? parseInt(searchParams.selectedPage) : openAllMonthTrans.SelectedPage;
                openAllMonthTrans.pageSize = searchParams.pageSize ? parseInt(searchParams.pageSize) : openAllMonthTrans.pageSize;
                currentPage = searchParams.selectedPage ? parseInt(searchParams.selectedPage) : currentPage;
                transactionRequest.pageSize = searchParams.pageSize ? parseInt(searchParams.pageSize) : transactionRequest.pageSize;
                transactionRequest.TransID = searchParams.TransID ? parseInt(searchParams.TransID) : transactionRequest.TransID;
                transactionRequest.Type = searchParams.Type ? searchParams.Type : transactionRequest.Type;
                filteringOptions.sortingKey = searchParams.sortingKey ? searchParams.sortingKey : transactionRequest.sortingKey;
                filteringOptions.sortOrder = searchParams.sortOrder ? searchParams.sortOrder : transactionRequest.sortOrder;
                transactionRequest.CustrOrder = searchParams.CustrOrder ? searchParams.CustrOrder : transactionRequest.CustrOrder;
                transactionRequest.jobRef = searchParams.jobRef ? searchParams.jobRef : transactionRequest.jobRef;
                selectedOrder = searchParams.selectedOrder ? searchParams.selectedOrder : selectedOrder;

                if (searchParams.selectedmonth) {
                    let currentMonthIndex = findIndex(this.state.months, "value", (searchParams.selectedmonth) + "");
                    if (!(currentMonthIndex > -1)) {
                        currentMonthIndex = 0;
                    }
                    selectedMonth = {
                        label: this.state.months[currentMonthIndex].label,
                        value: this.state.months[currentMonthIndex].value
                    }
                }

                if (searchParams.selectedYear) {
                    selectedYear = {
                        label: parseInt(searchParams.selectedYear),
                        value: parseInt(searchParams.selectedYear)
                    };
                }

            }

            if (debtor !== nextProps.debtor) {
                debtor = nextProps.debtor;
                if (!isEmpty(debtor) && debtor.email && emailModal.mail.to[0].value !== debtor.email) {
                    emailModal.mail.to[1] = {
                        label: debtor.email,
                        value: debtor.email
                    };
                    emailModal.mail.toOptions[1] = {
                        label: debtor.email,
                        value: debtor.email
                    };
                }
            }

            this.setState({
                accountID: nextProps.accountID,
                transactionRequest,
                currentPage,
                activeTransactionDisplayMode,
                filteringOptions,
                selectedOrder,
                openAllMonthTrans,
                selectedMonth,
                selectedYear,
                debtor,
                emailModal
            }, function () {
                this.refresh();
            });
        }
    }


    toggleEmailModal(change, OrderNo) {
        let { emailModal } = this.state;
        emailModal.isOpen = change;
        emailModal.mail.subject = "Invoice for Order No. " + OrderNo;
        emailModal.mail.body = "Hi,\nPlease find attached invoice for Order No. " + OrderNo;
        emailModal.mail.OrderNo = OrderNo;
        this.setState({ emailModal });
    }

    sendEmailModal(mail) {
        let { emailModal } = this.state;
        emailModal.isLoadingEmailSend = true;
        emailModal.mail = mail;
        this.setState({ emailModal });

        let request = {
            tos: getDistinctValues(emailModal.mail.to ? emailModal.mail.to : [], 'value'),
            ccs: getDistinctValues(emailModal.mail.cc ? emailModal.mail.cc : [], 'value'),
            bccs: getDistinctValues(emailModal.mail.bcc ? emailModal.mail.bcc : [], 'value'),
            subject: emailModal.mail.subject,
            body: emailModal.mail.body,
            OrderNo: emailModal.mail.OrderNo
        };

        this.OrderService.SendInvoiceInEmail(request).then(response => {
            if (response.data) {
                toast.success("Email sent!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
            this.toggleEmailModal(false);
            emailModal.isLoadingEmailSend = false;
            this.setState({ emailModal })
        }).catch(error => {
            console.log(error.data);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
            emailModal.isLoadingEmailSend = false;
            this.setState({ emailModal })
        });

    }


    refresh() {
        this.setState({ isLoading: true });
        switch (this.state.activeTransactionDisplayMode) {
            case "Outstanding":
                this.getCustomerTransactionInfo_byOutstanding();
                break;
            case "By-Month":
                this.getCustomerTransactionInfo_byMonth();
                break;
            case "All":
                this.getAllTransactions(this.state.currentPage);
                break;
            default:
                break;
        }
    }

    getCustomerTransactionInfo_byMonth() {
        let selectedMonth = this.state.selectedMonth.value;
        if (selectedMonth.length === '1' || selectedMonth.length === 1) {
            selectedMonth = '0' + selectedMonth;
        }
        let yearmonth = this.state.selectedYear.label.toString() + '.' + selectedMonth.toString();
        if (this.state.accountID) {
            this.setState({ CustomerTransactionInfo_byMonth: true });
            this.RemovePaginationObject();
            let transactionRequest = {
                AccountId: this.state.accountID,
                FilterColumn: this.state.filteringOptions.sortingKey,
                SortOrder: this.state.filteringOptions.sortOrder,
                YearMonth: yearmonth,
                SearchFieldName: this.state.transactionRequest.SearchFieldName,
                TransID: this.state.transactionRequest.TransID,
                Type: this.state.transactionRequest.Type,
                CustOrder: this.state.transactionRequest.CustOrder,
                jobRef: this.state.transactionRequest.jobRef,
                StartDate: this.state.transactionRequest.StartDate,
                EndDate: this.state.transactionRequest.EndDate
            }
            this.props.toggleModel(true);
            this.setState({ isLoading: true });
            this.crmService.getCustomerTransactionInfo_byMonth(transactionRequest).then(response => {
                let data = response.data;
                
                if (response.status === 200 || response.status === "200") {
                    if (data.length > 0) {
                        this.setState({
                            ByMonthTransactions: data,
                            CustomerTransactionInfo_byMonth: false
                        }, () => this.getBalances());
                    } else {
                        this.setState({
                            ByMonthTransactions: [],
                            CustomerTransactionInfo_byMonth: false
                        }, () => this.getBalances());
                    }

                    this.scrollToOrderNo(response.data);
                    this.props.toggleModel(false);
                    this.setState({ isLoading: false });
                }
            }).catch(error => {
                console.log(error.data);
                this.props.toggleModel(false);
                this.setState({ isLoading: false });
            });
        }
    }

    getCustomerTransactionInfo_byOutstanding() {
        if (this.state.accountID !== null && this.state.accountID !== undefined && this.state.accountID !== "") {
            this.setState({ CustomerTransactionInfo_byOutstanding: true });
            this.RemovePaginationObject();
            let transactionRequest = {
                AccountId: this.state.accountID,
                FilterColumn: this.state.filteringOptions.sortingKey,
                SortOrder: this.state.filteringOptions.sortOrder,
                SearchFieldName: this.state.transactionRequest.SearchFieldName,
                TransID: this.state.transactionRequest.TransID,
                Type: this.state.transactionRequest.Type,
                CustOrder: this.state.transactionRequest.CustOrder,
                jobRef: this.state.transactionRequest.jobRef,
                StartDate: this.state.transactionRequest.StartDate,
                EndDate: this.state.transactionRequest.EndDate
            };
            this.props.toggleModel(true);
            this.crmService.getCustomerTransactionInfo_byOutstanding(transactionRequest).then(response => {
                let data = response.data;
                if (response.status === 200 || response.status === "200") {
                    if (data.length > 0) {
                        let Out_bal;
                        let Dr = 0;
                        let cr = 0;

                        for (let i = 0; i < data.length; i++) {
                            cr = cr + data[i].cr;
                            Dr = Dr + data[i].dr;
                            data[i].isDownloadingInvoice = false;
                        }
                        Out_bal = Dr - cr;
                        this.setState({
                            OutstadingTransactions: data,
                            outstanding_balance: Out_bal,
                            CustomerTransactionInfo_byOutstanding: false
                        }, () => this.getBalances());
                    } else {
                        this.setState({
                            OutstadingTransactions: [],
                            outstanding_balance: 0,
                            CustomerTransactionInfo_byOutstanding: false
                        }, () => this.getBalances());
                    }

                    this.scrollToOrderNo(response.data);
                    this.props.toggleModel(false);
                    this.setState({ isLoading: false });
                }
            }).catch(error => {
                console.log(error);
                this.props.toggleModel(false);
                this.setState({ isLoading: false });
            });
        }
    }

    RemovePaginationObject() {
        let openAllMonthTrans = this.state.openAllMonthTrans;
        openAllMonthTrans.selectedPage = 1;
        openAllMonthTrans.pageSize = 50;
        openAllMonthTrans.totalRecords = 0;
        this.setState({ openAllMonthTrans, SeeAllMonth: false });
    }

    getBalances() {
        let selectedMonth = this.state.selectedMonth.value;
        if (selectedMonth.length === '1' || selectedMonth.length === 1) {
            selectedMonth = '0' + selectedMonth;
        }
        let yearmonth = this.state.selectedYear.label.toString() + '.' + selectedMonth.toString();
        if (this.state.accountID) {
            this.crmService.getCustomerBalacesWithYearMonth(this.state.accountID, yearmonth).then(response => {
                let data = response.data;
                if (data) {
                    this.setState({
                        CloseBal: data.closeBal,
                        OpenBal: data.openBal
                    })
                } else {
                    this.setState({ CloseBal: 0.00, OpenBal: 0.00 })
                }
            }).catch(error => {
                console.log(error.data)
            });
        }
    }

    yearChange = (selectedyear) => {
        let searchParams = new URLSearchParams(window.location.search);

        if (selectedyear) {
            searchParams.set('selectedYear', selectedyear.value);
            this.setState({ selectedYear: selectedyear }, () => {
                this.refresh()
            });
        } else {
            searchParams.delete('selectedYear');
            this.setState({ selectedYear: {} });
        }

        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
    };

    monthChanged = (selectedmonth) => {
        let searchParams = new URLSearchParams(window.location.search);
        if (selectedmonth) {
            searchParams.set('selectedmonth', selectedmonth.value);
            this.setState({ selectedMonth: selectedmonth }, () => {
                this.refresh()
            });
        } else {
            searchParams.delete('selectedmonth');
            this.setState({ selectedMonth: {} });
        }

        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
    };

    convertToDecimalplaces(num) {
        if (num !== undefined && num !== null) {
            return (<NumberFormat value={num}
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={'$'} />);
        } else {
            return num;
        }
    }

    handleRadioboxChange(item) {
        this.setState({ isLoading: true });
        let radiobox = { ...this.state.radiobox };
        let { Outstanding, ByMonth } = this.state;
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set("activeTransactionDisplayMode", item);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);

        switch (item) {
            case "Outstanding":
                this.setState({ activeTransactionDisplayMode: item }, function () {
                    this.getCustomerTransactionInfo_byOutstanding();
                });
                break;
            case "By-Month":
                this.setState({ activeTransactionDisplayMode: item }, function () {
                    this.getCustomerTransactionInfo_byMonth();
                });
                break;
            case "All":
                this.setState({ activeTransactionDisplayMode: item }, function () {
                    this.getAllTransactions(1);
                });
                break;
            default:
                break;
        }
    }

    handleTotalRecordsFetch(e) {
        let { openAllMonthTrans } = this.state;
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set("pageSize", e.target.value);
        searchParams.set("selectedPage", 1);

        openAllMonthTrans.pageSize = e.target.value;
        openAllMonthTrans.selectedPage = 1;
        openAllMonthTrans.dataLoading = true;
        this.setState({ openAllMonthTrans }, () => this.getAllTransactions(1));

        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
    }

    changeTotalRecordsLine(selectedPageNumber) {
        let { openAllMonthTrans } = this.state;
        let recShwoing = 0;
        let num = selectedPageNumber * openAllMonthTrans.pageSize;
        for (let i = 1; i < selectedPageNumber; i++) {
            recShwoing += openAllMonthTrans.pageSize;
        }
        let string_line = `Showing ${recShwoing + 1} to ${num} of ${openAllMonthTrans.totalRecords} entries.`;
        this.setState({ RecordsLine: string_line });
    }

    async getAllTransactions(selectDpage) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set("selectedPage", selectDpage);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);

        this.setState({ isLoading: true, currentPage: selectDpage });
        let radiobox = { ...this.state.radiobox };
        radiobox.Outstanding = false;
        radiobox.ByMonth = false;
        this.setState({ radiobox, Outstanding: false, ByMonth: false, SeeAllMonth: true });

        if (this.state.accountID !== null && this.state.accountID !== undefined && this.state.accountID !== "") {
            let transactionRequest = {
                AccountId: this.state.accountID,
                FilterColumn: this.state.filteringOptions.sortingKey,
                SortOrder: this.state.filteringOptions.sortOrder,
                YearMonth: 0.00,
                SearchFieldName: this.state.transactionRequest.SearchFieldName,
                TransID: this.state.transactionRequest.TransID,
                Type: this.state.transactionRequest.Type,
                CustOrder: this.state.transactionRequest.CustOrder,
                jobRef: this.state.transactionRequest.jobRef,
                StartDate: this.state.transactionRequest.StartDate,
                EndDate: this.state.transactionRequest.EndDate,
                filterrequest: {
                    AccountId: this.state.openAllMonthTrans.totalRecords,
                    SelectedPage: selectDpage,
                    PageSize: this.state.openAllMonthTrans.pageSize,
                }
            }
            this.props.toggleModel(true);
            await this.crmService.AllCustomerTransactions(transactionRequest).then(response => {
                let data = response.data.records;
                if (response.status === 200 || response.status === "200") {
                    if (data.length > 0) {
                        this.setState({ OutstadingTransactions: data }, () => this.getBalances());
                    } else {
                        this.setState({ OutstadingTransactions: [] }, () => this.getBalances());
                    }
                    this.props.toggleModel(false);
                    let openAllMonthTrans = this.state.openAllMonthTrans;
                    openAllMonthTrans.selectedPage = response.data.selectedPage;
                    openAllMonthTrans.pageSize = response.data.pageSize;
                    openAllMonthTrans.totalRecords = response.data.totalRecords;
                    openAllMonthTrans.dataLoading = false;
                    this.setState({ isLoading: false });
                    this.setState({ openAllMonthTrans }, () => this.changeTotalRecordsLine(selectDpage));

                    this.scrollToOrderNo(response.data.records);
                }
            }).catch(error => {
                console.log(error.data);
                this.setState({ isLoading: false });
                this.props.toggleModel(false);
            });
        }
    }

    scrollToOrderNo(records) {
        let { selectedOrder, highlightedOrder } = this.state;
        if (selectedOrder) {
            let orderIndex = findIndex(records, 'transID', parseInt(selectedOrder));
            if (orderIndex > -1) {
                // highlight order
                highlightedOrder = records[orderIndex];
            }

            this.setState({
                highlightedOrder,
                selectedOrder
            }, () => {
                setTimeout(() => {
                    scrollTo("Trans" + highlightedOrder.transID)
                }, 1000);

            });
        }
    }


    handleChange(value, key) {
        let searchParams = new URLSearchParams(window.location.search);

        let { transactionRequest } = this.state;
        transactionRequest[key] = value;
        transactionRequest.SearchFieldName = key;

        if (key === "TransID") {
            if (value === "") {
                transactionRequest.TransID = 0;
                searchParams.set("TransID", 0);
            }
        }
        if (key === "StartDate" || key === "EndDate") {
            if (key === 'StartDate') {
                searchParams.set('startDate', getDateString(value, "DD/MM/YYYY"))
            }

            if (key === 'EndDate') {
                searchParams.set('endDate', getDateString(value, "DD/MM/YYYY"))
            }

            this.setState({ transactionRequest });
            if (transactionRequest.StartDate && transactionRequest.EndDate)
                this.setState({ transactionRequest }, () => this.refresh());
        } else {
            searchParams.set(key, value);
            this.setState({ transactionRequest }, () => this.refresh());
        }


        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
    }

    printOrderDetail(orderNo) {
        let loading = this.state.loading;
        loading.orderDetailInvoice = true;
        this.setState({ loading });
        this.OrderService.GenerateDebtorOrderReport(orderNo).then(response => {
            FileSaver.saveAs(response.data, "OrderDetail" + orderNo + ".pdf");
            loading.orderDetailInvoice = false;
            this.setState({ loading });
        }).catch(error => {
            loading.orderDetailInvoice = false;
            this.setState({ loading });
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });

        });
    }

    printOrderSummary(orderNo) {
        let loading = this.state.loading;
        loading.orderSummaryInvoice = true;
        this.setState({ loading });
        this.OrderService.GenerateDebtorOrderSummaryReport(orderNo).then(response => {
            FileSaver.saveAs(response.data, "OrderSummary" + orderNo + ".pdf");
            loading.orderSummaryInvoice = false;
            this.setState({ loading });
        }).catch(error => {
            loading.orderSummaryInvoice = false;
            this.setState({ loading });
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }


    handleSort(filteroptions, fieldName) {
        this.setState({ isLoading: true });
        let { filteringOptions } = this.state;
        let SortOrder = filteroptions.sortOrder;
        let searchParams = new URLSearchParams(window.location.search);

        if (filteroptions.sortingKey === fieldName) {
            SortOrder = SortOrder === 'asc' ? 'desc' : 'asc';
        }
        filteringOptions.sortingKey = fieldName;
        filteringOptions.sortOrder = SortOrder;

        searchParams.set('sortingKey', fieldName);
        searchParams.set('sortOrder', SortOrder);
        this.setState({ filteringOptions }, () => this.refresh());

        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);

    }

    prepareExportData(datas) {
        let temp = {};
        let exportData = {
            headers: [
                { label: 'Date', key: 'date' },
                { label: 'TransID', key: 'transID' },
                { label: 'Branch', key: 'branch' },
                { label: 'Type', key: 'type' },
                { label: 'Customer OrdNum', key: 'custOrdNum' },
                { label: 'JobRef', key: 'jobRef' },
                { label: 'Age', key: 'age' },
                { label: 'Dr', key: 'dr' },
                { label: 'Cr', key: 'cr' },
            ],
            data: []
        };


        for (let i in datas) {
            temp = {
                date: datas[i].date,
                transID: datas[i].transID,
                branch: datas[i].branch,
                type: datas[i].type,
                custOrdNum: datas[i].custOrdNum,
                jobRef: datas[i].jobRef,
                age: datas[i].age,
                dr: datas[i].dr,
                cr: datas[i].cr,
            };
            exportData.data.push(cloneDeep(temp));
        }
        return exportData;
    }

    onSelectOrder(ordNum) {
        this.setState({ selectedOrder: ordNum });
        let searchParams = new URLSearchParams(window.location.search);

        searchParams.set('selectedOrder', ordNum);

        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
    }

    renderTransactionSummary() {
        if (this.state.activeTransactionDisplayMode === "By-Month") {
            return (
                <Table size={"sm"} responsive>
                    <tbody>
                        <tr>
                            <th>Opening Balance:</th>
                            <td className={"text-right"}>{this.convertToDecimalplaces(this.state.OpenBal)}</td>
                        </tr>
                        <tr>
                            <th>Closing Balance:</th>
                            <td className={"text-right"}>{this.convertToDecimalplaces(this.state.CloseBal)}</td>
                        </tr>
                    </tbody>
                </Table>

            );
        }
        if (this.state.activeTransactionDisplayMode === "Outstanding") {
            return (
                <Table size={"sm"} responsive>
                    <tbody>
                        <tr>
                            <th>Balance:</th>
                            <td className={"text-right"}>{this.convertToDecimalplaces(this.state.outstanding_balance)}</td>
                        </tr>
                    </tbody>
                </Table>
            );
        }
    }

    //Deprecated
    /*renderTransactionId(Id) {
        const {loading} = this.state;
        return (
            <ButtonGroup>
                <UncontrolledDropdown>
                    <DropdownToggle caret color={"primary"}>
                        {
                            *//*<i className="fa fa-print" aria-hidden="true" />*//*
}
{Id}
</DropdownToggle>
<DropdownMenu>
<DropdownItem
    onClick={() => this.printOrderSummary(Id)}
    disabled={loading.orderSummaryInvoice}>
    {loading.orderSummaryInvoice ?
        <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"/> :
        null} &nbsp;
    {loading.orderSummaryInvoice ? "Downloading " :
        <span title="Download"><i className="fa fa-download" aria-hidden="true"/> Invoice Summary</span>}
</DropdownItem>

<DropdownItem onClick={() => this.printOrderDetail(Id)}
              disabled={loading.orderDetailInvoice}>
    {loading.orderDetailInvoice ?
        <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"/> :
        null} &nbsp;
    {loading.orderDetailInvoice ? "Downloading " :
        <span title="Download"><i className="fa fa-download" aria-hidden="true"/> Invoice Order in Detail</span>}
</DropdownItem>
</DropdownMenu>
</UncontrolledDropdown>
</ButtonGroup>
);
}*/

    downloadInvoice(order, index) {
        let Data = this.state.OutstadingTransactions;
        Data[index].isDownloadingInvoice = true;
        this.setState({ OutstadingTransactions: Data });
        {
            this.OrderService.downloadInvoice(order.transID).then(response => {
                let fileName = order.transID + " Invoice.pdf";
                // download response as file
                Data[index].isDownloadingInvoice = false;
                this.setState({ OutstadingTransactions: Data });
                FileSaver.saveAs(response.data, fileName);
            }).catch(error => {
                toast.error(handleErrorMessage(error));
                Data[index].isDownloadingInvoice = false;
                this.setState({ OutstadingTransactions: Data });
            });
        }

    }

    renderOutstandingTransactions(Trans, isloaded) {
        isloaded = false;
        const exportData = this.prepareExportData(Trans);
        const { previousYears, selectedYear, selectedMonth, radiobox, filteringOptions, transactionRequest, RecordsLine, highlightedOrder, emailModal } = this.state;
        let { selectedPage, pageSize, totalRecords, dataLoading } = this.state.openAllMonthTrans;
        const options = [];

        for (let k = 20; k >= 0; k--) {
            const year = previousYears + k;
            options.push({ 'label': year, 'value': year });
        }

        let totalDr = 0;
        for (let i = 0; i < Trans.length; i++) {
            totalDr = totalDr + Trans[i].dr;
        }
        let totalCr = 0;
        for (let i = 0; i < Trans.length; i++) {
            totalCr = totalCr + Trans[i].cr;
        }

        let filename = "-transactions.csv";
        if (this.state.activeTransactionDisplayMode === "By-Month") {
            filename = selectedMonth.label + "-" + selectedYear.label + filename;
        } else {
            filename = "Outstanding" + filename;
        }
        return (
            <div>
                <div>
                    <TransactionsFilter
                        handleChange={this.handleChange}
                        transactionRequest={transactionRequest}
                    />
                </div>
                <Table size='sm' striped bordered responsive hover>
                    <thead>
                        <tr>
                            <th className="hoverableItem" style={{ minWidth: '100px', maxWidth: '150px' }}
                                onClick={() => this.handleSort(filteringOptions, "Date")}>Date
                                <span>
                                    <i className={classnames("fa", "float-right", "pt-1", {
                                        "fa-sort": (filteringOptions.sortingKey !== "Date"),
                                        "fa-sort-amount-asc": (filteringOptions.sortingKey === "Date" && filteringOptions.sortOrder === 'desc'),
                                        "fa-sort-amount-desc": (filteringOptions.sortingKey === "Date" && filteringOptions.sortOrder === 'asc'),
                                    }
                                    )} aria-hidden="true" />
                                </span>
                            </th>
                            <th className="hoverableItem" onClick={() => this.handleSort(filteringOptions, "TransID")} style={{minWidth:'100px'}}>Order no.
                                <span>
                                    <i className={classnames("fa", "float-right", "pt-1", {
                                        "fa-sort": (filteringOptions.sortingKey !== "TransID"),
                                        "fa-sort-amount-asc": (filteringOptions.sortingKey === "TransID" && filteringOptions.sortOrder === 'desc'),
                                        "fa-sort-amount-desc": (filteringOptions.sortingKey === "TransID" && filteringOptions.sortOrder === 'asc'),
                                    }
                                    )} aria-hidden="true" />
                                </span>
                            </th>
                            <th className="hoverableItem" onClick={() => this.handleSort(filteringOptions, "Type")}>Type
                                <span>
                                    <i className={classnames("fa", "float-right", "pt-1", {
                                        "fa-sort": (filteringOptions.sortingKey !== "Type"),
                                        "fa-sort-amount-asc": (filteringOptions.sortingKey === "Type" && filteringOptions.sortOrder === 'desc'),
                                        "fa-sort-amount-desc": (filteringOptions.sortingKey === "Type" && filteringOptions.sortOrder === 'asc'),
                                    }
                                    )} aria-hidden="true" />
                                </span>
                            </th>
                            <th className="hoverableItem" onClick={() => this.handleSort(filteringOptions, "CustOrder")}>Customer Order No
                                <span>
                                    <i className={classnames("fa", "float-right", "pt-1", {
                                        "fa-sort": (filteringOptions.sortingKey !== "CustOrder"),
                                        "fa-sort-amount-asc": (filteringOptions.sortingKey === "CustOrder" && filteringOptions.sortOrder === 'desc'),
                                        "fa-sort-amount-desc": (filteringOptions.sortingKey === "CustOrder" && filteringOptions.sortOrder === 'asc'),
                                    }
                                    )} aria-hidden="true" />
                                </span>
                            </th>
                            <th className="hoverableItem" onClick={() => this.handleSort(filteringOptions, "Ref")} style={{ minWidth: '50px' }}>Ref
                                <span>
                                    <i className={classnames("fa", "float-right", "pt-1", {
                                        "fa-sort": (filteringOptions.sortingKey !== "Ref"),
                                        "fa-sort-amount-asc": (filteringOptions.sortingKey === "Ref" && filteringOptions.sortOrder === 'desc'),
                                        "fa-sort-amount-desc": (filteringOptions.sortingKey === "Ref" && filteringOptions.sortOrder === 'asc'),
                                    }
                                    )} aria-hidden="true" />
                                </span>
                            </th>
                            <th className="hoverableItem" style={{ minWidth: '80px' }} onClick={() => this.handleSort(filteringOptions, "Age")}>Age
                                <span>
                                    <i className={classnames("fa", "float-right", "pt-1", {
                                        "fa-sort": (filteringOptions.sortingKey !== "Age"),
                                        "fa-sort-amount-asc": (filteringOptions.sortingKey === "Age" && filteringOptions.sortOrder === 'desc'),
                                        "fa-sort-amount-desc": (filteringOptions.sortingKey === "Age" && filteringOptions.sortOrder === 'asc'),
                                    }
                                    )} aria-hidden="true" />
                                </span>
                            </th>
                            <th className="hoverableItem" style={{ minWidth: '50px' }} onClick={() => this.handleSort(filteringOptions, "DR")}>DR
                                <span>
                                    <i className={classnames("fa", "float-right", "pt-1", {
                                        "fa-sort": (filteringOptions.sortingKey !== "DR"),
                                        "fa-sort-amount-asc": (filteringOptions.sortingKey === "DR" && filteringOptions.sortOrder === 'desc'),
                                        "fa-sort-amount-desc": (filteringOptions.sortingKey === "DR" && filteringOptions.sortOrder === 'asc'),
                                    }
                                    )} aria-hidden="true" />
                                </span>
                            </th>
                            <th className="hoverableItem" onClick={() => this.handleSort(filteringOptions, "CR")}>CR
                                <span>
                                    <i className={classnames("fa", "float-right", "pt-1", {
                                        "fa-sort": (filteringOptions.sortingKey !== "CR"),
                                        "fa-sort-amount-asc": (filteringOptions.sortingKey === "CR" && filteringOptions.sortOrder === 'desc'),
                                        "fa-sort-amount-desc": (filteringOptions.sortingKey === "CR" && filteringOptions.sortOrder === 'asc'),
                                    }
                                    )} aria-hidden="true" />
                                </span>
                            </th>
                        </tr>
                    </thead>
                    {
                        isloaded ? <tbody>
                            <tr>
                                <td colSpan="9"><i className="fa fa-circle-o-notch fa-spin" aria-hidden="true" />Loading...
                                </td>
                            </tr>
                        </tbody> :
                            (Trans.length === 0 || Trans.length === '0') ?
                                <tbody>
                                    <tr>
                                        <td colSpan="9">No Items ...</td>
                                    </tr>
                                </tbody> :
                                <tbody>

                                    {(Trans || []).map((Tran, index) =>
                                        <tr key={index}
                                            id={highlightedOrder.transID === Tran.transID ? "Trans" + highlightedOrder.transID : null}>
                                            <td>{getDateString(Tran.date, 'DD MMM YYYY')}</td>
                                            <td>
                                                <Link
                                                    className={classnames("btn", "btn-sm", {
                                                        "btn-primary": (highlightedOrder.transID !== Tran.transID),
                                                        "btn-success": (highlightedOrder.transID === Tran.transID)
                                                    })}
                                                    style={{ color: "white" }}
                                                    title={"Click here to see detailed invoice"}
                                                    onClick={this.onSelectOrder.bind(this, Tran.transID)}
                                                    to={"/sales/order/view?" + queryString.stringify({ ordNum: Tran.transID })}
                                                >{Tran.transID}</Link>&nbsp;
                                                {(Tran.type === "Invoice" || Tran.type === "Credit Note") ?
                                                    <>
                                                        <Button
                                                            color={"link"}
                                                            size={"sm"}
                                                            onClick={() => this.downloadInvoice(Tran, index)}
                                                        >{(Tran.isDownloadingInvoice)
                                                            ? <Spinner size={"sm"}
                                                                className={"mr-2"} />
                                                            : <i className="fa fa-download mr-2"
                                                                aria-hidden="true" />}
                                                        </Button>
                                                        <Button
                                                            color={"link"}
                                                            size={"sm"}
                                                            onClick={() => this.toggleEmailModal(!emailModal.isOpen, Tran.transID)}
                                                        ><i className="fa fa-envelope" aria-hidden="true" />
                                                        </Button>
                                                    </>

                                                    : null}
                                            </td>
                                            <td>{Tran.type}</td>
                                            <td className="text-left">{Tran.custOrdNum}</td>
                                            <td className="text-left">{Tran.jobRef}</td>
                                            <td className="text-right">{Tran.age}</td>

                                            <td style={{ 'textAlign': 'right' }} className={classnames({
                                                'table-danger text-right': Tran.dr > 0,
                                                'table-success text-right': Tran.dr < 0
                                            })}><strong>
                                                    <NumberFormat
                                                        value={Tran.dr}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                        prefix={'$'} />
                                                </strong>

                                            </td>
                                            <td style={{ 'textAlign': 'right' }} className={classnames({
                                                'table-success': Tran.cr > 0,
                                                'table-danger': Tran.cr < 0
                                            })}>
                                                <strong>
                                                    <NumberFormat value={Tran.cr}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                        prefix={'$'} />
                                                </strong>
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td colSpan={6} className={'text-right'}>
                                            <strong>Total</strong>
                                        </td>
                                        <td style={{ 'textAlign': 'right' }} className={classnames({
                                            'table-danger': totalDr > 0,
                                            'table-success': totalDr < 0
                                        })}>
                                            <strong><NumberFormat
                                                value={totalDr}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                prefix={'$'} />
                                            </strong>
                                        </td>
                                        <td style={{ 'textAlign': 'right' }} className={classnames({
                                            'table-success text-right': totalCr > 0,
                                            'table-danger text-right': totalCr < 0
                                        })}><strong><NumberFormat value={totalCr}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                            prefix={'$'} /></strong>
                                        </td>

                                    </tr>
                                </tbody>
                    }
                </Table>
                {totalRecords > pageSize ?
                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <div className={"text-left"}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>{RecordsLine}</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type={"select"}
                                        name={"pageSize"}
                                        value={pageSize}
                                        onChange={(e) => this.handleTotalRecordsFetch(e)}>
                                        <option value={10}>10 Rows</option>
                                        <option value={25}>25 Rows</option>
                                        <option value={50}>50 Rows</option>
                                        <option value={100}>100 Rows</option>
                                        <option value={500}>500 Rows</option>
                                    </Input>
                                </InputGroup>
                            </div>
                        </Col>
                        <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                            <div style={{ 'float': 'right' }} className="text-right">
                                <Pagination
                                    activePage={selectedPage}
                                    itemsCountPerPage={pageSize}
                                    totalItemsCount={totalRecords}
                                    pageRangeDisplayed={4}
                                    onChange={this.getAllTransactions}
                                    itemClass='page-item'
                                    linkClass='page-link'
                                    activeClass='active'
                                    innerClass='pagination'
                                    activeLinkClass='active'
                                />
                            </div>
                        </Col>
                    </Row> : null}
                <div className={"text-right"}>
                    <CSVLink data={exportData.data}
                        headers={exportData.headers}
                        filename={filename}
                        className={'btn btn-outline-success btn-sm'}>SpreadSheet &nbsp;
                        <i className="fa fa-file-excel-o" aria-hidden="true" />
                    </CSVLink>
                </div>
            </div>
        );
    }

    render() {
        const { previousYears, selectedYear, radiobox, accountID, isLoading, activeTransactionDisplayMode, emailModal } = this.state;
        const options = [];
        let disable_Months_Years = false;
        for (let k = 20; k >= 0; k--) {
            const year = previousYears + k;
            options.push({ 'label': year, 'value': year });
        }
        if (this.state.Outstanding || this.state.SeeAllMonth) {
            disable_Months_Years = true;
        }
        let Transactions = null;
        switch (activeTransactionDisplayMode) {

            case "By-Month":
                Transactions = this.renderOutstandingTransactions(this.state.ByMonthTransactions, this.state.CustomerTransactionInfo_byMonth);
                break;
            case "Outstanding":
            case "All":
                Transactions = this.renderOutstandingTransactions(this.state.OutstadingTransactions, this.state.CustomerTransactionInfo_byOutstanding);
                break;
            default:
                break;
        }

        let summary = this.renderTransactionSummary();

        return (
            <div>
                <div>
                    <Row className={"mb-2"}>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div>
                                <InputGroup>
                                    <InputGroup>
                                        <InputGroupText>Display Transactions: </InputGroupText>
                                        <InputGroupAddon addonType="append"> <ButtonGroup>
                                            {
                                                (transactionDisplayModes).map(mode => {
                                                    return <Button key={mode}
                                                        color={"primary"}
                                                        outline={mode !== activeTransactionDisplayMode}
                                                        onClick={() => this.handleRadioboxChange(mode)}>
                                                        {mode}
                                                        {
                                                            mode === activeTransactionDisplayMode
                                                                ? (
                                                                    isLoading ?
                                                                        <Spinner
                                                                            color={mode === activeTransactionDisplayMode ? "light" : "primary"}
                                                                            className={"ml-2"} size={"sm"} />
                                                                        : <i className="fa fa-check ml-2"
                                                                            aria-hidden="true" />)
                                                                : null
                                                        }
                                                    </Button>;
                                                })
                                            }
                                        </ButtonGroup></InputGroupAddon>
                                    </InputGroup>
                                </InputGroup>
                            </div>
                            <Collapse isOpen={activeTransactionDisplayMode === "By-Month"}>
                                <div className={"mt-3 mb-3"}>
                                    <div>
                                        <Row>
                                            <Col xl={6} lg={6} md={8} sm={12} xs={12}>
                                                <div className={"text-left"}>
                                                    <p className={"mb-0"}>Select month</p>
                                                    <Select
                                                        isDisabled={disable_Months_Years}
                                                        options={this.state.months}
                                                        value={this.state.selectedMonth}
                                                        placeholder={"Select Month"}
                                                        onChange={this.monthChanged}
                                                        closeMenuOnSelect={true}
                                                        isSearchable={false}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                                <p className={"mb-0"}>Select Year</p>
                                                <Select
                                                    isDisabled={disable_Months_Years}
                                                    options={options}
                                                    value={selectedYear}
                                                    placeholder={"Select Year"}
                                                    onChange={this.yearChange}
                                                    closeMenuOnSelect={true}
                                                    isSearchable={true}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr />
                                </div>
                            </Collapse>
                        </Col>
                        <Col xl={3} lg={3} md={1} sm={12} xs={12} />
                        <Col xl={3} lg={3} md={5} sm={12} xs={12}>
                            {summary}
                        </Col>
                    </Row>
                </div>
                <div className={"mt-2"}>
                    {Transactions}
                </div>
                <ComposeEmail isOpen={emailModal.isOpen}
                    isSendingEmail={emailModal.isLoadingEmailSend}
                    toggle={this.toggleEmailModal}
                    message={emailModal.mail}
                    handleSubmit={this.sendEmailModal}
                    parentType={"Transactions"}
                />
            </div>
        );
    }
}