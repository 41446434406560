import React, {Component} from 'react';
import {Button, Col, Input, InputGroup, InputGroupAddon, Badge,InputGroupText, Row, Spinner, Table} from 'reactstrap';
import OrderService from '../../services/OrderService';
import classnames from 'classnames';
import {
    changeFormatOfDateString,
    concatenateStrings,
    getChildAccountID,
    getDateString, findIndex,
    handleErrorMessage, getOrderColorName, scrollTo, isDeepEqual
} from '../../services/CommonService';
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import {Link as Link} from "react-router-dom";
import {toast, ToastContainer} from 'react-toastify';
import {cloneDeep, isEmpty} from 'lodash';
import SearchGatewayOrder from '../../components/search/SearchGatewayOrder';
import UserService from '../../services/UserService';
import queryString from 'query-string';
import ConfirmModal from "../../components/modal/ConfirmModal";

export default class GatewayOrdersEDIUnmatched extends Component {

    constructor(props) {
        super(props);

        let ediRequest = cloneDeep(props.request);
        ediRequest.sortRequest.key = "orderDate";
        ediRequest.sortRequest.direction = false;
        this.state = {
            orders: {
                request: cloneDeep(props.request),
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            ediOrders: {
                request: ediRequest,
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            isLoadingSallyOrders: false,
            isLoadingEDIOrders: false,
            isLoadingAction: "",
            isOpenConfirmModal: false,
            currentUser: {},
            highlightedOrder: {},
            highlightedEDIOrder: {},
            selectedOrder: null,
            selectedOrderNumber: props.selectedOrderNumber,
        };

        this.orderService = new OrderService();
        this.userService = new UserService();
        this.refresh = this.refresh.bind(this);
        this.getOrder = this.getOrder.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.getEDIOrders = this.getEDIOrders.bind(this);
        this.getStore = this.getStore.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.takeActionOnOrder = this.takeActionOnOrder.bind(this);
        this.toggleExpandRow = this.toggleExpandRow.bind(this);
        this.toggleAllRowExpanded = this.toggleAllRowExpanded.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.getDebtorInvoice = this.getDebtorInvoice.bind(this);
        this.getEDIOrder = this.getEDIOrder.bind(this);
    }

    componentDidMount() {
        let currentUser = this.userService.getLoggedInUser();
        this.setState({currentUser}, () => {
            this.refresh();
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedOrderNumber !== this.state.selectedOrderNumber
            || nextProps.type !== this.state.type
            || (!isDeepEqual(this.props.request.filterRequest, this.state.orders.request.filterRequest))
        ) {
            this.setState({selectedOrderNumber: nextProps.selectedOrderNumber, type: nextProps.type}, () => {
                this.refresh();
            });
        }
    }

    refresh() {
        this.getOrders(this.state.orders);
        this.getEDIOrders(this.state.ediOrders);
    }

    getOrders(orders) {
        // let { highlightedOrder } = this.state;
        // let { selectedOrderNumber } = this.props;
        this.setState({isLoadingSallyOrders: true});

        let request = cloneDeep(orders.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        this.orderService.getAllGatewayOrdersSally(request, this.props.type).then(response => {
            orders.response = response.data;
            //if (selectedOrderNumber) {
            //    let orderIndex = findIndex(orders.response.records, 'ordNum', parseInt(selectedOrderNumber));
            //    if (orderIndex > -1) {
            //        // highlight order
            //        highlightedOrder = orders.response.records[orderIndex];
            //    }

            //    this.setState({
            //        highlightedOrder,
            //        selectedOrderNumber
            //    }, () => {
            //        setTimeout(() => {
            //            scrollTo("Order" + highlightedOrder.ordNum)
            //        }, 1000);

            //    });
            //}
            this.setState({orders, isLoadingSallyOrders: false, type: this.props.type}, () => {
                this.getHighlightedOrder();
            });
        }).catch(error => {
            this.setState({isLoadingSallyOrders: false});
            console.log(error);
            toast.error(handleErrorMessage(error));
        })
    }

    getEDIOrders(ediOrders) {
        //  let { highlightedOrder } = this.state;
        // let { selectedOrderNumber } = this.props;
        this.setState({isLoadingEDIOrders: true});

        let request = cloneDeep(ediOrders.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        this.orderService.getAllGatewayOrdersEDI(request, this.props.type).then(response => {
            ediOrders.response = response.data;
            //if (selectedOrderNumber) {
            //    let orderIndex = findIndex(ediOrders.response.records, 'ordNum', parseInt(selectedOrderNumber));
            //    if (orderIndex > -1) {
            //        // highlight order
            //        highlightedOrder = ediOrders.response.records[orderIndex];
            //    }

            //    this.setState({
            //        highlightedOrder,
            //        selectedOrderNumber
            //    }, () => {
            //        setTimeout(() => {
            //            scrollTo("Order" + highlightedOrder.ordNum)
            //        }, 1000);

            //    });
            //}
            this.setState({ediOrders, isLoadingEDIOrders: false, type: this.props.type}, () => {
                this.getHighlightedOrder();
            });
        }).catch(error => {
            this.setState({isLoadingEDIOrders: false});
            console.log(error);
            toast.error(handleErrorMessage(error));
        })
    }

    getHighlightedOrder() {
        let { orders, ediOrders, highlightedOrder, highlightedEDIOrder } = this.state;
        let { selectedOrderNumber } = this.props;
        if (selectedOrderNumber) {
            //check in edi order table based on order firsst
            let ediOrderIndex = findIndex(ediOrders.response.records, 'ordNum', parseInt(selectedOrderNumber));
            if (ediOrderIndex > -1) {
                // highlight order
                highlightedOrder = ediOrders.response.records[ediOrderIndex];
            }

            //check in edi order table based on EDIOrdId
            let ediSalesOrderIndex = findIndex(ediOrders.response.records, 'ediSalesOrderID', parseInt(selectedOrderNumber));
            if (ediSalesOrderIndex > -1) {
                // highlight order
                highlightedEDIOrder = ediOrders.response.records[ediSalesOrderIndex];
            }

            //sally order
            let sallOrderIndex = findIndex(orders.response.records, 'ordNum', parseInt(selectedOrderNumber));
            if (sallOrderIndex > -1) {
                // highlight order
                highlightedOrder = orders.response.records[sallOrderIndex];
            }
        }
        this.setState({
            highlightedOrder, highlightedEDIOrder,
            selectedOrderNumber
        }, () => {
            setTimeout(() => {
                scrollTo("Order" + highlightedOrder.ordNum)
                scrollTo("SalesOrder" + highlightedEDIOrder.ediSalesOrderID)
            }, 1000);

        });
    }

    toggleAllRowExpanded(change, type) {
        let {orders, ediOrders} = this.state;

        switch (type) {
            case "Sally":
                orders.response.records.forEach((order, orderIndex) => {
                    orders.response.records[orderIndex].isExpanded = change;
                });
                this.setState({orders});
                break;
            case "EDI":
                ediOrders.response.records.forEach((order, orderIndex) => {
                    ediOrders.response.records[orderIndex].isExpanded = change;
                });
                this.setState({ediOrders});
                break;
        }
    }

    toggleExpandRow(orderIndex, change, type) {
        let {orders, ediOrders} = this.state;

        switch (type) {
            case "Sally":
                orders.response.records[orderIndex].isExpanded = change;
                this.setState({orders});
                if (!(orders.response.records[orderIndex].items && orders.response.records[orderIndex].items.length > 0)) {
                    this.getOrder("Sally", orders.response.records[orderIndex].ordNum, "current", "Expand", true);
                }
                break;
            case "EDI":
                ediOrders.response.records[orderIndex].isExpanded = change;
                this.setState({ediOrders});
                if (!(ediOrders.response.records[orderIndex].items && ediOrders.response.records[orderIndex].items.length > 0)) {
                    this.getOrder("EDI", ediOrders.response.records[orderIndex].ediSalesOrderID, "current", "Expand", true);
                }
                break;
        }
    }

    getOrder(type, id, navigation, action, isTrue) {
        switch (type) {
            case "Sally":
                this.getDebtorInvoice(id, navigation, action, isTrue);
                break;
            case "EDI":
                this.getEDIOrder(id, navigation, action, isTrue);
                break;
        }
    }

    handleSelect(orderIndex, change, type) {
        let {orders, ediOrders} = this.state;

        switch (type) {
            case "Sally":
                orders.response.records.forEach((order, orderIndex) => {
                    orders.response.records[orderIndex].isSelected = false;
                    orders.response.records[orderIndex].isExpanded = false;
                });
                orders.response.records[orderIndex].isSelected = change;
                this.setState({orders}, () => {
                    if (change) {
                        this.getOrder("Sally", orders.response.records[orderIndex].ordNum, "current", "Select", change);
                    }
                });
                break;
            case "EDI":
                ediOrders.response.records.forEach((order, orderIndex) => {
                    ediOrders.response.records[orderIndex].isSelected = false;
                    ediOrders.response.records[orderIndex].isExpanded = false;
                });
                ediOrders.response.records[orderIndex].isSelected = change;
                this.setState({ediOrders}, () => {
                    if (change) {
                        this.getOrder("EDI", ediOrders.response.records[orderIndex].ediSalesOrderID, "current", "Select", change);
                    }
                });
                break;
        }
    }

    getDebtorInvoice(ordNum, navigation, actionType, isTrue) {
        let {orders, ediOrders} = this.state;
        let orderIndex = -1;

        orderIndex = orders.response.records.findIndex(r => r.ordNum === ordNum);
        switch (actionType) {
            case "Expand":
                orders.response.records[orderIndex].isLoadingItems = true;
                break;
            case "Select":
                orders.response.records[orderIndex].isLoadingLinkedOrder = true;
                break;
        }


        this.setState({orders});

        this.orderService.getOrder(ordNum, true, null, false, navigation).then(response => {
            orderIndex = orders.response.records.findIndex(r => r.ordNum === response.data.ordNum);
            if (orderIndex > -1) {
                orders.response.records[orderIndex].isLoadingItems = false;
                orders.response.records[orderIndex].isLoadingLinkedOrder = false;
                orders.response.records[orderIndex].isExpanded = isTrue;
                orders.response.records[orderIndex].items = response.data.items;

                switch (actionType) {
                    case "Expand":
                        break;
                    case "Select":
                        if (response.data.ediOrder) {
                            orderIndex = ediOrders.response.records.findIndex(r => r.ediSalesOrderID === response.data.ediOrder.oid);
                            if (orderIndex > -1) {
                                ediOrders.response.records.forEach((order, orderIndex) => {
                                    ediOrders.response.records[orderIndex].isSelected = false;
                                    ediOrders.response.records[orderIndex].isExpanded = false;
                                });

                                ediOrders.response.records[orderIndex].isSelected = isTrue;
                                ediOrders.response.records[orderIndex].isExpanded = isTrue;
                                ediOrders.response.records[orderIndex].items = response.data.ediOrder.salesOrderItems;
                            }
                        }
                        break;
                }
                this.setState({orders, ediOrders});
            }
        }).catch(error => {
            console.error(error);
            orderIndex = orders.response.records.findIndex(r => r.ordNum === ordNum);
            if (orderIndex > -1) {
                orders.response.records[orderIndex].isLoadingItems = false;
                orders.response.records[orderIndex].isLoadingLinkedOrder = false;
                orders.response.records[orderIndex].items = [];
                this.setState({orders});
            }

            toast.error(handleErrorMessage(error));
        });
    }

    getEDIOrder(ediSalesOrderID, navigation, actionType, isTrue) {
        let {ediOrders, orders} = this.state;
        let orderIndex = -1;
        orderIndex = ediOrders.response.records.findIndex(r => r.ediSalesOrderID === ediSalesOrderID);
        switch (actionType) {
            case "Expand":
                ediOrders.response.records[orderIndex].isLoadingItems = true;
                break;
            case "Select":
                ediOrders.response.records[orderIndex].isLoadingLinkedOrder = true;
                break;
        }

        this.setState({ediOrders});
        this.orderService.getEDIOrder(ediSalesOrderID, navigation).then(response => {
            orderIndex = ediOrders.response.records.findIndex(r => r.ediSalesOrderID === response.data.oid);
            if (orderIndex > -1) {
                ediOrders.response.records[orderIndex].isLoadingItems = false;
                ediOrders.response.records[orderIndex].isLoadingLinkedOrder = false;
                ediOrders.response.records[orderIndex].isExpanded = isTrue;
                ediOrders.response.records[orderIndex].items = response.data.salesOrderItems;
                switch (actionType) {
                    case "Expand":
                        break;
                    case "Select":
                        if (response.data.debtorInvoice) {
                            orderIndex = orders.response.records.findIndex(r => r.ordNum === response.data.debtorInvoice.ordNum);
                            if (orderIndex > -1) {
                                orders.response.records.forEach((order, orderIndex) => {
                                    orders.response.records[orderIndex].isSelected = false;
                                    orders.response.records[orderIndex].isExpanded = false;
                                });

                                orders.response.records[orderIndex].isSelected = isTrue;
                                orders.response.records[orderIndex].isExpanded = isTrue;
                                orders.response.records[orderIndex].items = response.data.debtorInvoice.items;
                            }
                        }
                        break;
                }

                this.setState({orders, ediOrders});
            }
        }).catch(error => {
            console.error(error);
            orderIndex = ediOrders.response.records.findIndex(r => r.ediSalesOrderID === ordNum);
            if (orderIndex > -1) {
                ediOrders.response.records[orderIndex].isLoadingItems = false;
                ediOrders.response.records[orderIndex].isLoadingLinkedOrder = false;
                ediOrders.response.records[orderIndex].items = [];
                this.setState({ediOrders});
            }

            toast.error(handleErrorMessage(error));
        });
    }

    takeActionOnOrder(actionCdName, isConfirmed) {
        let {orders, ediOrders} = this.state;
        let ediSalesOrderID, ordNum, actionCd, error;
        let orderIndex = orders.response.records.findIndex((order, orderIndex) => order.isSelected);
        let ediOrderIndex = ediOrders.response.records.findIndex((order, orderIndex) => order.isSelected);
        if (orderIndex > -1) {
            ordNum = orders.response.records[orderIndex].ordNum;
        }

        if (ediOrderIndex > -1) {
            ediSalesOrderID = ediOrders.response.records[ediOrderIndex].ediSalesOrderID;
        }

        switch (actionCdName) {
            case "Match":
                if (!ordNum) {
                    error = "Please select Sally order first!"
                }
                if (!ediSalesOrderID) {
                    error = "Please select EDI order first!"
                }
                break;
            case "Cancel":
                if (!ediSalesOrderID && !ordNum) {
                    error = "Please select order first!"
                }
                break;
        }
        if (error) {
            toast.info(error);
            return;
        }
        if (isConfirmed) {
            this.setState({isLoadingAction: actionCdName, isOpenConfirmModal: false});
            this.orderService.forceMatchSalesOrderEDIMatchManager(ediSalesOrderID, ordNum, actionCdName).then(response => {
                this.setState({isLoadingAction: ""});
                toast.success("Completed!");
                this.refresh();
            }).catch(error => {
                console.error(error);
                toast.error(handleErrorMessage(error));
                this.setState({isLoadingAction: ""});
            });
        } else {
            this.setState({isOpenConfirmModal: true})
        }

    }

    handleChange(change, key, type) {
        let {orders, ediOrders} = this.state;
        let searchParams = new URLSearchParams(window.location.search);

        switch (type) {
            case "EDI":
                switch (key) {
                    case "sortKey":
                        if (ediOrders.request.sortRequest.key === change) {
                            ediOrders.request.sortRequest.direction = !ediOrders.request.sortRequest.direction;
                        } else {
                            ediOrders.request.sortRequest.key = change;
                            ediOrders.request.sortRequest.direction = false;
                        }
                        searchParams.set("sortRequestKey", change);
                        searchParams.set("sortRequestDirection", ediOrders.request.sortRequest.direction);

                        this.getEDIOrders(ediOrders);
                        break;
                    case "pageSize":
                        ediOrders.request.pageRequest[key] = change;
                        searchParams.set("pageSize", change);
                        this.getEDIOrders(ediOrders);
                        break;
                    case "currentPage":
                        ediOrders.request.pageRequest[key] = change;
                        searchParams.set("currentPage", change);
                        this.getEDIOrders(ediOrders);
                        break;
                    default:
                        alert("default");
                }
                break;
            case "Sally":
                switch (key) {
                    case "sortKey":
                        if (orders.request.sortRequest.key === change) {
                            orders.request.sortRequest.direction = !orders.request.sortRequest.direction;
                        } else {
                            orders.request.sortRequest.key = change;
                            orders.request.sortRequest.direction = false;
                        }
                        searchParams.set("sortRequestKey", change);
                        searchParams.set("sortRequestDirection", orders.request.sortRequest.direction);

                        this.getOrders(orders);
                        break;
                    case "pageSize":
                        orders.request.pageRequest[key] = change;
                        searchParams.set("pageSize", change);
                        this.getOrders(orders);
                        break;
                    case "currentPage":
                        orders.request.pageRequest[key] = change;
                        searchParams.set("currentPage", change);
                        this.getOrders(orders);
                        break;
                    default:
                        alert("default");
                }
                break;
            default:


                orders.request.filterRequest[key] = change;
                ediOrders.request.filterRequest[key] = change;

                orders.request.pageRequest.currentPage = 1;
                ediOrders.request.pageRequest.currentPage = 1;

                this.props.handleFilterFromParent(orders.request.filterRequest);
                this.setState({orders, ediOrders}, () => {
                    this.getEDIOrders(ediOrders);
                    this.getOrders(orders);
                });
                break;
        }

    }

    getStore({filterRequest}, target) {
        let type = this.props.type;
        let { hasOrderGatewayReadonlyPrivilege, highlightedOrder, highlightedEDIOrder } = this.state;
        return [
            {
                key: "isSelected",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                showColumn: target === "Sally",
                labelClassName: "text-center align-middle",
                valueClassName: "text-center hoverableItem align-middle",
                renderLabel: function (rows, componentRef) {
                    return null;
                },
                render: function (value, row, rowIndex, rows, componentRef) {
                    return <Button color={"link"} size={"sm"}
                                   disabled={rows[rowIndex].isLoadingLinkedOrder || rows[rowIndex].isLoadingLinkedOrder}
                                   onClick={() => componentRef.handleSelect(rowIndex, !rows[rowIndex].isSelected, "Sally")}>
                        {
                            rows[rowIndex].isLoadingLinkedOrder
                                ? <Spinner color={"primary"} size={"sm"}/>
                                : <i className={classnames("fa", "fa-lg", "fw", {
                                    "fa-square-o": !rows[rowIndex].isSelected,
                                    "fa-check-square-o": rows[rowIndex].isSelected,
                                }
                                )}/>
                        }
                    </Button>
                }
            },
            {
                key: "isExpanded",
                colSpan: 1,
                minWidth: 30,
                sorterApplicable: false,
                showColumn: target === "Sally",
                labelClassName: "text-center align-middle",
                valueClassName: "text-center hoverableItem align-middle",
                renderLabel: function (rows, componentRef) {
                    /* let isExpandedAll =  rows.every(row => row.isExpanded);
                     return <Button color={"primary"} size={"sm"}
                                    disabled={!isExpandedAll}
                                    onClick={() => componentRef.toggleAllRowExpanded(!isExpandedAll, "Sally")}>
                         <i className={classnames("fa", {
                                 "fa-plus-circle": !isExpandedAll,
                                 "fa-minus-circle": isExpandedAll,
                             }
                         )}/>
                     </Button>*/
                    return null;
                },
                render: function (value, row, rowIndex, rows, componentRef) {
                    return <Button color={"primary"} size={"sm"}
                                   disabled={rows[rowIndex].isLoadingLinkedOrder || rows[rowIndex].isLoadingLinkedOrder}
                                   onClick={() => componentRef.toggleExpandRow(rowIndex, !rows[rowIndex].isExpanded, "Sally")}>
                        <i className={classnames("fa", {
                                "fa-plus-circle": !rows[rowIndex].isExpanded,
                                "fa-minus-circle": rows[rowIndex].isExpanded,
                            }
                        )}/>
                    </Button>
                }
            },
            {
                key: "ordNum",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: target === "Sally",
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>
                        <Link
                            className={"btn btn-sm btn-" + getOrderColorName(record, highlightedOrder.ordNum) + ""}
                            style={{color: "white"}}
                            title={"Click here to view order in detail"}
                            to={"/sales/order/enquiry?" + queryString.stringify({ordNum: value})}>
                            {value}
                        </Link></span>;
                },
                renderLabel: function (rows, componentRef) {
                    return "Order No";
                },
            },

            {
                key: "ordDate",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                showColumn: target === "Sally",
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return changeFormatOfDateString(value, "YYYY-MM-DD hh:mm", 'DD MMM YYYY HH:mm');
                },
                renderLabel: function (rows, componentRef) {
                    return "Order Date";
                },
            },
            {
                key: "accountID",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: target === "Sally",
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {

                    let className = "";
                    switch (record.debtorCategoryID) {
                        case 1:
                            className = "btn btn-sm btn-warning";
                            break;
                        case 2:
                            className = "btn btn-sm btn-secondary";
                            break;
                        case 3:
                        default:
                            className = "btn btn-sm btn-primary";
                            break;
                    }

                    let result1 = "";
                    if (value) {
                        if (hasOrderGatewayReadonlyPrivilege) {
                            result1 = <Button className={className} disabled>{getChildAccountID(value)}</Button>;
                        }
                        else {
                            result1 = <Link
                                className={className}
                                style={{color: "white"}}
                                to={"/customer/account/detail?" + queryString.stringify({accountID: value})}
                                title={record.company}>{getChildAccountID(value)}</Link>;
                        }
                    } else {
                        result1 = ''
                    }
                    return <span>{result1}</span>;
                },
                renderLabel: function (rows, componentRef) {
                    return "Store Code";
                },
            },
            {
                key: "orderNumber",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                showColumn: target === "Sally",
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                },
                renderLabel: function (rows, componentRef) {
                    return "Cust.Ord Ref.";
                },
            }, {
                key: "errorShortName",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                showColumn: target === "Sally",
                valueClassName: "align-middle",
                labelClassName: "align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                },
                renderLabel: function (rows, componentRef) {
                    return "Unmatch Reason";
                },
            },
            {
                key: "isSelected",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                showColumn: target === "EDI",
                labelClassName: "text-center align-middle",
                valueClassName: "text-center hoverableItem align-middle",
                renderLabel: function (rows, componentRef) {
                    return null;
                },
                render: function (value, row, rowIndex, rows, componentRef) {
                    return <Button color={"link"} size={"sm"}
                                   disabled={rows[rowIndex].isLoadingLinkedOrder || rows[rowIndex].isLoadingLinkedOrder}
                                   onClick={() => componentRef.handleSelect(rowIndex, !rows[rowIndex].isSelected, "EDI")}>
                        {
                            rows[rowIndex].isLoadingLinkedOrder
                                ? <Spinner color={"primary"} size={"sm"}/>
                                : <i className={classnames("fa", "fa-lg", "fw", {
                                    "fa-square-o": !rows[rowIndex].isSelected,
                                    "fa-check-square-o": rows[rowIndex].isSelected,
                                }
                                )}/>
                        }
                    </Button>
                }
            },
            {
                key: "isExpanded",
                colSpan: 1,
                minWidth: 30,
                sorterApplicable: false,
                showColumn: target === "EDI",
                labelClassName: "text-center align-middle",
                valueClassName: "text-center hoverableItem align-middle",
                renderLabel: function (rows, componentRef) {
                    /* let isExpandedAll =  rows.every(row => row.isExpanded);
                     return <Button color={"primary"} size={"sm"}
                                    onClick={() => componentRef.toggleAllRowExpanded(!isExpandedAll, "EDI")}>
                         <i className={classnames("fa", {
                                 "fa-plus-circle": !isExpandedAll,
                                 "fa-minus-circle": isExpandedAll,
                             }
                         )}/>
                     </Button>*/
                    return null;
                },
                render: function (value, row, rowIndex, rows, componentRef) {
                    return <Button color={"primary"} size={"sm"}
                                   disabled={rows[rowIndex].isLoadingLinkedOrder || rows[rowIndex].isLoadingLinkedOrder}
                                   onClick={() => componentRef.toggleExpandRow(rowIndex, !rows[rowIndex].isExpanded, "EDI")}>
                        <i className={classnames("fa", {
                                "fa-plus-circle": !rows[rowIndex].isExpanded,
                                "fa-minus-circle": rows[rowIndex].isExpanded,
                            }
                        )}/>
                    </Button>
                }
            },
            {
                key: "ediSalesOrderID",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: target === "EDI",
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>
                        <Badge color={(highlightedEDIOrder.ediSalesOrderID === value ? "success" : "primary")}> { value }
                        </Badge>
                        </span>;
                },
                renderLabel: function (rows, componentRef) {
                    return "EDI Ord ID";
                },
            },
            {
                key: "ordNum",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: target === "EDI",
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    if (value) {
                        return <span>
                        <Link
                            className={"btn btn-sm btn-" + getOrderColorName(record, highlightedOrder.ordNum) + ""}
                            style={{color: "white"}}
                            title={"Click here to view order in detail"}
                            to={"/sales/order/enquiry?" + queryString.stringify({ordNum: value})}>
                            {value}
                        </Link></span>
                    }
                    return null;

                },
                renderLabel: function (rows, componentRef) {
                    return "Order No";
                },
            },
            {
                key: "orderDate",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                showColumn: target === "EDI",
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return changeFormatOfDateString(value, "YYYY-MM-DD hh:mm", 'DD MMM YYYY HH:mm');
                },
                renderLabel: function (rows, componentRef) {
                    return "Order Date";
                },
            }, {
                key: "accountID",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: target === "EDI",
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {

                    let className = "";
                    switch (record.debtorCategoryID) {
                        case 1:
                            className = "btn btn-sm btn-warning";
                            break;
                        case 2:
                            className = "btn btn-sm btn-secondary";
                            break;
                        case 3:
                        default:
                            className = "btn btn-sm btn-primary";
                            break;
                    }

                    let result1 = "";
                    if (value) {
                        if (hasOrderGatewayReadonlyPrivilege) {
                            result1 = <Button className={className} disabled>{getChildAccountID(value)}</Button>;
                        }
                        else {
                            result1 = <Link
                                className={className}
                                style={{color: "white"}}
                                to={"/customer/account/detail?" + queryString.stringify({accountID: value})}
                                title={record.company}>{getChildAccountID(value)}</Link>;
                        }
                    } else {
                        result1 = ''
                    }
                    return <span>{result1}</span>;
                },
                renderLabel: function (rows, componentRef) {
                    return "Store Code";
                },
            }, {
                key: "orderNumber",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                showColumn: target === "EDI",
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                },
                renderLabel: function (rows, componentRef) {
                    return "Cust.Ord Ref.";
                },
            }, {
                key: "errorShortName",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: false,
                showColumn: target === "EDI",
                valueClassName: "align-middle",
                labelClassName: "align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                },
                renderLabel: function (rows, componentRef) {
                    return "Unmatch Reason";
                },
            },
        ];
    }

    render() {
        let { orders, ediOrders, isLoadingSallyOrders, highlightedOrder, highlightedEDIOrder,isLoadingEDIOrders, isLoadingAction, currentUser, isOpenConfirmModal} = this.state;
        let {filterRequest} = orders.request;
        let sallyStore = this.getStore(orders.request, "Sally");
        let ediStore = this.getStore(orders.request, "EDI");

        let orderIndex = orders.response.records.findIndex((order, orderIndex) => order.isSelected);
        let ediOrderIndex = ediOrders.response.records.findIndex((order, orderIndex) => order.isSelected);

        return (
            <div>
                <div className="mb-2">
                    <SearchGatewayOrder
                        handleChange={(value, key) => this.handleChange(value, key, "")}
                        filterRequest={filterRequest} currentUser={currentUser}/>
                </div>
                <div className="mb-2">
                    <Button color={"primary"} size={"sm"} className="mr-2"
                            disabled={(!(orderIndex > -1 && ediOrderIndex > -1) || isLoadingAction)}
                            onClick={() => this.takeActionOnOrder("Match", true)}>
                        {
                            isLoadingAction === "Match"
                                ? <Spinner color={"light"} size={"sm"} className={"mr-2"}/>
                                : <i className="fa fa-arrows-alt mr-2" aria-hidden="true"/>
                        }
                        Match</Button>
                    <Button color={"danger"} size={"sm"} className="mr-2"
                            disabled={(((!(orderIndex > -1)) && (!(ediOrderIndex > -1))) || isLoadingAction)}
                            onClick={() => this.takeActionOnOrder("Cancel", false)}>
                        {
                            isLoadingAction === "Cancel"
                                ? <Spinner color={"light"} size={"sm"} className={"mr-2"}/>
                                : <i className="fa fa-times  mr-2" aria-hidden="true"/>
                        }
                        Cancel</Button>
                </div>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <Row className={"align-items-center"}>
                                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <div className={"text-left"}>
                                        <h6>Sally Orders</h6>
                                    </div>
                                </Col>

                                <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                    <div className={"text-right"}>
                                        {
                                            isLoadingSallyOrders
                                                ?
                                                <Spinner size={"sm"} color={"primary"}/>
                                                : <span>Showing{' '}
                                                    {((orders.request.pageRequest.currentPage - 1) * orders.request.pageRequest.pageSize) + 1}
                                                    {' '}to {((orders.request.pageRequest.currentPage) * orders.request.pageRequest.pageSize)}
                                                    {' '}of {orders.response.totalRecords}
                                                    {' '}entries</span>
                                        }
                                    </div>
                                </Col>

                            </Row>
                            <div>
                                <Table hover bordered size={"sm"} striped responsive>
                                    <thead>
                                    <tr style={{height: 60}}>
                                        {(sallyStore || []).filter(item => item.showColumn).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey", "Sally")) : undefined}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{minWidth: item.minWidth}}>
                                                    {item.renderLabel(orders.response.records, this)}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                    "fa-sort": (orders.request.sortRequest.key !== item.key),
                                                                    "fa-sort-amount-asc": (orders.request.sortRequest.key === item.key && orders.request.sortRequest.direction),
                                                                    "fa-sort-amount-desc": (orders.request.sortRequest.key === item.key && !orders.request.sortRequest.direction),
                                                                }
                                                            )} aria-hidden="true"/> : null
                                                    }
                                                </th>
                                            );
                                        })}
                                    </tr>
                                    </thead>
                                    {(orders.response.records || []).map((order, i) => {
                                        return (
                                            <tbody key={i}>
                                            <tr className={classnames({
                                                    "table-primary": order.isSelected,
                                                }
                                            )}
                                                id={highlightedOrder.ordNum === order.ordNum ? "Order" + highlightedOrder.ordNum : null}>

                                                {(sallyStore || []).filter(item => item.showColumn).map((storeItem, index) => {
                                                    return (
                                                        <td key={index} className={storeItem.valueClassName}>
                                                            {storeItem.render(order[storeItem.key], order, i, orders.response.records, this)}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                            {
                                                order.isExpanded
                                                    ? <tr className={classnames({
                                                        "table-light": order.isSelected,
                                                    }
                                                    )}>
                                                        <td colSpan={7}>
                                                            {
                                                                order.isLoadingItems
                                                                    ? <Spinner size={"sm"} color={"primary"}/>
                                                                    : <Table>
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Prodcode</th>
                                                                            <th>Description</th>
                                                                            <th className="text-right">Qty</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {
                                                                            (order.items || []).map((soi, soiIndex) => {
                                                                                return <tr key={soiIndex}
                                                                                           className={classnames({
                                                                                                   "table-primary": order.isSelected,
                                                                                               }
                                                                                           )}>
                                                                                    <td>{soi.prodCode}</td>
                                                                                    <td>{soi.description}</td>
                                                                                    <td className="text-right">{soi.qty}</td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                        <tr className={classnames({
                                                                                "table-primary": order.isSelected,
                                                                            }
                                                                        )}>
                                                                            <th colSpan={2}>Total&nbsp;
                                                                                ({(order.items || []).length} Lines)
                                                                            </th>
                                                                            <th className="text-right">{(order.items || []).reduce((n, {qty}) => n + qty, 0)}</th>
                                                                        </tr>
                                                                        </tbody>
                                                                    </Table>
                                                            }</td>
                                                    </tr>
                                                    : null
                                            }
                                            </tbody> );
                                    })}

                                </Table>
                                {!isLoadingSallyOrders ?
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div className={"text-left"} style={{maxWidth: 200}}>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Show</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type={"select"}
                                                        name={"pageSize"}
                                                        value={orders.request.pageRequest.pageSize}
                                                        disabled={isLoadingSallyOrders}
                                                        onChange={(e) => this.handleChange(e.target.value, "pageSize", "Sally")}>
                                                        <option value={10}>10 Rows</option>
                                                        <option value={25}>25 Rows</option>
                                                        <option value={50}>50 Rows</option>
                                                        <option value={100}>100 Rows</option>
                                                        <option value={500}>500 Rows</option>
                                                    </Input>
                                                </InputGroup>


                                            </div>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div className={"float-right"}>
                                                <Pagination
                                                    activePage={orders.request.pageRequest.currentPage}
                                                    itemsCountPerPage={orders.request.pageRequest.pageSize}
                                                    totalItemsCount={orders.response.totalRecords}
                                                    pageRangeDisplayed={3}
                                                    onChange={(activePage) => this.handleChange(activePage, "currentPage", "Sally")}
                                                    itemClass='page-item'
                                                    linkClass='page-link'
                                                    activeClass='active'
                                                    innerClass='pagination'
                                                    activeLinkClass='active'
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <Row className={"align-items-center"}>
                                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <div className={"text-left"}>
                                        <h6>EDI Orders</h6>
                                    </div>
                                </Col>
                                <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                    <div className={"text-right"}>
                                        {
                                            isLoadingEDIOrders
                                                ?
                                                <Spinner size={"sm"} color={"primary"}/>
                                                : <span>Showing{' '}
                                                    {((ediOrders.request.pageRequest.currentPage - 1) * ediOrders.request.pageRequest.pageSize) + 1}
                                                    {' '}to {((ediOrders.request.pageRequest.currentPage) * ediOrders.request.pageRequest.pageSize)}
                                                    {' '}of {ediOrders.response.totalRecords}
                                                    {' '}entries</span>
                                        }
                                    </div>
                                </Col>

                            </Row>
                            <div>
                                <Table hover bordered size={"sm"} striped responsive>
                                    <thead>
                                    <tr style={{height: 60}}>
                                        {(ediStore || []).filter(item => item.showColumn).map((item, index) => {
                                            return (
                                                <th key={index}
                                                    onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey", "EDI")) : undefined}
                                                    colSpan={item.colSpan}
                                                    className={item.labelClassName}
                                                    style={{minWidth: item.minWidth}}>
                                                    {item.renderLabel(ediOrders.response.records, this)}
                                                    {
                                                        item.sorterApplicable ?
                                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                                    "fa-sort": (ediOrders.request.sortRequest.key !== item.key),
                                                                    "fa-sort-amount-asc": (ediOrders.request.sortRequest.key === item.key && ediOrders.request.sortRequest.direction),
                                                                    "fa-sort-amount-desc": (ediOrders.request.sortRequest.key === item.key && !ediOrders.request.sortRequest.direction),
                                                                }
                                                            )} aria-hidden="true"/> : null
                                                    }
                                                </th>
                                            );
                                        })}
                                    </tr>
                                    </thead>
                                    {(ediOrders.response.records || []).map((order, i) => {
                                        return (
                                            <tbody key={i}>
                                            <tr className={classnames({
                                                    "table-primary": order.isSelected,
                                            }
                                                )} id={highlightedOrder.ordNum === order.ordNum ? "Order" + highlightedOrder.ordNum
                                                    : highlightedEDIOrder.ediSalesOrderID === order.ediSalesOrderID ? "SalesOrder" + highlightedEDIOrder.ediSalesOrderID :null}>
                                                {(ediStore || []).filter(item => item.showColumn).map((storeItem, index) => {
                                                    return (
                                                        <td key={index} className={storeItem.valueClassName}>
                                                            {storeItem.render(order[storeItem.key], order, i, ediOrders.response.records, this)}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                            {
                                                order.isExpanded
                                                    ? <tr>
                                                        <td colSpan={8} className={classnames({
                                                                "table-light": order.isSelected,
                                                            }
                                                        )}>
                                                            {
                                                                order.isLoadingItems
                                                                    ? <Spinner size={"sm"} color={"primary"}/>
                                                                    : <Table>
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Prodcode</th>
                                                                            <th>Description</th>
                                                                            <th className="text-right">Qty</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {
                                                                            (order.items || []).map((soi, soiIndex) => {
                                                                                return <tr key={soiIndex}
                                                                                           className={classnames({
                                                                                                   "table-primary": order.isSelected,
                                                                                               }
                                                                                           )}>
                                                                                    <td>{soi.approvedProduct}</td>
                                                                                    <td>{concatenateStrings("", soi.lineItemComment1, soi.lineItemComment2)}</td>
                                                                                    <td className="text-right">{soi.qty}</td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                        <tr className={classnames({
                                                                                "table-primary": order.isSelected,
                                                                            }
                                                                        )}>
                                                                            <th colSpan={2}>Total&nbsp;
                                                                                ({(order.items || []).length} Lines)
                                                                            </th>
                                                                            <th className="text-right">{(order.items || []).reduce((n, {qty}) => n + qty, 0)}</th>
                                                                        </tr>
                                                                        </tbody>
                                                                    </Table>
                                                            }</td>
                                                    </tr>
                                                    : null
                                            }
                                            </tbody>
                                        );
                                    })}

                                </Table>
                                {!isLoadingEDIOrders ?
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div className={"text-left"} style={{maxWidth: 200}}>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Show</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type={"select"}
                                                        name={"pageSize"}
                                                        value={ediOrders.request.pageRequest.pageSize}
                                                        disabled={isLoadingEDIOrders}
                                                        onChange={(e) => this.handleChange(e.target.value, "pageSize", "EDI")}>
                                                        <option value={10}>10 Rows</option>
                                                        <option value={25}>25 Rows</option>
                                                        <option value={50}>50 Rows</option>
                                                        <option value={100}>100 Rows</option>
                                                        <option value={500}>500 Rows</option>
                                                    </Input>
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div className={"float-right"}>
                                                <Pagination
                                                    activePage={ediOrders.request.pageRequest.currentPage}
                                                    itemsCountPerPage={ediOrders.request.pageRequest.pageSize}
                                                    totalItemsCount={ediOrders.response.totalRecords}
                                                    pageRangeDisplayed={3}
                                                    onChange={(activePage) => this.handleChange(activePage, "currentPage", "EDI")}
                                                    itemClass='page-item'
                                                    linkClass='page-link'
                                                    activeClass='active'
                                                    innerClass='pagination'
                                                    activeLinkClass='active'
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </Col>
                </Row>

                {
                    isOpenConfirmModal
                        ? <ConfirmModal
                            isOpen={isOpenConfirmModal}
                            toggle={() => this.setState({isOpenConfirmModal: false})}
                            handleCancel={() => this.setState({isOpenConfirmModal: false})}
                            handleSubmit={() => this.takeActionOnOrder("Cancel", true)}
                            primaryMessage={"You are about to cancel this Order."}
                            secondaryMessage={"<div>This action will update the selected order to cancelled.</div><div>If SALLY Order has been selected, matching EDI Order will also be cancelled.</div><div class='mt-2'>Please confirm you with to proceed.</div>"}
                            submitColor={"danger"}
                            cancelColor={"secondary"}
                            icon={"fa fa-question-circle-o fa-2x"}
                            loading={!!isLoadingAction}
                            size="md"
                        />
                        : null
                }
                <ToastContainer/>
            </div>
        );
    }
}