import React, { Component } from 'react';
import {
    Badge, Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner,
    Table,
    CardFooter,
    InputGroup,
    InputGroupAddon,
    InputGroupText, UncontrolledButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';

import { Link as Link } from "react-router-dom";
import stockService from '../../services/StockService';
import { findIndex, handleErrorMessage } from '../../services/CommonService';
import classnames from 'classnames';
import queryString from 'query-string';
import ProductConfigurationStockModal from "../../components/modal/ProductConfigurationStockModal";
import cloneDeep from 'lodash/cloneDeep';
import productConfigurationService from '../../services/product/ProductConfigurationService';
import DeleteModal from '../../components/modal/DeleteModal';
import UserService from '../../services/UserService';
import { toast, ToastContainer } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import ProductConfigurationOptionImportModal from "../../components/modal/ProductConfigurationOptionImportModal";
import Pagination from "react-js-pagination";
import ProductConfigurationStockDefaultValuesModal from '../../components/modal/ProductConfigurationStockDefaultValuesModal';
import * as FileSaver from 'file-saver';
import ManageProductConfigurationStockFormulaModal from "../../components/modal/ManageProductConfigurationStockFormulaModal";

export default class ProductConfigurationOptionPage extends Component {

    constructor(props) {
        super(props);


        this.state = {
            productConfiguration: {
                id: ""
            },
            configurationOptions: {
                request: {
                    pageRequest: {
                        currentPage: 1,
                        pageSize: 50
                    },
                    sortRequest: {
                        key: '',
                        direction: false,
                    },
                    filterRequest: {
                        productConfigurationId: '',
                        optionKey: '',
                        optionLabel: '',
                        description: '',
                        primaryAttribute: '',
                        secondaryAttribute: '',
                        condition1: '',
                        condition2: '',
                        condition3: '',
                    },
                },
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: {
                productConfiguration: false,
                configurationOptions: false
            },
            isOpenStockModal: false,
            workingOption: {},
            canDeleteProductionConfiguration: false,
            isOpenImportModal: false,
            isOpenDefaultStockModal: false,
            isOpenAddFormulaModal: false,
            defaultStockValue: {},
        };
        this.userService = new UserService();

        this.getProductConfigurationOptions = this.getProductConfigurationOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.saveProductConfigurationOption = this.saveProductConfigurationOption.bind(this);
        this.refreshAgain = this.refreshAgain.bind(this);
        this.handleProdCodeData = this.handleProdCodeData.bind(this);
        this.handleDeleteProductConfiguration = this.handleDeleteProductConfiguration.bind(this);
        this.toggleDeleteProductConfigurationModal = this.toggleDeleteProductConfigurationModal.bind(this);
        this.toggleDefaultStockModal = this.toggleDefaultStockModal.bind(this);
        this.toggleImportModal = this.toggleImportModal.bind(this);
        this.handleSubmitDefaultStockValue = this.handleSubmitDefaultStockValue.bind(this);
        this.openMultiDeleteConfirmationModal = this.openMultiDeleteConfirmationModal.bind(this);
        this.toggleMultiDeleteProductConfigurationModal = this.toggleMultiDeleteProductConfigurationModal.bind(this);
        this.handleMultiDeleteProductConfiguration = this.handleMultiDeleteProductConfiguration.bind(this);
        this.handleMultipleShallowCopyProductConfiguration = this.handleMultipleShallowCopyProductConfiguration.bind(this);
        this.handleMultipleDeepCopyProductConfiguration = this.handleMultipleDeepCopyProductConfiguration.bind(this);
        this.openAddFormulaModal = this.openAddFormulaModal.bind(this);
        this.toggleDefaultAddFormulaModal = this.toggleDefaultAddFormulaModal.bind(this);
    }

    componentDidMount() {
        let { productConfiguration } = this.state;
        let currentUser = this.userService.getLoggedInUser();
        let canDeleteProductionConfiguration = this.userService.hasPrivilege(currentUser, 'product-delete');
        this.setState({ canDeleteProductionConfiguration });

        if (this.props.location.search) {
            let searchParams = queryString.parse(this.props.location.search);
            productConfiguration.id = searchParams.configurationId;
            if (productConfiguration.id) {
                this.getProductConfiguration(productConfiguration);
            }
        }
    }

    refreshAgain() {
        this.setState({ isOpenStockModal: false, isOpenAddFormulaModal: false });
        this.getProductConfiguration(this.state.productConfiguration);
    }

    getProductConfiguration(productConfiguration) {
        let { loading } = this.state;
        loading.productConfiguration = true;
        this.setState({ loading });

        productConfigurationService.getProductConfiguration(productConfiguration.id).then(response => {
            loading.productConfiguration = false;
            productConfiguration = response.data;
            let { configurationOptions } = this.state;
            configurationOptions.request.filterRequest.productConfigurationId = productConfiguration.id;

            this.setState({ productConfiguration, loading, configurationOptions });
            this.getProductConfigurationOptions(configurationOptions);
        }).catch(error => {
            loading.productConfiguration = false;
            this.setState({ loading });
            console.error(error);
            toast.error(handleErrorMessage(error))
        })
    }

    getProductConfigurationOptions(configurationOptions) {
        let { loading } = this.state;
        loading.configurationOptions = true;
        this.setState({ loading });
        productConfigurationService.getProductConfigurationOptions(configurationOptions.request).then(response => {
            loading.configurationOptions = false;
            configurationOptions.response = response.data;
            (configurationOptions.response.records || []).map((item, index) => {
                item.uuid = 'option' + uuidv4();
            });
            this.setState({ configurationOptions, loading });
        }).catch(error => {
            loading.configurationOptions = false;
            this.setState({ loading });
            alert(handleErrorMessage(error));
        })
    }


    addNewItem() {
        let { configurationOptions, productConfiguration } = this.state;
        let item = {
            id: 0,
            productConfigurationId: productConfiguration.id,
            optionLabel: "",
            optionKey: "",
            description: "",
            prodCode: "",
            isActive: true,
            parentOptionId: undefined,
            condition1: "",
            condition2: "",
            condition3: "",
            primaryAttribute: "",
            primaryAttributeMin: 0,
            primaryAttributeMax: 0,
            secondaryAttribute: "",
            secondaryAttributeMin: 0,
            secondaryAttributeMax: 0,
            uuid: 'option' + uuidv4()
        };
        configurationOptions.response.records.push(item);
        this.setState({ configurationOptions });
    }

    handleItemAction(key, itemIndex) {
        let { configurationOptions } = this.state;


        switch (key) {
            case "clone":
                let copiedOption = cloneDeep(configurationOptions.response.records[itemIndex]);
                copiedOption.uuid = 'option' + uuidv4();
                copiedOption.id = 0;
                configurationOptions.response.records.push(copiedOption);
                break;
        }

        this.setState({ configurationOptions });
    }

    handleChange(value, key, index) {
        let { configurationOptions, workingOption } = this.state;
        let i = undefined;
        switch (key) {
            case "stocks":
                i = findIndex(configurationOptions.response.records[index].stocks, 'prodCode', value.prodCode);
                if (i > -1) {
                    configurationOptions.response.records[index].stocks.splice(i, 1);
                } else {
                    configurationOptions.response.records[index].stocks.push(value);
                }
                break;
            case "productConfigurationOptionStocks_isOpen":
                workingOption = configurationOptions.response.records[index];
                this.setState({ workingOption, isOpenStockModal: value });
                break;
            case "productConfigurationOptionStocks_toggle":
                if (!value) {
                    workingOption = {};
                }
                this.setState({ workingOption, isOpenStockModal: value });
                break;
            case "isAllSelected":
                configurationOptions.response.records.forEach(item => {
                    item.isSelected = value;
                })
                break;
            default:
                configurationOptions.response.records[index][key] = value;

        }
        this.setState({ configurationOptions });
    }

    handleChangeRequest(change, key) {
        let { configurationOptions } = this.state;
        switch (key) {
            case "searchText":
                this.setState({ searchText: change });
                break;
            case "sortKey":
                if (configurationOptions.request.sortRequest.key === change) {
                    configurationOptions.request.sortRequest.direction = !configurationOptions.request.sortRequest.direction;
                } else {
                    configurationOptions.request.sortRequest.key = change;
                    configurationOptions.request.sortRequest.direction = false;
                }
                if (!(change === "action" || change === "srNo")) {
                    this.getProductConfigurationOptions(configurationOptions);
                }
                break;
            case "pageSize":
                configurationOptions.request.pageRequest[key] = parseInt(change);
                configurationOptions.request.pageRequest.currentPage = 1;
                this.getProductConfigurationOptions(configurationOptions);
                break;
            case "currentPage":
                configurationOptions.request.pageRequest[key] = change;
                this.getProductConfigurationOptions(configurationOptions);
                break;


            default:
                configurationOptions.request.filterRequest[key] = change;

                configurationOptions.request.pageRequest.currentPage = 1;
                this.getProductConfigurationOptions(configurationOptions);
                this.setState({ configurationOptions });
        }

    }

    saveProductConfigurationOption(config, index) {
        let { configurationOptions, loading } = this.state;

        let item = cloneDeep(config);
        loading.save = index;
        this.setState({ loading });

        productConfigurationService.saveProductConfigurationOption(item).then(response => {
            if (response.status == 200 || response.status == '200') {
                toast.success("Updated");
                configurationOptions.response.records[index] = response.data;
                configurationOptions.response.records[index].uuid = 'option' + uuidv4();
                loading.save = '';
        }     
            this.setState({ configurationOptions, loading });
        }).catch(error => {
            loading.save = '';
            this.setState({ loading });
            console.error(error);
            toast.error(handleErrorMessage(error));
        })
    }

    openDeleteConfirmationModal(item) {
        if (item.id) {
            this.setState({ isOpenDeleteProductConfigurationModal: true, selectedItemProductConfigurationOption: item });
        }
        else {
            let { configurationOptions } = this.state;
            let index = findIndex(configurationOptions.response.records, "uuid", item.uuid);
            configurationOptions.response.records.splice(index, 1);
            this.setState({ configurationOptions });
        }
    }

    openMultiDeleteConfirmationModal() {
        this.setState({ isOpenMultiDeleteProductConfigurationModal: true })
    }

    toggleMultiDeleteProductConfigurationModal(data) {
        this.setState({ isOpenMultiDeleteProductConfigurationModal: data });
    }

    toggleDeleteProductConfigurationModal(data) {
        this.setState({ isOpenDeleteProductConfigurationModal: data });
    }

    toggleImportModal(isOpenImportModal, isRefresh) {
        this.setState({ isOpenImportModal });
        if (isRefresh) {
            this.getProductConfigurationOptions(this.state.configurationOptions)
        }
    }

    handleDeleteProductConfiguration() {
        let { selectedItemProductConfigurationOption, configurationOptions } = this.state;
        let index = findIndex(configurationOptions.response.records, 'id', selectedItemProductConfigurationOption.id);

        this.setState({ deletingProductConfiguration: true });
        productConfigurationService.deleteProductConfigurationOption(selectedItemProductConfigurationOption.id).then(response => {
            if (response.status === 200) {
                toast.success("Deleted");
                configurationOptions.response.records.splice(index, 1);
                this.setState({
                    configurationOptions,
                    deletingProductConfiguration: false,
                    isOpenDeleteProductConfigurationModal: false
                });
            }
        }).catch(error => {
            this.setState({ deletingProductConfiguration: false });
            toast.error(handleErrorMessage(error));
        })
    }

    handleMultiDeleteProductConfiguration() {
        let { configurationOptions } = this.state;
        let optionCommaSeparatedIds = "";
        this.setState({ deletingProductConfiguration: true });
        optionCommaSeparatedIds = (configurationOptions.response.records || []).filter(x => x.isSelected).map(option => option.id).join(",");
        productConfigurationService.deleteMultiProductConfigurationOption(optionCommaSeparatedIds).then(response => {
            if (response.status === 200) {
                    this.setState({
                        configurationOptions,
                        deletingProductConfiguration: false,
                        isOpenMultiDeleteProductConfigurationModal: false
                    });
                toast.success("Deleted");
                this.refreshAgain();
            }
        }).catch(error => {
            this.setState({ deletingProductConfiguration: false, isOpenMultiDeleteProductConfigurationModal: false });
            toast.error(handleErrorMessage(error));
        })

    }

    handleMultipleShallowCopyProductConfiguration() {
        let { configurationOptions, loading } = this.state;
        loading.clone = true;
        this.setState({ loading });
        (configurationOptions.response.records || []).filter(x => x.isSelected).forEach(configurationOption => {
            configurationOption.isSelected = false;
            let copiedOption = cloneDeep(configurationOption);
            copiedOption.uuid = 'option' + uuidv4();
            copiedOption.id = 0;
            configurationOptions.response.records.push(copiedOption);
            if (configurationOptions.response.records.every(x => !x.isSelected)) {
                loading.clone = false;
                this.setState({ configurationOptions, loading });
            }
        });
    }

    handleMultipleDeepCopyProductConfiguration() {
        let { configurationOptions, loading } = this.state;
        let copiedOptions = [];
        loading.clone = true;
        this.setState({ loading });
        (configurationOptions.response.records || []).filter(x => x.isSelected).forEach(configurationOption => {
            let copiedOption = cloneDeep(configurationOption);
            copiedOption.id = 0;
            copiedOptions.push(copiedOption);
        })
        productConfigurationService.saveProductConfigurationOptionList(copiedOptions).then(response => {
            if (response.status === 200) {
                loading.clone = false;
                toast.success("Copied!")
                this.setState({ configurationOptions, loading });
                this.refreshAgain();
            }
        }).catch(error => {
            loading.clone = false;
            this.setState({ loading });
            console.error(error);
            toast.error(handleErrorMessage(error));
        })

    }

    handleProdCodeData(prodCode) {
        let { configurationOptions, workingOption } = this.state;
        let index = findIndex(configurationOptions.response.records, 'id', workingOption.id);
        let i = findIndex(configurationOptions.response.records[index].stocks, 'prodCode', prodCode);
        if (i > -1) {
            configurationOptions.response.records[index].stocks.splice(i, 1);
        }
        this.setState({ configurationOptions });
    }

    openDefaultStockModal() {
        this.setState({ isOpenDefaultStockModal: true });
    }

    toggleDefaultStockModal(data) {
        this.setState({ isOpenDefaultStockModal: data });
    }

    handleSubmitDefaultStockValue(data) {
        this.setState({ defaultStockValue: data, isOpenDefaultStockModal: false });
    } 

    openAddFormulaModal() {
        this.setState({ isOpenAddFormulaModal: true });
    }

    toggleDefaultAddFormulaModal(data) {
        this.setState({ isOpenAddFormulaModal: data });
    }

    exportProductConfigurationOptionInExcel() {
        let { loading, productConfiguration } = this.state;
        loading.export = true;
        this.setState({ loading });
        productConfigurationService.exportProductConfigurationOptionInExcel(productConfiguration.id).then(response => {
            FileSaver.saveAs(response.data, "ProductConfigurationOptionReport.xlsx");
            loading.export = false;
            this.setState({ loading });
        }).catch(error => {
            loading.export = false;
            this.setState({ loading });
            toast.error(handleErrorMessage(error));
        })
    }

    render() {
        let { productConfiguration, loading, configurationOptions, isOpenStockModal, workingOption, canDeleteProductionConfiguration, isOpenDeleteProductConfigurationModal, isOpenImportModal, isOpenDefaultStockModal, isOpenMultiDeleteProductConfigurationModal, isOpenAddFormulaModal } = this.state;
        let { filterRequest, pageRequest, sortRequest } = configurationOptions.request;
        let isAllSelected = configurationOptions.response.records.every(x => x.isSelected);

        return (
            <div>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/')}>Home</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push('/inventory/products/all/list')}>Products</BreadcrumbItem>
                    <BreadcrumbItem tag="a" href="javascript:void(0)"
                        onClick={() => this.props.history.push("/inventory/product/configuration?" + queryString.stringify({ productID: productConfiguration.productId }))}>Configuration</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Options</BreadcrumbItem>
                </Breadcrumb>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl='4' lg='4' md='4' sm='12' xs='12'>
                                {
                                    loading.productConfiguration
                                        ? <Spinner color="primary" />
                                        : <h5>{productConfiguration.attributeLabel}</h5>
                                }
                            </Col>
                            <Col xl='8' lg='8' md='8' sm='12' xs='12'> 
                                <div className='text-right'>
                                    <Button color={"primary"} size={"sm"} className='mr-2'
                                        onClick={() => this.openAddFormulaModal()}>
                                        <i class="fa fa-calculator mr-2" aria-hidden="true"/>
                                        Add Formulas
                                    </Button>
 
                                    <Button color={"primary"} size={"sm"}
                                        onClick={() => this.openDefaultStockModal()}>
                                        Default Stock Configurations
                                    </Button>
                                    <Button color={"primary"} size={"sm"} className='ml-2'
                                        onClick={() => this.exportProductConfigurationOptionInExcel()} disabled={loading.export}>
                                        {
                                            loading.export ?
                                                <Spinner size="sm"
                                                    className={"mr-2"}
                                                    color={"light"} /> :
                                                <i className="fa fa-file-excel-o mr-2" aria-hidden="true" />
                                        }Export
                                    </Button>
                                    <Button color={"primary"} size={"sm"} className='ml-2'
                                        onClick={() => this.toggleImportModal(true, false)}>
                                        <i className="fa fa-upload mr-2" aria-hidden="true" />Import
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-left"}>
                                    {loading.configurationOptions ? <p>Loading...</p> :
                                        <p>Showing
                                            {' '}{((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                            {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                            {' '}of {configurationOptions.response.totalRecords}
                                            {' '}entries</p>
                                    }
                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={"text-right"}>
                                    {configurationOptions.response.records.some(x => x.isSelected) ?
                                        <>
                                            <Button color={"danger"} size={"sm"} className='mr-2'
                                                onClick={() => this.openMultiDeleteConfirmationModal()}>
                                                <i className="fa fa-trash mr-2" aria-hidden="true" />Delete
                                        </Button>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle caret color={"secondary"}
                                                    size={"sm"}
                                                    disabled={loading.clone}>
                                                    {
                                                        loading.clone ?
                                                            <Spinner size="sm"
                                                                className={"mr-2"}
                                                                color={"light"} /> :
                                                            <i className="fa fa-clone fa-lg mr-2" aria-hidden="true" />
                                                    }
                                                    Copy
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem
                                                        onClick={() => this.handleMultipleShallowCopyProductConfiguration()}>
                                                        <i className="fa fa-clone fa-lg mr-2" aria-hidden="true" /> Shallow
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => this.handleMultipleDeepCopyProductConfiguration()}>
                                                        <i className="fa fa-clone fa-lg mr-2" aria-hidden="true" />Deep
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </> : null}
                                </div>
                            </Col>
                        </Row>

                        <div>
                            <Table responsive={true} striped bordered size={"sm"}>
                                <thead>
                                    <tr>
                                        <th className={"text-center"}>Select All</th>
                                        <th>S.No</th>
                                        <th style={{ minWidth: '200px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("optionLabel", "sortKey")}
                                            className={'hoverableItem align-middle'}>Option-name
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "optionLabel"),
                                                "fa-sort-amount-asc": (sortRequest.key === "optionLabel" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "optionLabel" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                            <small>
                                                <div>It will be displayed as label</div>
                                            </small>
                                        </th>
                                        <th style={{ minWidth: '200px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("optionKey", "sortKey")}
                                            className={'hoverableItem align-middle'}>Option-value
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "optionKey"),
                                                "fa-sort-amount-asc": (sortRequest.key === "optionKey" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "optionKey" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                            <small>
                                                <div>It should be exact final value</div>
                                            </small>
                                        </th>
                                        <th style={{ minWidth: '200px' }}>Parent option
                                                <small>
                                                <div>Option-name</div>
                                            </small>
                                        </th>
                                        <th style={{ minWidth: '200px' }}>Prod-code
                                                <small>
                                            </small>
                                        </th>
                                        <th style={{ minWidth: '150px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("description", "sortKey")}
                                            className={'hoverableItem algin-middle'}                                        >
                                            Description
                                             <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "description"),
                                                "fa-sort-amount-asc": (sortRequest.key === "description" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "description" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '150px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("primaryAttribute", "sortKey")}
                                            className={'hoverableItem algin-middle'} >
                                            <div>Primary</div>
                                            <div>Attribute</div>
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "primaryAttribute"),
                                                "fa-sort-amount-asc": (sortRequest.key === "primaryAttribute" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "primaryAttribute" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '100px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("primaryAttributeMin", "sortKey")}
                                            className={'hoverableItem algin-middle'}>
                                            <div>Primary</div>
                                            <div>Attribute</div>
                                            <div>Min</div>
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "primaryAttributeMin"),
                                                "fa-sort-amount-asc": (sortRequest.key === "primaryAttributeMin" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "primaryAttributeMin" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '100px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("primaryAttributeMax", "sortKey")}
                                            className={'hoverableItem algin-middle'}>
                                            <div>Primary</div>
                                            <div>Attribute</div>
                                            <div>Max</div>
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "primaryAttributeMax"),
                                                "fa-sort-amount-asc": (sortRequest.key === "primaryAttributeMax" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "primaryAttributeMax" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '150px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("secondaryAttribute", "sortKey")}
                                            className={'hoverableItem algin-middle'}>
                                            <div>Sec.</div>
                                            <div>Attribute</div>
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "secondaryAttribute"),
                                                "fa-sort-amount-asc": (sortRequest.key === "secondaryAttribute" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "secondaryAttribute" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '100px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("secondaryAttributeMin", "sortKey")}
                                            className={'hoverableItem algin-middle'}>
                                            <div>Sec.</div>
                                            <div>Attribute</div>
                                            <div>Min</div>
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "secondaryAttributeMin"),
                                                "fa-sort-amount-asc": (sortRequest.key === "secondaryAttributeMin" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "secondaryAttributeMin" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '100px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("secondaryAttributeMax", "sortKey")}
                                            className={'hoverableItem algin-middle'}>
                                            <div>Sec.</div>
                                            <div>Attribute</div>
                                            <div>Max</div>
                                            <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "secondaryAttributeMax"),
                                                "fa-sort-amount-asc": (sortRequest.key === "secondaryAttributeMax" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "secondaryAttributeMax" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '150px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("condition1", "sortKey")}
                                            className={'hoverableItem algin-middle'}>
                                            Condition1
                                             <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "condition1"),
                                                "fa-sort-amount-asc": (sortRequest.key === "condition1" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "condition1" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '150px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("condition2", "sortKey")}
                                            className={'hoverableItem algin-middle'}>
                                            Condition2
                                             <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "condition2"),
                                                "fa-sort-amount-asc": (sortRequest.key === "condition2" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "condition2" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th style={{ minWidth: '150px', cursor: 'pointer' }}
                                            onClick={() => this.handleChangeRequest("condition3", "sortKey")}
                                            className={'hoverableItem algin-middle'}>
                                            Condition3
                                             <i className={classnames("fa", "float-right", "pt-1", {
                                                "fa-sort": (sortRequest.key !== "condition3"),
                                                "fa-sort-amount-asc": (sortRequest.key === "condition3" && sortRequest.direction),
                                                "fa-sort-amount-desc": (sortRequest.key === "condition3" && !sortRequest.direction),
                                            }
                                            )} aria-hidden="true" />
                                        </th>
                                        <th className={"text-center"}>Active</th>
                                        <th style={{ minWidth: '100px' }} className={"text-center"}>Sets</th>
                                        <th style={{ minWidth: '250px' }} className={"text-center"}>Action</th>
                                    </tr>
                                    <tr>
                                        <td><a href={"javascript:void(0)"} className="ml-3"
                                            onClick={() => this.handleChange(!isAllSelected, "isAllSelected")}>
                                            <i className={classnames("mt-2", "fa", "fa-lg", {
                                                "fa-check-square-o": isAllSelected,
                                                "fa-square-o": !isAllSelected,
                                            }
                                            )} />
                                        </a></td>
                                        <td></td>

                                        <td>
                                            <Input type='text' name='optionLabel'
                                                placeholder='Search'
                                                value={filterRequest.optionLabel}
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td>
                                            <Input type='text' name='optionKey'
                                                placeholder='Search'
                                                value={filterRequest.optionKey}
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td>
                                            <Input type={"text"}
                                                name={"optionKey"}
                                                value={filterRequest.optionKey}
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)}
                                            />
                                        </td>

                                        <td></td>
                                        <td>
                                            <Input type='text' name='description'
                                                value={filterRequest.description}
                                                placeholder='Search'
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td>
                                            <Input type='text' name='primaryAttribute'
                                                value={filterRequest.primaryAttribute}
                                                placeholder='Search'
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <Input type='text' name='secondaryAttribute'
                                                placeholder='Search'
                                                value={filterRequest.secondaryAttribute}
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <Input type='text' name='condition1'
                                                placeholder='Search'
                                                value={filterRequest.condition1}
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td>
                                            <Input type='text' name='condition2'
                                                placeholder='Search'
                                                value={filterRequest.condition2}
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td>
                                            <Input type='text' name='condition3'
                                                placeholder='Search'
                                                value={filterRequest.condition3}
                                                onChange={(e) => this.handleChangeRequest(e.target.value, e.target.name)} />
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(configurationOptions.response.records || []).map((item, index) => {
                                        return <tr key={index}>
                                            <td className={"text-center"}>
                                                {
                                                    item.id ?
                                                        <a href={"javascript:void(0)"}
                                                            onClick={() => this.handleChange(!item.isSelected, "isSelected", index)}>
                                                            <i className={classnames("mt-2", "fa", "fa-lg", {
                                                                "fa-check-square-o": item.isSelected,
                                                                "fa-square-o": !item.isSelected,
                                                            }
                                                            )} />
                                                        </a> : null
                                                }

                                            </td>
                                            <td>{((pageRequest.currentPage - 1) * pageRequest.pageSize) + (index + 1)}</td>
                                            <td>
                                                <Input
                                                    type={"text"}
                                                    name={"optionLabel"}
                                                    placeholder={" option name"}
                                                    value={item.optionLabel}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"text"}
                                                    name={"optionKey"}
                                                    placeholder={" option value"}
                                                    value={item.optionKey}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input type={"select"}
                                                    name={"parentOptionId"}
                                                    value={item.parentOptionId}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                >
                                                    <option value={""}>Select</option>
                                                    {(configurationOptions.response.records || [])
                                                        .filter(option => (option.id && (option.id !== item.id)))
                                                        .map(option => {
                                                            return <option key={option.id}
                                                                value={option.id}>{option.optionLabel}</option>
                                                        }
                                                        )
                                                    }
                                                </Input>
                                            </td>
                                            <td>
                                                {item.id
                                                    ? <div>
                                                        {
                                                            (item.stocks || []).map((stock, pIndex) => {
                                                                return <Badge key={pIndex}
                                                                    color="primary"
                                                                    className={"m-1"}
                                                                >{stock.prodCode}</Badge>
                                                            }
                                                            )
                                                        }
                                                        <Button
                                                            color="link"
                                                            size={"sm"}
                                                            className={"m-1"}
                                                            onClick={() => this.handleChange(true, "productConfigurationOptionStocks_isOpen", index)}
                                                        ><i className="fa fa-plus mr-2" aria-hidden="true" />Add/View/Delete
                                                            </Button>
                                                    </div>
                                                    : null
                                                }
                                            </td>
                                            <td>
                                                <Input
                                                    type={"textarea"}
                                                    name={"description"}
                                                    placeholder={"description"}
                                                    value={item.description}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"text"}
                                                    name={"primaryAttribute"}
                                                    placeholder={"primaryAttribute"}
                                                    value={item.primaryAttribute}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"number"}
                                                    name={"primaryAttributeMin"}
                                                    placeholder={"primaryAttributeMin"}
                                                    value={item.primaryAttributeMin}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"number"}
                                                    name={"primaryAttributeMax"}
                                                    placeholder={"primaryAttributeMax"}
                                                    value={item.primaryAttributeMax}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"text"}
                                                    name={"secondaryAttribute"}
                                                    placeholder={"secondaryAttribute"}
                                                    value={item.secondaryAttribute}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"number"}
                                                    name={"secondaryAttributeMin"}
                                                    placeholder={"secondaryAttributeMin"}
                                                    value={item.secondaryAttributeMin}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"number"}
                                                    name={"secondaryAttributeMax"}
                                                    placeholder={"secondaryAttributeMax"}
                                                    value={item.secondaryAttributeMax}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"textarea"}
                                                    name={"condition1"}
                                                    placeholder={"condition1"}
                                                    value={item.condition1}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"textarea"}
                                                    name={"condition2"}
                                                    placeholder={"condition2"}
                                                    value={item.condition2}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type={"textarea"}
                                                    name={"condition3"}
                                                    placeholder={"condition3"}
                                                    value={item.condition3}
                                                    onChange={(e) => this.handleChange(e.target.value, e.target.name, index)}
                                                />
                                            </td>
                                            <td className={"text-center"}>
                                                <a href={"javascript:void(0)"}
                                                    onClick={() => this.handleChange(!item.isActive, "isActive", index)}>
                                                    <i className={classnames("mt-2", "fa", "fa-lg", {
                                                        "fa-check-square-o": item.isActive,
                                                        "fa-square-o": !item.isActive,
                                                    }
                                                    )} />
                                                </a>
                                            </td>
                                            <td className={"text-center"}>
                                                {
                                                    item.id
                                                        ? <Link
                                                            to={"/inventory/product/configuration/option/set/?" + queryString.stringify({
                                                                productId: productConfiguration.productId,
                                                                configurationId: productConfiguration.id,
                                                                configurationOptionId: item.id
                                                            })}>
                                                            <Button color={"primary"} size={"sm"}>
                                                                <i className="fa fa-cog mr-2" aria-hidden="true" />Sets
                                                                </Button>
                                                        </Link>
                                                        : null
                                                }
                                            </td>
                                            <td className={"text-center"}>
                                                <Button
                                                    size={"sm"}
                                                    color={"primary"}
                                                    className={"mr-2"}
                                                    onClick={() => this.saveProductConfigurationOption(item, index)}>
                                                    {
                                                        loading.save === index
                                                            ? <Spinner size="sm"
                                                                className={"mr-2"}
                                                                color={"light"} /> :
                                                            <i className="fa fa-floppy-o mr-2" aria-hidden="true" />
                                                    } {item.id ? "Update" : "Save"}
                                                </Button>

                                                <Button color={"primary"}
                                                    className={"mr-2"}
                                                    size={"sm"}
                                                    title={"click here to copy item"}
                                                    onClick={() => this.handleItemAction("clone", index)}>
                                                    <i className="fa fa-clone fa-lg"
                                                        aria-hidden="true" />
                                                </Button>

                                                {
                                                    canDeleteProductionConfiguration ?
                                                        <Button color='danger'
                                                            size={"sm"}
                                                            onClick={() => this.openDeleteConfirmationModal(item)}><i
                                                                className='fa fa-trash' /></Button>
                                                        : null
                                                }
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                            <Button
                                className={"mt-2"}
                                size={"sm"}
                                color={"primary"}
                                onClick={this.addNewItem}>
                                <i className="fa fa-plus mr-2" aria-hidden="true" />
                                        Add
                                    </Button>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{ maxWidth: 200 }}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading.configurationOptions}
                                            onChange={(e) => this.handleChangeRequest(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={configurationOptions.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChangeRequest(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>

                            </Col>
                        </Row>
                    </CardFooter>
                </Card>

                <ProductConfigurationStockModal
                    isOpen={isOpenStockModal}
                    toggle={(change) => this.handleChange(change, "productConfigurationOptionStocks_toggle", null)}
                    header={workingOption.optionLabel}
                    items={workingOption.stocks}
                    productConfigurationOptionID={workingOption.id}
                    type={'options'}
                    handleRefresh={this.refreshAgain}
                    maxStocksAllowed={50}
                    canDeleteProductionConfiguration={this.state.canDeleteProductionConfiguration}
                    handlerData={this.handleProdCodeData}
                    defaultItem={this.state.defaultStockValue}
                />

                {isOpenDeleteProductConfigurationModal ?
                    <DeleteModal
                        header={'Are your sure you want to delete product configuration option'}
                        isOpen={isOpenDeleteProductConfigurationModal}
                        toggle={this.toggleDeleteProductConfigurationModal}
                        handleDelete={this.handleDeleteProductConfiguration}
                        deleting={this.state.deletingProductConfiguration}
                    /> : null}
                {isOpenMultiDeleteProductConfigurationModal ?
                    <DeleteModal
                        header={'Are your sure you want to delete selected product configuration option'}
                        isOpen={isOpenMultiDeleteProductConfigurationModal}
                        toggle={this.toggleMultiDeleteProductConfigurationModal}
                        handleDelete={this.handleMultiDeleteProductConfiguration}
                        deleting={this.state.deletingProductConfiguration}
                    /> : null}

                {isOpenImportModal ?
                    <ProductConfigurationOptionImportModal
                        isOpen={isOpenImportModal}
                        toggle={this.toggleImportModal}
                        configurationId={productConfiguration.id}
                    /> : null}

                <ProductConfigurationStockDefaultValuesModal
                    isOpen={isOpenDefaultStockModal}
                    toggle={this.toggleDefaultStockModal}
                    handleStockDefaultValue={this.handleSubmitDefaultStockValue}
                />

                { isOpenAddFormulaModal ? 
                    <ManageProductConfigurationStockFormulaModal
                        isOpen={isOpenAddFormulaModal}
                        toggle={this.toggleDefaultAddFormulaModal}
                        refreshAgain={this.refreshAgain}
                    /> : null}
                <ToastContainer />
            </div>
        );
    }
}