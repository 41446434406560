import { authHeader } from '../../helpers/auth-header';
import { config } from '../../helpers/config'
import axios from 'axios';
import axiosFileDownloader from '../axiosFileDownloader';
import axiosFileUploader from '../axiosFileUploader';

export default class CRMService {

    AllCustomerTransactions(request) {
        const requestt = {
            method: 'POST',
            headers: authHeader(),
            data: request,
            url: config.apiUrl + 'api/customer/AllTransactions'
        };
        return axios(requestt)
    }

    getCustomerTransactionInfo_byMonth(request) {
        const requestt = {
            method: 'POST',
            headers: authHeader(),
            data: request,
            url: config.apiUrl + 'api/customer/ByMonthCustomerTransactions'
        };
        return axios(requestt)
    }

getCustomerTransactionInfo_byOutstanding(requestBody) {
        const request = {
            method: 'POST',
            headers: authHeader(),
            data: requestBody,
            url: config.apiUrl + 'api/customer/OutstandingCustomerTransactions'
        };
        return axios(request)
    }

    getCustomerBalacesWithYearMonth(id, yearmonth) {
        const request = {
            method: 'GET',
            headers: authHeader(),
            url: config.apiUrl + 'api/customer/CustomerBalacesWithYearMonth?AccountId=' + id + '&YearMonth=' + yearmonth
        };
        return axios(request)
    }



}
