import React, {Component} from 'react';
import {
    Button,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Spinner,
    Table, Badge
} from 'reactstrap';
import OrderService from '../../services/OrderService';
import classnames from 'classnames';
import {
    changeFormatOfDateString,
    findIndex,
    getDateString,
    handleErrorMessage,
    scrollTo, getShipmentStatusColorName, getOrderColorName
} from '../../services/CommonService';
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import {Link as Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import {toast, ToastContainer} from 'react-toastify';
import {cloneDeep, isEmpty} from 'lodash';
import SearchGatewayOrder from '../../components/search/SearchGatewayOrder';
import UserService from '../../services/UserService';
import ShowShipmentStatusLogDetailModal from '../../components/modal/ShowShipmentStatusLogDetailModal';
import queryString from 'query-string';
import SalesOrderItemProductDetailModal from '../../components/modal/SalesOrderItemProductDetailModal';

export default class GatewayOrders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orders: {
                request: props.request,
                response: {
                    records: [],
                    totalRecords: 0
                },
            },
            loading: false,
            selectedOrder: null,
            highlightedOrder: {},
            selectedOrderNumber: props.selectedOrderNumber,
            currentUser: {},
            isOpenShipmentStatusLogModal: false,
            salesOrderJobStatuses: {
                statuses: [], statusesById: {}
            },
            type: props.type
        };

        this.orderService = new OrderService();
        this.userService = new UserService();
        this.refresh = this.refresh.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.getStore = this.getStore.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openProductGroupModal = this.openProductGroupModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.getProductGroupString = this.getProductGroupString.bind(this);
    }

    componentDidMount() {
        let currentUser = this.userService.getLoggedInUser();
        this.setState({currentUser}, () => {
            this.refresh();

        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedOrderNumber !== this.state.selectedOrderNumber || nextProps.type !== this.state.type) {
            this.setState({selectedOrderNumber: nextProps.selectedOrderNumber, type: nextProps.type}, () => {
                this.refresh();
            });
        }
    }

    refresh() {
        this.getSalesOrderJobStatuses();
        this.getOrders(this.state.orders);
    }

    getSalesOrderJobStatuses() {
        this.orderService.getOrderStatuses().then(response => {
            let {salesOrderJobStatuses} = this.state;
            salesOrderJobStatuses.statuses = response.data;
            salesOrderJobStatuses.statuses.forEach(status => {
                salesOrderJobStatuses.statusesById[status.id] = status;
            });
            this.setState({salesOrderJobStatuses})
        }).catch(error => {
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    getOrders(orders) {
        let {highlightedOrder} = this.state;
        let {selectedOrderNumber} = this.props;

        this.setState({loading: true});

        let request = cloneDeep(orders.request);
        if (request.filterRequest.startDate) {
            request.filterRequest.startDate = getDateString(request.filterRequest.startDate, "DD/MM/YYYY");
        }
        if (request.filterRequest.endDate) {
            request.filterRequest.endDate = getDateString(request.filterRequest.endDate, "DD/MM/YYYY");
        }
        this.orderService.getAllGatewayOrders(request, this.state.type).then(response => {
            orders.response = response.data;
            if (selectedOrderNumber) {
                let orderIndex = findIndex(orders.response.records, 'ordNum', parseInt(selectedOrderNumber));
                if (orderIndex > -1) {
                    // highlight order
                    highlightedOrder = orders.response.records[orderIndex];
                }

                this.setState({
                    highlightedOrder,
                    selectedOrderNumber
                }, () => {
                    setTimeout(() => {
                        scrollTo("Order" + highlightedOrder.ordNum)
                    }, 1000);

                });
            }
            this.setState({orders, loading: false, type: this.state.type});
        }).catch(error => {
            this.setState({loading: false});
            console.log(error);
            toast.error(handleErrorMessage(error));
        })
    }

    handleChange(change, key) {
        let {orders} = this.state;
        let searchParams = new URLSearchParams(window.location.search);
        switch (key) {
            case "sortKey":
                if (orders.request.sortRequest.key === change) {
                    orders.request.sortRequest.direction = !orders.request.sortRequest.direction;
                } else {
                    orders.request.sortRequest.key = change;
                    orders.request.sortRequest.direction = false;
                }
                searchParams.set("sortRequestKey", change);
                searchParams.set("sortRequestDirection", orders.request.sortRequest.direction);

                this.getOrders(orders);
                break;
            case "pageSize":
                orders.request.pageRequest[key] = change;
                searchParams.set("pageSize", change);
                this.getOrders(orders);
                break;
            case "currentPage":
                orders.request.pageRequest[key] = change;
                searchParams.set("currentPage", change);
                this.getOrders(orders);
                break;
            default:
                if (change && (typeof change === 'string' || change instanceof String)) {
                    change = change.trim();
                }
                orders.request.filterRequest[key] = change;
                orders.request.pageRequest.currentPage = 1;
                searchParams.set(key, change);
                searchParams.set("currentPage", orders.request.pageRequest.currentPage);
                this.setState({orders});
                this.getOrders(orders);
        }
    }

    getStore({filterRequest}) {
        let type = this.state.type;
        let {hasOrderGatewayReadonlyPrivilege, highlightedOrder, salesOrderJobStatuses} = this.state;
        return [
            {
                key: "ordNum",
                label: "Order No",
                type: "link",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>
                        <Link
                            className={"btn btn-sm btn-" + getOrderColorName(record, highlightedOrder.ordNum) + ""}
                            style={{color: "white"}}
                            title={"Click here to view order in detail"}
                            to={"/sales/order/enquiry?" + queryString.stringify({ordNum: value})}>
                            {value}
                        </Link></span>;
                }
            },
            {
                key: "ordDate",
                label: "Order Date",
                type: "date",
                colSpan: 1,
                minWidth: 150,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return changeFormatOfDateString(value, "YYYY-MM-DD hh:mm", 'DD MMM YYYY HH:mm');
                }
            },
            {
                key: "required",
                label: "Required Date",
                type: "date",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: type !== "SUB" && type !== "DISP",
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY');
                }
            },
            {
                key: "productionReadyDate",
                label: "ECD Date",
                type: "ECD",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: type === 'PROD',
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY');
                }
            },
            {
                key: "salesOrderJobStatusId",
                label: "Status",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: type === 'PROD',
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle text-left",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    let color = "info";
                    if (salesOrderJobStatuses) {
                        if (salesOrderJobStatuses.statusesById[value]) {
                            if (salesOrderJobStatuses.statusesById[value].isOnHold) {
                                color = "warning";
                            }
                            if (salesOrderJobStatuses.statusesById[value].isCompleted) {
                                color = "success";
                            }
                        }

                    }
                    return <div className={"text-center"}>
                        <Badge color={color}>
                            {value ? salesOrderJobStatuses.statusesById[value].statusDescription : ""}</Badge>
                    </div>;
                }
            },
            {
                key: "accountID",
                label: "Account",
                type: "categoryByID",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    let className = "";
                    switch (record.debtorCategoryID) {
                        case 1:
                            className = "btn btn-sm btn-warning";
                            break;
                        case 2:
                            className = "btn btn-sm btn-secondary";
                            break;
                        case 3:
                        default:
                            className = "btn btn-sm btn-primary";
                            break;
                    }

                    let result1 = "";
                    if (value) {
                        if (hasOrderGatewayReadonlyPrivilege) {
                            result1 = <Button className={className} disabled>{value}</Button>;
                        }
                        else {
                            result1 = <Link
                                className={className}
                                style={{color: "white"}}
                                to={"/customer/account/detail?" + queryString.stringify({accountID: value})}
                                title={"Click here to see account details"}>{value}</Link>;
                        }
                    } else {
                        result1 = ''
                    }
                    return <span>{result1}</span>;
                }
            },
            {
                key: "delCompany",
                label: "Company Name",
                type: "delCompany",
                colSpan: 1,
                minWidth: 170,
                sorterApplicable: true,
                showColumn: true,
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <small>{value}</small>;
                }
            },
            {
                key: "jobRef",
                label: "Job Ref",
                type: "default",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                showColumn: type !== "SUB" && type !== "PROD" && type !== "DISP",
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "cancelReason",
                label: "Cancel Reason",
                type: "default",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                showColumn: type === "CAN",
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "custOrdNum",
                label: "Cust. Ord Ref.",
                type: "default",
                colSpan: 1,
                minWidth: 50,
                sorterApplicable: true,
                showColumn: (type !== "CAN"),
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "shipDate",
                label: "Shipping Date",
                type: "date",
                colSpan: 1,
                minWidth: 110,
                sorterApplicable: true,
                showColumn: type === "DISP",
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY');
                }
            },
            {
                key: "couriers",
                label: "Shipment",
                type: "default",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: type === "DISP",
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{value}</span>
                }
            },
            {
                key: "consignNum",
                label: "Connote#",
                type: "default",
                colSpan: 1,
                minWidth: 100,
                sorterApplicable: true,
                showColumn: type === "DISP",
                valueClassName: "align-middle",
                labelClassName: "hoverableItem align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    let url = record.consignmentTrackingUrl;
                    if (url) {
                        return (<a
                            href={url}
                            target={"_blank"}>
                            <Button color={"link"} size={"sm"}>
                                <i className="fa fa-truck mr-2" aria-hidden="true" /><small>{value}</small>
                            </Button>
                        </a>)
                    }
                    return <span><small>{value}</small></span>;
                }
            },
            {
                key: "shippingETADate",
                label: "ETA",
                type: "default",
                colSpan: 1,
                minWidth: "110px",
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                showColumn: type === "PROD" || type === "DISP",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span>{changeFormatOfDateString(value, "DD/MM/YYYY", 'DD MMM YYYY')}</span>
                }
            },
            {
                key: "shipmentStatus",
                label: "Shipment Status",
                type: "default",
                colSpan: 1,
                minWidth: "150px",
                sorterApplicable: true,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                showColumn: type === "DISP",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <Link size={"sm"} className={"ml-2"}
                                 onClick={() => this.openShipmentStatusLogModal(order)}
                                 title={"view to see tracking status log"}>
                        <Badge color={getShipmentStatusColorName(record.shipmentStatusID)}>{value}</Badge>
                    </Link>;
                }
            },
            {
                key: "orderDetails",
                label: "Stock Group(s)",
                type: "product",
                colSpan: 1,
                minWidth: "100px",
                sorterApplicable: false,
                valueClassName: "align-middle text-center",
                labelClassName: "hoverableItem align-middle",
                showColumn: type === "SUB" || type === "PROD",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    if (value) {
                        return <div><Button className="btn btn-sm" color="link"
                                            onClick={() => ref.openProductGroupModal(record)}>{ref.getProductGroupString(record)}</Button>
                        </div>;
                    }

                },
            },
            {
                key: "ordTotal",
                label: "Order Value",
                type: "currency",
                colSpan: 1,
                minWidth: 75,
                sorterApplicable: false,
                showColumn: type !== "SUB",
                valueClassName: "text-right align-middle",
                labelClassName: "text-right align-middle",
                searchNode: null,
                render: function (value, record, recordIndex, data, ref) {
                    return <span><NumberFormat value={Math.floor(value)}
                                               displayType={'text'}
                                               thousandSeparator={true}
                                               prefix={'$'}/></span>;
                }
            },
        ];


    }

    openShipmentStatusLogModal(order) {
        this.setState({selectedOrder: order, isOpenShipmentStatusLogModal: true});
    }

    toggleShipmentStatusLogModal(change) {
        this.setState({isOpenShipmentStatusLogModal: change});
    }

    getProductGroupString(order) {
        let productGroupString = [];
        let productGroups = order.orderDetails;

        (productGroups || []).map((data) => {
            productGroupString.push(data.product + "(" + data.qty + ")");
        });

        return productGroupString.toString();
    }

    openProductGroupModal(order) {
        this.setState({selectedOrder: order, isOpenProductGroupModal: true});
    }

    toggleModal(change) {
        this.setState({isOpenProductGroupModal: change});
    }

    render() {
        let {orders, loading, highlightedOrder, currentUser, isOpenShipmentStatusLogModal, isOpenProductGroupModal, selectedOrder} = this.state;
        let {pageRequest, sortRequest, filterRequest} = orders.request;
        let store = this.getStore(orders.request);
        let type = this.props.type;
        let totalOrdervalue = 0;
        return (
            <div>
                <div className="mb-2">
                    <SearchGatewayOrder
                        handleChange={this.handleChange} type={"gatewayOrder"}
                        filterRequest={filterRequest} currentUser={currentUser}/>
                </div>
                <Row className={"align-items-center"}>
                    <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                        <div className={"text-left"}>
                            {loading
                                ? <Spinner size={"sm"} color={"primary"}/>
                                : <span>
                                    Showing{' '}
                                    {((pageRequest.currentPage - 1) * pageRequest.pageSize) + 1}
                                    {' '}to {((pageRequest.currentPage) * pageRequest.pageSize)}
                                    {' '}of {orders.response.totalRecords}
                                    {' '}entries
                                </span>
                            }

                        </div>
                    </Col>
                </Row>
                <div>
                    <Table hover bordered size={"sm"} striped responsive>
                        <thead>
                        <tr>
                            {(store || []).filter(item => item.showColumn).map((item, index) => {
                                return (
                                    <th key={index}
                                        onClick={item.sorterApplicable ? (() => this.handleChange(item.key, "sortKey")) : undefined}
                                        colSpan={item.colSpan}
                                        className={item.labelClassName}
                                        style={{minWidth: item.minWidth}}>
                                        {item.label}
                                        {
                                            item.sorterApplicable ?
                                                <i className={classnames("fa", "float-right", "pt-1", {
                                                        "fa-sort": (sortRequest.key !== item.key),
                                                        "fa-sort-amount-asc": (sortRequest.key === item.key && sortRequest.direction),
                                                        "fa-sort-amount-desc": (sortRequest.key === item.key && !sortRequest.direction),
                                                    }
                                                )} aria-hidden="true"/> : null
                                        }
                                    </th>
                                );
                            })}
                        </tr>
                        </thead>
                        <tbody>

                        {(orders.response.records || []).map((order, i) => {
                            totalOrdervalue = totalOrdervalue + order.ordTotal;
                            return (
                                <tr key={i}
                                    id={highlightedOrder.ordNum === order.ordNum ? "Order" + highlightedOrder.ordNum : null}>

                                    {(store || []).filter(item => item.showColumn ? true : false).map((storeItem, index) => {
                                        return (
                                            <td key={index} className={storeItem.valueClassName}>
                                                {storeItem.render(order[storeItem.key], order, i, orders.response.records, this)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                        {this.state.type === "DISP" ?
                            <tr>
                                <td colSpan={(store || []).filter(item => item.showColumn ? true : false).length - 1}
                                    className={"text-right"}><b>
                                    Total Order value</b></td>
                                <td className={"text-right"}>
                                    <b>
                                        <NumberFormat value={totalOrdervalue}
                                                      displayType={'text'}
                                                      decimalScale={0}
                                                      thousandSeparator={true}
                                                      prefix={'$'}/>
                                    </b>
                                </td>
                            </tr> : null}
                        </tbody>
                    </Table>

                    {!loading ?
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                <div className={"text-left"} style={{maxWidth: 200}}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Show</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type={"select"}
                                            name={"pageSize"}
                                            value={pageRequest.pageSize}
                                            disabled={loading}
                                            onChange={(e) => this.handleChange(e.target.value, "pageSize")}>
                                            <option value={10}>10 Rows</option>
                                            <option value={25}>25 Rows</option>
                                            <option value={50}>50 Rows</option>
                                            <option value={100}>100 Rows</option>
                                            <option value={500}>500 Rows</option>
                                        </Input>
                                    </InputGroup>


                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={6} sm={12} xs={12}>
                                <div className={"float-right"}>
                                    <Pagination
                                        activePage={pageRequest.currentPage}
                                        itemsCountPerPage={pageRequest.pageSize}
                                        totalItemsCount={orders.response.totalRecords}
                                        pageRangeDisplayed={3}
                                        onChange={(activePage) => this.handleChange(activePage, "currentPage")}
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='active'
                                        innerClass='pagination'
                                        activeLinkClass='active'
                                    />
                                </div>
                            </Col>
                        </Row>
                        :
                        null
                    }
                </div>
                {
                    isOpenShipmentStatusLogModal ?
                        <ShowShipmentStatusLogDetailModal
                            isOpen={isOpenShipmentStatusLogModal}
                            toggle={this.toggleShipmentStatusLogModal}
                            shipment={this.state.selectedOrder}/>
                        : null
                }
                {isOpenProductGroupModal ?
                    <SalesOrderItemProductDetailModal
                        order={selectedOrder}
                        isOpen={isOpenProductGroupModal}
                        toggle={this.toggleModal}/>
                    : null
                }
                <ToastContainer/>
            </div>
        );
    }
}