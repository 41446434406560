import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import UserService from '../../services/UserService';
import { handleErrorMessage, DebtorEmailContent, getDistinctValues } from '../../services/CommonService';
import ComposeEmail from "../modal/ComposeEmail";
import OrderService from '../../services/OrderService';
import SalesOrderService from '../../services/sales/SalesOrderService';

export default class SalesOrderShareWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: {},
            emailMessage: {},
            isLoading: false,
            currentUser: {}
        };
        this.userService = new UserService();
        this.orderService = new OrderService();
        this.salesOrderService = new SalesOrderService();

        this.getDebtorInvoice = this.getDebtorInvoice().bind(this);
        this.sendEmailModal = this.sendEmailModal.bind(this);
        this.setEmailMessageBody = this.setEmailMessageBody.bind(this);
        this.getSalesOrderAttachmentID = this.getSalesOrderAttachmentID.bind(this);
    }

    componentDidMount() {
        let currentUser = this.userService.getLoggedInUser();
        let { ordNum } = this.props;
        this.setState({ currentUser });
        if (ordNum) {
            this.getDebtorInvoice(ordNum, "");

            this.getSalesOrderAttachmentID(ordNum);
        }
    }

    getDebtorInvoice(ordNum, navigation) {
        this.setState({ordNum, isLoadingOrder: true});
        this.orderService.getOrder(ordNum, true, true, true, navigation).then(response => {
            let order = response.data;
            if (order) {
                let salesOrder = order.salesOrder;
                if (salesOrder) {
                    if (salesOrder.attachmentIDs) {
                        let attachmentIDs = salesOrder.attachmentIDs;
                        salesOrder.attachmentIDs = [];
                        salesOrder.attachmentIDs.push(attachmentIDs);
                    }
                    else if (!salesOrder.attachmentIDs) {
                        salesOrder.attachmentIDs = [];
                    }


                    if (salesOrder.salesOrderItems && salesOrder.salesOrderItems.length > 0) {
                        salesOrder.salesOrderItems.forEach(soi => {
                            if (soi.attachmentIDs) {
                                salesOrder.attachmentIDs.push(soi.attachmentIDs);
                            }
                        });
                    }

                    if (salesOrder.attachmentIDs && salesOrder.attachmentIDs.length > 0) {
                        salesOrder.attachmentIDs = salesOrder.attachmentIDs.join();
                    }
                    order.createdFrom = salesOrder.createdFrom;
                }
                //Default open Product Table
                if (order.products) {
                    if (order.products[0]) {
                        order.products[0].isOpen = true;
                    }
                }
                this.setState({
                    order: order,
                    isLoadingOrder: false,
                    salesOrder: salesOrder,
                    ordNum: order.ordNum
                }, () => {
                    this.updateUrl(order.ordNum);
                });
            } else {
                this.setState({isLoadingOrder: false});
            }
        }).catch(error => {
            console.error(error);
            this.setState({isLoadingOrder: false});
            toast.error(handleErrorMessage(error));
        });
    }


    setEmailMessageBody(attachmentID) {
        let { documentType, ordNum} = this.props;
        let { currentUser,emailMessage, order } = this.state;
        let fullName = currentUser.firstName;
        if (currentUser.lastName) {
            fullName = fullName + " " + currentUser.lastName;
        }
        emailMessage = DebtorEmailContent(documentType, order);
        emailMessage.senderName = fullName;
        //emailMessage.body = emailMessage.body.replace('@FullName', fullName);
        emailMessage.subject = emailMessage.subject.replace('@OrdNum', ordNum);
        emailMessage.attachmentIDs = attachmentID;
        let toEmail = order.isRetail ? order.retailEmail : order.email;
        let fromEmail = order.isRetail ? order.email : "keyway@windoware.com.au";

        emailMessage.to = [{ label: toEmail, value: toEmail }];
        emailMessage.toOptions = [{ label: toEmail, value: toEmail }];
        emailMessage.from = [{ label: fromEmail, value: fromEmail }];
        emailMessage.fromOptions = [{ label: fromEmail, value: fromEmail }];
        emailMessage.isLoadingEmailSend = false;
        emailMessage.id = ordNum;
        this.setState({ emailMessage });
    }

    sendEmailModal(emailMessage) {
        let { ordNum } = this.props;
        emailMessage.isLoadingEmailSend = true;
        this.setState({ emailMessage });
        if (emailMessage) {
            emailMessage.toEmails = getDistinctValues(emailMessage.to ? emailMessage.to : [], 'value');
            emailMessage.fromEmail = emailMessage.from[0].value;
            emailMessage.ccs = getDistinctValues(emailMessage.cc ? emailMessage.cc : [], 'value');
            emailMessage.bccs = getDistinctValues(emailMessage.bcc ? emailMessage.bcc : [], 'value');
            emailMessage.body = emailMessage.body.replaceAll('\n', '<br/>');
            this.orderService.shareDebtorInvoiceOnEmail(ordNum, emailMessage).then(response => {
                emailMessage.isLoadingEmailSend = false;
                this.setState({ emailMessage });
                toast.success("Email Send!");
                this.props.toggle(false);
            }).catch(error => {
                emailMessage.isLoadingEmailSend = false;
                this.setState({ emailMessage });
                toast.error(handleErrorMessage(error), { position: toast.POSITION.BOTTOM_CENTER });
            });
        }
    }

    render() {
        let { isOpen, toggle } = this.props;
        let { emailMessage, isLoading } = this.state;
        return (
                    <ComposeEmail
                        isOpen={isOpen}
                        isSendingEmail={emailMessage.isLoadingEmailSend}
                        toggle={toggle}
                        message={emailMessage}
                        handleSubmit={this.sendEmailModal}
                        parentType={"Share"}
                        isLoadingData={isLoading}
                    />
        );
    }

}