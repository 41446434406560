import React, { Component } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Label, Spinner } from 'reactstrap';
import CustomerService from '../../services/CustomerService';
import { toast, ToastContainer } from 'react-toastify';
import { handleErrorMessage } from '../../services/CommonService';

export default class DebtorCreditTermsAndLimit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            creditterm: 0,
            creditlimit: 0,
            creditTerms: [],
            isLoadingSave: false,
            debtor: props.debtor
        };

        this.customerService = new CustomerService();
        this.getCreditTerms = this.getCreditTerms.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
        this.setState({ creditterm: this.props.creditTermsID, creditlimit: this.props.creditLimit });
    }

    refresh() {
        this.getCreditTerms();
    }

    getCreditTerms() {
        this.customerService.getDebtorCreditTerms().then(response => {
            if (response.status === 200 || response.status === "200") {
                let creditTerms = response.data;
                this.setState({ creditTerms });
            }
        }).catch(error => {
            toast.error(handleErrorMessage(error));
        });
    }


    handleChange(key, value) {
        let { debtor } = this.state;
        debtor[key] = value;
        this.setState({ debtor });
    }

    handleSubmit(e) {
        e.preventDefault();
        let req = { ...this.state.debtor };
            this.setState({ isLoadingSave: true });
            this.customerService.updateDebtor(req).then(response => {
                if (response.status === 200 || response.status === "200") {
                    toast.success("Customer updated!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.props.toggleModal(false);
                    this.props.refresh();
                    this.setState({ isLoadingSave: false });
                }
            }).catch(error => {
                this.setState({ isLoadingSave: false });
                toast.error(handleErrorMessage(error));
            });
    }

    render() {
        let { isLoadingSave, creditTerms, debtor } = this.state;
        let { isOpen, toggleModal } = this.props;
        return (
            <Modal isOpen={isOpen} size="md" scrollable={false} toggle={() => toggleModal(!isOpen)}>
                <ModalHeader toggle={() => toggleModal(!isOpen)}>
                    Credit Status Edit
                </ModalHeader>
                <ModalBody>
                            <div>
                                <Label className="col-form-label font-weight-bold">Credit Limit</Label>
                                <Input className="form-control"
                                    type="Number"
                                    name="creditLimit"
                                    value={debtor.creditLimit}
                                    onChange={(e) => this.handleChange("creditLimit", e.target.value)}
                            placeholder="Enter Credit Limit here..." />
                            </div>
                    <div>
                        <Label className="col-form-label font-weight-bold">Credit Terms</Label>
                                
                                <Input type="select" name="courier"
                            value={debtor.creditTermsID} onChange={(e) => this.handleChange("creditTermsID", e.target.value)}>
                                    <option value={-1}>Select Credit Terms</option>
                                    {(creditTerms || []).map((creditTerm, index) =>
                                        <option key={index} value={creditTerm.creditTermsID}> {creditTerm.description}</option>
                                    )}
                        </Input>
                            </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button size={"sm"}
                            color='primary'
                            onClick={this.handleSubmit}
                            disabled={isLoadingSave}>
                            {
                                isLoadingSave
                                    ? <Spinner size="sm" className="mr-2" color={"light"} />
                                    : <i className="fa fa-floppy-o mr-2" aria-hidden="true" />
                            }
                            {
                                isLoadingSave
                                    ? "Saving"
                                    : "Save"
                            }
                        </Button>
                        <Button size={"sm"}
                            color={"secondary"}
                            className={"ml-2"}
                            onClick={() => toggleModal(!isOpen)}>
                            <i className="fa fa-times mr-2" aria-hidden="true" />Cancel
                        </Button>
                    </div>
                </ModalFooter>
                <ToastContainer />
            </Modal>
        )
    }
}