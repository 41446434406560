import React, { Component } from 'react';
import invitationService from "../../services/InvitationService";
import { handleErrorMessage } from "../../services/CommonService";
import UserService from "../../services/UserService";
import { EventBus } from "../../components/events/event";
import { Link, Redirect } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Row,
    Spinner
} from "reactstrap";
import { toast, ToastContainer } from 'react-toastify';

export default class CustomerInvite extends Component {
    constructor(props) {
        super(props);

        const params = new URLSearchParams(this.props.location.search);
        const email = params.get('email');
        const mode = params.get('mode');

        this.state = {
            email: email,
            signupMode: mode,
            token: '',
            password: '',
            confirmPassword: '',
            error: '',

            isLoadingSendToken: false,
            isSubmittedTokenSent: false,
            isTokenSentSuccessfully: false,


            isLoadingVerifyToken: false,
            isSubmittedVerifyToken: false,
            isVerifiedSuccessfully: false,

            loading: false,
        };

        this.userService = new UserService();
        this.handleChange = this.handleChange.bind(this);
        this.sendTokenEmail = this.sendTokenEmail.bind(this);
        this.verifyToken = this.verifyToken.bind(this);
        this.createUser = this.createUser.bind(this);
    }

    componentDidMount() {
        /* Sending code on page loading disabled on request of Tui
        if (this.state.email) {
            this.sendTokenEmail(this.state.email);
        }*/
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    sendTokenEmail(email) {
        if (!email) {
            this.setState({ isSubmittedTokenSent: true });
            toast.error("Email required!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }
        this.setState({ isLoadingSendToken: true, isTokenSentSuccessfully: false });
        invitationService.sendTokenEmail(email).then(res => {
            toast.success("Security code has been sent to your email.");
            this.setState({ isLoadingSendToken: false, isTokenSentSuccessfully: true, isSubmittedTokenSent: true });
        }).catch(error => {
            this.setState({ isLoadingSendToken: false, isTokenSentSuccessfully: false, isSubmittedTokenSent: true });
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
    }

    verifyToken(token) {
        if (!token) {
            toast.error("Security code required!", {
                position: toast.POSITION.TOP_RIGHT
            });
            this.setState({ isSubmittedVerifyToken: true });
            return;
        }
        let requestBody = {
            Token: token,
            EmailAddress: this.state.email
        };
        this.setState({ isLoadingVerifyToken: true });
        invitationService.verifyToken(requestBody).then(response => {
            this.setState({ isVerifiedSuccessfully: true, isLoadingVerifyToken: false, isSubmittedVerifyToken: true });
        }).catch(error => {
            this.setState({ isVerifiedSuccessfully: false, isLoadingVerifyToken: false, isSubmittedVerifyToken: true });
            console.log(error);
            toast.error(handleErrorMessage(error), {
                position: toast.POSITION.TOP_RIGHT
            });
        });
    }

    validateCreateUserRequest(email, password, confirmPassword, token, signupMode) {
        let result = {
            errors: [],
            isValid: true
        };
        if (!email) {
            result.isValid = false;
            result.errors.push({
                param: "email",
                message: "Empty email!"
            });
        }
        if (!token) {
            result.isValid = false;
            result.errors.push({
                param: "token",
                message: "Empty security code!"
            });
        }
        if (!password) {
            result.isValid = false;
            result.errors.push({
                param: "password",
                message: "Empty password!"
            });
        }
        if (!confirmPassword) {
            result.isValid = false;
            result.errors.push({
                param: "confirmPassword",
                message: "Empty confirmPassword!"
            });
        }
        if (password !== confirmPassword) {
            result.isValid = false;
            result.errors.push({
                param: "confirmPassword",
                message: "Entered passwords do not match!"
            });
        }

        if (!signupMode) {
            result.isValid = false;
            result.errors.push({
                param: "signupMode",
                message: "Empty signup mode!"
            });
        }
        return result;
    }

    createUser(event) {
        event.preventDefault();
        let { email, password, confirmPassword, token, signupMode } = this.state;
        let validationResult = this.validateCreateUserRequest(email, password, confirmPassword, token, signupMode);

        if (!validationResult.isValid) {
            validationResult.errors.forEach(error => {
                toast.info(error.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
            return;
        }
        let requestBody = {
            Email: email,
            Password: password,
            token: token,
            SignupMode: signupMode
        };

        this.setState({ loading: true });
        invitationService.createUserUponVerification(requestBody).then(res => {
            this.setState({ loading: false });
            this.userService.login(this.state.email, password).then(response => {
                const { from } = this.props.location.state || { from: { pathname: "/" } };
                this.props.history.push(from);
                EventBus.publish('login-logout', {})
            }, error => {
                console.log(error);
                toast.info(handleErrorMessage(error), {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            )
        }).catch(error => {
            toast.info
                (handleErrorMessage(error), {
                position: toast.POSITION.TOP_RIGHT
            });
            this.setState({ loading: false });
        });
    }

    render() {
        if (UserService.isLoggedIn())
            return <Redirect to={{ pathname: '/' }} />;
        const { email, token, password, isLoadingSendToken, isLoadingVerifyToken, loading, isVerifiedSuccessfully, isTokenSentSuccessfully, isSubmittedTokenSent, isSubmittedVerifyToken, confirmPassword } = this.state;
        return (
            <div style={{ marginTop: "30px" }}>
                <Row>
                    <Col xl={3} lg={3} md={2} sm={0} xs={0} />
                    <Col xl={6} lg={6} md={8} sm={12} xs={12}>

                        <div className="text-center o-visible">
                            <Row style={{ "justify-content": "space-between" }}>
                                <Col xl={2} lg={2} md={2} sm={12} xs={12} className={"text-left"}>
                                    <img
                                        style={{ maxWidth: 200 }}
                                        alt=""
                                        src="https://winonline.blob.core.windows.net/winonline/company_logo/Windoware.png" />
                                </Col>
                                <Col xl={4} lg={4} md={4} sm={12} xs={12} className="text-center mr-4">
                                    <img
                                        style={{ maxWidth: 200 }}
                                        alt=""
                                        src="https://winonline.blob.core.windows.net/winonline/company_logo/SMARTHP.png" />
                                </Col>
                                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                    <img
                                        style={{ maxWidth: 200 }}
                                        alt=""
                                        src="https://winonline.blob.core.windows.net/winonline/company_logo/Bistro.png" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row className={"align-items-center"}>
                    <Col xl={3} lg={3} md={2} sm={0} xs={0} />
                    <Col xl={6} lg={6} md={8} sm={12} xs={12}>
                        <hr />
                        <Card style={{ borderRadius: ".50rem" }} className={"ds-9"}>
                            <CardBody>
                                <Container>
                                    <h4 className={"mb-2"}>Sign Up</h4>
                                    <form name="form" onSubmit={this.handleSubmit} autoComplete="off">
                                        <div>
                                            <Label htmlFor="email">
                                                Email
                                                <small>(to receive security code)</small>
                                            </Label>
                                            <InputGroup>
                                                <Input type="email"
                                                    placeholder={"Enter email"}
                                                    valid={isTokenSentSuccessfully}
                                                    invalid={isSubmittedTokenSent && (!email || !isTokenSentSuccessfully)}
                                                    className="form-control"
                                                    name="email" value={email}
                                                    onChange={this.handleChange}
                                                    autoComplete="off" />
                                                <InputGroupAddon addonType="append">
                                                    <Button color="primary"
                                                        onClick={() => this.sendTokenEmail(email)}>
                                                        {
                                                            isLoadingSendToken
                                                                ? <Spinner size={"sm"} color={"light"}
                                                                    className={"mr-2"} />
                                                                : <i className="fa fa-envelope-o mr-2"
                                                                    aria-hidden="true" />
                                                        }
                                                        {
                                                            isLoadingSendToken
                                                                ? "Sending security code"
                                                                : "Send security code"
                                                        }
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>

                                        {isTokenSentSuccessfully
                                            ? <div className={"mt-3"}>
                                                <Label htmlFor="token">Security code</Label>
                                                <InputGroup>
                                                    <Input type="token"
                                                        placeholder={"Enter security code"}
                                                        valid={isVerifiedSuccessfully}
                                                        invalid={isSubmittedVerifyToken && (!token || !isVerifiedSuccessfully)}
                                                        className="form-control" name="token" value={token}
                                                        onChange={this.handleChange} autoComplete="off" />
                                                    <InputGroupAddon addonType="append">
                                                        <Button color="primary"
                                                            onClick={() => this.verifyToken(token)}>
                                                            {
                                                                isLoadingVerifyToken
                                                                    ? <Spinner size={"sm"} color={"light"}
                                                                        className={"mr-2"} />
                                                                    : <i className="fa fa-shield mr-2"
                                                                        aria-hidden="true" />
                                                            }
                                                            {
                                                                isLoadingVerifyToken
                                                                    ? "Verifying security code"
                                                                    : "Verify security code"
                                                            }
                                                        </Button>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                            : null
                                        }


                                        {
                                            isVerifiedSuccessfully ?
                                                <div>
                                                    <div className={"mt-3"}>
                                                        <Label htmlFor="password">Create Password</Label>
                                                        <Input type="password"
                                                            placeholder={"Enter password"}
                                                            className="form-control"
                                                            name="password"
                                                            value={password}
                                                            onChange={this.handleChange}
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                    <div className={"mt-3"}>
                                                        <Label htmlFor="password">Confirm Password</Label>
                                                        <Input type="password"
                                                            placeholder={"Re-enter password"}
                                                            className="form-control"
                                                            name="confirmPassword"
                                                            value={confirmPassword}
                                                            onChange={this.handleChange}
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                    <div className={"mt-3"}>
                                                        <button className="btn btn-primary btn-block"
                                                            onClick={this.createUser}
                                                            disabled={loading}>
                                                            {
                                                                loading
                                                                    ? "Signing up..."
                                                                    : "Sign up"
                                                            }
                                                            {
                                                                loading
                                                                    ? <Spinner className={"ml-2"} size={"sm"} />
                                                                    : <i className="fa fa-sign-in ml-2"
                                                                        aria-hidden="true" />
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </form>
                                    <hr />
                                    <div className={"text-center"}>
                                        <p className={"mb-0"}>
                                            Already register?
                                            <Link to="/login" className={"ml-2"}>Sign in instead</Link>
                                        </p>
                                    </div>
                                </Container>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ToastContainer />
            </div>
        );
    }
}



