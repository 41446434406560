import React, { Component } from 'react';
import UserService from "../../services/UserService";
import { Col, Card, CardHeader, CardBody, Table } from "reactstrap";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

export default class SallyUsersCard extends Component {

    constructor(props) {
        super(props);
        this.userService = new UserService();
        this.state = { loading: true }
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.userService.getSallyUser().then(response => {
            this.setState({ loading: false, stats: response.data })
        })
    }


    render() {

        const { stats, loading } = this.state;

        if (loading) {
            return null;
        }

        return (
            <Col xl={4} lg={5} md={6} sm={12} xs={12} className={"p-2"}>
                <Card>
                    <CardHeader>
                        <h6 className={"mb-0"}>Sally Users
                            <span style={{ float: "right" }}>
                                <Link to="/users">
                                    <i className={"fa fa-pencil"} />
                                </Link>
                            </span></h6>
                    </CardHeader>
                    <CardBody>
                        <Table size={"sm"} className={"mb-0"}>
                            <tbody>
                                <tr>
                                    <td>Staff</td>
                                    <td className={"text-right"}><strong><NumberFormat value={stats.staff}
                                        displayType={'text'}
                                        thousandSeparator={true} /></strong></td>
                                </tr>
                                <tr>
                                    <td>Bunnings Stores</td>
                                    <td className={"text-right"}><strong><NumberFormat value={stats.bunningsStores}
                                        displayType={'text'}
                                        thousandSeparator={true} /></strong></td>
                                </tr>
                                <tr>
                                    <td>New signups in last month</td>
                                    <td className={"text-right"}><strong><NumberFormat value={stats.totalLastMonthSignups}
                                        displayType={'text'}
                                        thousandSeparator={true} /></strong></td>
                                </tr>
                                <tr>
                                    <td>Bunnings Stores using CTS PB</td>
                                    <td className={"text-right"}><strong><NumberFormat value={stats.bunningsStoresUsingPB}
                                        displayType={'text'}
                                        thousandSeparator={true} /></strong></td>
                                </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}