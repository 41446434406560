import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import {Redirect} from "react-router-dom";
import {changeFormatOfDateString} from '../../services/CommonService';
import {isEmpty} from 'lodash';
import OrderEnquiryOrderItem from "./OrderEnquiryOrderItem";
import '../../assets/css/app/Print.css';

export default class OrderEnquiryReadOnlyMode extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        let {
            order,
            toggleExpandRow, toggleAllRowExpanded, visibleColumnsInOrderItems, currentUser

        } = this.props;

        return (
            <div>
                <div>
                    <div>
                        <Row>
                            <Col>
                                <h5>Order #{order.ordNum}</h5>
                                <div className="mb-1">
                                    <strong>Customer Account ID</strong>:<span className="ml-2">{order.accountID}</span>
                                </div>
                                <div className="mb-1">
                                    <strong>{order.delCompany}</strong>
                                </div>
                            </Col>
                            <Col>
                                <div className="text-right mt-4">
                                    <div className="mb-1">
                                        <strong>Order
                                            Date</strong>:<span
                                        className="ml-2">{changeFormatOfDateString(order.ordDate, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}</span>
                                    </div>
                                    <div>
                                        <strong>Require
                                            Date</strong>:<span
                                        className="ml-2">{changeFormatOfDateString(order.required, "DD/MM/YYYY HH:mm:ss", 'ddd, Do MMM YYYY')}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <OrderEnquiryOrderItem
                            toggleExpandRow={toggleExpandRow}
                            toggleAllRowExpanded={toggleAllRowExpanded}
                            order={order}
                            currentUser={currentUser}
                            visibleColumnsInOrderItems={visibleColumnsInOrderItems}
                        />
                    </div>
                </div>
            </div>
        );

    }
}
