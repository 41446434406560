import axios from "./axios";

class ApiLogService {

    static Instance() {
        return new ApiLogService();
    }


    getLogs(request) {
        return axios.post('api/log/list', request);
    }
    getLog(caseID) {
        return axios.get('api/log/one?caseID=' + caseID);
    }

    getStatusList() {
        return axios.get('api/log/status/list');
    }

    updateApiLogStatus(request) {
        return axios.post('api/log/update/status', request);
    }

    updateApiLogComment(request) {
        return axios.post('api/log/update/comment', request);
    }
}
export default ApiLogService.Instance()