import React, {Component} from 'react';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
    Row,
    Col,
    FormText,
    FormGroup,
    Label
} from 'reactstrap';
import CustomerService from '../../services/CustomerService';
import {toast, ToastContainer} from 'react-toastify';
import {handleErrorMessage} from '../../services/CommonService';

export default class CustomerCreditTradingTermModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accountID: props.accountID,
            debtor: props.debtor,
            saving: false
        };

        this.customerService = new CustomerService();
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.accountID !== this.state.accountID) {
            this.setState({accountID: nextProps.accountID});
        }
    }


    handleChange(value, key) {
        let {debtor} = this.state;
        debtor[key] = value;
        this.setState({debtor});
    }

    handleSubmit() {
        let req = {...this.state.debtor};
        this.setState({saving: true});
        this.customerService.updateDebtor(req).then(response => {
            if (response.status === 200 || response.status === "200") {
                toast.success("Customer updated!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.props.refresh();
                this.setState({saving: false});
            }

        }).catch(error => {
            this.setState({saving: false});
            toast.error(handleErrorMessage(error));
        });
    }


    render() {
        let {saving} = this.state;
        let {isOpen, toggleModal, debtor, editDetailsType} = this.props;
        return (
            <Modal isOpen={isOpen}
                   size="md"
                   scrollable={false}
                   toggle={() => toggleModal(!isOpen)}>
                <ModalHeader toggle={() => toggleModal(!isOpen)}>
                    Edit {editDetailsType === "tradingTerms" ? "Trading Terms" : "Special Requirements"}
                </ModalHeader>
                <ModalBody>
                    <div>
                        <FormGroup>
                            <Label>{editDetailsType === "tradingTerms" ? "Trading Terms" : "Special Requirements"}</Label>
                            {
                                editDetailsType === "tradingTerms"
                                    ?
                                    <Input
                                        name={"tradingTerms"}
                                        type="textarea"
                                        rows={"4"}
                                        cols={"5"}
                                        value={debtor.tradingTerms}
                                        placeholder={"Enter Trading Terms"}
                                        onChange={(e) => this.handleChange(e.target.value, "tradingTerms")}/>
                                    :
                                    <Input
                                        name={"specialReq"}
                                        type="textarea"
                                        rows={"4"}
                                        cols={"5"}
                                        value={debtor.specialReq}
                                        placeholder={"Enter Special Requirements"}
                                        onChange={(e) => this.handleChange(e.target.value, "specialReq")}/>
                            }
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className={"text-right"}>
                        <Button size={"sm"}
                                color='primary'
                                disabled={saving}
                                onClick={this.handleSubmit}>
                            {saving
                                ? <Spinner size="sm"
                                           className={"mr-2"}
                                           style={{color: "white"}}/>
                                : <i className="fa fa-floppy-o mr-2"/>}
                            {
                                saving ? "Saving" : "Save"
                            }
                        </Button>
                        <Button size={"sm"}
                                color={"secondary"}
                                className={"ml-2"}
                                onClick={() => toggleModal(!isOpen)}>
                            <i className="fa fa-times mr-2" aria-hidden="true"/>Cancel
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}